import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import MenuItem from '../../../components/Select/MenuItem';
import { BEARING_TYPES } from '../../../constants/applicationScreen';
import { changeFieldValueToNumber } from '../../../helpers/changeFieldValueToNumber';
import { stringOrNumber } from '../../../helpers/clientDataPropTypes';

const SizeLabel = styled.p`
  margin: 30px 0 -10px;
  font-size: 16px;
`;

const Bearing = ({ values, setFieldValue, errors, disabled }) => {
  const { t } = useTranslation();

  const { type, innerSize, outerSize, maxRpm } = values;

  return (
    <>
      <Select
        id="bearingType"
        data-testid="bearingType"
        label={t('app_bearing_type')}
        fullWidth
        value={type}
        onChange={(type) => {
          setFieldValue('bearing.type', type);
        }}
        disabled={disabled}
      >
        {BEARING_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <SizeLabel>{t('app_bearing_size')}</SizeLabel>
      <Input
        id="innerSize"
        label={t('app_bearing_size_inner')}
        onchange={(e) => {
          setFieldValue('bearing.innerSize', changeFieldValueToNumber(e));
        }}
        value={innerSize}
        fullWidth
        rightText={t('app_size_mm')}
        type="number"
        errorMessage={errors && errors.innerSize}
        disabled={disabled}
      />
      <Input
        id="outerSize"
        label={t('app_bearing_size_outer')}
        onchange={(e) => {
          setFieldValue('bearing.outerSize', changeFieldValueToNumber(e));
        }}
        value={outerSize}
        fullWidth
        rightText={t('app_size_mm')}
        type="number"
        errorMessage={errors && errors.outerSize}
        disabled={disabled}
      />
      <Input
        id="maxRpm"
        label={t('app_bearing_max_rpm')}
        onchange={(e) => {
          setFieldValue('bearing.maxRpm', changeFieldValueToNumber(e));
        }}
        value={maxRpm}
        fullWidth
        rightText={t('app_rpm')}
        type="number"
        errorMessage={errors && errors.maxRpm}
        disabled={disabled}
      />
    </>
  );
};

Bearing.propTypes = {
  values: PropTypes.shape({
    type: PropTypes.string,
    innerSize: stringOrNumber,
    outerSize: stringOrNumber,
    maxRpm: stringOrNumber,
  }),
  setFieldValue: PropTypes.func,
  errors: PropTypes.shape({
    type: PropTypes.string,
    innerSize: PropTypes.string,
    outerSize: PropTypes.string,
    maxRpm: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default Bearing;
