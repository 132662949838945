import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { secToMins } from 'frontend-components/lib/helpers';

const MAX_VIDEO_TIME = 45; // in sec
const MAX_VIDEO_TIME_BUFFER = 5; // in sec

const blinkAnimation = keyframes`
 0% { background: #ffffff; color: #e42313; }
 50% { background: #e42313; color: #ffffff; }
`;

const StyledTimer = styled.div`
  background: #ffffff;
  color: #e42313;
  animation-name: ${({ alert }) => (alert ? blinkAnimation : 'none')};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  padding: 5px;
  font-weight: bold;
`;

const Timer = ({ onMaxTime }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => setTimer(timer + 1), 1000);

    if (timer >= MAX_VIDEO_TIME) {
      onMaxTime();
    }

    return () => clearInterval(interval);
  }, [timer, onMaxTime]);

  return (
    <StyledTimer alert={timer + MAX_VIDEO_TIME_BUFFER >= MAX_VIDEO_TIME}>
      {secToMins(timer)}
    </StyledTimer>
  );
};

Timer.propTypes = {
  onMaxTime: PropTypes.func,
};

export default Timer;
