import styled from 'styled-components';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const StyledListItemSecondaryAction = styled(MuiListItemSecondaryAction)`
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

export default StyledListItemSecondaryAction;
