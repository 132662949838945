import styled from 'styled-components';
import ListItem from '../../components/List/ListItem';

export const TabContainer = styled.div`
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  padding-top: 1px;

  .MuiListItem-root {
    justify-content: space-between;
    padding-top: 10px;
  }

  .MuiListItemText-root {
    flex: initial;
    width: 100px;
  }
`;

export const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`;

export const ExpirationText = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 4px;
  color: #000;
`;

export const SideItems = styled.div`
  margin: 4px 0;
  display: flex;
  width: 35%;
  justify-content: space-between;
  align-items: center;
`;

export const QuotationListItem = styled(ListItem)`
  align-items: stretch;
`;

export const OrderListItem = styled(ListItem)`
  align-items: stretch;
`;

export const ItemsSize = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #e5e5e5;
  color: #666666;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
