import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useCheckLocationState = (locationState) => {
  const location = useLocation();
  const [isLocationWithState, setIsLocationWithState] = useState(false);

  useEffect(() => {
    if (location.state && location.state.from === locationState) {
      setIsLocationWithState(true);
    } else {
      setIsLocationWithState(false);
    }
  }, [location, locationState]);

  return isLocationWithState;
};

export default useCheckLocationState;
