import styled from 'styled-components';
import MuiListItem from '@material-ui/core/ListItem';

const StyledListItem = styled(MuiListItem)`
  padding: 20px 0 10px 0;
  align-items: baseline;

  &.MuiListItem-secondaryAction {
    padding: ${({ $withRightIcon }) =>
      $withRightIcon ? '20px 110px 20px 0' : '20px 56px 20px 0'};
    min-height: 96px;

    .MuiListItemText-primary {
      white-space: ${({ $withMultilineText }) =>
        $withMultilineText ? 'initial' : 'nowrap'};
      text-overflow: ${({ $withMultilineText }) =>
        $withMultilineText ? 'clip' : 'ellipsis'};
      overflow: ${({ $withMultilineText }) =>
        $withMultilineText ? 'visible' : 'hidden'};
    }
  }

  .MuiDialog-paper &.MuiListItem-secondaryAction {
    padding: ${({ $withOneRightIcon }) =>
      $withOneRightIcon ? '10px 56px 10px 0' : '10px 0'};
  }
`;

export default StyledListItem;
