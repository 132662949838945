import SvgIcon from '@material-ui/core/SvgIcon';

function NextActionsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 9V5l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default NextActionsIcon;
