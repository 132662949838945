import { createAction } from '@reduxjs/toolkit';
import { fetchClientDataById } from './clientDataActions';

export const postQuotation = (quotationId, quotationData, clientId) => ({
  type: 'quotation/post',
  payload: quotationData,
  meta: {
    quotationId,
    clientId,
    offline: {
      effect: {
        url: `/qac/quotation/${quotationId}`,
        method: 'POST',
        data: quotationData,
      },
      commit: {
        type: 'quotation/post/commit',
        meta: {
          quotationId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'quotation/post/rollback', meta: { quotationId } },
    },
  },
});

export const updateQuotationHistory = createAction(
  'clientData/updateQuotationHistory',
  function prepare(clientId, values) {
    return {
      payload: { clientId, values },
    };
  }
);

export const convertQuotation = (quotationId, quotationData, clientId) => ({
  type: 'quotation/convert/post',
  payload: quotationData,
  meta: {
    quotationId,
    clientId,
    offline: {
      effect: {
        url: `/qac/quotation/convert/${quotationId}`,
        method: 'POST',
        data: quotationData,
      },
      commit: {
        type: 'quotation/convert/post/commit',
        meta: {
          quotationId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: {
        type: 'quotation/convert/post/rollback',
        meta: { quotationId },
      },
    },
  },
});
