import styled from 'styled-components';

const FullCalendarTheme = styled.div`
  .fc {
    width: 100%;
    margin: auto;
  }

  .fc-scrollgrid {
    border: none;
  }

  .fc-toolbar.fc-header-toolbar {
    margin: 0;
  }

  .fc-dayGridMonth-view .fc-daygrid-day,
  .fc-dayGridMonth-view .fc-daygrid-day-top {
    justify-content: center;
  }

  .fc-dayGridMonth-view .fc-col-header-cell {
    color: #666666;
    font-size: 12px;
    padding: 8px;
    font-weight: 500;
  }

  .fc-timeGridWeek-view .fc-col-header-cell.fc-day.fc-day-today {
    font-weight: 700;
  }

  .fc-dayGridMonth-view .fc-day-today {
    background: none;
    font-weight: 700;
  }

  .fc-dayGridMonth-view td,
  .fc-dayGridMonth-view th {
    // border: none;
  }

  .fc-dayGridMonth-view tr {
    border-bottom: 1px solid #d8d8d8;
  }

  .fc-dayGridMonth-view .fc-daygrid-day {
    background: #fafafa;
  }

  .fc-dayGridMonth-view .fc-daygrid-day:nth-child(2n) {
    background: #f2f2f2;
  }

  .fc-dayGridMonth-view .mia-event,
  .fc-dayGridMonth-view .mia-event:hover {
    height: 8px;
    border-radius: 2px;
    background-color: #e42313;
  }

  .fc-dayGridMonth-view .mia-event-current,
  .fc-dayGridMonth-view .mia-event-currentt:hover {
    background-color: #888;
  }

  .fc-dayGridMonth-view .mia-event * {
    display: none;
  }

  .fc-timeGridWeek-view tr td,
  .fc-timeGridWeek-view tr th {
    border-left: 0;
    border-right: 0;
    height: 32px;
  }

  .fc-timeGridWeek-view .fc-timegrid-slot {
    border-right-color: transparent;
  }

  .fc-timeGridWeek-view .fc-timegrid-col.fc-day-today {
    background-color: initial;
  }

  .fc-timeGridWeek-view .fc-timegrid-col:nth-child(2n - 1):not(:first-child) {
    background-color: #f2f2f2;
  }

  .fc-timeGridWeek-view .fc-timegrid-col:nth-child(2n) {
    background-color: #fafafa;
  }

  .fc-timeGridWeek-view .fc-col-header-cell.fc-day {
    padding-bottom: 12px;
    color: #666666;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .fc-timeGridWeek-view .mia-event,
  .fc-timeGridWeek-view .mia-event:hover {
    border-radius: 2px;
    background-color: #e42313;
    border-color: #e42313;
  }

  .fc-timeGridWeek-view .mia-event-current,
  .fc-timeGridWeek-view .mia-event-current:hover {
    background: repeating-linear-gradient(
      -45deg,
      #e5e5e5,
      #e5e5e5 5px,
      #d8d8d8 5px,
      #d8d8d8 10px
    );
    border-color: #e5e5e5;
    color: #000;

    .fc-event-main {
      color: #000;
    }
  }

  .fc-timeGridWeek-view .mia-event .fc-event-time {
    display: none;
  }

  .fc-timeGridWeek-view .mia-event .fc-event-title-container {
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default FullCalendarTheme;
