import styled from 'styled-components';

export const TextAreaContainer = styled.div`
  margin: 24px 0;

  label.disabled {
    color: #666666;
  }

  textarea[disabled] {
    background: #f2f2f2;
    color: #666666;
    font-size: 14px;
    border: 1px solid #999999;
    -webkit-text-fill-color: #666;
    opacity: 1;
    border-radius: 0;
    -webkit-appearance: none;
  }
`;

export const DetailField = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    color: #999999;
  }
`;

export const DetailLabel = styled.label`
  font-size: 12px;
  color: #666666;
  display: block;
  margin-bottom: 4px;
  line-height: 16px;
`;

export const DetailValue = styled.div`
  font-size: 14px;
  color: #000;
  line-height: 20px;
  min-height: 20px;

  + div {
    margin-top: 10px;
  }
`;
