export const COMPRESSION_TYPE = 'jpeg';
export const VIDEO = 'video';
export const PHOTO = 'photo';

export const MAXIMUM_IMAGE_SIZE = 31 * 1024 * 1024; // 31MB
export const MAXIMUM_VIDEO_SIZE = 200 * 1024 * 1024; // 200MB
export const MAXIMUM_FILES_COUNT = 50;
export const MAXIMUM_FILES_COUNT_AT_ONCE = 10;
export const MAXIMUM_FILES_COUNT_DCA = 36;
export const MAXIMUM_FILES_COUNT_AT_ONCE_DCA = 30;

export const SUPPORTED_FILE_TYPES = {
  'image/bmp': 'bmp',
  'image/gif': 'gif',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'video/webm;codecs=vp8': 'webm',
  'video/webm': 'webm',
  'video/mp4': 'mp4',
  'video/quicktime': 'quicktime',
};
