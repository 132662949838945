import PropTypes from 'prop-types';
import ClassificationA from '../Icons/ClassificationA';
import ClassificationB from '../Icons/ClassificationB';
import ClassificationC from '../Icons/ClassificationC';
import ClassificationStar from '../Icons/ClassificationStar';

export const EMPTY_VALUE = 'empty';

const CODES = new Map([
  ['A', <ClassificationA data-testid="classificationA" />],
  ['B', <ClassificationB data-testid="classificationB" />],
  ['C', <ClassificationC data-testid="classificationC" />],
  ['K', <ClassificationStar data-testid="classificationStar" />],
]);

export const CLASSIFICATION_CODE_OPTIONS = [
  { value: EMPTY_VALUE, label: 'classification_empty' },
  { value: 'A', label: 'classification_A' },
  { value: 'B', label: 'classification_B' },
  { value: 'C', label: 'classification_C' },
  { value: 'K', label: 'classification_K' },
];

const ClassificationCode = ({ code }) => {
  return CODES.has(code) ? CODES.get(code) : null;
};

ClassificationCode.propTypes = {
  code: PropTypes.string,
};

export default ClassificationCode;
