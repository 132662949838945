import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: 16px;
  background: #e5e5e5;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin: 0 -16px;

  label {
    font-size: 12px;
    color: #666666;
    margin-right: 16px;
  }
`;

const Client = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="client-name">
      <label>{t('client_label')}</label>
      {name}
    </Container>
  );
};

Client.propTypes = {
  name: PropTypes.string,
};

export default Client;
