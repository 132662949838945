import styled from 'styled-components';
import PropTypes from 'prop-types';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslation } from 'react-i18next';
import { Divider } from 'frontend-components';
import List from '../List/List';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import GeneralPhoneNumberListItem from '../GeneralPhoneNumberListItem/GeneralPhoneNumberListItem';
import { StyledIconButton, ContactName } from './ContactPeopleList.styles';
import { SHOW_DIALOG_DELAY } from '../../constants/callReportDialog';

const StyledList = styled(List)`
  overflow: hidden;
`;

const ContactsDialogList = ({
  contactsDialogData,
  generalPhoneNumber,
  setShowCreateCallReportDialog,
  setShowContactsDialog,
  setCallReportSelectedContact,
}) => {
  const { t } = useTranslation();
  return (
    <StyledList>
      {generalPhoneNumber && (
        <GeneralPhoneNumberListItem
          generalPhoneNumber={generalPhoneNumber}
          showDivider={contactsDialogData?.length > 0}
          onPhoneClick={() => {
            setShowContactsDialog(false);
            setTimeout(() => {
              setShowCreateCallReportDialog(true);
              setCallReportSelectedContact('');
            }, SHOW_DIALOG_DELAY);
          }}
        />
      )}
      {contactsDialogData &&
        contactsDialogData.map(
          ({ firstName, lastName, phoneNumber, phoneType }, index) => {
            const isLast = index === contactsDialogData.length - 1;
            const phoneTypeLabel = phoneType
              ? ` - ${t(`phone_info_${phoneType}`)}`
              : '';

            return (
              <div key={`${firstName}-${phoneNumber}`}>
                <ListItem button $withOneRightIcon $withMultilineText>
                  <ListItemText
                    primary={
                      <ContactName>{`${firstName} ${lastName} ${phoneTypeLabel}`}</ContactName>
                    }
                    secondary={phoneNumber}
                  />
                  <ListItemSecondaryAction>
                    <StyledIconButton
                      href={`tel:${phoneNumber}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowContactsDialog && setShowContactsDialog(false);
                        setTimeout(() => {
                          setCallReportSelectedContact &&
                            setCallReportSelectedContact(phoneNumber);
                          setShowCreateCallReportDialog &&
                            setShowCreateCallReportDialog(true);
                        }, SHOW_DIALOG_DELAY);
                      }}
                      edge="start"
                      aria-label="phone"
                    >
                      <PhoneIcon />
                    </StyledIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {!isLast && <Divider />}
              </div>
            );
          }
        )}
    </StyledList>
  );
};

ContactsDialogList.propTypes = {
  contactsDialogData: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
  generalPhoneNumber: PropTypes.string,
  setShowCreateCallReportDialog: PropTypes.func,
  setShowContactsDialog: PropTypes.func,
  setCallReportSelectedContact: PropTypes.func,
};

export default ContactsDialogList;
