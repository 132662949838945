import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'frontend-components';

const Container = styled.div`
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0 -16px;
  height: calc(100vh - 56px);
  border-top: 1px solid #d8d8d8;
  justify-content: center;
`;

const Image = styled.img`
  min-height: 168px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000000;
  margin: 40px 0 10px 0;
`;

const SubTitle = styled.p`
  font-size: 14px;
  color: #000000;
  margin: 0 0 24px 0;
`;

const StyledContainer = styled(Container)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  height: calc(100vh - 64px);
`;

const NoClients = ({ newClientAction }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer data-testid="no-customers-view">
      <Image src="/assets/no_customers.svg" />
      <Title>{t('no_client_title')}</Title>
      <SubTitle>{t('no_client_subtitle')}</SubTitle>
      <Button theme="primary" onClick={newClientAction}>
        {t('no_client_button')}
      </Button>
    </StyledContainer>
  );
};

NoClients.propTypes = {
  newClientAction: PropTypes.func,
};

export default NoClients;
