import PropTypes from 'prop-types';
import {
  NoClientsDescription,
  NoPlannedClientsContainer,
  SearchIcon,
} from './SelectCustomerView.styles';

const NoPlannedClients = ({ description = '', search = '' }) => {
  const [searchBefore, searchAfter] = search.split('#searchIcon');
  return (
    <NoPlannedClientsContainer>
      <NoClientsDescription>{description}</NoClientsDescription>
      <NoClientsDescription>
        {searchBefore} <SearchIcon /> {searchAfter}
      </NoClientsDescription>
    </NoPlannedClientsContainer>
  );
};

NoPlannedClients.propTypes = {
  description: PropTypes.string,
  search: PropTypes.string,
};

export default NoPlannedClients;
