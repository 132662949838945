import { LinearProgress } from 'frontend-components';
import Splash from '../components/Splash/Splash';

import { useFetchVisitsIfNeeded } from './customHooks';

const defaultOptions = {
  forceFetch: false,
};

const withClientVisitsData =
  (BaseComponent, { forceFetch } = defaultOptions) =>
  ({ ...props }) => {
    const { data, loadingStatus } = useFetchVisitsIfNeeded(forceFetch);

    if (loadingStatus === 'fetched') {
      return <BaseComponent {...props} clientVisitsData={data} />;
    }

    return (
      <>
        <Splash variant="light" />
        <LinearProgress />
      </>
    );
  };

export default withClientVisitsData;
