import styled from 'styled-components';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';

const StyledTimePicker = styled(KeyboardTimePicker)`
  input {
    font-size: 16px;
  }

  & .MuiFormLabel-root {
    font-size: 16px;
  }

  & .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiButtonBase-root.Mui-disabled .MuiIconButton-label {
    color: rgba(0, 0, 0, 0.26);
  }

  & .Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }
`;

const TimePicker = ({
  label,
  fullWidth,
  ampm = false,
  onChange,
  value,
  margin = 'normal',
  ...rest
}) => {
  const handleTimeChange = (time) => {
    if (onChange) {
      onChange(time);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledTimePicker
        fullWidth={fullWidth}
        margin={margin}
        label={label}
        ampm={ampm}
        value={value}
        onChange={handleTimeChange}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        keyboardIcon={<Icon>query_builder</Icon>}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  ampm: PropTypes.bool,
  margin: PropTypes.string,
  value: PropTypes.number,
};

export default TimePicker;
