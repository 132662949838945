import SvgIcon from '@material-ui/core/SvgIcon';

function DraftIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.215 10L20 12.785 11.785 21H9v-2.785L17.215 10zM20 3a2 2 0 011.995 1.85L22 5v1.5a1 1 0 01-1.993.117L20 6.5V5H5v14h1.5a1 1 0 01.117 1.993L6.5 21H5a2 2 0 01-1.995-1.85L3 19V5a2 2 0 011.85-1.995L5 3h15zm-.754 5.2c.24-.24.611-.264.877-.072l.084.071L21.8 9.793c.239.24.263.61.071.876l-.071.085L20.554 12 18 9.446l1.246-1.247zM11.5 10a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h4zm3-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h7z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default DraftIcon;
