import { createSlice } from '@reduxjs/toolkit';
import { postMessage } from '../actions/helpActions';

const helpSlice = createSlice({
  name: 'help',
  initialState: {
    open: false,
    pending: false,
    sent: false,
    message: '',
  },
  reducers: {
    openHelpDialog: (state) => {
      state.open = true;
      state.sent = false;
      state.pending = false;
    },
    setHelpDialog: (state, payload) => {
      state.message = payload.payload;
    },
    closeHelpDialog: (state) => {
      state.open = false;
      state.sent = false;
      state.pending = false;
      state.message = '';
    },
  },
  extraReducers: {
    [postMessage.pending]: (state) => {
      state.pending = true;
    },
    [postMessage.fulfilled]: (state) => {
      state.pending = false;
      state.sent = true;
      state.message = '';
    },
    [postMessage.rejected]: (state) => {
      state.pending = false;
    },
  },
});

export const {
  openHelpDialog,
  closeHelpDialog,
  setHelpDialog,
  sendHelpDialog,
} = helpSlice.actions;

export default helpSlice.reducer;
