import PropTypes from 'prop-types';
import Big from 'big.js';
import isEmpty from 'lodash/isEmpty';

export const getPackageLabel = (packageCapacity, packageName) =>
  `${packageCapacity} ${packageName}`;

export const packagingRangeProps = {
  price: PropTypes.string,
  from: PropTypes.number,
  to: PropTypes.number,
};

export const packagingVariantProps = {
  packageCapacity: PropTypes.string,
  packageName: PropTypes.string,
  code: PropTypes.string,
  setCapacity: PropTypes.number,
  prices: PropTypes.arrayOf(PropTypes.shape(packagingRangeProps)),
};

export const packagingDataProps = {
  quantity: PropTypes.number,
  code: PropTypes.string,
  label: PropTypes.string,
};

export const productProps = {
  code: PropTypes.string,
  name: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape(packagingVariantProps)),
};

export const productDataProps = {
  categoryName: PropTypes.string,
  isMisc: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      variants: PropTypes.arrayOf(PropTypes.shape(packagingVariantProps)),
    })
  ),
};

export const getPriceByQuantity = (quantity, priceRanges) => {
  return !isEmpty(priceRanges)
    ? priceRanges.find(({ from, to }) => {
        return quantity >= from && quantity < (to || Infinity);
      }) || priceRanges[0]
    : { price: '0' };
};

const getLineItems = (packagingData) => {
  return Object.entries(packagingData).reduce((acc, curr) => {
    curr[1].forEach(({ quantity, price }) => {
      acc.push({
        quantity,
        price: parseFloat(price.replace(',', '.')),
      });
    });
    return acc;
  }, []);
};

const sumLineItems = (lineItems) => {
  return lineItems.reduce(
    (acc, { quantity, price }) =>
      acc.plus(new Big(quantity).times(new Big(price))),
    new Big(0)
  );
};

export const calculateTotalAmount = (packagingData, productsData) => {
  if (!packagingData || !productsData) {
    return {
      totalAmount: new Big(0).toFixed(2),
      total: 0,
    };
  }

  const lineItems = getLineItems(packagingData);
  const sum = sumLineItems(lineItems);

  return {
    totalAmount: sum.toFixed(2),
    total: lineItems.length,
  };
};

export const onlyWithQuantity = (packagingDetails) => {
  return packagingDetails.filter(({ quantity }) => quantity > 0);
};

const getProductsLength = (products, packagingData) =>
  products.reduce((acc, curr) => {
    const { code } = curr;
    if (packagingData[code]?.length > 0) {
      return acc + 1;
    }
    return acc;
  }, 0);

export const countProducts = (products, packagingData) => {
  if (!packagingData || !products) {
    return 0;
  }

  return getProductsLength(products, packagingData);
};

export const countAllSubProducts = (subCategories, packagingData) => {
  if (!packagingData || !subCategories) {
    return 0;
  }

  const flattenSubProducts = subCategories
    .map((subCategory) => subCategory.products.flat())
    .flat();

  return getProductsLength(flattenSubProducts, packagingData);
};

export const getQuantityThreshold = (quantity, priceRanges) => {
  const rangeIndex = !isEmpty(priceRanges)
    ? priceRanges.findIndex(
        (price) => quantity < price.to && quantity >= price.from
      )
    : -1;

  return rangeIndex >= 0 && priceRanges[rangeIndex + 1]
    ? priceRanges[rangeIndex + 1].from
    : null;
};

export const transformUnitPrices = (packagingData) =>
  packagingData.map((packaging) => {
    const temp = { ...packaging };
    temp.price = Number(packaging.price).toFixed(2);
    return temp;
  });

export const calculateCurrentAmount = (
  product,
  packagingDetails,
  packagingData,
  productsData
) => {
  const currentPackagingData = {
    ...packagingData,
    [product.code]: transformUnitPrices(
      onlyWithQuantity([...packagingDetails])
    ),
  };
  const calculatedData = calculateTotalAmount(
    currentPackagingData,
    productsData
  );
  return new Big(calculatedData.totalAmount).toFixed(2);
};
