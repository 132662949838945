import { createAction } from '@reduxjs/toolkit';
import { fetchClientDataById } from './clientDataActions';

export const createContactPerson = createAction(
  'contactPerson/create',
  function prepare(
    contactPersonId,
    contactPersonData,
    clientId,
    fetchDataNeeded
  ) {
    return {
      payload: {
        contactPersonId,
        contactPersonData,
        fetchDataNeeded,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/contact-person/${contactPersonId}`,
            method: 'POST',
            data: contactPersonData,
          },
          commit: {
            type: 'contactPerson/create/commit',
            meta: {
              contactPersonId,
              contactPersonData,
              clientId,
              after: fetchDataNeeded ? undefined : fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'contactPerson/create/rollback',
            meta: { contactPersonId, contactPersonData },
          },
        },
      },
    };
  }
);

export const addContactPersonToApplication = createAction(
  'contactPerson/addToApplication',
  function prepare(clientId, applicationId, contactPersonId) {
    return {
      payload: {
        clientId,
        applicationId,
        contactPersonId,
      },
    };
  }
);

export const deleteContactPersonInStore = createAction(
  'contactPerson/deleteInStore',
  function prepare(contactPersonId, clientId) {
    return {
      payload: {
        contactPersonId,
        clientId,
      },
    };
  }
);

export const deleteContactPerson = createAction(
  'contactPerson/delete',
  function prepare(contactPersonId, clientId) {
    return {
      payload: {
        contactPersonId,
        clientId,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/contact-person/${contactPersonId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'contactPerson/delete/commit',
            meta: {
              contactPersonId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'contactPerson/delete/rollback',
            meta: { contactPersonId },
          },
        },
      },
    };
  }
);

export const editContactPerson = createAction(
  'contactPerson/edit',
  function prepare(contactPersonId, contactPersonData, clientId) {
    return {
      payload: {
        contactPersonId,
        contactPersonData,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/contact-person/${contactPersonId}`,
            method: 'PUT',
            data: contactPersonData,
          },
          commit: {
            type: 'contactPerson/edit/commit',
            meta: {
              contactPersonId,
              contactPersonData,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'contactPerson/edit/rollback',
            meta: { contactPersonId, contactPersonData },
          },
        },
      },
    };
  }
);

export const setApplicationContactPerson = createAction(
  'clientData/setApplicationContactPerson',
  function prepare(contactPersonId, applicationId, clientId) {
    return {
      payload: { contactPersonId, applicationId, clientId },
    };
  }
);

export const deleteApplicationContactPerson = createAction(
  'clientData/removeApplicationContactPerson',
  function prepare(contactPersonId, applicationId, clientId) {
    return {
      payload: { contactPersonId, applicationId, clientId },
    };
  }
);
