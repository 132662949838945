import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import AppBar from '../../components/AppBar/AppBar.connected';
import Tabs from '../../components/Tabs/Tabs';
import withClientData from '../../helpers/withClientData';
import QuotationsTab from './QuotationsTab';
import OrdersTab from './OrdersTab';
import ProductsOrderedTab from './ProductsOrderdTab';
import {
  TAB_QUOTATIONS,
  TAB_ORDERS,
  TAB_PRODUCTS,
} from '../../constants/orders';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const tabNames = [TAB_QUOTATIONS, TAB_ORDERS, TAB_PRODUCTS];

const OrderHistoryView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);

  const orderHistoryData = clientData.orderHistory;

  useEffect(() => {
    if (location.state && tabNames.includes(location.state.from)) {
      setSelectedTab(tabNames.indexOf(location.state.from));
      history.replace(location.pathname, {
        from: '',
      });
    }
  }, [history, location.pathname, location.state]);

  const backButtonAction = () => {
    history.push(`/customers/${clientId}`);
  };

  return (
    <>
      <Container maxWidth="sm">
        <AppBar
          title={t(tabNames[selectedTab])}
          displayBackButton
          backButtonAction={backButtonAction}
        />
      </Container>
      {orderHistoryData && (
        <Tabs
          tabLabels={tabNames.map((tabName) => t(tabName))}
          scrollable
          fixedTop
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        >
          <QuotationsTab quotationsData={orderHistoryData.quotations} />
          <OrdersTab ordersData={orderHistoryData.orders} />
          <ProductsOrderedTab productsData={orderHistoryData.products} />
        </Tabs>
      )}
    </>
  );
};

OrderHistoryView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(OrderHistoryView, { forceFetch: true });
