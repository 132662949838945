import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'frontend-components';

import List from '../List/List';

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const LimitedList = ({ items, label, renderItem, topItem }) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(5);

  const handleShowMore = (e) => {
    e.stopPropagation();
    setLimit(limit + 5);
  };

  if ((!items || !items.length) && !topItem) {
    return null;
  }

  return (
    <List aria-label={label} onClick={(e) => e.stopPropagation()}>
      {topItem}
      {items.slice(0, limit).map((item, index) => renderItem(item, index))}
      {limit < items.length ? (
        <ButtonContainer>
          <Button theme="secondary" size="small" onClick={handleShowMore}>
            {t('see_more')}
          </Button>
        </ButtonContainer>
      ) : null}
    </List>
  );
};

LimitedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  renderItem: PropTypes.func,
  topItem: PropTypes.element,
};

export default LimitedList;
