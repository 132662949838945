import { createAction } from '@reduxjs/toolkit';
import { fetchClientDataById } from './clientDataActions';

export const postRemark = (remarkId, callData, clientId) => ({
  type: 'remark/post',
  payload: callData,
  meta: {
    remarkId,
    clientId,
    offline: {
      effect: {
        url: `/qac/remark/${remarkId}`,
        method: 'POST',
        data: callData,
      },
      commit: {
        type: 'remark/post/commit',
        meta: {
          remarkId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'remark/post/rollback', meta: { remarkId } },
    },
  },
});

export const putRemark = (remarkId, callData, clientId) => ({
  type: 'remark/put',
  payload: callData,
  meta: {
    offline: {
      effect: {
        url: `/qac/remark/${remarkId}`,
        method: 'PUT',
        data: callData,
      },
      commit: {
        type: 'remark/put/commit',
        meta: {
          remarkId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'remark/put/rollback', meta: { remarkId } },
    },
  },
});

export const deleteRemarkById = createAction(
  'remark/delete',
  function prepare(remarkId, clientId) {
    return {
      payload: { remarkId, clientId },
      meta: {
        offline: {
          effect: {
            url: `/qac/remark/${remarkId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'remark/delete/commit',
            meta: {
              remarkId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'remark/delete/rollback',
            meta: { remarkId },
          },
        },
      },
    };
  }
);
