import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dialog from '../../components/Dialog/Dialog';
import {
  StyledButton,
  StyledDialogActions,
} from '../../components/Dialog/DeleteDialog/DeleteDialog';

const ShowNextActionDialog = ({ open, setOpen, handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        {t('note_no_action_title')}
      </DialogTitle>
      <DialogContent>{t('note_no_action_message')}</DialogContent>
      <StyledDialogActions>
        <StyledButton
          onClick={() => {
            handleSubmit();
          }}
          theme="secondary"
          data-testid="no_next_action_dialog_exit"
        >
          {t('note_exit')}
        </StyledButton>
        <StyledButton
          autoFocus
          onClick={() => {
            setOpen(false);
          }}
          theme="primary"
        >
          {t('note_back')}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

ShowNextActionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default ShowNextActionDialog;
