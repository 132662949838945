import { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DialogHeader from '../../components/Dialog/DialogHeader';
import { StyledDialogActions } from '../../components/Dialog/DeleteDialog/DeleteDialog';
import CustomButton from '../../components/Button/Button';
import Dialog from '../../components/Dialog/Dialog';
import Checkbox from '../../components/Checkbox/Checkbox';

const CancelReportDialog = ({
  openCancelDialog,
  cancelReportHandle,
  closeHandle,
}) => {
  const { t } = useTranslation();

  const [backDialogCheckbox, setBackDialogCheckbox] = useState(false);

  return (
    <Dialog aria-labelledby="cancel-dialog" open={openCancelDialog}>
      <DialogHeader
        title={t('report_cancel_title')}
        handleClose={closeHandle}
      />

      <DialogContent>
        <p>{t('report_cancel_text')}</p>
        <div>
          <Checkbox
            label={t('report_remove_images')}
            onChange={(checkedOrNot) => setBackDialogCheckbox(checkedOrNot)}
          />
        </div>
      </DialogContent>
      <StyledDialogActions>
        <CustomButton
          onClick={closeHandle}
          theme="secondary"
          data-testid="confirm-no"
        >
          {t('report_no')}
        </CustomButton>
        <CustomButton
          onClick={() => cancelReportHandle(backDialogCheckbox)}
          theme="primary"
          data-testid="confirm-yes"
        >
          {t('report_yes')}
        </CustomButton>
      </StyledDialogActions>
    </Dialog>
  );
};

CancelReportDialog.propTypes = {
  openCancelDialog: PropTypes.bool,
  cancelReportHandle: PropTypes.func,
  closeHandle: PropTypes.func,
};

export default CancelReportDialog;
