import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Label, Button } from 'frontend-components';
import Input from '../../components/Input/Input';

const ContactPersonSection = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;

  label {
    display: block;
    position: relative;
    left: 0;
    top: -8px;
    font-size: 12px;
  }
`;

const AddContactPersonSection = ({
  data,
  deleteContactPerson,
  handleAddButton,
  disabled,
  label,
  callDialogContactPersonId,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const { clientContacts } = useSelector((state) => ({
    clientContacts: state.clientData.data[clientId].contactPersons.data,
  }));

  const contactPersonId =
    callDialogContactPersonId ||
    (typeof data !== 'undefined' ? data.contactPersonId : null);

  if (contactPersonId && clientContacts.length > 0) {
    const contactPerson = clientContacts.find(
      (contact) => contact.id === contactPersonId
    );

    const isContactPersonDeleted =
      contactPerson && contactPerson.isDeleted === 1;

    const prefix = isContactPersonDeleted
      ? `[${t('contact_deleted').toUpperCase()}] `
      : '';

    const inputValue =
      typeof contactPerson !== 'undefined'
        ? `${contactPerson.firstName} ${contactPerson.lastName}`
        : t('note_no_contact');

    return (
      <ContactPersonSection data-testid="contact_person_section">
        <Label disabled={disabled}>{label || t('contact_person_title')}</Label>
        <Input
          customColor={isContactPersonDeleted ? '#666666' : 'inherit'}
          leftText={prefix}
          value={inputValue}
          rightIcon={<CloseIcon />}
          fullWidth
          onIconClick={() => {
            deleteContactPerson();
          }}
          disabled={disabled}
        />
      </ContactPersonSection>
    );
  }
  return (
    <Button
      data-testid="add_contact_person_btn"
      startIcon={<AddIcon />}
      theme="secondary"
      fullWidth
      onClick={() => {
        handleAddButton('addContactPerson');
      }}
      disabled={disabled}
    >
      {t('note_add_contact')}
    </Button>
  );
};

AddContactPersonSection.propTypes = {
  data: PropTypes.shape({ contactPersonId: PropTypes.string }),
  deleteContactPerson: PropTypes.func,
  handleAddButton: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.bool,
  callDialogContactPersonId: PropTypes.string,
};

export default AddContactPersonSection;
