import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import SummaryView from './SummaryView';
import {
  prepareQuotationHistory,
  prepareQuotationPayload,
} from '../../../helpers/prepOrderPayload';
import {
  postQuotation,
  updateQuotationHistory,
} from '../../../store/actions/quotationActions';
import {
  updateQuotationDeliveryCost,
  updateQuotationHideTotals,
  asyncSetQuotationSendConfirmation,
  setQuotationDiscount,
} from '../../../store/reducers/drafts';
import OrderConfirmationDialog from '../../../components/Dialog/OrderConfirmationDialog/OrderConfirmationDialog';

const QuotationSummaryView = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { newQuotation } = useSelector((state) => state.drafts);
  const { currency, contactPersons } = useSelector(
    (state) => state.clientData.data[clientId]
  );
  const { contactPersonId } = newQuotation;
  const contactPersonData = contactPersons.data.find(
    (contact) => contact.id === contactPersonId
  );
  const isSendActionActive =
    contactPersonData.emailsList.length || newQuotation.contactsCC?.length;

  const sendQuotation = (quotationData, id) => {
    const quotPayload = prepareQuotationPayload(quotationData, clientId);
    dispatch(postQuotation(id || uuidv4(), quotPayload, clientId));
  };

  const updateOfflineQuotationHistory = (quotationData) => {
    const quotData = prepareQuotationHistory(quotationData, currency);
    dispatch(updateQuotationHistory(clientId, quotData));
    return quotData;
  };

  const updateDeliverCost = (transport) => {
    dispatch(updateQuotationDeliveryCost(transport));
  };

  const updateHideTotals = (hideTotals) => {
    dispatch(updateQuotationHideTotals(hideTotals));
  };

  const finishQuotation = (quotationData) => {
    const quotData = updateOfflineQuotationHistory(quotationData);

    sendQuotation(quotationData, quotData?.id);
    history.push(`/customers/${clientId}/create/quotation/confirmation`);
  };

  const finishAndSendAction = () => {
    dispatch(asyncSetQuotationSendConfirmation(true)).then(({ payload }) => {
      finishQuotation(payload);
    });
  };

  const finishWithoutSendingAction = () => {
    dispatch(asyncSetQuotationSendConfirmation(false)).then(({ payload }) => {
      finishQuotation(payload);
    });
  };

  const setDiscount = (discount) => {
    dispatch(setQuotationDiscount(discount));
  };

  return (
    <>
      <SummaryView
        title={t('quotation_summary')}
        listHeader={t('quotation_subtitle')}
        dateLabel={t('quotation_total_date')}
        handleBackButton={() =>
          history.push(`/customers/${clientId}/create/quotation/selectProducts`)
        }
        footerButtonTitle={t('finish')}
        footerButtonAction={() => setShowConfirmationDialog(true)}
        draftData={newQuotation}
        updateDeliverCost={updateDeliverCost}
        updateHideTotals={updateHideTotals}
        setDiscount={setDiscount}
      />
      <OrderConfirmationDialog
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        finishAndSendAction={isSendActionActive && finishAndSendAction}
        finishWithoutSendingAction={finishWithoutSendingAction}
        data-testid="confirm-summary"
      />
    </>
  );
};

export default QuotationSummaryView;
