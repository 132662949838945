import { useTranslation } from 'react-i18next';
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from 'prop-types';
import { Divider } from 'frontend-components';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import {
  ContactName,
  StyledIconButton,
} from '../Lists/ContactPeopleList.styles';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';

const GeneralPhoneNumberListItem = ({
  generalPhoneNumber,
  showDivider,
  onPhoneClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListItem button $withOneRightIcon $withMultilineText>
        <ListItemText
          primary={<ContactName>{t('contact_list_general_phone')}</ContactName>}
          secondary={generalPhoneNumber}
        />
        <ListItemSecondaryAction>
          <StyledIconButton
            href={`tel:${generalPhoneNumber}`}
            onClick={(e) => {
              e.stopPropagation();
              onPhoneClick();
            }}
            edge="start"
            aria-label="phone"
          >
            <PhoneIcon />
          </StyledIconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {showDivider && <Divider />}
    </>
  );
};

GeneralPhoneNumberListItem.propTypes = {
  generalPhoneNumber: PropTypes.string,
  showDivider: PropTypes.bool,
  onPhoneClick: PropTypes.func,
};

export default GeneralPhoneNumberListItem;
