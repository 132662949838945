import PhoneIcon from '@material-ui/icons/Phone';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import moment from 'moment';
import { isEmpty, pickBy } from 'lodash';
import { endOfToday, startOfToday } from 'date-fns';

import DropInIcon from '../components/Icons/DropInIcon';
import OtherIcon from '../components/Icons/OtherIcon';
import {
  APPOINTMENT,
  DROP_IN,
  NEXT_ACTION_TYPE,
  PHONE_CALL,
  NEXT_ACTION_PATH,
  NEXT_ACTION_TYPES,
} from '../constants/nextAction';
import { NOTE_TYPES } from '../constants/noteTypes';
import { pickerMaxDate, pickerMinDate } from '../constants/fixedDates';
import {
  addNextActionToVisitNote,
  addNextActionToCallNote,
  addNextActionToRemarkNote,
} from '../store/actions/nextActionActions';
import { addNextActionToApplicationNote } from '../store/actions/applicationNoteActions';
import { DAY_FILTER_VALUES } from '../views/SelectCustomerView/DayFilter';

export const getActionIcon = (action) => {
  switch (action) {
    case APPOINTMENT:
      return <DriveEtaIcon key={action} />;
    case PHONE_CALL:
      return <PhoneIcon key={action} />;
    case DROP_IN:
      return <DropInIcon key={action} />;
    default:
      return <OtherIcon key={action} />;
  }
};

export const getActionName = (action) => {
  return NEXT_ACTION_TYPE.filter((item) => item.value === action)[0]?.label;
};

export const addNextActionToNoteMap = {
  [NOTE_TYPES.VISITS]: addNextActionToVisitNote,
  [NOTE_TYPES.CALLS]: addNextActionToCallNote,
  [NOTE_TYPES.APPLICATIONS]: addNextActionToApplicationNote,
  [NOTE_TYPES.REMARKS]: addNextActionToRemarkNote,
};

export const getNextActionReportPath = (
  customerId,
  reportType,
  reportId,
  applicationReportId
) => {
  const path = NEXT_ACTION_PATH.find(({ type }) => type === reportType)?.path;
  if (reportType === 'applicationNote')
    return `/customers/${customerId}/applications/${applicationReportId}`;

  return path
    ? `/customers/${customerId}/${path}/${reportId}`
    : `/customers/${customerId}`;
};

export const filterDoneActionsList = (nextActions) => {
  const dateFrom = new Date(moment().subtract(7, 'days').startOf('day'));
  const dateTo = new Date(moment().add(7, 'days').endOf('day'));

  return nextActions.filter(({ doAt, actionDone }) => {
    const actionDate = new Date(doAt);

    return actionDate >= dateFrom && actionDate <= dateTo && actionDone;
  });
};

export const filterDateActionsList = (nextActions, dateFrom, dateTo) => {
  dateFrom.setHours(0, 0, 0);
  dateTo.setHours(23, 59, 59);

  return nextActions.filter(({ doAt, actionDone }) => {
    const actionDate = new Date(doAt);
    return actionDate >= dateFrom && actionDate <= dateTo && !actionDone;
  });
};

export const filterPlannedActions = (
  nextActions,
  actionFilter,
  dayFilter,
  dayFilterFrom,
  dayFilterTo
) => {
  const dateFrom = new Date(
    dayFilter === DAY_FILTER_VALUES.TODAY
      ? startOfToday()
      : dayFilterFrom || pickerMinDate
  );
  const dateTo = new Date(
    dayFilter === DAY_FILTER_VALUES.TODAY
      ? endOfToday()
      : dayFilterTo || pickerMaxDate
  );

  let result =
    dayFilter === DAY_FILTER_VALUES.DONE
      ? filterDoneActionsList(nextActions)
      : filterDateActionsList(nextActions, dateFrom, dateTo);

  result = result.map(
    ({
      id,
      actionDone,
      client,
      description,
      doAt,
      startTime,
      endTime,
      actionType,
      reportType,
      applicationReportId,
      contactPersonId,
    }) => {
      return {
        ...client,
        actionId: id,
        actionDone,
        description,
        doAt,
        startTime,
        endTime,
        actionType,
        reportType,
        applicationReportId,
        contactPersonId,
      };
    }
  );

  if (!actionFilter || isEmpty(pickBy(actionFilter, Boolean))) {
    return result;
  }

  return result.filter(({ actionType }) => {
    if (!actionType) {
      return null;
    }

    return NEXT_ACTION_TYPES.reduce(
      (acc, curr) => acc || !!(actionFilter[curr] && actionType === curr),
      false
    );
  });
};
