import SvgIcon from '@material-ui/core/SvgIcon';

function StatusProcessing(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <pattern
          id="pattern-1"
          width="100%"
          height="100%"
          patternUnits="objectBoundingBox"
        >
          <use xlinkHref="#image-2" />
        </pattern>
        <image
          id="image-2"
          width="20"
          height="20"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFKADAAQAAAABAAAAFAAAAACy3fD9AAACe0lEQVQ4EU2US27bQBBEu8mRbBkIkJwigI/mRXaBbxogl7C9cyKJHNernpFNYEjOp19Xf8h8+f3U+75HeGwR2x59u+q51fOq55W5hp7zvWufMz43bfVssSyREdE1ouu+aPSlHKSeuUXqAFu9bjUZIqRGZwUecwFXkfITymzvYsqRDnXv2BseDXNEBgmmiCaMdSucNlaKilVOMLBCVhm6rHAASI2VoY70aF02LTHes5QoNkNla3WLDklpLcoRyh0agK85/BoyQClJGfsQMIsBsCuFy2fYwCiWCkWRZvEcttRVyGuTCsIQlOxLUR9KAdrIVQFGpS8e3c8RrlVrXympkKmqqttHdRMoRZFSK2IOXJB+Pnu4hQibPM7cAowmhSzoSleOosgbykkH6///RVwU4vt7DdQZVmGWveys0EDMClqP2nQO7+7ZjMvfP7G/vUa/CEaIFjHO+YRuVogSx+bb7X0Z+YzDIdrPx/j26zmW7z8iNa/Kc16Dc/PSe0sUzkX6btEB8ke47RB5ejBof3uxWZ5OkVp3OjinUfYFrxwC1EgDdUAwOzreCSgAqkYOTT2qEBKC086XZvsCV5VvyrSJV1Sj7ngsNVpzQ1OM8ygUTd6US7WcVQ6lzdUkTJqbMD3kHQWAmaOADph9qPOUMCnMKvAqdfSxoAIOVXhgqEiVv4LZe1Wtqju+FD4CoHRHdhj08WxsQsaL8jFVJrmZTiTQbTK+5fTPgO+ejdoy3m0zjYDN96GaNDjcqZAQ3fyEr6Ye/0pDUSqV9T8UyDCDBHaRtEbuJswBAlRoamx+Jvylan8o1az+2FOZQdpkfmuFIdIs8lVQfy3kUSPFntcHzoieD/1eOrsAAAAASUVORK5CYII="
        />
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="16.0.photo_gallery-v2"
          transform="translate(-139.000000, -714.000000)"
        >
          <g id="Group-5" transform="translate(139.000000, 714.000000)">
            <circle id="Oval" fill="#FFFFFF" cx="12" cy="12" r="12" />
            <path
              d="M4.92893219,4.92893219 C1.02368927,8.83417511 1.02368927,15.1658249 4.92893219,19.0710678 C8.83417511,22.9763107 15.1658249,22.9763107 19.0710678,19.0710678 C22.9763107,15.1658249 22.9763107,8.83417511 19.0710678,4.92893219 C15.1658249,1.02368927 8.83417511,1.02368927 4.92893219,4.92893219 Z M6.34314575,6.34314575 C9.46734008,3.21895142 14.5326599,3.21895142 17.6568542,6.34314575 C20.7810486,9.46734008 20.7810486,14.5326599 17.6568542,17.6568542 C14.5326599,20.7810486 9.46734008,20.7810486 6.34314575,17.6568542 C3.21895142,14.5326599 3.21895142,9.46734008 6.34314575,6.34314575 Z"
              id="Shape"
              fill="url(#pattern-1)"
              fillRule="nonzero"
            />
            <polygon
              id="Path"
              fill="#E42313"
              transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
              points="16 12 14 12 14 8 10 8 10 12 8 12 12 16"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default StatusProcessing;
