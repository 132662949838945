import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SummaryView from './SummaryView';
import useTermsAndSignatureOptions from '../../../hooks/useTermsAndSignatureOptions';
import {
  setOrderDiscount,
  updateOrderDeliveryCost,
  updateOrderHideTotals,
} from '../../../store/reducers/drafts';
import OrderConfirmationDialog from '../../../components/Dialog/OrderConfirmationDialog/OrderConfirmationDialog';

const OrderSummaryView = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newOrder } = useSelector((state) => state.drafts);

  const {
    summaryFooterButtonTitle,
    summaryFooterButtonAction,
    showConfirmationDialog,
    setShowConfirmationDialog,
    finishAndSendAction,
    finishWithoutSendingAction,
  } = useTermsAndSignatureOptions();

  const updateDeliverCost = (transport) => {
    dispatch(updateOrderDeliveryCost(transport));
  };

  const updateHideTotals = (hideTotals) => {
    dispatch(updateOrderHideTotals(hideTotals));
  };

  const setDiscount = (discount) => {
    dispatch(setOrderDiscount(discount));
  };

  return (
    <>
      <SummaryView
        title={t('order_summary')}
        listHeader={t('order_subtitle')}
        dateLabel={t('order_total_date')}
        handleBackButton={() =>
          history.push(`/customers/${clientId}/create/order/selectProducts`)
        }
        footerButtonTitle={summaryFooterButtonTitle}
        footerButtonAction={summaryFooterButtonAction}
        draftData={newOrder}
        updateDeliverCost={updateDeliverCost}
        updateHideTotals={updateHideTotals}
        setDiscount={setDiscount}
      />
      <OrderConfirmationDialog
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        finishAndSendAction={finishAndSendAction}
        finishWithoutSendingAction={finishWithoutSendingAction}
        data-testid="confirm-summary"
      />
    </>
  );
};

export default OrderSummaryView;
