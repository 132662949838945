import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import { scrollToError } from 'frontend-components/lib/helpers';

const FixedTopTabsContainer = styled.div`
  margin-top: 55px;

  .MuiAppBar-colorPrimary {
    color: #000000;
    background-color: #ffffff;
    margin-top: 55px;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
    border-bottom: 1px solid #d8d8d8;
  }

  .MuiTabs-root {
    padding: 0 16px;
  }
`;

const StyledTabs = styled(Tabs)`
  & .MuiTabs-fixed {
    height: 48px;
  }
  && .indicator {
    background-color: #e42313;
  }
`;

const StyledTab = styled(Tab)`
  min-width: 110px;
  color: #000000;
  .selected {
    opacity: 0.6;
  }

  @media (max-width: 410px) {
    min-width: 25%;
  }

  @media (max-width: 350px) {
    min-width: 20%;
  }
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

const TabsComponent = ({
  tabLabels,
  children,
  scrollable,
  fixedTop,
  selectedTab = 0,
  blockTabs,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);

  const tabs = tabLabels.map((tabLabel, idx) => (
    <StyledTab
      key={tabLabel}
      label={tabLabel}
      value={idx}
      onClick={() => {
        if (blockTabs) {
          scrollToError();
          return;
        }
        setCurrentTab(idx);
        window.scrollTo(0, 0);
      }}
    />
  ));

  const views = children.map((view, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <TabPanel value={currentTab} index={idx} key={idx}>
      {view}
    </TabPanel>
  ));
  return (
    <>
      {fixedTop ? (
        <FixedTopTabsContainer>
          <MuiAppBar>
            <StyledTabs
              value={currentTab}
              variant={scrollable ? 'scrollable' : 'fullWidth'}
              scrollButtons={scrollable ? 'auto' : 'off'}
            >
              {tabs}
            </StyledTabs>
          </MuiAppBar>
          {views}
        </FixedTopTabsContainer>
      ) : (
        <>
          <StyledTabs
            value={currentTab}
            variant={scrollable ? 'scrollable' : 'fullWidth'}
            scrollButtons={scrollable ? 'auto' : 'off'}
          >
            {tabs}
          </StyledTabs>
          {views}
        </>
      )}
    </>
  );
};

TabsComponent.propTypes = {
  tabLabels: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  scrollable: PropTypes.bool,
  fixedTop: PropTypes.bool,
  selectedTab: PropTypes.number,
  blockTabs: PropTypes.bool,
};

export default TabsComponent;
