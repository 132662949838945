import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTAAllowed,
  getDelegationAllowed,
  getCurrentlyLoggedUser,
} from '../../services/ta.service';

export const fetchDelegationAllowed = createAsyncThunk(
  'user/fetchDelegationAllowed',
  async () => {
    return getDelegationAllowed();
  }
);

export const fetchTAAllowed = createAsyncThunk(
  'user/fetchTAAllowed',
  async () => {
    return getTAAllowed();
  }
);

export const fetchCurrentlyLoggedUser = createAsyncThunk(
  'user/fetchCurrentlyLoggedUser',
  async () => {
    return getCurrentlyLoggedUser();
  }
);
