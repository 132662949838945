import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'frontend-components';

import ActionsList from '../../components/Lists/ActionsList';

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const AddNextActionSection = ({
  nextActions,
  handleAddButton,
  editNextAction,
  onCheckboxClick,
  disabled,
}) => {
  const { t } = useTranslation();
  if (nextActions.total === 0) {
    return (
      <Container>
        <Button
          startIcon={<AddIcon />}
          theme="secondary"
          fullWidth
          onClick={() => {
            handleAddButton('actions/create');
          }}
          disabled={disabled}
          data-testid="add_nextaction_btn"
        >
          {t('note_add_action')}
        </Button>
      </Container>
    );
  }
  return (
    <Container>
      <ActionsList
        data-testid="actions-collapsible"
        expanded
        actions={nextActions}
        onItemClick={editNextAction}
        title={t('note_next_action_title')}
        onCheckboxClick={onCheckboxClick}
        disabledAction={disabled}
      />
    </Container>
  );
};
AddNextActionSection.propTypes = {
  nextActions: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        action: PropTypes.string,
        actionDone: PropTypes.bool,
        delegatedId: PropTypes.string,
        doAt: PropTypes.string,
        duration: PropTypes.number,
        type: PropTypes.string,
      })
    ),
  }),
  handleAddButton: PropTypes.func,
  editNextAction: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AddNextActionSection;
