import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { LinearProgress } from 'frontend-components';
import HelpDialog from '../Dialog/HelpDialog/HelpDialog';
import HelpIcon from '../Icons/HelpIcon';

const AppBarContainer = styled.div`
  flex-grow: 1;
  position: relative;

  .MuiAppBar-colorPrimary {
    color: #ffffff;
    background-color: #e42313;
  }

  .MuiToolbar-gutters {
    padding: 0 16px;
  }

  .MuiIconButton-edgeStart {
    margin-right: 8px;
  }

  .MuiPaper-elevation0 {
    box-shadow: none;
    border-bottom: none;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
  }

  .MuiIconButton-label {
    color: #ffffff;
  }
`;

const Aside = styled.div`
  color: #ffffff;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: normal;
  flex-grow: 1;
  line-height: 24px;
  margin: 0;
  word-break: break-all;
`;

const StyledAppBarContainer = styled(AppBarContainer)`
  ${({ hiddenHorizontaly }) =>
    hiddenHorizontaly
      ? `@media (orientation: landscape) and (max-height: 768px) {
    display: none;
  }`
      : ''}
`;

const HorizontalIconButton = styled(IconButton)`
  display: none;

  @media (orientation: landscape) and (max-height: 768px) {
    display: block;
    position: fixed;
    top: 20px;
    left: 10px;
    color: #e42313;
  }
`;

function ElevationScroll(props) {
  const { children, elevated } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger || elevated ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  elevated: PropTypes.bool,
};

const AppBar = ({
  title,
  aside,
  content,
  displayBackButton = false,
  elevated,
  backButtonAction,
  isDataLoading = false,
  titleClickAction = () => null,
  online = false,
  help = {},
  setHelpOpen,
  hiddenHorizontaly = false,
}) => {
  const history = useHistory();

  return (
    <>
      <StyledAppBarContainer hiddenHorizontaly={hiddenHorizontaly}>
        <ElevationScroll elevated={elevated}>
          <MuiAppBar>
            <Toolbar>
              {displayBackButton && (
                <IconButton
                  edge="start"
                  aria-label="back"
                  onClick={() => {
                    backButtonAction ? backButtonAction() : history.goBack();
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              )}
              {(title || title === '') && (
                <Title onClick={titleClickAction}>
                  <Truncate lines={2}>{title}</Truncate>
                </Title>
              )}
              {content && <Content>{content}</Content>}
              {aside && <Aside>{aside}</Aside>}
              <IconButton edge="end" aria-label="help" onClick={setHelpOpen}>
                <HelpIcon />
              </IconButton>
            </Toolbar>
            {isDataLoading && <LinearProgress bottom />}
          </MuiAppBar>
        </ElevationScroll>
        <Toolbar />
      </StyledAppBarContainer>
      <HelpDialog help={help} online={online} />
      {displayBackButton && (
        <HorizontalIconButton
          edge="start"
          aria-label="back-horizontal"
          onClick={() => {
            backButtonAction ? backButtonAction() : history.goBack();
          }}
        >
          <KeyboardArrowLeftIcon />
        </HorizontalIconButton>
      )}
    </>
  );
};

AppBar.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  aside: PropTypes.node,
  elevated: PropTypes.bool,
  displayBackButton: PropTypes.bool,
  backButtonAction: PropTypes.func,
  titleClickAction: PropTypes.func,
  isDataLoading: PropTypes.bool,
  online: PropTypes.bool,
  setHelpOpen: PropTypes.func,
  help: PropTypes.shape({
    open: PropTypes.bool,
    step: PropTypes.string,
    message: PropTypes.string,
  }),
  hiddenHorizontaly: PropTypes.bool,
};

export default AppBar;
