import {
  getDateFormatted,
  DATE_FORMAT,
  TIME_FORMAT,
} from 'frontend-components/lib/helpers';

function prepareNextAction(nextAction) {
  if (nextAction !== null) {
    const { action, actionDone, delegatedId, doAt, duration, type } =
      nextAction;
    return {
      action,
      actionDone,
      delegatedId,
      doAt,
      duration,
      type,
    };
  }

  return null;
}

export const prepareVisitNotePayload = (values, clientId) => {
  const { nextAction, noteDate } = values;

  const notePayload = {
    ...values,
    visitDate: getDateFormatted(
      new Date(noteDate),
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ),
    clientId,
    nextAction: prepareNextAction(nextAction),
  };

  delete notePayload.noteDate;
  delete notePayload.editable;

  return notePayload;
};

export const prepareCallNotePayload = (values, clientId) => {
  const { nextAction, noteDate } = values;

  const notePayload = {
    ...values,
    phoneCallDate: getDateFormatted(
      new Date(noteDate),
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ),
    clientId,
    nextAction: prepareNextAction(nextAction),
    suggestedProducts: values.demoProducts,
  };

  delete notePayload.noteDate;
  delete notePayload.isNoShow;
  delete notePayload.demoProducts;
  delete notePayload.editable;

  return notePayload;
};

export const prepareRemarkNotePayload = (values, clientId) => {
  const { nextAction, noteDate } = values;

  const notePayload = {
    ...values,
    remarkDate: getDateFormatted(new Date(noteDate)),
    clientId,
    nextAction: prepareNextAction(nextAction),
  };

  delete notePayload.noteDate;
  delete notePayload.isNoShow;
  delete notePayload.isDraft;
  delete notePayload.editable;
  delete notePayload.demoProducts;

  return notePayload;
};

export const prepareApplicationNotePayload = (values, clientId) => {
  const { nextAction, noteDate } = values;

  const notePayload = {
    ...values,
    noteDate: getDateFormatted(
      new Date(noteDate),
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ),
    clientId,
    nextAction: prepareNextAction(nextAction),
  };

  delete notePayload.isNoShow;
  delete notePayload.isDraft;
  delete notePayload.editable;

  return notePayload;
};
