import styled from 'styled-components';
import MuiList from '@material-ui/core/List';

const StyledList = styled(MuiList)`
  padding: 0;

  .MuiListItem-container {
    &:last-child {
      margin-bottom: -12px;
    }
  }
`;

export default StyledList;
