import { useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DialogHeader from '../../components/Dialog/DialogHeader';
import { StyledDialogActions } from '../../components/Dialog/DeleteDialog/DeleteDialog';
import CustomButton from '../../components/Button/Button';
import Dialog from '../../components/Dialog/Dialog';
import StatusOffline from '../../components/Icons/StatusOffline';
import StatusFail from '../../components/Icons/StatusFail';
import StatusDone from '../../components/Icons/StatusDone';

const StatusIcon = styled.div`
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;
    fill: none;
  }
`;

const SuccessIcon = styled(StatusIcon)`
  background: rgba(0, 187, 126, 0.2);
`;

const FailedIcon = styled(StatusIcon)`
  background: rgba(228, 35, 19, 0.2);
`;

const OfflineIcon = styled(StatusIcon)`
  background: rgba(255, 157, 41, 0.2);
`;

const ConfirmReportDialog = ({ leaveReportHandle }) => {
  const { t } = useTranslation();

  const { reportStatus } = useSelector((state) => state.clientUI);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleLeave = () => {
    setOpenConfirmationDialog(false);
    leaveReportHandle();
  };

  useEffect(() => {
    setOpenConfirmationDialog(!!reportStatus && reportStatus !== 'in-progress');
  }, [reportStatus]);

  return (
    <Dialog aria-labelledby="confirm-dialog" open={openConfirmationDialog}>
      {reportStatus === 'success' && (
        <>
          <DialogHeader title={t('report_confirm_title_on')} />
          <DialogContent>
            <SuccessIcon>
              <StatusDone />
            </SuccessIcon>
            <p>{t('report_confirm_online')}</p>
          </DialogContent>
        </>
      )}
      {reportStatus === 'fail' && (
        <>
          <DialogHeader title={t('new_report_title')} />
          <DialogContent>
            <FailedIcon>
              <StatusFail />
            </FailedIcon>
            <p>{t('report_error')}</p>
          </DialogContent>
        </>
      )}
      {reportStatus === 'offline' && (
        <>
          <DialogHeader title={t('report_confirm_title_off')} />
          <DialogContent>
            <OfflineIcon>
              <StatusOffline />
            </OfflineIcon>
            <p>{t('report_confirm_offline')}</p>
          </DialogContent>
        </>
      )}
      <StyledDialogActions>
        <CustomButton
          onClick={handleLeave}
          theme="secondary"
          fullWidth
          data-testid="confirm-leave"
        >
          {t('report_confirm_ok')}
        </CustomButton>
      </StyledDialogActions>
    </Dialog>
  );
};

ConfirmReportDialog.propTypes = {
  leaveReportHandle: PropTypes.func,
};

export default ConfirmReportDialog;
