import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setApplicationNoteContactPerson } from '../../store/actions/applicationNoteActions';
import AddContactPersonView from './AddContactPersonView';
import withClientData from '../../helpers/withClientData';
import { setApplicationContactPerson } from '../../store/actions/contactPersonActions';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const AddApplicationNoteContactPersonView = ({ clientData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { applicationId, clientId, noteId } = useParams();

  return (
    <AddContactPersonView
      clientData={clientData}
      goBackAction={() => {
        history.push(
          `/customers/${clientId}/applications/${applicationId}/notes/${noteId}`
        );
      }}
      onContactSelected={(contactPersonId) => {
        dispatch(
          setApplicationNoteContactPerson(contactPersonId, clientId, noteId)
        );
        dispatch(
          setApplicationContactPerson(contactPersonId, applicationId, clientId)
        );
        history.push(
          `/customers/${clientId}/applications/${applicationId}/notes/${noteId}`
        );
      }}
      onAddContactClicked={() => {
        history.push(
          `/customers/${clientId}/applications/${applicationId}/notes/${noteId}/addContactPerson/${uuidv4()}`
        );
      }}
    />
  );
};

AddApplicationNoteContactPersonView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(AddApplicationNoteContactPersonView);
