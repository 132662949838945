import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SelectProductsView from './SelectProductsView';
import {
  setNewQuotationPackagingDetails,
  updateNewQuotationNetPrice,
} from '../../../store/reducers/drafts';

const NewQuotationSelectProductsView = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const { packagingData } = useSelector((state) => state.drafts.newQuotation);

  return (
    <SelectProductsView
      packagingData={packagingData}
      updatePackaging={setNewQuotationPackagingDetails}
      handleBackButton={() =>
        history.push(`/customers/${clientId}/create/quotation`)
      }
      handleSeeSummary={() =>
        history.push(`/customers/${clientId}/create/quotation/summary`)
      }
      updateNetPrice={updateNewQuotationNetPrice}
    />
  );
};

export default NewQuotationSelectProductsView;
