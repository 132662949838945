import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SicCodes from './SicCodes';
import { updateClientDetails } from '../../store/reducers/clients';

const ClientSicCodeView = () => {
  const { clientId } = useParams();
  const { clients } = useSelector((state) => state.clients);
  const clientListItem = clients.find((client) => client.id === clientId);

  return (
    <SicCodes
      clientListItem={clientListItem}
      clientDetailsPath={`/customers/${clientId}/details`}
      updateClientDetails={updateClientDetails}
    />
  );
};

export default ClientSicCodeView;
