import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

const StyledChip = styled(Chip)`
  font-size: 14px;
  background-color: #e5e5e5;
  height: 40px;
  color: #000000;
  border-radius: 8px;
  padding: 0 0;

  & .MuiChip-label {
    padding: 0 16px;
  }
`;

export default StyledChip;
