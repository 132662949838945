import { validate } from 'uuid';
import PropTypes from 'prop-types';
import StatusDone from '../Icons/StatusDone';
import StatusFail from '../Icons/StatusFail';
import StatusWaiting from '../Icons/StatusWaiting';
import StatusProcessing from '../Icons/StatusProcessing';

const UploadStatusIcon = ({ id, status, ...props }) => {
  return (
    <div {...props}>
      {((status &&
        !status?.uploading &&
        !status?.uploaded &&
        !status?.failed &&
        !status?.cancelled) ||
        (!status && validate(id))) && <StatusWaiting />}
      {status?.uploading && <StatusProcessing />}
      {status?.uploaded && <StatusDone />}
      {status?.failed && <StatusFail />}
    </div>
  );
};

UploadStatusIcon.propTypes = {
  id: PropTypes.string,
  status: PropTypes.shape({
    uploading: PropTypes.bool,
    uploaded: PropTypes.bool,
    cancelled: PropTypes.bool,
    failed: PropTypes.bool,
  }),
};

export default UploadStatusIcon;
