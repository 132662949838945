import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DateTile, Divider } from 'frontend-components';
import OilIcon from '../Icons/OilIcon';
import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import LimitedList from '../LimitedList/LimitedList';

const DemoedProductsList = ({
  title = null,
  expanded,
  onChange,
  demoProducts,
  displayPlusButton = false,
  onPlusButtonClick = null,
  'data-testid': testId,
  boxShadow,
  disabled,
  productItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <Collapsible
      expanded={expanded}
      onChange={onChange}
      icon={<OilIcon />}
      title={title || t('demoed')}
      itemsSize={demoProducts.total}
      collapsible={demoProducts.total > 0}
      displayPlusButton={displayPlusButton}
      onPlusButtonClick={onPlusButtonClick}
      data-testid={testId}
      boxShadow={boxShadow}
      disabled={disabled}
    >
      <LimitedList
        items={demoProducts.data}
        label={title || t('demoed')}
        renderItem={(item, index) => {
          const isLast = index === demoProducts.data.length - 1;

          return (
            <div key={item.id}>
              <ListItem
                key={item.id}
                button
                onClick={() => productItemClick && productItemClick(item)}
              >
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction $disabled>
                  <DateTile value={item.presentedAt} />
                </ListItemSecondaryAction>
              </ListItem>
              {!isLast && <Divider />}
            </div>
          );
        }}
      />
    </Collapsible>
  );
};

DemoedProductsList.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  displayPlusButton: PropTypes.bool,
  onPlusButtonClick: PropTypes.func,
  demoProducts: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        presentedAt: PropTypes.string,
        reportId: PropTypes.string,
      })
    ),
  }),
  'data-testid': PropTypes.string,
  boxShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  productItemClick: PropTypes.func,
};

export default DemoedProductsList;
