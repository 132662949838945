import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  max-width: 85vw;
`;

const Subtitle = styled.p`
  color: #666666;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  color: #666666;
`;

const IconContainer = styled.div`
  margin: 0 8px 0 16px;
  color: #999999;
`;

const DialogHeader = ({
  title,
  subtitle,
  handleClose,
  titleIcon,
  showCloseButton = true,
}) => {
  return (
    <Wrapper>
      {titleIcon && <IconContainer>{titleIcon}</IconContainer>}
      <TitleWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleWrapper>
      {showCloseButton && (
        <CloseButton aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="medium" />
        </CloseButton>
      )}
    </Wrapper>
  );
};

DialogHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  titleIcon: PropTypes.element,
  showCloseButton: PropTypes.bool,
};

export default DialogHeader;
