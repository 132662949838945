import { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AppBar from '../../../components/AppBar/AppBar.connected';
import Product from '../Product';
import useProductsSearch from '../../../hooks/useProductsSearch';
import { isSearchTermLongEnough } from '../../../helpers/products';
import {
  SearchContainer,
  SearchInput,
  SearchIcon,
} from '../../../components/Search/Search';
import ProductsListInCategory from './ProductsListInCategory';
import NothingFound from '../../../components/NothingFound/NothingFound';

const CollapsibleContainer = styled.div`
  padding: ${({ $subCategory }) => ($subCategory ? '0' : '5px 0')};
`;

export const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const Products = ({
  title,
  productsFromStore,
  productsData,
  addProduct,
  removeProduct,
  addProductsPerNote,
}) => {
  const { clientId, noteId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [demoedProducts, setDemoed] = useState([]);
  const [isCategoryExpanded, setIsCategoryExpanded] = useState({});
  const [isSubCategoryExpanded, setIsSubCategoryExpanded] = useState({});
  const {
    searchVisible,
    setSearchVisible,
    searchTerm,
    setSearchTerm,
    searchInputRef,
    title: searchOrTitle,
    filteredProductsData,
  } = useProductsSearch(productsData, title);

  const handleCheckboxChange = useCallback(
    (product, isChecked) => {
      let demoedArray;

      if (isChecked) {
        demoedArray = demoedProducts.concat([product]);
        addProduct &&
          dispatch(
            addProduct(noteId, clientId, {
              id: product.code,
              name: product.name,
            })
          );
      } else {
        demoedArray = demoedProducts.filter(
          (item) => item.code !== product.code
        );
        removeProduct &&
          dispatch(
            removeProduct(noteId, clientId, {
              id: product.code,
              name: product.name,
            })
          );
      }

      setDemoed(demoedArray);
      dispatch(addProductsPerNote(noteId, clientId, demoedArray));
    },
    [
      clientId,
      demoedProducts,
      dispatch,
      noteId,
      addProduct,
      removeProduct,
      addProductsPerNote,
    ]
  );

  useEffect(() => {
    if (Object.keys(demoedProducts).length !== 0) {
      return;
    }
    if (productsFromStore) {
      setDemoed(productsFromStore);
    }
  }, [demoedProducts, productsFromStore]);

  const getSubCategoryCheckedItemLength = (category, subCategoryName) => {
    const { products } = category.subCategories.find(
      (subCategory) => subCategory.subCategoryName === subCategoryName
    );
    const productsInDemoed = products.filter((product) =>
      demoedProducts.find((demoed) => demoed.code === product.code)
    );
    return productsInDemoed.length;
  };

  const howManyItemsAreCheckedByCategory = useCallback(
    (categoryName, subCategoryName) => {
      const foundCategory = productsData.find(
        (product) => product.categoryName === categoryName
      );

      if (subCategoryName) {
        return getSubCategoryCheckedItemLength(foundCategory, subCategoryName);
      }

      const productsInDemoed = foundCategory.products.filter((product) =>
        demoedProducts.find((demoed) => demoed.code === product.code)
      );
      return productsInDemoed.length;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [demoedProducts, productsData]
  );

  const howManyItemsAreCheckedByAllSubCategories = (categoryName) => {
    const { subCategories } = productsData.find(
      (product) => product.categoryName === categoryName
    );

    const productsInDemoed = subCategories
      .map((subCategory) =>
        subCategory.products.filter((product) =>
          demoedProducts.find((demoed) => demoed.code === product.code)
        )
      )
      .flat();

    return productsInDemoed.length;
  };

  const handleExpandedStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsCategoryExpanded({ [title]: !isExpanded });
  };

  const handleExpandedSubCategoryStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsSubCategoryExpanded({ [title]: !isExpanded });
  };

  return (
    <StyledContainer maxWidth="sm" data-testid="demo-product-view">
      <AppBar
        title={searchOrTitle}
        titleClickAction={() => setSearchVisible(true)}
        content={
          searchVisible && (
            <SearchContainer maxWidth="sm">
              <SearchInput
                placeholder={t('sic_search_placeholder')}
                onChange={(event) => setSearchTerm(event.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                inputRef={searchInputRef}
                autoFocus
                value={searchTerm}
              />
            </SearchContainer>
          )
        }
        displayBackButton
        aside={
          searchTerm ? (
            <IconButton
              onClick={() => {
                setSearchVisible(false);
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setSearchVisible(true);
                searchInputRef.current && searchInputRef.current.focus();
              }}
            >
              <SearchIcon />
            </IconButton>
          )
        }
      />
      <CollapsibleContainer data-testid="products_collapsible">
        {filteredProductsData &&
          filteredProductsData.map((category) => {
            const { categoryName, products, subCategories } = category || null;

            return subCategories?.length ? (
              <Product
                key={categoryName}
                title={categoryName}
                itemsSize={howManyItemsAreCheckedByAllSubCategories(
                  categoryName
                )}
                isExpanded={
                  !!isCategoryExpanded[categoryName] ||
                  isSearchTermLongEnough(searchTerm)
                }
                onChange={handleExpandedStateChange}
              >
                {subCategories.map(
                  ({ subCategoryName, products: subProducts }) => (
                    <CollapsibleContainer key={subCategoryName} $subCategory>
                      <ProductsListInCategory
                        name={subCategoryName}
                        products={subProducts}
                        handleExpandedStateChange={
                          handleExpandedSubCategoryStateChange
                        }
                        isCategoryExpanded={isSubCategoryExpanded}
                        searchTerm={searchTerm}
                        demoedProducts={demoedProducts}
                        howManyItemsAreCheckedByCategory={
                          howManyItemsAreCheckedByCategory
                        }
                        handleCheckboxChange={handleCheckboxChange}
                        isSubCategory
                        parentCategoryName={categoryName}
                      />
                    </CollapsibleContainer>
                  )
                )}
              </Product>
            ) : (
              <ProductsListInCategory
                key={categoryName}
                name={categoryName}
                products={products}
                handleExpandedStateChange={handleExpandedStateChange}
                isCategoryExpanded={isCategoryExpanded}
                searchTerm={searchTerm}
                demoedProducts={demoedProducts}
                howManyItemsAreCheckedByCategory={
                  howManyItemsAreCheckedByCategory
                }
                handleCheckboxChange={handleCheckboxChange}
                subCategory
              />
            );
          })}
      </CollapsibleContainer>
      {filteredProductsData.length === 0 && <NothingFound />}
    </StyledContainer>
  );
};

Products.propTypes = {
  title: PropTypes.string,
  productsFromStore: PropTypes.arrayOf(PropTypes.shape({})),
  productsData: PropTypes.arrayOf(PropTypes.shape({})),
  addProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  addProductsPerNote: PropTypes.func,
};

export default Products;
