import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDateWithMonthName } from 'frontend-components/lib/helpers';

import { calculatePriceWithDiscount } from '../../helpers/priceManipulations';

const Container = styled.div`
  padding: 24px 16px 16px 16px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin: 0 -16px;
  border-bottom: 1px solid #d8d8d8;

  label {
    font-size: 12px;
    color: #666666;
    display: block;
    margin-bottom: 4px;
    line-height: 16px;
  }

  .total {
    text-align: right;
    margin-left: 16px;
  }
`;

const Date = styled.span`
  line-height: 20px;
`;

const DiscountSection = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const Totals = ({ createdAt, currency, totalCost, dateLabel, discount }) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="totals">
      <div>
        <label>{dateLabel}</label>
        <Date>{formatDateWithMonthName(createdAt).toUpperCase()}</Date>
      </div>
      <div className="total" data-testid="total">
        {discount && discount !== '0.00' && (
          <DiscountSection>
            <label>{t('discount_label')}</label>
            <BoldText>{`${discount}%`}</BoldText>
          </DiscountSection>
        )}
        <label>{t('total_amount')}</label>
        <BoldText>
          {currency} {calculatePriceWithDiscount(totalCost, discount)}
        </BoldText>
      </div>
    </Container>
  );
};

Totals.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currency: PropTypes.string,
  totalCost: PropTypes.string,
  dateLabel: PropTypes.string,
  discount: PropTypes.string,
};

export default Totals;
