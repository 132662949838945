import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { saveAs } from 'file-saver';
import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, AsideButton } from 'frontend-components';

import { findPhoto } from '../../helpers/findPhoto';
import { fallbackImg } from '../../helpers/uploadPhoto';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import AppBar from '../../components/AppBar/AppBar.connected';
import UploadStatusIcon from '../../components/ImageUploader/UploadStatusIcon';
import { addPhoto } from '../../store/actions/photoActions';
import {
  setPhotoUploadingStart,
  clearPhoto,
} from '../../store/reducers/photosFromDevice';
import { clearPhotoInApplication } from '../../store/reducers/clientData';
import { cancelTokensMap } from '../../helpers/cancelTokens';

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 20px 0 10px;
  min-height: 200px;
  text-align: center;
`;

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  min-height: 200px;
`;

const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const StyledStatusText = styled.div`
  text-align: center;
  padding: 10px 0 24px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const GalleryItemViewInProgress = ({ clientData }) => {
  const { photoId, clientId, applicationId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [photo, setPhoto] = useState({});
  const [photoStatus, setPhotoStatus] = useState({});

  const { photosFromDevice, uploaded } = useSelector((state) => ({
    photosFromDevice: state.photosFromDevice.addedPhotos[clientId] || [],
    uploaded: state.photosFromDevice.upload || [],
  }));
  const { online } = useSelector((state) => state.offline);
  const { currentPhoto } = useSelector((state) => state.drafts);

  const addedPhotos = clientData.photos.data;

  const handleSave = () => {
    if (!photo?.link) return;

    saveAs(photo.link, photo.fileName);
  };

  const handleImageUploadProgress = (progressEvent, imageData) => {
    if (online)
      dispatch(
        setPhotoUploadingStart({
          photoId: imageData.photo.id,
        })
      );
  };

  const handleUploadAgain = () => {
    dispatch(addPhoto(clientId, null, photo, null, handleImageUploadProgress));
  };

  const handleDelete = () => {
    if (!photoStatus?.failed && !photoStatus?.uploaded) {
      if (cancelTokensMap.has(photo.id)) {
        cancelTokensMap.get(photo.id).cancel('');
        cancelTokensMap.delete(photo.id);
      }
      dispatch(clearPhoto({ id: photo.id, clientId }));
      if (applicationId) {
        dispatch(
          clearPhotoInApplication({
            photoId: photo.id,
            clientId,
            applicationId,
          })
        );
      }
    } else {
      dispatch(clearPhoto({ id: photoStatus.id, clientId }));
      if (applicationId) {
        dispatch(
          clearPhotoInApplication({
            photoId: photoStatus.id,
            clientId,
            applicationId,
          })
        );
      }
    }

    history.goBack();
  };

  useEffect(() => {
    const photosAndVideos = [...photosFromDevice];

    const id = photo.id || photoId;

    if (clientData) {
      setPhoto(findPhoto(addedPhotos, photosAndVideos, id));
      setPhotoStatus(uploaded[id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded, addedPhotos, currentPhoto]);

  useEffect(() => {
    if (currentPhoto.uuId === photoId) {
      history.replace(
        applicationId
          ? `/customers/${clientId}/applications/${applicationId}/gallery/${currentPhoto.targetId}`
          : `/customers/${clientId}/gallery/${currentPhoto.targetId}`
      );
    }
  }, [currentPhoto, clientId, history, photoId, applicationId]);

  return (
    <Container maxWidth="sm">
      <AppBar
        title="Photo details"
        displayBackButton
        elevated
        aside={
          <AsideButton onClick={handleDelete} data-testid="delete-button">
            <DeleteIcon />
          </AsideButton>
        }
      />
      {isEmpty(photo) ? (
        <StyledCircularProgress size={40} color="inherit" />
      ) : (
        <>
          <ImageWrapper>
            <StyledImg src={photo.fileUrl || photo.link || fallbackImg} />
          </ImageWrapper>

          {!photoStatus?.uploaded && (
            <StyledStatus>
              <UploadStatusIcon status={photoStatus} id={photoId} />
              <p>
                {photoStatus?.failed && t('file_stat_not_uploaded')}
                {photoStatus?.uploading && t('file_stat_uploaded')}
                {!photoStatus?.uploading &&
                  !photoStatus?.failed &&
                  t('file_stat_pending')}
              </p>
            </StyledStatus>
          )}
          {photoStatus?.failed && (
            <>
              <StyledStatusText>
                {t('file_stat_not_uploaded_inf')}
              </StyledStatusText>
              <StyledButton
                theme="primary"
                fullWidth
                onClick={handleUploadAgain}
              >
                {t('file_upload_again_btn')}
              </StyledButton>
            </>
          )}
          {!photoStatus?.failed &&
            !photoStatus?.uploaded &&
            !photoStatus?.cancelled && (
              <StyledStatusText>{t('file_stat_uploaded_inf')}</StyledStatusText>
            )}
          <StyledButton theme="secondary" fullWidth onClick={handleSave}>
            {t('file_save_device_btn')}
          </StyledButton>
        </>
      )}
    </Container>
  );
};

GalleryItemViewInProgress.propTypes = {
  clientData: clientDataShape,
};

export default GalleryItemViewInProgress;
