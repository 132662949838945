import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactPersonView from './ContactPersonView';
import {
  createContactPerson,
  editContactPerson,
} from '../../store/actions/contactPersonActions';
import {
  setNewOrderContactPerson,
  setNewOrderContactsCC,
} from '../../store/reducers/drafts';
import useCheckLocationState from '../../hooks/useCheckLocationState';
import { updateQrderOrQuotationExpandedPanel } from '../../store/reducers/clientUI';
import { ORDER_OR_QUOTATION_PANEL_TYPE } from '../../constants/lists';

const AddContactPersonToNewOrderView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId, contactPersonId } = useParams();

  const isFromSendCopyTo = useCheckLocationState('sendCopyTo');

  const handleNewOrderContactsCC = (contactPersonId) => {
    dispatch(setNewOrderContactsCC(contactPersonId));
    dispatch(
      updateQrderOrQuotationExpandedPanel({
        clientId,
        expandedPanel: ORDER_OR_QUOTATION_PANEL_TYPE.CC,
      })
    );
  };

  return (
    <ContactPersonView
      onSubmit={(values, isExistingContact) => {
        const newValues = {
          ...values,
          clientId,
        };
        if (isExistingContact) {
          dispatch(editContactPerson(contactPersonId, newValues, clientId));
        } else {
          dispatch(
            createContactPerson(contactPersonId, newValues, clientId, true)
          );

          isFromSendCopyTo
            ? handleNewOrderContactsCC(contactPersonId)
            : dispatch(setNewOrderContactPerson(contactPersonId));
        }
        history.push(`/customers/${clientId}/create/order`);
      }}
      goBackAction={() =>
        history.push(`/customers/${clientId}/create/order/addContactPerson`)
      }
      validateEmail={isFromSendCopyTo}
    />
  );
};

export default AddContactPersonToNewOrderView;
