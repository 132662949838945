import styled from 'styled-components';
import Search from '@material-ui/icons/Search';
import { Button } from 'frontend-components';
import Fab from '@material-ui/core/Fab';

export const SearchIcon = styled(Search)`
  color: #ffffff;
  font-size: 24px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin: 0 auto 24px auto;
`;

export const NoClientsTitle = styled.h2`
  color: #666666;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const NoClientsDescription = styled.p`
  text-align: center;
  margin: 4px;
  padding: 0 30px;

  svg {
    color: #999999;
    vertical-align: middle;
  }
`;

export const ReasonsTitle = styled.p`
  text-align: left;
  padding: 0 32px;
`;

export const ReasonsContainer = styled.div`
  padding: 0 32px;
  margin-top: 32px;

  p {
    text-align: left;
  }
`;

export const NoPlannedClientsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 48px;
`;

export const Version = styled.div`
  color: #666666;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding: 8px;
  text-align: right;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  color: #999999;
  flex-direction: column;
  align-items: flex-start;

  .MuiIconButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }
`;

export const AddClientButton = styled(Fab)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  z-index: 1;

  &.MuiFab-primary {
    background-color: #e42313;
  }
`;
