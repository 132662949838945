import Big from 'big.js';

const removeCommaStringToNum = (string) => parseFloat(string.replace(/,/g, ''));

const addCommaToThousands = (string) =>
  string.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const calculatePriceWithDiscount = (price, discount) => {
  if (!parseFloat(discount)) return price;
  const priceNum = new Big(removeCommaStringToNum(price));
  const discountNum = new Big(parseFloat(discount) / 100);
  const totalWithDiscount = priceNum
    .minus(priceNum.times(discountNum))
    .toFixed(2);
  return addCommaToThousands(totalWithDiscount);
};
