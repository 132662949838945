import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Products from './Products';
import withClientData from '../../../helpers/withClientData';
import { addProductsPerCallNote } from '../../../store/actions/demoProductsActions';
import { excludeMiscellaneous } from '../../../helpers/products';
import { clientDataShape } from '../../../helpers/clientDataPropTypes';

const CallProductsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, noteId } = useParams();

  const productsFromStore = clientData.phoneCalls.data.find(
    ({ id }) => id === noteId
  ).suggestedProducts;

  const productsData = useSelector((state) =>
    excludeMiscellaneous(state.products[clientId].data)
  );

  return (
    <Products
      title={t('suggested_products_title')}
      productsFromStore={productsFromStore}
      productsData={productsData}
      addProductsPerNote={addProductsPerCallNote}
    />
  );
};

CallProductsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(CallProductsView);
