import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CancelToken } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useRollbar } from '@rollbar/react';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { formatDate } from 'frontend-components/lib/helpers';
import UploaderButtons from './UploaderButtons';
import ErrorImage from '../Icons/ErrorImage';
import {
  VIDEO,
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_VIDEO_SIZE,
  MAXIMUM_FILES_COUNT_DCA,
  MAXIMUM_FILES_COUNT_AT_ONCE_DCA,
  SUPPORTED_FILE_TYPES,
  PHOTO,
} from '../../constants/photo';
import {
  uploadPhoto,
  dataURLtoFile,
  getFileNameWithPrefix,
} from '../../helpers/uploadPhoto';

import { getVideoMimeTypeSupported } from '../../helpers/video';

import {
  StyledFilesUploader,
  StyledWebcamWrapper,
  StyledWebcam,
  StyledError,
  StyledTimerWrapper,
} from './FilesUploaderDCA.styles';
import useDeviceOrientation from '../../hooks/useDeviceOrientation';
import Timer from './Timer';

const FilesUploaderDCA = ({
  allFiles,
  reportFiles,
  uploadedFiles,
  handleAddFile,
  disabled,
  clientName,
  tag,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const orientation = useDeviceOrientation();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { online } = useSelector((state) => state.offline);
  const rollbar = useRollbar();

  const [takingPhotoDisabled, setTakingPhotoDisabled] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isButtonActive, setButtonActive] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordError, setRecordError] = useState('');

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setIsRecording(true);
    const mimeType = getVideoMimeTypeSupported();
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setIsRecording, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  }, [mediaRecorderRef, setIsRecording]);

  const handleAddVideo = () => {
    if (recordedChunks.length) {
      const mimeType = getVideoMimeTypeSupported();

      if (!mimeType) {
        enqueueSnackbar(`${t('validation_video_type')}`, {
          variant: 'error',
        });
        return;
      }

      const type = SUPPORTED_FILE_TYPES[mimeType];
      const blob = new Blob(recordedChunks, { type: mimeType });
      setRecordedChunks([]);

      const uploadDate = new Date().toISOString();
      const source = CancelToken.source();
      const fileName = `${uploadDate}.${type}`
        .toLowerCase()
        .replace(/[^a-zA-Z0-9_\-.]+/g, '-');
      const data = {
        id: uuidv4(),
        link: URL.createObjectURL(blob),
        fileName,
        createdAt: uploadDate,
        updatedAt: uploadDate,
        description: '',
        tags: tag ? [tag] : [],
        mediaType: VIDEO,
      };

      const newFile = new File([blob], data.fileName);
      handleAddFile([{ file: newFile, data, source }]);
    }
  };

  const isValidNumber = (files) => {
    if (reportFiles + files.length > MAXIMUM_FILES_COUNT_DCA) {
      enqueueSnackbar(
        t('report_pic_limit', { number: MAXIMUM_FILES_COUNT_DCA }),
        {
          variant: 'error',
          autoHideDuration: null,
        }
      );
      return files.slice(0, MAXIMUM_FILES_COUNT_DCA - reportFiles);
    }

    if (uploadedFiles + files.length > MAXIMUM_FILES_COUNT_AT_ONCE_DCA) {
      enqueueSnackbar(
        t('report_pic_limit_at_once', {
          number: MAXIMUM_FILES_COUNT_AT_ONCE_DCA,
        }),
        {
          variant: 'error',
        }
      );
      return files.slice(0, MAXIMUM_FILES_COUNT_AT_ONCE_DCA - uploadedFiles);
    }
    return files;
  };

  const isValidType = ({ type }) => {
    const isValid = Object.keys(SUPPORTED_FILE_TYPES).includes(type);

    if (!isValid) {
      enqueueSnackbar(
        `${
          type.includes('video')
            ? t('validation_video_type')
            : t('validation_app_pic_type')
        }: ${type}`,
        {
          variant: 'error',
        }
      );
    }
    return isValid;
  };

  const isValidSize = ({ size, type }, maxSize) => {
    const isValid = size < maxSize;
    if (!isValid) {
      enqueueSnackbar(
        type.includes('video')
          ? t('validation_video_size')
          : t('validation_app_pic_size'),
        { variant: 'error' }
      );
    }
    return isValid;
  };

  const isValidOnline = () => {
    if (!online) {
      enqueueSnackbar(t('validation_video_offline'), { variant: 'error' });
    }
    return online;
  };

  const isPhotoValid = (file) => {
    return isValidType(file) && isValidSize(file, MAXIMUM_IMAGE_SIZE);
  };

  const isVideoValid = (file) => {
    return (
      isValidType(file) &&
      isValidSize(file, MAXIMUM_VIDEO_SIZE) &&
      isValidOnline
    );
  };

  const getFileData = (file, type) => {
    const uploadDate = formatDate(new Date());

    return {
      id: uuidv4(),
      fileName: getFileNameWithPrefix(uploadDate, file, clientName),
      createdAt: uploadDate,
      updatedAt: uploadDate,
      description: '',
      mediaType: type,
    };
  };

  const handleCapturePhoto = async () => {
    const screenshot = webcamRef.current.getScreenshot();
    const source = CancelToken.source();

    const data = getFileData(null, PHOTO);

    if (screenshot && !takingPhotoDisabled) {
      const file = dataURLtoFile(screenshot, data.fileName);
      data.link = await uploadPhoto(file);

      const newFile = new File([screenshot], data.fileName);

      rollbar.info('screenshot', screenshot);
      rollbar.info('file', { size: file.size });
      rollbar.info('data', data.link);

      handleAddFile([{ file: newFile, data, source }]);

      setTakingPhotoDisabled(true);

      setTimeout(() => {
        setTakingPhotoDisabled(false);
      }, 500);
    }
  };

  const handleSelectFile = async (e) => {
    const files = isValidNumber(Array.from(e.target.files));

    if (isEmpty(files)) {
      return;
    }

    const photos = files.filter((file) => file.type.includes('image'));
    const videos = files.filter((file) => file.type.includes('video'));

    if (videos.length > 0) {
      videos.forEach((file) => {
        if (!isVideoValid(file)) {
          return;
        }

        const source = CancelToken.source();
        const data = getFileData(file, VIDEO);

        data.link = URL.createObjectURL(file);

        const newFile = new File([file], data.fileName);

        handleAddFile([{ file: newFile, data, source }]);
      });
    }

    await Promise.all(
      [...photos].map(async (file) => {
        if (!isPhotoValid(file)) {
          return;
        }

        const source = CancelToken.source();
        const data = getFileData(file, PHOTO);

        data.link = await uploadPhoto(file);

        const newFile = new File([data.link], data.fileName);

        handleAddFile([{ file: newFile, data, source }]);
      })
    );
  };
  const handleOnMaxTime = () => {
    enqueueSnackbar(t('validation_video_too_long'), { variant: 'error' });
    handleStopCaptureClick();
  };

  useEffect(() => {
    if (allFiles.length >= MAXIMUM_FILES_COUNT_AT_ONCE_DCA) {
      enqueueSnackbar(
        t('report_pic_limit_at_once', {
          number: MAXIMUM_FILES_COUNT_AT_ONCE_DCA,
        }),
        {
          variant: 'error',
        }
      );
      setButtonActive(false);
    } else if (reportFiles >= MAXIMUM_FILES_COUNT_DCA) {
      enqueueSnackbar(
        t('report_pic_limit', { number: MAXIMUM_FILES_COUNT_DCA }),
        {
          variant: 'error',
          autoHideDuration: null,
        }
      );
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, reportFiles]);

  useEffect(() => {
    handleAddVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  return (
    <StyledFilesUploader>
      <StyledWebcamWrapper>
        {!recordError && (
          <StyledWebcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize
            onUserMediaError={(error) => {
              setRecordError(isString(error) ? error : error.message);
            }}
            width={orientation === 'landscape' ? 1920 : 1080}
            height={orientation === 'landscape' ? 1080 : 1920}
            videoConstraints={{
              facingMode: 'environment',
            }}
            screenshotQuality={1}
          />
        )}
        {recordError && (
          <StyledError>
            <ErrorImage />
            {recordError}
          </StyledError>
        )}
        {isRecording && (
          <StyledTimerWrapper>
            <Timer onMaxTime={handleOnMaxTime} />
          </StyledTimerWrapper>
        )}
      </StyledWebcamWrapper>
      <UploaderButtons
        handleCapturePhoto={handleCapturePhoto}
        handleStartCaptureClick={handleStartCaptureClick}
        handleStopCaptureClick={handleStopCaptureClick}
        handleSelectFile={handleSelectFile}
        isRecording={isRecording}
        photoDisabled={!!recordError || !isButtonActive || disabled}
        videoDisabled={
          !getVideoMimeTypeSupported() || !!recordError || !isButtonActive
        }
        galleryDisabled={!isButtonActive || disabled}
        disableRecording
      />
    </StyledFilesUploader>
  );
};

FilesUploaderDCA.propTypes = {
  allFiles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
  reportFiles: PropTypes.number,
  uploadedFiles: PropTypes.number,
  handleAddFile: PropTypes.func,
  disabled: PropTypes.bool,
  clientName: PropTypes.string,
  tag: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
};

export default FilesUploaderDCA;
