import { useState, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'frontend-components';

import { useSnackbar } from 'notistack';
import Cookies from 'universal-cookie';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Input from '../../components/Input/Input';
import { StyledLoginView } from './StyledLoginView';
import { login } from '../../services/auth.service';
import {
  logIn,
  setAccessDenied,
  setAccessDeniedDisplayed,
  setAuthErrorDisplayed,
} from '../../store/reducers/userSlice';
import { redirectToICTLoginForgotPassword } from '../../helpers/redirects';

const LoginView = () => {
  const cookies = new Cookies();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    accessDenied,
    accessDeniedDisplayed,
    authErrorDisplayed,
  } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    if (accessDenied && !accessDeniedDisplayed) {
      enqueueSnackbar(t('access_denied'), { variant: 'error' });
      dispatch(setAccessDeniedDisplayed(true));
    }
  }, [accessDenied, accessDeniedDisplayed, dispatch, enqueueSnackbar, t]);

  useEffect(() => {
    if (from?.pathname !== '/' && !isLoggedIn && !authErrorDisplayed) {
      enqueueSnackbar(t('authorization_error'), { variant: 'error' });
      dispatch(setAuthErrorDisplayed(true));
    }
  }, [isLoggedIn, enqueueSnackbar, t, from, dispatch, authErrorDisplayed]);

  const { trackEvent } = useMatomo();

  const handleSubmit = (e) => {
    trackEvent({
      category: 'LoginCatogory',
      action: 'LoginAction',
      name: 'LoginName',
      value: 0,
    });
    e.preventDefault();
    dispatch(setAccessDenied(false));
    dispatch(setAccessDeniedDisplayed(false));
    login(email, password)
      .then((res) => {
        if (res.token) {
          cookies.set('token', res.token, { path: '/', maxAge: 31536000 });
          cookies.set('refresh_token', res.refresh_token, {
            path: '/',
            maxAge: 31536000,
          });
          dispatch(logIn());
          history.replace(from);
          dispatch(setAuthErrorDisplayed(false));
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message
            ? t(error?.response.data.message)
            : t('error_page_title'),
          { variant: 'error' }
        );
      });
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <StyledLoginView>
      <title>{t('login_title')}</title>
      <span className="subtitle">{t('login_description')}</span>
      <form onSubmit={handleSubmit}>
        <Input
          data-testid="email-input"
          label={t('login_user')}
          fullWidth
          value={email}
          onchange={setEmail}
        />
        <Input
          data-testid="password-input"
          label={t('login_password')}
          password
          fullWidth
          value={password}
          onchange={setPassword}
        />
        <div
          className="forgotPassword"
          onClick={() => {
            redirectToICTLoginForgotPassword();
          }}
        >
          {t('forgot_password')}
        </div>
        <Button
          type="submit"
          theme="primary"
          fullWidth
          onClick={handleSubmit}
          data-testid="login-button"
        >
          {t('login_button')}
        </Button>
      </form>
    </StyledLoginView>
  );
};

export default LoginView;
