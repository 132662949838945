import styled from 'styled-components';

export const StyledLoginView = styled.div`
  padding: 88px 39px;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  color: #000000;

  title {
    display: block;
    align-self: center;
    font-size: 32px;
    font-weight: bolder;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }
  .subtitle {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  & .MuiTextField-root:first-child {
    margin-bottom: 2rem;
  }

  & .MuiButton-root {
    margin-top: 1rem;
  }

  .forgotPassword {
    display: inline-block;
    margin: 1.5rem 0 2rem 0;
    font-size: 14px;
    cursor: pointer;
  }
`;
