import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Big from 'big.js';
import Dialog from '../../../components/Dialog/Dialog';
import {
  StyledButton,
  StyledDialogActions,
} from '../../../components/Dialog/DeleteDialog/DeleteDialog';
import Input from '../../../components/Input/Input';
import DialogHeader from '../../../components/Dialog/DialogHeader';

const StyledDialogContent = styled(DialogContent)`
  height: 80px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledInput = styled(Input)`
  margin-top: 10px;
  max-width: 110px;
`;

const DiscountDialog = ({ open, setOpen, discountProp, setDiscount }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    discount: Yup.number()
      .transform((value, originalValue) =>
        parseFloat(originalValue.replace(/,/g, '.'))
      )
      .typeError(t('validation_percentage'))
      .positive(t('validation_percentage'))
      .min(0, t('validation_percentage'))
      .max(100, t('validation_percentage')),
  });

  const { setFieldValue, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      discount: discountProp || '',
    },
    validationSchema,
  });

  useEffect(() => {
    open && setFieldValue('discount', discountProp);
  }, [open, discountProp, setFieldValue]);

  const { discount } = values;

  return (
    <Dialog
      data-testid="discount-dialog"
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogHeader
        title={t('discount_dialog_title')}
        handleClose={() => setOpen(false)}
        subtitle={t('discount_dialog_subtitle')}
      />
      <StyledDialogContent>
        <StyledInput
          id="discount"
          label={t('discount_label')}
          onchange={(value) => {
            setFieldValue('discount', value);
          }}
          onBlur={() => {
            if (discount) {
              const value = new Big(discount.replace(/,/g, '.') || 0);
              setFieldValue(
                'discount',
                value.toNumber() === 0 ? '' : value.toFixed(1)
              );
            }
          }}
          value={discount}
          type="number"
          rightText="%"
          errorMessage={errors && errors.discount}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton
          theme="secondary"
          aria-label="cancel"
          onClick={() => {
            setOpen(false);
          }}
          data-testid="cancel"
        >
          {t('discount_dialog_cancel')}
        </StyledButton>
        <StyledButton
          autoFocus
          aria-label="done"
          onClick={() => {
            setDiscount(discount);
            setOpen(false);
          }}
          theme="primary"
          disabled={errors?.discount}
        >
          {t('discount_dialog_done')}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

DiscountDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  discountProp: PropTypes.string,
  setDiscount: PropTypes.func,
};
export default DiscountDialog;
