function ErrorImage(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={168} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect fill="#FFF" x={43} y={12} width={82} height={144} rx={8} />
        <path
          d="M118 11a8 8 0 018 8v130a8 8 0 01-8 8H50a8 8 0 01-8-8V19a8 8 0 018-8h68zm0 2H50a6 6 0 00-5.996 5.775L44 19v130a6 6 0 005.775 5.996L50 155h68a6 6 0 005.996-5.775L124 149V19a6 6 0 00-5.775-5.996L118 13zM88 149a1 1 0 110 2h-8a1 1 0 110-2h8zm28-134a6 6 0 016 6v118a6 6 0 01-6 6H52a6 6 0 01-6-6V21a6 6 0 016-6h64zm0 2H52a4 4 0 00-3.995 3.8L48 21v118a4 4 0 003.8 3.995l.2.005h64a4 4 0 003.995-3.8l.005-.2V21a4 4 0 00-3.8-3.995L116 17zm-94.707 96.293l1.414 1.414L20.414 117l2.293 2.293-1.414 1.414L19 118.414l-2.293 2.293-1.414-1.414L17.586 117l-2.293-2.293 1.414-1.414L19 115.586l2.293-2.293zm63.511-21.417c5.331 0 9.537 5.544 9.667 12.336l.003.324v.511l-.008 1.146-.013.697-.02.647-.014.307-.035.58-.044.539c-.165 1.732-.512 2.843-1.199 3.906-1.348 2.087-3.842 3.07-7.947 3.128l-.39.003-.636-.006-.608-.018c-5.249-.213-7.392-1.88-8.114-6.097l-.043-.262-.069-.5-.058-.524-.025-.27-.042-.558-.032-.581-.023-.605-.008-.31-.01-.642-.002-1.09c0-6.94 4.254-12.66 9.67-12.66zm0 2c-4.088 0-7.547 4.555-7.667 10.348l-.003.311.002 1.075.007.608.014.58.022.552.03.524.038.497.048.472c.37 3.197 1.486 4.536 4.44 4.977l.25.034.452.05.236.021.49.034.52.023.269.008.277.006.575.004.286-.001.279-.003.536-.013.258-.01.616-.034.119-.009.457-.041.22-.025.42-.059c2.637-.416 3.767-1.58 4.214-4.25l.04-.254.058-.44.025-.23.043-.477.035-.504.026-.53.01-.276.021-.867.006-.614.001-.827c0-5.94-3.509-10.66-7.67-10.66zM104.5 75a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-42 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-.707-19c2.2 0 4.32.562 6.196 1.618a1 1 0 01-.98 1.743A10.606 10.606 0 0061.793 58c-1.532 0-3.017.323-4.382.939a1 1 0 01-.822-1.823A12.617 12.617 0 0161.793 56zm42.453 0c2.2 0 4.32.562 6.197 1.618a1 1 0 11-.98 1.743A10.606 10.606 0 00104.246 58a10.61 10.61 0 00-4.38.939 1 1 0 01-.824-1.823A12.617 12.617 0 01104.246 56zM142 35a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6zM25 14a7 7 0 110 14 7 7 0 010-14zm0 2a5 5 0 100 10 5 5 0 000-10z"
          fill="#CCC"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default ErrorImage;
