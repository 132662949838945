import { Collapse } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding: 10px 0px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
`;

const IconButtonContainer = styled(IconButton)`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CollapsibleSection = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>{title}</TitleContainer>
        <IconButtonContainer
          size="small"
          onClick={() => setExpanded(!expanded)}
        >
          {t(expanded ? 'app_show_less' : 'app_show_more')}
          <IconContainer>
            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconContainer>
        </IconButtonContainer>
      </HeaderContainer>
      <div>
        <Collapse in={expanded} timeout="auto">
          {children}
        </Collapse>
      </div>
    </Container>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CollapsibleSection;
