import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Divider, Button } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import withClientData from '../../helpers/withClientData';
import Client from '../../components/Client/Client';
import Totals from '../../components/Totals/Totals';
import NoData from '../../components/NoData/NoData';
import { TAB_QUOTATIONS } from '../../constants/orders';
import ProductListHeader from '../../components/ProductsListHeader/ProductListHeader';
import ProductsList from '../../components/Lists/ProductsList';
import TextArea from '../../components/TextArea/TextArea';
import {
  TextAreaContainer,
  DetailField,
  DetailLabel,
  DetailValue,
} from './QuotationDetailsView.styles';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import FixedFooter, {
  ButtonContainer,
} from '../../components/FixedFooter/FixedFooter';
import { createNewOrder, setNewOrder } from '../../store/reducers/drafts';
import { getPackageLabel } from '../../helpers/packaging';
import { getAllProductsByGroupCode } from '../../helpers/products';

const Content = styled.div`
  padding-bottom: 96px;
`;

const QuotationDetailsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, quotationId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { name, orderHistory, contactPersons } = clientData;
  const productsData = useSelector((state) => state.products[clientId].data);

  const quotation =
    orderHistory &&
    orderHistory.quotations &&
    orderHistory.quotations.find((quotation) => quotation.id === quotationId);

  const {
    createdAt,
    currency,
    totalCost,
    products,
    contactPersonId,
    contactsCC,
    clientQuotationNumber,
    note,
    internalNote,
    emailNote,
    referenceNumber,
    deliveryCost,
    discount,
  } = quotation;

  const backButtonAction = () => {
    history.push(`/customers/${clientId}/orderHistory`, {
      from: TAB_QUOTATIONS,
    });
  };

  const productsToPackaging = () => {
    const result = {};
    const alreadyAdded = [];

    const productsList = getAllProductsByGroupCode(productsData);
    products.map((product) => {
      return productsList.forEach(({ name, code, variants }) => {
        return variants.map((variant) => {
          if (variant.code === product.code) {
            if (!result[code]) result[code] = [];

            const isAlreadyAdded = alreadyAdded.indexOf(product.id);

            if (isAlreadyAdded < 0) {
              alreadyAdded.push(product.id);
              result[code] = [
                ...result[code],
                {
                  code: variant.code,
                  name,
                  packageUnit: variant.setCapacity,
                  packageType: variant.packageName,
                  packageCapacity: variant.packageCapacity,
                  price: product.price,
                  currency: product.currency,
                  quantity: product.quantity,
                  label: getPackageLabel(
                    variant.packageCapacity,
                    variant.packageName
                  ),
                  createdAt: new Date(),
                },
              ];
            }

            return variant;
          }
          return false;
        });
      });
    });

    return result;
  };

  const mapQuotationToOrder = () => {
    return {
      quotationId,
      contactPersonId,
      customerOrderNumber: clientQuotationNumber,
      createdAt,
      currency,
      totalCost,
      packagingData: productsToPackaging(),
      contactsCC,
      clientQuotationNumber,
      note,
      internalNote,
      emailNote,
      referenceNumber,
      deliveryCost,
      discount,
    };
  };

  const handleChangeIntoOrder = () => {
    dispatch(createNewOrder());
    dispatch(setNewOrder(mapQuotationToOrder()));
    history.push(`/customers/${clientId}/create/order`);
  };

  const contactPerson = contactPersons.data.find(
    ({ id }) => id === contactPersonId
  );

  const contactCCData = contactsCC?.length
    ? contactPersons.data.filter(({ id }) => contactsCC.includes(id))
    : [];

  mapQuotationToOrder();

  return (
    <Container maxWidth="sm" data-testid="quotation-details">
      <AppBar
        title={t('quotation_title')}
        displayBackButton
        backButtonAction={backButtonAction}
      />
      <Content>
        {quotation ? (
          <>
            <Client name={name} />
            <Totals
              createdAt={createdAt}
              currency={currency}
              totalCost={totalCost}
              dateLabel={t('quotation_total_date')}
              discount={discount}
            />
            <ProductListHeader
              title={t('quotation_subtitle')}
              price={t('unit_price')}
            />
            {products && <ProductsList products={products} />}
            <DetailField>
              <div>
                <DetailLabel>{t('contact_person_title')}</DetailLabel>
                <DetailValue>{`${contactPerson.firstName} ${contactPerson.lastName}`}</DetailValue>
              </div>
              <ContactsIcon />
            </DetailField>
            <Divider />
            <DetailField>
              <div>
                <DetailLabel>{t('send_copy_to')}</DetailLabel>
                {contactCCData.length
                  ? contactCCData.map(({ firstName, lastName, id }) => (
                      <DetailValue
                        key={id}
                      >{`${firstName} ${lastName}`}</DetailValue>
                    ))
                  : '-'}
              </div>
            </DetailField>
            <Divider />
            <DetailField>
              <div>
                <DetailLabel>{t('create_order_num')}</DetailLabel>
                <DetailValue>{clientQuotationNumber || '-'}</DetailValue>
              </div>
            </DetailField>
            <Divider />
            <DetailField>
              <div>
                <DetailLabel>{t('quote_reference_number')}</DetailLabel>
                <DetailValue>{referenceNumber || '-'}</DetailValue>
              </div>
            </DetailField>
            <Divider />
            <TextAreaContainer>
              <TextArea
                id="note"
                label={t('create_quot_note_label')}
                fullWidth
                value={note || ''}
                disabled
                rowsMin={3}
              />
            </TextAreaContainer>
            <TextAreaContainer>
              <TextArea
                id="emailNote"
                label={t('create_quot_email_label')}
                fullWidth
                value={emailNote || ''}
                disabled
                rowsMin={3}
              />
            </TextAreaContainer>
            <TextAreaContainer>
              <TextArea
                id="internalNote"
                label={t('create_quot_internal_label')}
                fullWidth
                value={internalNote || ''}
                disabled
                rowsMin={3}
              />
            </TextAreaContainer>
          </>
        ) : (
          <NoData noDataMessage={t('quotation_no_data')} />
        )}
      </Content>
      <FixedFooter>
        <ButtonContainer>
          <Button theme="primary" onClick={() => handleChangeIntoOrder()}>
            {t('change_into_order')}
          </Button>
        </ButtonContainer>
      </FixedFooter>
    </Container>
  );
};

QuotationDetailsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(QuotationDetailsView);
