import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dialog from '../Dialog';
import {
  StyledButton,
  StyledDialogActions,
} from '../DeleteDialog/DeleteDialog';
import DialogHeader from '../DialogHeader';

const CreateCallReportDialog = ({ open, setOpen, createCallReport }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="discount-dialog"
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogHeader
        title={t('call_dialog_title')}
        handleClose={() => setOpen(false)}
        subtitle={t('call_dialog_subtitle')}
      />
      <StyledDialogActions>
        <StyledButton
          theme="secondary"
          aria-label="cancel"
          onClick={() => {
            setOpen(false);
          }}
          data-testid="cancel"
        >
          {t('call_dialog_cancel')}
        </StyledButton>
        <StyledButton
          autoFocus
          aria-label="create"
          onClick={() => {
            createCallReport();
            setOpen(false);
          }}
          theme="primary"
        >
          {t('call_dialog_create')}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

CreateCallReportDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  createCallReport: PropTypes.func,
};
export default CreateCallReportDialog;
