import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClientAddressView from './ClientAddressView';
import { updateNewClientDetails } from '../../store/reducers/client';

const NewClientAddressView = () => {
  const { clientId } = useParams();
  const { newClientListItem } = useSelector((state) => state.client);

  return (
    <ClientAddressView
      updateClientDetails={updateNewClientDetails}
      detailsPath={`/customers/${clientId}/client/create`}
      clientListItem={newClientListItem}
    />
  );
};

export default NewClientAddressView;
