import PropTypes from 'prop-types';
import FileWordIcon from '../Icons/FileWordIcon';
import FileVideoIcon from '../Icons/FileVideoIcon';
import FilePDFIcon from '../Icons/FilePDFIcon';
import FileTextIcon from '../Icons/FileTextIcon';
import FileExcelIcon from '../Icons/FileExcelIcon';
import FileImageIcon from '../Icons/FileImageIcon';
import FileOtherIcon from '../Icons/FileOtherIcon';

const IMAGE_EXT = ['jpg', 'png', 'jpeg', 'bmp', 'gif', 'tif', 'tiff'];

const EXCEL_EXT = ['xlsx', 'xlsm', 'xlsb', 'xls', 'csv'];

const WORD_EXT = ['doc', 'docx', 'docm'];

const PDF_EXT = ['pdf'];

const TEXT_EXT = ['txt', 'odt'];

const VIDEO_EXT = [
  'mp4',
  'mov',
  'wmv',
  'avi',
  'flv',
  'mkv',
  'webm',
  'm4v',
  'mpg',
  'mts',
  'asf',
  'f4v',
  '3gp',
];

const mapExtToIcon = (fileExt) => {
  if (IMAGE_EXT.includes(fileExt)) {
    return <FileImageIcon />;
  }

  if (EXCEL_EXT.includes(fileExt)) {
    return <FileExcelIcon />;
  }

  if (WORD_EXT.includes(fileExt)) {
    return <FileWordIcon />;
  }

  if (PDF_EXT.includes(fileExt)) {
    return <FilePDFIcon />;
  }

  if (VIDEO_EXT.includes(fileExt)) {
    return <FileVideoIcon />;
  }

  if (TEXT_EXT.includes(fileExt)) {
    return <FileTextIcon />;
  }

  return <FileOtherIcon />;
};

const FileIcon = ({ fileExt }) => {
  return mapExtToIcon(fileExt);
};

FileIcon.propTypes = {
  fileExt: PropTypes.string.isRequired,
};

export default FileIcon;
