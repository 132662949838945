import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppBar from '../../components/AppBar/AppBar.connected';
import withClientData from '../../helpers/withClientData';
import List from '../../components/List/List';
import { TAB_PRODUCTS } from '../../constants/orders';
import Product from '../../components/Product/Product';
import NoData from '../../components/NoData/NoData';
import ProductListHeader from '../../components/ProductsListHeader/ProductListHeader';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ProductName = styled.div`
  margin-top: 24px;
  height: 20px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
`;

const ProductDetailsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, productGroupCode } = useParams();
  const history = useHistory();
  const { orderHistory } = clientData;

  const product =
    orderHistory &&
    orderHistory.products &&
    orderHistory.products.find(
      (product) => product.groupCode.toString() === productGroupCode
    );

  const backButtonAction = () => {
    history.push(`/customers/${clientId}/orderHistory`, {
      from: TAB_PRODUCTS,
    });
  };

  return (
    <Container maxWidth="sm" data-testid="product-details">
      <AppBar
        title={t('product_title')}
        displayBackButton
        backButtonAction={backButtonAction}
      />
      {product && product.products.length ? (
        <>
          <ProductName>{product.name || product.groupCode}</ProductName>
          <ProductListHeader
            title={t('product_subtitle')}
            price={t('unit_price')}
          />
          <List data-testid="details-products">
            {product.products &&
              product.products.map((product) => {
                const {
                  id,
                  packageType,
                  packageCapacity,
                  quantity,
                  price,
                  currency,
                  orderedAt,
                } = product;

                const packageDetails = {
                  packageCapacity,
                  packageType,
                };

                return (
                  <Product
                    key={id}
                    packageDetails={packageDetails}
                    quantity={quantity}
                    currency={currency}
                    price={price}
                    orderedAt={orderedAt}
                  />
                );
              })}
          </List>
        </>
      ) : (
        <NoData noDataMessage={t('product_no_data')} />
      )}
    </Container>
  );
};

ProductDetailsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ProductDetailsView);
