import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getProductsByClient } from '../../services/products.service';

export const fetchProductsInCategories = createAsyncThunk(
  'productsData/fetchByCategoryId',
  async ({ clientId }) => {
    return getProductsByClient({ clientId });
  }
);

const productsDataSlice = createSlice({
  name: 'productsData',
  initialState: {},
  reducers: {
    addProductsStore: (state, action) => {
      const { clientId, products } = action.payload;
      if (!state[clientId]) {
        state[clientId] = {};
      }
      state[clientId].data = products;
      state[clientId].loadingStatus = 'fetched';
    },
  },
  extraReducers: {
    [fetchProductsInCategories.pending]: (state, action) => {
      const { clientId } = action.meta.arg;
      if (!state[clientId]) {
        state[clientId] = {};
      }
      state[clientId].loadingStatus = 'fetching';
    },
    [fetchProductsInCategories.fulfilled]: (state, action) => {
      const { clientId } = action.meta.arg;
      state[clientId].data = action.payload.data;
      state[clientId].loadingStatus = 'fetched';
    },
    [fetchProductsInCategories.rejected]: (state, action) => {
      const { clientId } = action.meta.arg;
      state[clientId].loadingStatus = 'not-fetched';
    },
  },
});

export const { addProductsStore } = productsDataSlice.actions;
export default productsDataSlice.reducer;
