import { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonIcon from '@material-ui/icons/Person';
import FolderIcon from '@material-ui/icons/Folder';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { StyledIconLineList } from '../../views/SelectCustomerView/CustomersListy.styles';
import {
  createDepartment,
  createTileContent,
  DEFAULT_TILE_LINES,
} from './TileContent';

import { CollapseButton, CollapsibleWrapper } from './Card.styles';
import { contactPersonDataShape } from '../../helpers/clientDataPropTypes';

const CardCollapsed = ({
  tileLines,
  address,
  department,
  showDepartment,
  contactPersonId,
  contactPersons,
  clientNumber,
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const contactPerson = contactPersons.find(({ id }) => id === contactPersonId);

  return (
    <CollapsibleWrapper>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {showDepartment && department && (
          <StyledIconLineList>
            <ApartmentIcon fontSize="small" />
            {showDepartment && createDepartment(department)}
          </StyledIconLineList>
        )}
        {address && (
          <StyledIconLineList>
            <LocationOnIcon fontSize="small" />
            {createTileContent(tileLines || DEFAULT_TILE_LINES, address)}
          </StyledIconLineList>
        )}

        {contactPersonId && (
          <StyledIconLineList>
            <PersonIcon fontSize="small" />
            <div>
              {contactPerson?.firstName} {contactPerson?.lastName}
            </div>
          </StyledIconLineList>
        )}
        {clientNumber && (
          <StyledIconLineList>
            <FolderIcon fontSize="small" />
            <div>{clientNumber}</div>
          </StyledIconLineList>
        )}
      </Collapse>
      <CollapseButton onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <ExpandLessIcon color="secondary" />
        ) : (
          <ExpandMoreIcon color="secondary" />
        )}
      </CollapseButton>
    </CollapsibleWrapper>
  );
};

CardCollapsed.propTypes = {
  tileLines: PropTypes.arrayOf(PropTypes.string),
  department: PropTypes.string,
  address: PropTypes.string,
  contactPersons: PropTypes.arrayOf(contactPersonDataShape),
  contactPersonId: PropTypes.string,
  clientNumber: PropTypes.string,
  showDepartment: PropTypes.bool,
  isExpanded: PropTypes.bool,
};

export default CardCollapsed;
