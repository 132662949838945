import styled from 'styled-components';
import Search from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Container from '@material-ui/core/Container';
import { Divider } from 'frontend-components';

export const SearchIcon = styled(Search)`
  color: #ffffff;
  font-size: 24px;
`;

export const Input = styled(InputBase)`
  width: 100%;

  input {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 0;
  }
`;

export const SearchContainer = styled(Container)`
  padding-left: 24px;
  padding-right: 24px;
`;

export const DarkDivider = styled(Divider)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const Filters = styled.div`
  margin-bottom: 12px;
`;
