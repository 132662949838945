export default function ConfirmationIcon() {
  return (
    <svg width="168px" height="168px" viewBox="0 0 168 168" version="1.1">
      <title>Confirmation Icon</title>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="12.8.have_a_question"
          transform="translate(-104.000000, -236.000000)"
        >
          <g id="Group-8" transform="translate(15.000000, 193.000000)">
            <g id="Group-3" transform="translate(89.000000, 43.000000)">
              <circle
                id="Oval"
                fill="#FFFFFF"
                fillRule="nonzero"
                cx="83.2928932"
                cy="95"
                r="58"
              />
              <polygon
                id="Path"
                fill="#CCCCCC"
                fillRule="nonzero"
                points="27.2928932 37.2928932 28.7071068 38.7071068 26.4138932 40.9998932 28.7071068 43.2928932 27.2928932 44.7071068 24.9998932 42.4138932 22.7071068 44.7071068 21.2928932 43.2928932 23.5858932 40.9998932 21.2928932 38.7071068 22.7071068 37.2928932 24.9998932 39.5858932"
              />
              <path
                d="M133,151 C135.761424,151 138,153.238576 138,156 C138,158.761424 135.761424,161 133,161 C130.238576,161 128,158.761424 128,156 C128,153.238576 130.238576,151 133,151 Z M133,153 C131.343146,153 130,154.343146 130,156 C130,157.656854 131.343146,159 133,159 C134.656854,159 136,157.656854 136,156 C136,154.343146 134.656854,153 133,153 Z"
                id="Combined-Shape"
                fill="#CCCCCC"
                fillRule="nonzero"
              />
              <path
                d="M119,15 C122.865993,15 126,18.1340068 126,22 C126,25.8659932 122.865993,29 119,29 C115.134007,29 112,25.8659932 112,22 C112,18.1340068 115.134007,15 119,15 Z M119,17 C116.238576,17 114,19.2385763 114,22 C114,24.7614237 116.238576,27 119,27 C121.761424,27 124,24.7614237 124,22 C124,19.2385763 121.761424,17 119,17 Z"
                id="Combined-Shape"
                fill="#CCCCCC"
                fillRule="nonzero"
              />
              <path
                d="M83,36 C50.4151998,36 24,62.4151998 24,95 C24,127.5848 50.4151998,154 83,154 C115.5848,154 142,127.5848 142,95 C142,62.4151998 115.5848,36 83,36 Z M83,38 C114.480231,38 140,63.5197693 140,95 C140,126.480231 114.480231,152 83,152 C51.5197693,152 26,126.480231 26,95 C26,63.5197693 51.5197693,38 83,38 Z"
                id="Oval"
                fill="#CCCCCC"
                fillRule="nonzero"
              />
              <polyline
                id="Path-2"
                stroke="#CCCCCC"
                strokeWidth="2"
                points="61.7173157 99.2173157 76 113.5 109.5 80"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
