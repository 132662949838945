import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SignaturePad from 'react-signature-pad-wrapper';
import trimCanvas from 'trim-canvas';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { Button } from 'frontend-components';

import AppBar from '../../../components/AppBar/AppBar.connected';
import FixedFooter from '../../../components/FixedFooter/FixedFooter';
import {
  setOrderSignature,
  setOrderSignatureNotTrimmed,
} from '../../../store/reducers/drafts';
import OrderConfirmationDialog from '../../../components/Dialog/OrderConfirmationDialog/OrderConfirmationDialog';
import useTermsAndSignatureOptions from '../../../hooks/useTermsAndSignatureOptions';

const SignatureHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0;
  justify-content: space-between;
  align-items: flex-end;
`;

const SignatureSubTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin: 0;
`;

const ClearButton = styled(Button)`
  padding: 8px 16px;
  font-size: 12px;
  height: auto;
`;

const FinishButtonsContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  justify-content: center;

  .MuiButton-root {
    margin: 0 8px;
  }

  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: inherit;
  }
`;

const SignatureContainer = styled.div`
  canvas {
    height: calc(100vh - 56px - 82px - 96px);
  }
`;

const resizeCanvas = (canvas, signaturePad) => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  signaturePad.clear();
};

const cloneCanvas = (oldCanvas) => {
  // create a new canvas
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;

  // apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0);

  return newCanvas;
};

const SignatureView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true);
  const signaturePadRef = useRef();
  const { clientId } = useParams();

  const { signBinaryNotTrimmed, contactPersonId, contactsCC } = useSelector(
    (state) => state.drafts.newOrder
  );
  const { contactPersons } = useSelector(
    (state) => state.clientData.data[clientId]
  );

  const contactPersonData = contactPersons.data.find(
    (contact) => contact.id === contactPersonId
  );
  const isSendActionActive =
    contactPersonData.emailsList.length || contactsCC?.length;

  const {
    signatureFooterButtonAction,
    showConfirmationDialog,
    setShowConfirmationDialog,
    finishAndSendAction,
    finishWithoutSendingAction,
  } = useTermsAndSignatureOptions(true);

  const setCanvasEmptyState = () => {
    setIsEmpty(signaturePadRef?.current?.isEmpty());
  };

  useEffect(() => {
    resizeCanvas(signaturePadRef.current.canvas, signaturePadRef.current);
  }, []);

  useEffect(() => {
    if (signBinaryNotTrimmed) {
      signaturePadRef.current.fromDataURL(signBinaryNotTrimmed);
      setCanvasEmptyState();
    }
  }, [signBinaryNotTrimmed]);

  const handleClearButton = () => {
    signaturePadRef.current.clear();
    dispatch(setOrderSignature(''));
    dispatch(setOrderSignatureNotTrimmed(''));
    setCanvasEmptyState();
  };

  const handleTouchEnd = () => {
    setCanvasEmptyState();
    const clonedCanvas = cloneCanvas(signaturePadRef.current.canvas);
    const trimmedSignature = trimCanvas(clonedCanvas);
    dispatch(setOrderSignature(trimmedSignature.toDataURL()));
    dispatch(setOrderSignatureNotTrimmed(signaturePadRef.current.toDataURL()));
  };

  return (
    <Container maxWidth="sm" data-testid="signature-view">
      <AppBar
        title={t('signature_title')}
        displayBackButton
        backButtonAction={() => history.goBack()}
      />
      <SignatureHeader>
        <SignatureSubTitle>{t('signature_sub_title')}</SignatureSubTitle>
        <ClearButton
          onClick={handleClearButton}
          theme="secondary"
          data-testid="clear"
        >
          {t('signature_clear')}
        </ClearButton>
      </SignatureHeader>
      <SignatureContainer onPointerUp={handleTouchEnd}>
        <SignaturePad
          ref={signaturePadRef}
          options={{ minWidth: 1, maxWidth: 4 }}
        />
      </SignatureContainer>
      <FixedFooter>
        <FinishButtonsContainer>
          <Button
            aria-label="signature_skip"
            onClick={signatureFooterButtonAction}
            theme="secondary"
            disabled={!isEmpty}
          >
            {t('order_skip')}
          </Button>
          <Button
            aria-label="sign"
            onClick={signatureFooterButtonAction}
            theme="primary"
            disabled={isEmpty}
          >
            {t('order_sign')}
          </Button>
        </FinishButtonsContainer>
      </FixedFooter>
      <OrderConfirmationDialog
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        finishAndSendAction={isSendActionActive && finishAndSendAction}
        finishWithoutSendingAction={finishWithoutSendingAction}
        data-testid="confirm-signature"
      />
    </Container>
  );
};

export default SignatureView;
