import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { updateCameraIOSTipDialogDoNotShowAgain } from '../../store/reducers/clientUI';
import Tip from '../Tip/Tip';

const CameraIOSTip = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showCameraIOSTipDialog, setShowCameraIOSTipDialog] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { cameraIOSTipDialogDoNotShowAgain } = useSelector(
    (state) => state.clientUI
  );

  const isIOS =
    navigator.platform === 'iPad' ||
    navigator.platform === 'iPhone' ||
    navigator.platform === 'iPod';

  useEffect(() => {
    if (!cameraIOSTipDialogDoNotShowAgain && isIOS) {
      setShowCameraIOSTipDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    dispatch(updateCameraIOSTipDialogDoNotShowAgain(doNotShowAgain));
    setShowCameraIOSTipDialog(false);
  };

  return (
    <Tip
      title={t('camera_ios_tip_title')}
      text={
        <>
          <p>{parse(t('camera_ios_tip_1'))}</p>
          <p>{parse(t('camera_ios_tip_2'))}</p>
          <p>{parse(t('camera_ios_tip_3'))}</p>
        </>
      }
      showTip={showCameraIOSTipDialog}
      setShowTip={handleButtonClick}
      showButton
      buttonLabel={t('file_upload_tip_button')}
      onButtonClick={handleButtonClick}
      showCheckbox
      onCheckboxClick={(checkedOrNot) => {
        setDoNotShowAgain(checkedOrNot);
      }}
      icon="info"
    />
  );
};

export default CameraIOSTip;
