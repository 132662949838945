import { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientDataById } from '../store/actions/clientDataActions';
import { fetchProductsInCategories } from '../store/reducers/products';
import { fetchVisitsByDateAndClientId } from '../store/actions/visitsActions';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useFetchClientDataIfNeeded = (forceFetch = false) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const history = useHistory();
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { data, loadingStatus } = useSelector((state) => ({
    data: state.clientData.data[clientId],
    loadingStatus: state.clientData.loadingStatus[clientId],
  }));
  const { busy } = useSelector((state) => state.offline);

  useEffect(() => {
    if (busy) {
      return;
    }
    if (loadingStatus === 'fetched' || loadingStatus === 'not-fetched') {
      setFetching(false);
    }
    if (
      (loadingStatus !== 'fetching' &&
        loadingStatus !== 'fetched' &&
        loadingStatus !== 'not-fetched') ||
      (!fetching && forceFetch && !alreadyFetched && history.action === 'POP')
    ) {
      setFetching(true);
      dispatch(fetchClientDataById({ clientId }));
      setAlreadyFetched(true);
    }
  }, [
    alreadyFetched,
    clientId,
    dispatch,
    fetching,
    forceFetch,
    history,
    loadingStatus,
    busy,
  ]);

  return {
    data,
    loadingStatus,
  };
};

export const useFetchProductsIfNeeded = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [fetching, setFetching] = useState(false);

  const { products, loadingStatus } = useSelector((state) => ({
    products: state.products[clientId]
      ? state.products[clientId].data
      : undefined,
    loadingStatus: state.products[clientId]
      ? state.products[clientId].loadingStatus
      : undefined,
  }));

  useEffect(() => {
    if (loadingStatus === 'fetched' || loadingStatus === 'not-fetched') {
      setFetching(false);
    }

    if (
      !fetching &&
      loadingStatus !== 'fetching' &&
      loadingStatus !== 'fetched' &&
      loadingStatus !== 'not-fetched'
    ) {
      setFetching(true);
      dispatch(fetchProductsInCategories({ clientId }));
    }
  }, [clientId, dispatch, fetching, loadingStatus]);

  return {
    products,
    loadingStatus,
  };
};

export const useFetchVisitsIfNeeded = (forceFetch = false) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const history = useHistory();
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { data, loadingStatus } = useSelector((state) => ({
    data: state.clientVisitsData.data[clientId],
    loadingStatus: state.clientVisitsData.loadingStatus[clientId],
  }));
  const { busy } = useSelector((state) => state.offline);

  useEffect(() => {
    if (busy) {
      return;
    }
    if (loadingStatus === 'fetched' || loadingStatus === 'not-fetched') {
      setFetching(false);
    }
    if (
      (loadingStatus !== 'fetching' &&
        loadingStatus !== 'fetched' &&
        loadingStatus !== 'not-fetched') ||
      (!fetching && forceFetch && !alreadyFetched)
    ) {
      setFetching(true);
      dispatch(fetchVisitsByDateAndClientId({ clientId, date: new Date() }));
      setAlreadyFetched(true);
    }
  }, [
    alreadyFetched,
    clientId,
    dispatch,
    fetching,
    forceFetch,
    history,
    loadingStatus,
    busy,
  ]);

  return {
    data,
    loadingStatus,
  };
};
