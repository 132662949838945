import apiService from './api.service';

export const getCurrentlyLoggedUser = () =>
  apiService({
    url: `/qac/ta-details`,
  });

export const getDelegationAllowed = () =>
  apiService({
    method: 'GET',
    url: `/ict/ta/delegation-allowed`,
    transformResponse: [
      (data) => {
        return {
          ...data,
          data: data.data?.map((item) => ({ ...item, id: item.id.toString() })),
        };
      },
    ],
  });

export const getTAAllowed = () =>
  apiService({
    method: 'GET',
    url: `/ict/ta/allowed`,
    transformResponse: [
      (data) => {
        return {
          ...data,
          data: data.data?.map((item) => ({ ...item, id: item.id.toString() })),
        };
      },
    ],
  });
