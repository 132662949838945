import apiService from './api.service';

export const getClientListData = () => {
  return apiService({
    url: `/qac/client-list`,
  });
};

export const getClientListPlannedData = (date) => {
  return apiService({
    url: `/qac/client-list/${date}`,
  });
};

export const getNextActionsList = (from, to) => {
  return apiService({
    url: `/qac/next-action/${from}/${to}`,
  });
};

const NEAREST_CLIENTS_PER_PAGE = 5;

export const getClientListNearestData = (lat, lng, page = 1) => {
  return apiService({
    url: `/qac/client-list/${lat}/${lng}?page=${page}&perPage=${NEAREST_CLIENTS_PER_PAGE}`,
  });
};

export const getClientsSearch = (query, cancelToken, owner = '') => {
  const url = `/qac/client-list/search?keyword=${query}`;
  const ownerFilter = owner && owner !== -1 ? `&owner=${owner}` : '';
  return apiService({
    url: `${url}${ownerFilter}`,
    cancelToken,
  });
};

export const getClientsActions = (from, to) => {
  const toParam = to ? `/${to}` : '';
  return apiService({
    url: `/qac/calendar/${from}${toParam}`,
  });
};
