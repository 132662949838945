import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FieldArray, Form, Formik } from 'formik';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button } from 'frontend-components';

import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import MenuItem from '../../components/Select/MenuItem';
import FormikAutoSave from '../../components/FormikAutoSave/FormikAutoSave';
import {
  APPLICATION_VIEW_TABS,
  APPLICATION_METHODS,
  CLEAN_BEFORE_OPTIONS,
  COMPONENTS_LIFETIME_PERIODS,
  LUBRICATION_PERIODS,
  MACHINE_RUNNING_OPTIONS,
  VALIDATION_CONSTANTS,
} from '../../constants/applicationScreen';
import { FORM_DEBOUNCE_MS } from '../../constants/forms';
import { changeFieldValueToNumber } from '../../helpers/changeFieldValueToNumber';
import { applicationDataShape } from '../../helpers/clientDataPropTypes';
import { getApplicationTypeName } from '../../helpers/application';

const GreySection = styled.div`
  margin: -15px 0 0;
  background-color: #f2f2f2;
  padding-bottom: 30px;
`;

const ApplicationName = styled.div`
  padding: 35px 15px 5px;
  text-transform: uppercase;
  max-width: 600px;
  max-width: 600px;
  margin: 0 auto;
  word-break: break-word;

  @media (min-width: 600px) {
    padding: 35px 24px 5px;
  }
`;

const BeforeTabContainer = styled.div`
  padding-bottom: 50px;

  & .MuiFormControl-root {
    margin: 20px 0 16px;
  }
`;

const PrevLubricantsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const AddPrevLubricant = styled.div`
  margin: 22px 0 0;
`;

const AddLubricantButton = styled(Button)`
  &.MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: inherit;
  }
`;

const TwoInputsInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > .MuiFormControl-root:first-child {
    margin-right: 30px;

    & .MuiFormLabel-root {
      max-width: 150px;
      margin-top: -16px;

      &.MuiInputLabel-shrink {
        margin-top: -10px;
      }
    }
  }
`;

const { maxFloatNum, maxIntNum } = VALIDATION_CONSTANTS;

const BeforeTab = ({
  applicationData: { before: appData, name, componentType },
  updateAppSection,
  disabled,
  currency,
}) => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();

  const initialValues = {
    lubricant: appData.lubricant || '',
    viscosity: appData.viscosity || '',
    previousLubricants: appData.previousLubricants.length
      ? appData.previousLubricants
      : [],
    price: appData.price || '',
    lubricationFrequency: appData.lubricationFrequency || '',
    lubricationFrequencyPeriod: appData.lubricationFrequencyPeriod || '',
    amountPerLubrication: appData.amountPerLubrication || '',
    manualApplication: appData.manualApplication || '',
    manualApplicationTime: appData.manualApplicationTime || '',
    machineRunning: appData.machineRunning || '',
    cleanBefore: appData.cleanBefore || '',
    cleanBeforeTime: appData.cleanBeforeTime || '',
    avgComponentLifetime: appData.avgComponentLifetime || '',
    avgComponentLifetimePeriod: appData.avgComponentLifetimePeriod || '',
    componentCost: appData.componentCost || '',
    downtimeToReplace: appData.downtimeToReplace || '',
    peopleToReplace: appData.peopleToReplace || '',
  };

  const updateForm = (values) => {
    updateAppSection(
      applicationId,
      clientId,
      values,
      APPLICATION_VIEW_TABS.BEFORE
    );
  };

  const floatValidation = Yup.number()
    .typeError(t('validation_app_positive'))
    .positive(t('validation_app_positive'))
    .max(maxFloatNum, t('validation_app_float_range'));

  const integerValidation = Yup.number()
    .typeError(t('validation_app_uint'))
    .positive(t('validation_app_uint'))
    .integer(t('validation_app_uint'))
    .max(maxIntNum, t('validation_app_uint_range'));

  const validationSchema = Yup.object({
    price: floatValidation,
    lubricationFrequency: integerValidation,
    amountPerLubrication: integerValidation,
    manualApplicationTime: integerValidation,
    cleanBeforeTime: integerValidation,
    avgComponentLifetime: integerValidation,
    componentCost: floatValidation,
    downtimeToReplace: integerValidation,
    peopleToReplace: integerValidation,
  });

  const appType = getApplicationTypeName(componentType);

  return (
    <BeforeTabContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => updateForm(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => {
          const {
            lubricant,
            viscosity,
            previousLubricants,
            price,
            lubricationFrequency,
            lubricationFrequencyPeriod,
            amountPerLubrication,
            manualApplication,
            manualApplicationTime,
            machineRunning,
            cleanBefore,
            cleanBeforeTime,
            avgComponentLifetime,
            avgComponentLifetimePeriod,
            componentCost,
            downtimeToReplace,
            peopleToReplace,
          } = values;

          return (
            <Form>
              <GreySection>
                <ApplicationName>
                  {name} {appType && `(${t(appType)})`}
                </ApplicationName>
                <Container maxWidth="sm">
                  <Input
                    id="lubricant"
                    label={t('app_before_product')}
                    onchange={(e) => {
                      setFieldValue('lubricant', e);
                    }}
                    value={lubricant}
                    fullWidth
                    maxLength={100}
                    disabled={disabled}
                  />
                  <Input
                    id="viscosity"
                    label={t('app_before_viscosity')}
                    onchange={(e) => {
                      setFieldValue('viscosity', e);
                    }}
                    value={viscosity}
                    fullWidth
                    maxLength={100}
                    disabled={disabled}
                  />
                  <FieldArray name="previousLubricants">
                    {({ remove, push }) => (
                      <>
                        {previousLubricants.map((prevLubricants, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <PrevLubricantsContainer key={index}>
                            <Input
                              id={`previousLubricant${index}`}
                              data-testid="previousLubricant"
                              label={t('app_before_prev_lubricants')}
                              onchange={(e) => {
                                setFieldValue(
                                  `previousLubricants[${index}]`,
                                  e
                                );
                              }}
                              value={previousLubricants[index]}
                              fullWidth
                              maxLength={100}
                              disabled={disabled}
                            />
                            {previousLubricants.length > 1 && (
                              <IconButton
                                onClick={() => remove(index)}
                                disabled={disabled}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </PrevLubricantsContainer>
                        ))}
                        <AddPrevLubricant>
                          <AddLubricantButton
                            data-testid="addPreviousLubricants"
                            theme="secondary"
                            startIcon={<AddIcon />}
                            fullWidth
                            onClick={() => push('')}
                            bgcolor="grey"
                            disabled={
                              disabled || previousLubricants.includes('')
                            }
                          >
                            {t('before_prev_lubricants_add')}
                          </AddLubricantButton>
                        </AddPrevLubricant>
                      </>
                    )}
                  </FieldArray>

                  <Input
                    id="price"
                    label={t('app_before_price')}
                    onchange={(e) => {
                      setFieldValue('price', changeFieldValueToNumber(e));
                    }}
                    value={price}
                    fullWidth
                    type="number"
                    rightText={`${currency}/1l`}
                    errorMessage={errors && errors.price}
                    disabled={disabled}
                  />
                  <TwoInputsInRow>
                    <Input
                      id="lubricationFrequency"
                      label={t('app_before_freq')}
                      onchange={(e) => {
                        setFieldValue(
                          'lubricationFrequency',
                          changeFieldValueToNumber(e)
                        );
                      }}
                      value={lubricationFrequency}
                      type="number"
                      fullWidth
                      errorMessage={errors && errors.lubricationFrequency}
                      disabled={disabled}
                    />
                    <Select
                      id="lubricationFrequencyPeriod"
                      data-testid="lubricationFrequencyPeriod"
                      label={t('app_before_period')}
                      value={lubricationFrequencyPeriod}
                      fullWidth
                      onChange={(period) => {
                        setFieldValue('lubricationFrequencyPeriod', period);
                        setFieldValue('lubricationFrequencyPeriod', period);
                      }}
                      disabled={disabled}
                    >
                      {LUBRICATION_PERIODS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </TwoInputsInRow>
                  <Input
                    id="amountPerLubrication"
                    label={t('app_before_amount')}
                    onchange={(e) => {
                      setFieldValue(
                        'amountPerLubrication',
                        changeFieldValueToNumber(e)
                      );
                    }}
                    value={amountPerLubrication}
                    type="number"
                    fullWidth
                    rightText={t('app_ml')}
                    errorMessage={errors && errors.amountPerLubrication}
                    disabled={disabled}
                  />
                </Container>
              </GreySection>
              <Container maxWidth="sm">
                <Select
                  id="applicationMethod"
                  data-testid="applicationMethod"
                  label={t('app_before_app_method')}
                  value={manualApplication}
                  fullWidth
                  onChange={(manualApplication) => {
                    setFieldValue('manualApplication', manualApplication);
                  }}
                  disabled={disabled}
                >
                  {APPLICATION_METHODS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {t(label)}
                    </MenuItem>
                  ))}
                </Select>
                {manualApplication === APPLICATION_METHODS[0].value && (
                  <>
                    <Input
                      id="manualApplicationTime"
                      label={t('app_before_time')}
                      onchange={(e) => {
                        setFieldValue(
                          'manualApplicationTime',
                          changeFieldValueToNumber(e)
                        );
                      }}
                      value={manualApplicationTime}
                      type="number"
                      fullWidth
                      rightText={t('app_minutes')}
                      errorMessage={errors && errors.manualApplicationTime}
                      disabled={disabled}
                    />
                    <Select
                      id="machineRunning"
                      data-testid="machineRunning"
                      label={t('app_before_machine_state')}
                      value={machineRunning}
                      fullWidth
                      onChange={(machineState) => {
                        setFieldValue('machineRunning', machineState);
                      }}
                      disabled={disabled}
                    >
                      {MACHINE_RUNNING_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      id="cleanBefore"
                      data-testid="cleanBefore"
                      label={t('app_before_clean')}
                      value={cleanBefore}
                      fullWidth
                      onChange={(cleanBefore) => {
                        setFieldValue('cleanBefore', cleanBefore);
                      }}
                      disabled={disabled}
                    >
                      {CLEAN_BEFORE_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                    {cleanBefore === CLEAN_BEFORE_OPTIONS[0].value && (
                      <Input
                        id="cleanBeforeTime"
                        label={t('app_before_clean_time')}
                        onchange={(e) => {
                          setFieldValue(
                            'cleanBeforeTime',
                            changeFieldValueToNumber(e)
                          );
                        }}
                        value={cleanBeforeTime}
                        type="number"
                        fullWidth
                        rightText={t('app_minutes')}
                        errorMessage={errors && errors.cleanBeforeTime}
                        disabled={disabled}
                      />
                    )}
                  </>
                )}
                <TwoInputsInRow>
                  <Input
                    id="avgComponentLifetime"
                    label={t('app_before_avg_lifetime')}
                    onchange={(e) => {
                      setFieldValue(
                        'avgComponentLifetime',
                        changeFieldValueToNumber(e)
                      );
                    }}
                    value={avgComponentLifetime}
                    type="number"
                    fullWidth
                    errorMessage={errors && errors.avgComponentLifetime}
                    disabled={disabled}
                  />
                  <Select
                    id="avgComponentLifetimePeriod"
                    data-testid="avgComponentLifetimePeriod"
                    label={t('before_avg_lifetime_unit')}
                    value={avgComponentLifetimePeriod}
                    fullWidth
                    onChange={(period) => {
                      setFieldValue('avgComponentLifetimePeriod', period);
                    }}
                    disabled={disabled}
                  >
                    {COMPONENTS_LIFETIME_PERIODS.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </TwoInputsInRow>
                <Input
                  id="componentCost"
                  label={t('app_before_cost')}
                  onchange={(e) => {
                    setFieldValue('componentCost', changeFieldValueToNumber(e));
                  }}
                  value={componentCost}
                  type="number"
                  fullWidth
                  rightText={currency}
                  errorMessage={errors && errors.componentCost}
                  disabled={disabled}
                />
                <Input
                  id="downtimeToReplace"
                  label={t('app_before_downtime')}
                  onchange={(e) => {
                    setFieldValue(
                      'downtimeToReplace',
                      changeFieldValueToNumber(e)
                    );
                  }}
                  value={downtimeToReplace}
                  type="number"
                  fullWidth
                  rightText={t('app_hours')}
                  errorMessage={errors && errors.downtimeToReplace}
                  disabled={disabled}
                />
                <Input
                  id="peopleToReplace"
                  label={t('app_before_people')}
                  onchange={(e) => {
                    setFieldValue(
                      'peopleToReplace',
                      changeFieldValueToNumber(e)
                    );
                  }}
                  value={peopleToReplace}
                  type="number"
                  fullWidth
                  errorMessage={errors && errors.peopleToReplace}
                  disabled={disabled}
                />
              </Container>
              <FormikAutoSave debounceMs={FORM_DEBOUNCE_MS} />
            </Form>
          );
        }}
      </Formik>
    </BeforeTabContainer>
  );
};

BeforeTab.propTypes = {
  applicationData: applicationDataShape,
  updateAppSection: PropTypes.func,
  disabled: PropTypes.bool,
  currency: PropTypes.string,
};

export default BeforeTab;
