import { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { blockForm } from '../../store/reducers/clientUI';

const FormikAutoSave = ({ debounceMs }) => {
  const dispatch = useDispatch();
  const { values, submitForm, dirty, validateForm, isValid } =
    useFormikContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => submitForm(), debounceMs),
    [debounceMs, submitForm]
  );

  useEffect(() => {
    (dirty && debouncedSubmit()) || (!isValid && validateForm());
  }, [debouncedSubmit, values, dirty, validateForm, isValid]);

  useEffect(() => {
    dispatch(blockForm(!isValid));
  }, [dispatch, isValid]);

  return null;
};

export default FormikAutoSave;
