import SvgIcon from '@material-ui/core/SvgIcon';

function StatusFail(props) {
  return (
    <SvgIcon {...props}>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="16.0.photo_gallery-v2"
          transform="translate(-21.000000, -831.000000)"
        >
          <g
            id="check_circle_black_24dp-copy-2"
            transform="translate(21.000000, 831.000000)"
          >
            <circle id="Oval" fill="#FFFFFF" cx="12" cy="12" r="12" />
            <path
              d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12.0025065,14.8603516 C11.8338867,14.8603516 11.6766602,14.8808594 11.5308268,14.921875 C11.3849935,14.9628906 11.2573893,15.0289714 11.1480143,15.1201172 C11.0386393,15.211263 10.9531901,15.3308919 10.8916667,15.4790039 C10.8301432,15.6271159 10.7993815,15.8082682 10.7993815,16.0224609 C10.7993815,16.2275391 10.8301432,16.4029948 10.8916667,16.5488281 C10.9531901,16.6946615 11.0386393,16.8154297 11.1480143,16.9111328 C11.2573893,17.0068359 11.3849935,17.0763346 11.5308268,17.1196289 C11.6766602,17.1629232 11.8338867,17.1845703 12.0025065,17.1845703 C12.1620117,17.1845703 12.3135417,17.1629232 12.4570964,17.1196289 C12.600651,17.0763346 12.7271159,17.0068359 12.8364909,16.9111328 C12.9458659,16.8154297 13.0324544,16.6946615 13.0962565,16.5488281 C13.1600586,16.4029948 13.1919596,16.2275391 13.1919596,16.0224609 C13.1919596,15.8082682 13.1600586,15.6271159 13.0962565,15.4790039 C13.0324544,15.3308919 12.9458659,15.211263 12.8364909,15.1201172 C12.7271159,15.0289714 12.600651,14.9628906 12.4570964,14.921875 C12.3135417,14.8808594 12.1620117,14.8603516 12.0025065,14.8603516 Z M13.1919596,7.00585938 L10.8267253,7.00585938 L11.1753581,13.6845703 L12.8433268,13.6845703 L13.1919596,7.00585938 Z"
              id="Shape"
              fill="#E42313"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default StatusFail;
