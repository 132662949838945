import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GalleryView from './GalleryView';
import { NEW_CLIENT_GALLERY_KEY } from '../../constants/video';

const NewClientGalleryView = () => {
  const { newClientData } = useSelector((state) => state.client);
  const { clientId } = useParams();
  return (
    <GalleryView
      clientData={newClientData}
      galleryPath={`/customers/${clientId}/client/create`}
      backPath={`/customers/${clientId}/client/create`}
      fromKey={NEW_CLIENT_GALLERY_KEY}
    />
  );
};

export default NewClientGalleryView;
