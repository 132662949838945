import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Snackbar, Button } from '@material-ui/core';

const StyledSnackBar = styled(Snackbar)`
  bottom: 24px;

  & .MuiSnackbarContent-root {
    font-size: 14px;
    padding: 10px 16px;
    line-height: 14px;
  }

  & .MuiSnackbarContent-message {
    padding: 0;
  }

  & .MuiButton-text {
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
  }
`;

const SnackBar = ({ open, message, handleButtonClick, btnText }) => {
  return (
    <StyledSnackBar
      open={open}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button color="inherit" onClick={handleButtonClick}>
          {btnText}
        </Button>
      }
    />
  );
};

SnackBar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  handleButtonClick: PropTypes.func,
  btnText: PropTypes.string,
};

export default SnackBar;
