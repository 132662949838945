import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactPersonView from './ContactPersonView';
import {
  createContactPerson,
  editContactPerson,
} from '../../store/actions/contactPersonActions';
import { updateExpandedPanel } from '../../store/reducers/clientUI';
import { LIST_TYPES } from '../../constants/lists';

const AddContactPersonView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId, contactPersonId } = useParams();

  const handleCreateContact = (newValues) => {
    dispatch(createContactPerson(contactPersonId, newValues, clientId));
    dispatch(
      updateExpandedPanel({ clientId, expandedPanel: LIST_TYPES.CONTACTS })
    );
  };

  const handleEditPerson = (newValues) => {
    dispatch(editContactPerson(contactPersonId, newValues, clientId));
  };

  return (
    <ContactPersonView
      onSubmit={(values, isExistingContact) => {
        const newValues = {
          ...values,
          clientId,
        };
        isExistingContact
          ? handleEditPerson(newValues)
          : handleCreateContact(newValues);

        history.goBack();
      }}
    />
  );
};

export default AddContactPersonView;
