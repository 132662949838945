import { useParams, useLocation } from 'react-router-dom';
import GalleryView from './GalleryView';
import withClientData from '../../helpers/withClientData';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ApplicationGalleryView = ({ clientData }) => {
  const { clientId, applicationId } = useParams();
  const location = useLocation();

  return (
    <GalleryView
      clientData={clientData}
      galleryPath={`/customers/${clientId}/applications/${applicationId}`}
      backPath={`/customers/${clientId}/applications/${applicationId}`}
      fromKey={location.state.from}
    />
  );
};

ApplicationGalleryView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ApplicationGalleryView);
