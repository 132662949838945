import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from '../DatePicker/DatePicker';

const DateRangeStyled = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 8px;

  .MuiFormControl-root {
    margin-top: 8px;
  }
`;

const DateRange = ({ dateFrom, dateTo, setDateFrom, setDateTo }) => {
  const { t } = useTranslation();

  return (
    <DateRangeStyled>
      <DatePicker
        value={dateFrom}
        id="noteDate"
        onChange={(date) => {
          setDateFrom(date);
        }}
        fullWidth
        label={t('day_filter_from')}
        maxDate={dateTo}
      />
      <DatePicker
        value={dateTo}
        id="noteDate"
        onChange={(date) => {
          setDateTo(date);
        }}
        fullWidth
        label={t('day_filter_to')}
        minDate={dateFrom}
      />
    </DateRangeStyled>
  );
};

DateRange.propTypes = {
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
};

export default DateRange;
