const reduxOfflineThunkMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (typeof action === 'object' && action.meta && action.meta.after) {
    const afterAction = action.meta.after;
    const { afterArgs = [] } = action.meta;

    if (typeof afterAction === 'function') {
      store.dispatch(afterAction(...afterArgs));
    }

    if (Array.isArray(afterAction)) {
      afterAction.forEach((af, index) => {
        if (typeof af === 'function') {
          store.dispatch(af(...afterArgs[index]));
        }
      });
    }
  }

  return result;
};

export default reduxOfflineThunkMiddleware;
