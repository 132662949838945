export default function HelpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M19 2a4 4 0 014 4v9a4 4 0 01-4 4H5l-4 3V6a4 4 0 014-4h14zm-5.5 12.5h-2v2h2v-2zM12.5 4c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
