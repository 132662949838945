import { Suspense, useEffect, useState, createRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { addMonths, startOfMonth } from 'date-fns';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cookies from 'universal-cookie';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {
  Provider as RollbarProvider,
  useRollbar,
  ErrorBoundary,
} from '@rollbar/react';
import { LinearProgress } from 'frontend-components';
import { getPlannedDate } from 'frontend-components/lib/helpers';

import Notifier from './components/Notifier/Notifier';
import { setAccessDenied, logOut } from './store/reducers/userSlice';
import {
  fetchTAAllowed,
  fetchDelegationAllowed,
  fetchCurrentlyLoggedUser,
} from './store/actions/userActions';
import { fetchSicCodes } from './store/reducers/sicCodes';
import configureStoreWithPersistCallback from './store/store';
import http from './services/api.service';
import { dataLoading } from './store/reducers/app';
import { refreshAccessToken } from './services/auth.service';
import Routes from './Routes';
import {
  setUploadingDone,
  setUploadingFailed,
  clearUploadingProgress,
} from './store/reducers/video';
import {
  setPhotoUploadingDone,
  setPhotoUploadingFailed,
  setPhotoUploadingStart,
} from './store/reducers/photosFromDevice';
import { fetchClientsActions } from './store/reducers/clients';
import Splash from './components/Splash/Splash';
import NewAppVersionNotification from './components/NewAppVersionNotification/NewAppVersionNotification';
import useRegisterServiceWorker from './useRegisterServiceWorker';
import { addPhoto } from './store/actions/photoActions';
import AppMatomoProvider from './providers/AppMatomoProvider';

const cookies = new Cookies();

const DISABLED_BUTTON_STYLE = {
  '&.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root)': {
    display: 'none',
  },
};

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Noto Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#e42313',
    },
  },
  overrides: {
    MuiButton: {
      root: DISABLED_BUTTON_STYLE,
    },
    MuiIconButton: {
      root: DISABLED_BUTTON_STYLE,
    },
    MuiContainer: {
      root: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const [isFirstOpening, setIsFirstOpening] = useState(true);
  const { isLoggedIn, data } = useSelector((state) => state.user);
  const { online } = useSelector((state) => state.offline);
  const { addedPhotos, uploaded } = useSelector((state) => ({
    addedPhotos: state.photosFromDevice.addedPhotos || [],
    uploaded: state.photosFromDevice.upload || [],
    uploadedVideos: state.video.upload || {},
  }));
  const rollbar = useRollbar();

  const handleImageUploadProgress = (progressEvent, imageData) => {
    if (online)
      dispatch(
        setPhotoUploadingStart({
          photoId: imageData.photo.id,
        })
      );
  };

  useEffect(() => {
    if (isFirstOpening && online) {
      dispatch(clearUploadingProgress());

      Object.entries(addedPhotos).map((client) => {
        const [clientId, photos] = client;
        photos.map((photo) => {
          if (
            !uploaded[photo.id]?.failed &&
            !uploaded[photo.id]?.uploaded &&
            !uploaded[photo.id]?.cancelled
          )
            dispatch(
              addPhoto(clientId, null, photo, null, handleImageUploadProgress)
            );
          return photo;
        });
        return client;
      });

      setIsFirstOpening(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstOpening, addedPhotos, online]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchTAAllowed());
      dispatch(fetchDelegationAllowed());
      dispatch(fetchCurrentlyLoggedUser());
      dispatch(fetchSicCodes());
      dispatch(
        fetchClientsActions({
          from: getPlannedDate(startOfMonth(addMonths(new Date(), -1))),
        })
      );
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (rollbar && isLoggedIn && data?.id) {
      rollbar.configure({
        hostSafeList: [
          'mia.dev.interflon.cloud',
          'mia.uat.interflon.cloud',
          'mia.interflon.net',
        ],
        payload: {
          person: {
            id: data?.id,
          },
        },
      });
    }
  }, [isLoggedIn, data, rollbar]);

  if (isLoggedIn && !data) {
    return (
      <>
        <LinearProgress />
        <Splash />
      </>
    );
  }

  return (
    <BrowserRouter>
      <>
        <Suspense
          fallback={
            <>
              <Splash />
              <LinearProgress />
            </>
          }
        >
          <Notifier />
          <Routes />
        </Suspense>
      </>
    </BrowserRouter>
  );
};

const AppWithStore = () => {
  // we want to show the app only after state was rehydrated
  const [rehydrated, setRehydrated] = useState(false);
  const [store, setStore] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const rollbar = useRollbar();

  useEffect(() => {
    const store = configureStoreWithPersistCallback(() => {
      setRehydrated(true);
    });

    setStore(store);

    const { dispatch } = store;

    http.interceptors.request.use(
      (config) => {
        dispatch(dataLoading(true));
        const token = cookies.get('token');
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error) => Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => {
        dispatch(dataLoading(false));

        if (
          response.config.method === 'post' &&
          response.config.url.includes('/qac/video/')
        ) {
          dispatch(
            setUploadingDone({ id: response.config.url.split('/').pop() })
          );
        }

        if (
          response.config.method === 'post' &&
          response.config.url.includes('/qac/photo-gallery/')
        ) {
          dispatch(
            setPhotoUploadingDone({ id: response.config.url.split('/').pop() })
          );
        }

        if (
          response.config.method === 'post' &&
          response.config.url.includes('/qac/attachment/video/')
        ) {
          dispatch(
            setUploadingDone({
              id: response.config.url.split('/').slice(-2)[0],
            })
          );
        }

        if (
          response.config.method === 'post' &&
          response.config.url.includes('/qac/attachment/photo/')
        ) {
          dispatch(
            setPhotoUploadingDone({
              id: response.config.url.split('/').slice(-2)[0],
            })
          );
        }

        return response.data;
      },
      (error) => {
        dispatch(dataLoading(false));

        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        const { status } = error.response ? error.response : {};

        if (status !== 401 && window.location.hostname !== 'localhost')
          rollbar.error(error?.response?.config?.url || error, error?.response);

        if (status === 403) {
          dispatch(setAccessDenied(true));
          dispatch(logOut());
        } else if (status === 401) {
          const refreshToken = cookies.get('refresh_token');
          if (error.config.url !== '/token/refresh') {
            refreshAccessToken(refreshToken)
              .then((res) => {
                if ('token' in res) {
                  cookies.set('token', res.token, {
                    path: '/',
                    maxAge: 31536000,
                  });
                } else {
                  dispatch(logOut());
                }
              })
              .catch(() => {
                dispatch(logOut());
              });
          }
        } else if (
          error.config?.method === 'post' &&
          error.config.url.includes('/qac/video/')
        ) {
          dispatch(
            setUploadingFailed({ id: error.config.url.split('/').pop() })
          );
        } else if (
          error.config?.method === 'post' &&
          error.config?.url.includes('/qac/photo-gallery/')
        ) {
          dispatch(
            setPhotoUploadingFailed({ id: error.config.url.split('/').pop() })
          );
        } else {
          const isOfflineGetError =
            !store.getState().offline.online && error.config.method === 'get';

          const blockError = error.config.url.startsWith('/qac/check-visit/');

          if (!isOfflineGetError && !blockError) {
            enqueueSnackbar(
              error.message ||
                (error.data && error.data.message) ||
                'API error',
              {
                variant: 'error',
                autoHideDuration: 5000,
              }
            );
          }
        }
        return Promise.reject(error);
      }
    );
  }, [enqueueSnackbar, rollbar]);

  if (rehydrated) {
    return (
      <Provider store={store}>
        <AppMatomoProvider>
          <App />
        </AppMatomoProvider>
      </Provider>
    );
  }

  return (
    <>
      <Splash />
      <LinearProgress />
    </>
  );
};

const AppWithTheme = () => {
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const showNewVersionNotification = useRegisterServiceWorker();

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENV,
    },
  };

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          hideIconVariant
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
          preventDuplicate
        >
          <RollbarProvider config={rollbarConfig}>
            <ErrorBoundary>
              <AppWithStore />
            </ErrorBoundary>
          </RollbarProvider>
          <Suspense fallback={<LinearProgress />}>
            <NewAppVersionNotification open={showNewVersionNotification} />
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default AppWithTheme;
