import DialogContent from '@material-ui/core/DialogContent';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from 'frontend-components';

import Details from './Details';
import {
  getPackageLabel,
  productProps,
  packagingDataProps,
  productDataProps,
  onlyWithQuantity,
  getPriceByQuantity,
  calculateCurrentAmount,
} from '../../../helpers/packaging';
import usePriceValidationSchema from '../../../hooks/usePriceValidationSchema';
import {
  StyledDialog,
  StyledButton,
  StyledDialogActions,
  TotalsSection,
  AddPackagingButtonContainer,
  HeaderWrapper,
} from './PackagingDetailsDialog.styles';
import {
  TotalContainer,
  AmountValue,
  AmountTitle,
} from '../SelectProducts/SelectedProductsSummary.styles';
import DialogHeader from '../../../components/Dialog/DialogHeader';

const PACKAGING_LIMIT = 20;

const PackagingDetailsDialog = ({
  data,
  open,
  setOpen,
  done,
  product,
  currency,
  packagingData,
  productsData,
}) => {
  const { t } = useTranslation();

  const { variants, name } = product;

  const preparePackaging = (variant) => {
    const { code, setCapacity, packageCapacity, packageName, prices } = variant;

    return {
      code,
      name,
      quantity: setCapacity,
      label: getPackageLabel(packageCapacity, packageName),
      packageType: packageName,
      packageCapacity,
      packageUnit: setCapacity,
      price: getPriceByQuantity(setCapacity, prices).price,
      currency,
    };
  };

  const createDefaultPackaging = () => {
    const defaultVariant = variants[0];
    return preparePackaging(defaultVariant);
  };

  const initialValues = {
    packagingDetails:
      data && data.length > 0 ? data : [createDefaultPackaging()],
  };

  const validationSchema = Yup.object({
    packagingDetails: Yup.array().of(
      Yup.object().shape({
        price: usePriceValidationSchema(),
      })
    ),
  });

  const getFilteredVariants = (packagingDetails) => {
    const selectedCodes = packagingDetails.map(({ code }) => code);
    return variants.filter(({ code }) => !selectedCodes.includes(code));
  };

  const createNewPackaging = (packagingDetails) => {
    const variant = getFilteredVariants(packagingDetails).length
      ? getFilteredVariants(packagingDetails)[0]
      : variants[0];
    return preparePackaging(variant);
  };

  return (
    <StyledDialog
      aria-labelledby="packaging-details"
      open={open}
      fullScreen
      scroll="paper"
      PaperProps={{ square: true }}
    >
      <HeaderWrapper>
        <DialogHeader
          title={t('pck_details_title')}
          handleClose={() => setOpen(false)}
          subtitle={product?.name}
        />
      </HeaderWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors, handleBlur }) => {
          const { packagingDetails } = values;

          const currentAmount = calculateCurrentAmount(
            product,
            packagingDetails,
            packagingData,
            productsData
          );

          return (
            <>
              <DialogContent>
                <Form>
                  <FieldArray name="packagingDetails">
                    {({ remove, push }) => {
                      return (
                        <>
                          {packagingDetails.map((detailsData, index) => (
                            <Details
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              detailsData={detailsData}
                              remove={remove}
                              setFieldValue={setFieldValue}
                              index={index}
                              errors={errors}
                              handleFormikBlur={handleBlur}
                              dropdownItems={variants}
                            />
                          ))}
                          {packagingDetails.length < PACKAGING_LIMIT && (
                            <AddPackagingButtonContainer>
                              <Button
                                theme="secondary"
                                aria-label="add-quantity"
                                onClick={() => {
                                  push(createNewPackaging(packagingDetails));
                                }}
                                data-testid="add-quantity"
                                fullWidth
                                startIcon={<AddIcon />}
                              >
                                {t('pck_details_add')}
                              </Button>
                            </AddPackagingButtonContainer>
                          )}
                        </>
                      );
                    }}
                  </FieldArray>
                </Form>
                <StyledDialogActions>
                  <StyledButton
                    theme="secondary"
                    aria-label="cancel"
                    onClick={() => setOpen(false)}
                  >
                    {t('pck_details_cancel')}
                  </StyledButton>
                  <StyledButton
                    autoFocus
                    aria-label="done"
                    onClick={() =>
                      done(onlyWithQuantity(packagingDetails), product)
                    }
                    theme="primary"
                    disabled={!!errors.packagingDetails}
                  >
                    {t('pck_details_done')}
                  </StyledButton>
                </StyledDialogActions>
              </DialogContent>
              <TotalsSection>
                <TotalContainer>
                  <AmountTitle>{t('total_amount')}</AmountTitle>
                  <AmountValue>{`${currency} ${currentAmount}`}</AmountValue>
                </TotalContainer>
              </TotalsSection>
            </>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

PackagingDetailsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  done: PropTypes.func,
  product: PropTypes.shape(productProps),
  data: PropTypes.arrayOf(PropTypes.shape(packagingDataProps)),
  currency: PropTypes.string,
  packagingData: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape(packagingDataProps))
  ),
  productsData: PropTypes.arrayOf(PropTypes.shape(productDataProps)),
};

export default PackagingDetailsDialog;
