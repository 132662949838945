import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DialogContent, DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'frontend-components';

import TextArea from '../../TextArea/TextArea';
import Dialog from '../Dialog';
import { StyledDialogActions } from '../DeleteDialog/DeleteDialog';
import DialogHeader from '../DialogHeader';
import {
  openHelpDialog,
  closeHelpDialog,
  setHelpDialog,
} from '../../../store/reducers/helpSlice';
import { postMessage } from '../../../store/actions/helpActions';
import OfflineIcon from '../../Icons/OfflineIcon';
import ConfirmationIcon from '../../Icons/ConfirmationIcon';

const StyledDialogActionsCenter = styled(DialogActions)`
  justify-content: center;

  .MuiButton-root.Mui-disabled {
    display: inherit;
  }
`;

const HelpContent = styled(DialogContent)`
  margin-top: 12px;
  margin-bottom: 16px;
`;

const StyledTextArea = styled(TextArea)`
  @media (orientation: landscape) {
    max-height: 90px;
  }
`;

const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (orientation: landscape) {
    svg {
      height: 100px;
    }
  }
`;

const ConfirmTitle = styled.div`
  justify-content: center;
  font-size: 20px;
  margin-top: 30px;
  text-align: center;

  @media (orientation: landscape) {
    margin-top: 14px;
  }
`;

const ConfirmContentText = styled(DialogContentText)`
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px;
  color: #000;
  text-align: center;
`;

const CircularProgressButton = styled(CircularProgress)`
  position: absolute;
  left: 10px;
`;

const StyledButton = styled(Button)`
  width: 45%;
  max-width: 140px;
`;

const HelpDialog = ({ help, online }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, sent, message, pending } = help;

  const { handleSubmit, values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      messageInput: message || '',
    },
    onSubmit: () => {
      dispatch(postMessage(values.messageInput));
    },
  });

  const { messageInput } = values;

  const setOpen = (isOpen) => {
    if (isOpen) dispatch(openHelpDialog());
    else dispatch(closeHelpDialog());
  };

  return (
    <Dialog
      data-testid="help-dialog"
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      {online && !sent && (
        <form onSubmit={handleSubmit}>
          <DialogHeader
            title={t('help_dialog_title')}
            handleClose={() => setOpen(false)}
          />
          <HelpContent>{t('help_dialog_text')}</HelpContent>
          <StyledTextArea
            id="messageInput"
            label={t('help_dialog_message_label')}
            fullWidth
            onChange={(e) => {
              setFieldValue('messageInput', e.target.value);
              dispatch(setHelpDialog(e.target.value));
            }}
            value={messageInput}
          />
          <StyledDialogActions>
            <StyledButton
              theme="secondary"
              aria-label="cancel"
              onClick={() => {
                setOpen(false);
              }}
              data-testid="cancel"
            >
              {t('help_dialog_cancel')}
            </StyledButton>
            <StyledButton
              autoFocus
              aria-label="send"
              type="submit"
              theme="primary"
              disabled={!messageInput.trim()}
            >
              {pending && <CircularProgressButton size={20} color="inherit" />}
              {t('help_dialog_send')}
            </StyledButton>
          </StyledDialogActions>
        </form>
      )}

      {online && sent && (
        <>
          <DialogHeader handleClose={() => setOpen(false)} />

          <ConfirmWrapper>
            <ConfirmationIcon />
            <ConfirmTitle>{t('help_dialog_confirm_title')}</ConfirmTitle>
            <ConfirmContentText>
              {t('help_dialog_confirm_text')}
            </ConfirmContentText>
          </ConfirmWrapper>

          <StyledDialogActionsCenter>
            <StyledButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
              }}
              theme="primary"
            >
              {t('help_dialog_close')}
            </StyledButton>
          </StyledDialogActionsCenter>
        </>
      )}

      {!online && (
        <>
          <DialogHeader handleClose={() => setOpen(false)} />

          <ConfirmWrapper>
            <OfflineIcon />
            <ConfirmTitle>{t('help_dialog_offline_title')}</ConfirmTitle>
            <ConfirmContentText>
              {t('help_dialog_offline_text')}
            </ConfirmContentText>
          </ConfirmWrapper>

          <StyledDialogActionsCenter>
            <StyledButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
              }}
              theme="primary"
            >
              {t('help_dialog_close')}
            </StyledButton>
          </StyledDialogActionsCenter>
        </>
      )}
    </Dialog>
  );
};

HelpDialog.propTypes = {
  online: PropTypes.bool,
  help: PropTypes.shape({
    open: PropTypes.bool,
    sent: PropTypes.bool,
    pending: PropTypes.bool,
    step: PropTypes.string,
    message: PropTypes.string,
  }),
};
export default HelpDialog;
