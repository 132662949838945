import SvgIcon from '@material-ui/core/SvgIcon';

function ClassificationStar(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <rect id="path-1" x="15" y="152" width="345" height="145" rx="8" />
        <filter
          x="-3.8%"
          y="-7.6%"
          width="107.5%"
          height="117.9%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.0.1.select_customer-icons"
          transform="translate(-320.000000, -176.000000)"
        >
          <g
            id="Icon-/-New-/-Star"
            transform="translate(320.000000, 176.000000)"
            fill="#999999"
          >
            <polygon
              id="Path"
              points="12 17.27 18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ClassificationStar;
