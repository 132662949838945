import { useEffect, useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PhoneIcon from '@material-ui/icons/Phone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DateTile, Divider } from 'frontend-components';
import IconFilters from '../IconFilters/IconFilters';
import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import LimitedList from '../LimitedList/LimitedList';
import { DraftMarker } from '../DraftMarker/DraftMarker';
import { NOTE_BY_REPORT_TYPE, REPORT_TYPES } from '../../constants/noteTypes';
import { removeHTMLTags } from '../../helpers/removeHTML';

const StyledListItem = styled(ListItem)`
  align-items: center;
`;

const ReportType = styled.div`
  > svg {
    color: #999999;
    margin-right: 10px;
  }
`;

const NotesList = ({
  expanded,
  onChange,
  notes,
  'data-testid': testId,
  disabled,
  title,
  icon,
  listTestId,
  onPlusButtonClick,
  itemClickPath,
  showDemoLabel,
  menuOptions,
  onMenuOptionClick,
  showFilters,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [items, setItems] = useState(notes?.data || []);
  const [filters, setFilters] = useState();

  const handleNoteItemClick = (item) => {
    if (item?.reportType) {
      history.push(
        `/customers/${item.clientId}/${NOTE_BY_REPORT_TYPE[item.reportType]}/${
          item.id
        }`
      );
      return;
    }

    history.push(`${itemClickPath}/${item.id}`);
  };

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    if (!showFilters || !filters || isEmpty(pickBy(filters, Boolean))) {
      setItems(notes.data);
      return;
    }

    const result = notes.data.filter(({ reportType }) => {
      if (!reportType) {
        return null;
      }

      return Object.values(REPORT_TYPES).reduce((acc, curr) => {
        return acc || !!(filters[curr] && reportType === curr);
      }, false);
    });
    setItems(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters, filters, notes]);

  return (
    <Collapsible
      expanded={expanded && notes.total > 0}
      onChange={onChange}
      icon={icon}
      title={t(title)}
      itemsSize={items && items.length}
      displayPlusButton
      onPlusButtonClick={onPlusButtonClick}
      collapsible={notes && notes.total > 0}
      data-testid={testId}
      disabled={disabled}
      menuOptions={menuOptions}
      onMenuOptionClick={onMenuOptionClick}
    >
      {showFilters && (
        <IconFilters
          items={{
            [REPORT_TYPES.VISIT]: false,
            [REPORT_TYPES.PHONE_CALL]: false,
            [REPORT_TYPES.REMARK]: false,
          }}
          onChange={handleFilterChange}
        />
      )}
      <LimitedList
        items={items}
        label={t(title)}
        renderItem={(item, index) => {
          // Hides empty visits which get created if user navigates away from new visit screen with a
          // phone's back button. To be removed once visit creation logic is refactored to use a
          // drafts based approach logic which would prevent the creating of redundant visits.
          if (typeof item.edited !== 'undefined' && !item.edited) {
            return null;
          }

          const isLast = index === items.length - 1;
          const secondaryLabel = [
            ...(item.demoProducts &&
            item.demoProducts.length > 0 &&
            showDemoLabel
              ? [t('demo')]
              : []),
            ...(item.isNoShow ? [t('no_show')] : []),
          ].join(', ');

          return (
            <div key={item.id}>
              <StyledListItem
                data-testid={listTestId}
                key={item.id}
                button
                onClick={() => handleNoteItemClick(item)}
              >
                {item?.reportType && (
                  <ReportType>
                    {item.reportType === REPORT_TYPES.REMARK && (
                      <InsertDriveFileIcon />
                    )}
                    {item.reportType === REPORT_TYPES.VISIT && <DriveEtaIcon />}
                    {item.reportType === REPORT_TYPES.PHONE_CALL && (
                      <PhoneIcon />
                    )}
                  </ReportType>
                )}
                <ListItemText
                  primary={removeHTMLTags(item.note)}
                  secondary={secondaryLabel}
                />
                {item?.isDraft && <DraftMarker $withLabel />}
                <ListItemSecondaryAction $disabled>
                  <DateTile
                    value={
                      item.visitDate ||
                      item.phoneCallDate ||
                      item.noteDate ||
                      item.remarkDate
                    }
                    showBadge={item.nextAction === null}
                  />
                </ListItemSecondaryAction>
              </StyledListItem>
              {!isLast && <Divider />}
            </div>
          );
        }}
        disabled={disabled}
      />
    </Collapsible>
  );
};

NotesList.propTypes = {
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  notes: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        clientId: PropTypes.string,
        visitDate: PropTypes.string,
        phoneCallDate: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        note: PropTypes.string,
        isNoShow: PropTypes.bool,
        isDraft: PropTypes.bool,
        contactPersonId: PropTypes.string,
        nextAction: PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
          action: PropTypes.string,
          contactPersonId: PropTypes.string,
          doAt: PropTypes.string,
          duration: PropTypes.number,
          updatedAt: PropTypes.string,
        }),
        demoProducts: PropTypes.arrayOf(
          PropTypes.shape({ code: PropTypes.string })
        ),
      })
    ),
  }),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.shape({}),
  listTestId: PropTypes.string,
  onPlusButtonClick: PropTypes.func,
  itemClickPath: PropTypes.string,
  showDemoLabel: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onMenuOptionClick: PropTypes.func,
  showFilters: PropTypes.bool,
};

export default NotesList;
