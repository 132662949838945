import { createAction } from '@reduxjs/toolkit';

export const updateClientName = createAction(
  'clientData/updateClientName',
  function prepare(clientId, name) {
    return {
      payload: {
        clientId,
        name,
      },
    };
  }
);

export const deleteClientData = createAction(
  'clientData/delete',
  function prepare(clientId) {
    return {
      payload: {
        clientId,
      },
    };
  }
);

export const createClientStore = createAction(
  'clientData/createClientStore',
  function prepare(clientId, clientData) {
    return {
      payload: {
        clientId,
        clientData,
      },
    };
  }
);
