import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactPersonView from './ContactPersonView';
import {
  createContactPerson,
  editContactPerson,
} from '../../store/actions/contactPersonActions';
import { setCallNoteContactPerson } from '../../store/actions/notesActions';

const AddContactPersonToVisitView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { noteId, clientId, contactPersonId } = useParams();

  return (
    <ContactPersonView
      goBackAction={() => {
        history.push(`/customers/${clientId}/calls/${noteId}/addContactPerson`);
      }}
      onSubmit={(values, isExistingContact) => {
        const newValues = {
          ...values,
          clientId,
        };
        if (isExistingContact) {
          dispatch(editContactPerson(contactPersonId, newValues, clientId));
        } else {
          dispatch(
            createContactPerson(contactPersonId, newValues, clientId, true)
          );
          dispatch(setCallNoteContactPerson(contactPersonId, noteId, clientId));
        }

        history.push(`/customers/${clientId}/calls/${noteId}`, {
          from: 'addContactPerson',
        });
      }}
    />
  );
};

export default AddContactPersonToVisitView;
