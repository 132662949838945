import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;

  div {
    margin-top: -18px;
  }
`;

const Splash = () => (
  <Container>
    <div className="splash-icon" />
  </Container>
);

export default Splash;
