import { useState } from 'react';
import * as serviceWorker from './serviceWorker';

const useRegisterServiceWorker = () => {
  const [showNewVersionSnackbar, setShowNewVersionSnackbar] = useState(false);

  serviceWorker.register({
    onUpdate: async (registration) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        setShowNewVersionSnackbar(true);
      }
    },
  });

  return showNewVersionSnackbar;
};

export default useRegisterServiceWorker;
