import PropTypes from 'prop-types';

export function getAddress(address) {
  if (!address) {
    return '';
  }
  const { street, postcode, city } = address;
  return [street, postcode, city].filter(Boolean).join(', ');
}

export const addressProps = {
  street: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
};
