import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledContactPersonView = styled.div`
  margin: 0 0 32px 0;
  > * {
    margin-top: 24px;
  }
  & .MuiInputBase-input {
    color: black;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  & .MuiButtonBase-root {
    margin: 0 12px 58px 0;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  padding: 0 8px;
  background-color: ${(props) =>
    props.type === 'email' ? '#666666' : '#00BB7E'};

  & .MuiSvgIcon-root {
    color: #ffffff;
  }

  &:hover {
    background-color: ${(props) =>
      props.type === 'email' ? '#666666' : '#00BB7E'};
  }
`;
