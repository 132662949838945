import { createSlice, createAction } from '@reduxjs/toolkit';

export const updateNewClientDetails = createAction(
  'client/updateNewClientDetails',
  function prepare(clientId, clientData) {
    return {
      payload: {
        clientId,
        clientData,
      },
    };
  }
);

export const updateNewClientName = createAction(
  'clientData/updateNewClientName',
  function prepare(clientId, name) {
    return {
      payload: {
        clientId,
        name,
      },
    };
  }
);

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    newClientListItem: null,
    newClientData: null,
  },
  reducers: {
    createNewClient: (state, action) => {
      const clientId = action.payload;

      state.newClientListItem = {
        areaCode: '',
        classification: '',
        contactPersons: [],
        department: '',
        distance: '',
        entry: '',
        id: clientId,
        lat: null,
        lng: null,
        note: '',
        owned: true,
        sicCode: '',
        sicDescription: '',
        subDepartment: '',
        updatedAt: '',
        visitAddress: { name: '' },
        generalPhoneNumber: '',
        email: '',
        website: '',
      };

      state.newClientData = {
        id: clientId,
        name: '',
        photos: {
          total: 0,
          data: [],
        },
        visits: {
          total: 0,
          data: [],
        },
        phoneCalls: {
          total: 0,
          data: [],
        },
        applications: {
          total: 0,
          data: [],
        },
        applicationNotes: {
          total: 0,
          data: [],
        },
        orders: {
          data: [],
        },
        orderHistory: {
          orders: [],
          quotations: [],
          products: [],
        },
        demoProducts: {
          total: 0,
          data: [],
        },
        contactPersons: {
          total: 0,
          data: [],
        },
        openActions: {
          total: 0,
          data: [],
        },
        attachments: {
          total: 0,
          data: [],
        },
        access: 'W',
        owned: true,
        currency: '€',
        addresses: {
          invoice: { street: '', postcode: '', city: '', extraInfo: '' },
          delivery: { street: '', postcode: '', city: '', extraInfo: '' },
        },
      };
    },
  },
  extraReducers: {
    [updateNewClientDetails]: (state, action) => {
      const { clientData } = action.payload;
      state.newClientListItem = {
        ...state.newClientListItem,
        ...clientData,
        visitAddress: {
          ...state.newClientListItem.visitAddress,
          ...clientData.visitAddress,
        },
        edited: true,
      };
    },
    [updateNewClientName]: (state, action) => {
      const { name } = action.payload;
      state.newClientData.name = name;
    },
  },
});

export const { createNewClient } = clientSlice.actions;

export default clientSlice.reducer;
