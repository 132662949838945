import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClientAddressView from './ClientAddressView';
import { updateClientDetails } from '../../store/reducers/clients';

const EditClientAddressView = () => {
  const { clientId } = useParams();
  const { clients, searchedClients, plannedClients, plannedClientsTomorrow } =
    useSelector((state) => state.clients);
  const clientListItem =
    clients.find((client) => client.id === clientId) ||
    searchedClients.find((client) => client.id === clientId) ||
    plannedClients.find((client) => client.id === clientId) ||
    plannedClientsTomorrow.find((client) => client.id === clientId);

  return (
    <ClientAddressView
      updateClientDetails={updateClientDetails}
      detailsPath={`/customers/${clientId}/details`}
      clientListItem={clientListItem}
    />
  );
};

export default EditClientAddressView;
