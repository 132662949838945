import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PropTypes from 'prop-types';
import HelperText from '@material-ui/core/FormHelperText';
import {
  Label,
  ERROR_COLOR,
  DISABLED_COLOR,
  DEFAULT_COLOR,
} from 'frontend-components';

const StyledTextArea = styled.div`
  font-size: 16px;

  label {
    display: block;
    font-size: 12px;
    padding-bottom: 8px;
  }

  textarea {
    font: inherit;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    padding: 15px 15px;
    color: #000000;
    border-color: ${({ error }) => (error ? ERROR_COLOR : DEFAULT_COLOR)};
  }

  textarea::placeholder {
    color: ${DEFAULT_COLOR};
  }

  [disabled]::placeholder {
    color: ${DISABLED_COLOR};
  }

  textarea[disabled] {
    color: ${DISABLED_COLOR};
    cursor: default;
    border-color: ${DISABLED_COLOR};
    background: transparent;
    border-style: dotted;
    resize: none;
  }
`;

const ErrorMessage = styled(HelperText)`
  margin-top: -18px;
  padding-bottom: 18px;
`;

const HelpText = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

const TextArea = ({
  label,
  placeholder,
  fullWidth,
  onChange,
  value,
  id,
  rowsMin = 5,
  maxLength,
  errorMessage,
  helpText,
  disabled,
  ...rest
}) => {
  return (
    <StyledTextArea fullWidth={fullWidth} error={errorMessage}>
      <Label className="label" disabled={disabled} error={!!errorMessage}>
        {label}
      </Label>
      <TextareaAutosize
        aria-label={label}
        minRows={rowsMin}
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        {...rest}
        maxLength={maxLength}
      />
      {helpText && <HelpText>{helpText}</HelpText>}
      {errorMessage && (
        <ErrorMessage error={!!errorMessage}>{errorMessage}</ErrorMessage>
      )}
    </StyledTextArea>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rowsMin: PropTypes.number,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextArea;
