import SvgIcon from '@material-ui/core/SvgIcon';

function FileText(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M10,0 L2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C15.1,20 16,19.1 16,18 L16,6 L10,0 Z M12,16 L4,16 L4,14 L12,14 L12,16 Z M12,12 L4,12 L4,10 L12,10 L12,12 Z M9,7 L9,1.5 L14.5,7 L9,7 Z"
        transform="translate(4 2)"
      />
    </SvgIcon>
  );
}

export default FileText;
