import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from 'frontend-components';

import List from '../../components/List/List';
import ListItemText from '../../components/List/ListItemText';
import withClientData from '../../helpers/withClientData';
import {
  TabContainer,
  ExpirationContainer,
  ExpirationText,
  SideItems,
  QuotationListItem,
  ItemsSize,
} from './Tabs.styles';
import { quotationsHistoryShape } from '../../helpers/clientDataPropTypes';
import { calculatePriceWithDiscount } from '../../helpers/priceManipulations';

const calculateExpiration = (createdAt, quotationExpiration) => {
  const today = new Date();
  const date = new Date(createdAt);
  return quotationExpiration - differenceInDays(today, date);
};

const ExpirationInfo = ({ daysDiff }) => {
  const { t } = useTranslation();

  if (daysDiff === 0) {
    return <ExpirationText>{t('orders_expires_today')}</ExpirationText>;
  }

  if (daysDiff < 0) {
    return <ExpirationText>{t('orders_expired')}</ExpirationText>;
  }

  return (
    <>
      <ExpirationText>{t('orders_expiration_text')}</ExpirationText>
      <ExpirationText>
        {`${daysDiff} ${t('orders_expiration_days')}`}
      </ExpirationText>
    </>
  );
};

ExpirationInfo.propTypes = {
  daysDiff: PropTypes.number,
};

const QuotationsTab = ({ quotationsData }) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { quotationExpiration } = useSelector((state) => state.user.data);
  const history = useHistory();
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(
      [...quotationsData].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
  }, [quotationsData]);

  return (
    <Container maxWidth="sm">
      <TabContainer>
        <List data-testid="quotations-list">
          {sortedData &&
            sortedData.map((quotation) => {
              if (!quotation.products.length) return null;

              const secondaryLabel = `${
                quotation.products[0].currency
              } ${calculatePriceWithDiscount(
                quotation.totalCost,
                quotation.discount
              )}`;
              const daysDiff = calculateExpiration(
                quotation.createdAt,
                quotationExpiration
              );
              return (
                daysDiff <= quotationExpiration && (
                  <div key={quotation.id}>
                    <QuotationListItem
                      button
                      onClick={() => {
                        history.push(
                          `/customers/${clientId}/orderHistory/quotation/${quotation.id}`
                        );
                      }}
                      data-testid="quotation-list-item"
                    >
                      <ListItemText
                        primary={
                          quotation.referenceNumber || t('quotation_pending')
                        }
                        secondary={secondaryLabel}
                      />
                      <ListItemText
                        secondary={new Date(
                          quotation.createdAt
                        ).toLocaleDateString()}
                      />
                      <SideItems>
                        <ItemsSize>{quotation.products.length}</ItemsSize>
                        <ExpirationContainer>
                          <ExpirationInfo daysDiff={daysDiff} />
                        </ExpirationContainer>
                      </SideItems>
                    </QuotationListItem>
                    <Divider />
                  </div>
                )
              );
            })}
        </List>
      </TabContainer>
    </Container>
  );
};

QuotationsTab.propTypes = {
  quotationsData: quotationsHistoryShape,
};

export default withClientData(QuotationsTab);
