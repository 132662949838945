import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PhotoIcon from '@material-ui/icons/Photo';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import StopIcon from '@material-ui/icons/Stop';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (orientation: landscape) {
    flex-direction: column;
    margin-top: 16px;
    margin-left: 16px;
  }
`;

const ButtonWrapper = styled.div`
  width: 64px;
  margin: 4px;
  @media (orientation: landscape) {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    }
  }
`;

const ButtonLabel = styled.div`
  font-size: 12px;
  color: #666;
  text-align: center;
  margin-top: 8px;

  @media (orientation: landscape) {
    margin: 0 5px;
  }
`;

const StyledButton = styled.button`
  padding: 0 8px;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  box-shadow: none;
  background: #fff;
  position: relative;
  cursor: pointer;
  
  @media (orientation: landscape) {
    max-height: 20vh;
    max-width: 20vh;
  }
}
`;

const StyledInputFileWrapper = styled.div`
  width: 64px;
  height: 64px;
  position: relative;

  @media (orientation: landscape) {
    max-height: 20vh;
    max-width: 20vh;
  }

  label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #747474;
    line-height: 1;
    cursor: pointer;
  }
`;

const StyledInputFile = styled.input`
  width: 100%;
  height: 100%;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: '';
    background: #ffffff;
    display: inline-block;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 8px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 16px;
    height: 100%;
    width: 100%;
    outline: none;
    -webkit-user-select: none;
    cursor: pointer;
    text-align: center;
  }
  &:disabled {
    & ~ label svg {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

const UploaderButtons = ({
  handleCapturePhoto,
  handleStartCaptureClick,
  handleStopCaptureClick,
  handleSelectFile,
  isRecording,
  photoDisabled,
  videoDisabled,
  galleryDisabled,
  disableRecording = false,
}) => {
  const { t } = useTranslation();
  const { online } = useSelector((state) => state.offline);

  return (
    <ButtonsWrapper>
      <ButtonWrapper>
        <StyledButton
          disabled={photoDisabled}
          theme="secondary"
          aria-label="capture_photo"
          type="button"
          onClick={handleCapturePhoto}
        >
          <PhotoCameraIcon color={photoDisabled ? 'disabled' : 'secondary'} />
        </StyledButton>
        <ButtonLabel>{t('camera_button_photo')}</ButtonLabel>
      </ButtonWrapper>

      {!disableRecording && (
        <ButtonWrapper>
          {isRecording ? (
            <StyledButton
              theme="secondary"
              aria-label="stop_recording"
              type="button"
              onClick={handleStopCaptureClick}
            >
              <StopIcon color="secondary" />
            </StyledButton>
          ) : (
            <StyledButton
              disabled={!online || videoDisabled}
              theme="secondary"
              aria-label="record_video"
              type="button"
              onClick={handleStartCaptureClick}
            >
              <RadioButtonCheckedIcon
                color={!online || videoDisabled ? 'disabled' : 'secondary'}
              />
            </StyledButton>
          )}
          <ButtonLabel>{t('camera_button_video')}</ButtonLabel>
        </ButtonWrapper>
      )}
      <ButtonWrapper>
        <StyledInputFileWrapper>
          <StyledInputFile
            id="file-input"
            name="file-input"
            type="file"
            onChange={handleSelectFile}
            multiple="multiple"
            accept={online ? 'image/*,video/*' : 'image/*'}
            disabled={galleryDisabled}
          />
          <label htmlFor="file-input">
            <PhotoIcon color="secondary" />
          </label>
        </StyledInputFileWrapper>
        <ButtonLabel>{t('camera_button_gallery')}</ButtonLabel>
      </ButtonWrapper>
    </ButtonsWrapper>
  );
};

UploaderButtons.propTypes = {
  handleCapturePhoto: PropTypes.func,
  handleStartCaptureClick: PropTypes.func,
  handleStopCaptureClick: PropTypes.func,
  handleSelectFile: PropTypes.func,
  isRecording: PropTypes.bool,
  photoDisabled: PropTypes.bool,
  videoDisabled: PropTypes.bool,
  galleryDisabled: PropTypes.bool,
  disableRecording: PropTypes.bool,
};

export default UploaderButtons;
