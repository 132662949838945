import PropTypes from 'prop-types';

export const APPLICATION_VIEW_TABS = {
  TECHNICAL: 'technical',
  BEFORE: 'before',
  AFTER: 'after',
};

export const APPLICATIONS_TYPES = [
  { value: 'bearing', label: 'app_type_bearing' },
  { value: 'chain', label: 'app_type_chain' },
  { value: 'gearbox', label: 'app_type_gearbox' },
  { value: 'other', label: 'app_type_other' },
];

export const BEARING_TYPES = [
  { value: 'roller', label: 'app_bearing_type_roller' },
  { value: 'single_race_ball', label: 'app_bearing_type_sing_ball' },
  { value: 'double_race_ball', label: 'app_bearing_type_doub_ball' },
  { value: 'needle', label: 'app_bearing_type_needle' },
  { value: 'cylindrical', label: 'app_bearing_type_cylinder' },
  { value: 'spherical', label: 'app_bearing_type_spherical' },
  { value: 'plain', label: 'app_bearing_type_plain' },
];

export const CHAIN_TYPES = [
  { value: 'roller', label: 'app_chain_type_roller' },
  { value: 'bush', label: 'app_chain_type_bush' },
  { value: 'lifting', label: 'app_chain_type_lifting' },
  { value: 'link', label: 'app_chain_type_link' },
];

export const GEARBOX_TYPES = [
  { value: 'worm', label: 'app_gbox_type_worm' },
  { value: 'bevel_helical', label: 'app_gbox_type_bh' },
  { value: 'right_angle_helical', label: 'app_gbox_type_rah' },
  { value: 'parallel_shaft', label: 'app_gbox_type_ps' },
  { value: 'planetary', label: 'app_gbox_type_planetary' },
  { value: 'coaxial', label: 'app_gbox_type_coaxial' },
  { value: 'other', label: 'app_gbox_type_other' },
];

export const MOUNTING_POSITIONS = [
  { value: 'horizontal', label: 'gbox_position_horizontal' },
  { value: 'vertical', label: 'app_gbox_position_vertical' },
  { value: 'angled', label: 'app_gbox_position_angled' },
];

export const LUBRICATION_TYPES = [
  { value: 'splash', label: 'app_gbox_lub_type_splash' },
  { value: 'bath', label: 'app_gbox_lub_type_bath' },
  { value: 'forced', label: 'app_gbox_lub_type_forced' },
];

export const FILTER_OPTIONS = [
  { value: 'yes', label: 'app_gbox_filter_yes' },
  { value: 'no', label: 'app_gbox_filter_no' },
];

export const VENTILATION_SHAFT_OPTIONS = [
  { value: 'yes', label: 'app_gbox_ventilation_yes' },
  { value: 'no', label: 'app_gbox_ventilation_no' },
];

export const LEAKAGES_OPTIONS = [
  { value: 'yes', label: 'app_gbox_ventilation_yes' },
  { value: 'no', label: 'app_gbox_ventilation_no' },
];

export const LOAD_OPTIONS = [
  { value: 'low', label: 'app_load_low' },
  { value: 'medium', label: 'app_load_medium' },
  { value: 'high', label: 'app_load_high' },
];

export const LOCATION_OPTIONS = [
  { value: 'inside', label: 'app_location_inside' },
  { value: 'outside', label: 'app_location_outside' },
];

export const HUMIDITY_OPTIONS = [
  { value: 'dry', label: 'app_hum_dry' },
  { value: 'water_spray', label: 'app_hum_water_spray' },
  { value: 'constant', label: 'app_hum_constant' },
  { value: 'salt_water', label: 'app_hum_salt_water' },
];

export const CHEM_INFLU_OPTIONS = [
  { value: 'detergents', label: 'app_chem_detergents' },
  { value: 'acid', label: 'app_chem_acid' },
  { value: 'alcaline', label: 'app_chem_alcaline' },
  { value: 'other', label: 'app_chem_other' },
];

export const EXPOSURE_OPTIONS = [
  { value: 'uv', label: 'app_exp_uv' },
  { value: 'adhesives', label: 'app_exp_adhesives' },
  { value: 'dust', label: 'app_exp_dust' },
  { value: 'air', label: 'app_exp_air' },
  { value: 'other', label: 'app_exp_other' },
];

export const ZONE_OPTIONS = [
  { value: 'ex', label: 'app_zone_ex' },
  { value: 'normal', label: 'app_zone_normal' },
];

export const FOOD_GRADE_OPTIONS = [
  { value: 'not', label: 'app_food_not' },
  { value: 'h1', label: 'app_food_h1' },
  { value: 'h2', label: 'app_food_h2' },
  { value: 'h3', label: 'app_food_h3' },
];

export const BIODEGRADATION_OPTIONS = [
  { value: 'no', label: 'app_biodegradation_no' },
  { value: 'yes', label: 'app_biodegradation_yes' },
];

export const SAFETY_OPTIONS = [
  { value: 'not_free', label: 'app_safety_not_free' },
  { value: 'free', label: 'app_safety_free' },
];

export const MONITORING_OPTIONS = [
  { value: 'vibration', label: 'app_vibration_analysis' },
  { value: 'thermographic', label: 'app_thermographic' },
  { value: 'ultrasonic', label: 'app_ultrasonic' },
];

export const ISO_GRADE_OPTIONS = [
  { value: '10' },
  { value: '32' },
  { value: '46' },
  { value: '68' },
  { value: '100' },
  { value: '150' },
  { value: '220' },
  { value: '320' },
  { value: '460' },
  { value: '680' },
];

export const NLGI_GRADE_OPTIONS = [
  { value: '000' },
  { value: '00' },
  { value: '0' },
  { value: '1' },
  { value: '2' },
  { value: '2-3' },
  { value: '3' },
];

export const LUBRICATION_PERIODS = [
  { value: 'day', label: 'app_before_period_day' },
  { value: 'week', label: 'app_before_period_week' },
  { value: 'month', label: 'app_before_period_month' },
  { value: 'year', label: 'app_before_period_year' },
];

export const APPLICATION_METHODS = [
  { value: 'manual', label: 'before_method_manual' },
  { value: 'automatic', label: 'before_method_automatic' },
];

export const MACHINE_RUNNING_OPTIONS = [
  { value: 'running', label: 'before_machine_running' },
  { value: 'stopped', label: 'before_machine_stopped' },
];

export const CLEAN_BEFORE_OPTIONS = [
  { value: 'yes', label: 'app_before_clean_yes' },
  { value: 'no', label: 'app_before_clean_no' },
];

export const COMPONENTS_LIFETIME_PERIODS = [
  { value: 'day', label: 'before_avg_lifetime_days' },
  { value: 'week', label: 'before_avg_lifetime_weeks' },
  { value: 'month', label: 'before_avg_lifetime_months' },
  { value: 'year', label: 'before_avg_lifetime_years' },
];

export const VALIDATION_CONSTANTS = {
  maxIntNum: 2147483647,
  maxTemp: 5000,
  minTemp: -273.15,
  maxFloatNum: 2000000000,
  maxNoiseLevel: 300,
};

export const TAG_PROP_TYPES = {
  id: PropTypes.string,
  name: PropTypes.string,
};
