export const NO_EXPANDED_PANEL = '';

export const LIST_TYPES = {
  VISITS: 'visits',
  PHONE_CALLS: 'phoneCalls',
  PHOTOS: 'photos',
  APPLICATIONS: 'applications',
  ORDERS: 'orders',
  CONTACTS: 'contacts',
  REPORTS: 'reports',
};

export const APPLICATION_LIST_TYPES = {
  PHOTOS_GENERAL: 'photosGeneral',
  PHOTOS_BEFORE: 'photosBefore',
  PHOTOS_AFTER: 'photosAfter',
  NOTES: 'notes',
  CONATCTS: 'contacts',
};

export const ORDER_OR_QUOTATION_PANEL_TYPE = {
  CC: 'cc',
};

export const PHOTOS_GENERAL_GALLERY = `${APPLICATION_LIST_TYPES.PHOTOS_GENERAL}/gallery`;
export const PHOTOS_BEFORE_GALLERY = `${APPLICATION_LIST_TYPES.PHOTOS_BEFORE}/gallery`;
export const PHOTOS_AFTER_GALLERY = `${APPLICATION_LIST_TYPES.PHOTOS_AFTER}/gallery`;
