import platform from 'platform';
import apiService from './api.service';

const DATA_MAX_LENGTH = 255;

export const sendHelpMessage = (message) =>
  apiService({
    method: 'POST',
    url: `/qac/help`,
    data: {
      message,
      pageUrl: window.location.href.substr(0, DATA_MAX_LENGTH),
      deviceBrand:
        platform.manufacturer?.substr(0, DATA_MAX_LENGTH) || 'not available',
      deviceModel:
        platform.product?.substr(0, DATA_MAX_LENGTH) || 'not available',
      osVersion:
        platform.os?.version?.substr(0, DATA_MAX_LENGTH) || 'not available',
      userAgent: window.navigator.userAgent.substr(0, DATA_MAX_LENGTH),
    },
  });
