import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { prepareOrderPayload } from '../helpers/prepOrderPayload';
import { postOrder } from '../store/actions/orderActions';
import { asyncSetOrderSendConfirmation } from '../store/reducers/drafts';
import { convertQuotation } from '../store/actions/quotationActions';

function useTermsAndSignatureOptions(isFromSignatureView = false) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const { termsEnabled, signatureEnabled, termsContent, conditionsContent } =
    useSelector((state) => state.clientData.data[clientId]);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const finishOrder = (orderData) => {
    const orderPayload = prepareOrderPayload(orderData, clientId);
    orderData.quotationId
      ? dispatch(
          convertQuotation(orderData.quotationId, orderPayload, clientId)
        )
      : dispatch(postOrder(uuidv4(), orderPayload, clientId));
    history.push(`/customers/${clientId}/create/order/confirmation`);
  };

  const finishAndSendAction = () => {
    dispatch(asyncSetOrderSendConfirmation(true)).then(({ payload }) => {
      finishOrder(payload);
    });
  };

  const finishWithoutSendingAction = () => {
    dispatch(asyncSetOrderSendConfirmation(false)).then(({ payload }) => {
      finishOrder(payload);
    });
  };

  const summaryFinishButton = () => ({
    summaryFooterButtonTitle: t('order_finish'),
    summaryFooterButtonAction: () => setShowConfirmationDialog(true),
    finishAndSendAction,
    finishWithoutSendingAction,
    showConfirmationDialog,
    setShowConfirmationDialog,
  });

  const summarySignButton = () => ({
    summaryFooterButtonTitle: t('terms_sign'),
    summaryFooterButtonAction: () => {
      history.push(`/customers/${clientId}/create/order/signature`);
    },
  });

  const summaryTermsButton = () => ({
    summaryFooterButtonTitle: t('view_terms'),
    summaryFooterButtonAction: () => {
      history.push(`/customers/${clientId}/create/order/terms`);
    },
  });

  const termsFinishButton = () => ({
    termsFooterButtonTitle: t('order_finish'),
    termsFooterButtonAction: () => setShowConfirmationDialog(true),
    termsFooterButtonEnabled: true,
    finishAndSendAction,
    finishWithoutSendingAction,
    showConfirmationDialog,
    setShowConfirmationDialog,
  });

  const termsSignButton = () => ({
    termsFooterButtonTitle: t('terms_sign'),
    termsFooterButtonAction: () => {
      history.push(`/customers/${clientId}/create/order/signature`);
    },
  });

  const withContent = (termsAndSignature) => ({
    ...termsAndSignature,
    termsContent,
    conditionsContent,
  });

  if (isFromSignatureView) {
    return {
      signatureFooterButtonAction: () => setShowConfirmationDialog(true),
      finishAndSendAction,
      finishWithoutSendingAction,
      showConfirmationDialog,
      setShowConfirmationDialog,
    };
  }

  if (termsEnabled && signatureEnabled) {
    return withContent({
      ...summaryTermsButton(),
      ...termsSignButton(),
    });
  }

  if (termsEnabled && !signatureEnabled) {
    return withContent({
      ...summaryTermsButton(),
      ...termsFinishButton(),
    });
  }

  if (!termsEnabled && signatureEnabled) {
    return withContent(summarySignButton());
  }

  return withContent(summaryFinishButton());
}

export default useTermsAndSignatureOptions;
