import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Collapsible from '../../components/Collapsible/Collapsible';

const StyledCollapsible = styled(Collapsible)`
  margin: 8px auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  h2:first-letter {
    text-transform: capitalize;
  }
`;

const SicCodeGroup = memo(
  ({ title, itemsSize, children, isExpanded, onChange }) => (
    <StyledCollapsible
      expanded={isExpanded}
      onChange={() => onChange(title, isExpanded)}
      title={title}
      displayPlusButton={false}
      itemsSize={itemsSize}
      collapsible
      multiline
      removePlusButtonEmptySpace
      data-testid="sic-codes-group"
    >
      {children}
    </StyledCollapsible>
  )
);

SicCodeGroup.propTypes = {
  title: PropTypes.string,
  itemsSize: PropTypes.number,
  children: PropTypes.node,
  isExpanded: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SicCodeGroup;
