import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/icons/KeyboardArrowDown';

const StyledFormControl = styled(FormControl)`
  & .MuiListItem-button:hover {
    background-color: #f2f2f2;
  }
`;
const StyledInputLabel = styled(InputLabel)``;
const StyledSelect = styled(MuiSelect)`
  color: rgba(0, 0, 0, 0.6);

  input {
    font-size: 16px;
  }

  &.MuiInput-underline:before {
    border-bottom: 1px solid #999999;
  }

  &.MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
  }

  & .MuiFormLabel-root {
    font-size: 16px;
  }

  & .MuiSelect-select {
    color: #000000;
  }

  & .MuiSelect-select.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

const ArrowIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.54);
  margin: 0 12px;
`;

const Select = (props) => {
  const {
    label,
    disabled,
    fullWidth,
    error,
    errorMessage = '',
    initialValue = '',
    onChange,
    children,
    id,
    PaperProps = null,
    ...selectProps
  } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    const { value } = event.target;

    setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <StyledFormControl fullWidth={fullWidth} disabled={disabled} error={error}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        id={id}
        value={value}
        onChange={handleChange}
        IconComponent={ArrowIcon}
        {...selectProps}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps,
        }}
      >
        {children}
      </StyledSelect>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </StyledFormControl>
  );
};

Select.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
  PaperProps: PropTypes.shape({}),
};

export default Select;
