import styled from 'styled-components';
import { default as MuiCard } from '@material-ui/core/Card/Card';
import NearMe from '@material-ui/icons/NearMe';
import PhoneIcon from '@material-ui/icons/Phone';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

export const StyledCard = styled(MuiCard)`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: none;
  padding: 8px 0;
  width: 100%;
`;

export const TextContainer = styled.div`
  min-width: 100px;
  flex-grow: 1;

  & > div {
    margin-top: 8px;
  }
`;

export const SubTitle = styled.div`
  color: #666666;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h2`
  color: #000000;
  font-size: 14px;
  margin: 0 50px 16px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
`;

export const LockIconContainer = styled.div`
  color: #999999;
  position: absolute;
  top: 8px;
  right: 32px;
`;

export const StyledNearMe = styled(NearMe)`
  fill: #426389;
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  fill: #00bb7e;
`;

export const StyledCameraAltIcon = styled(CameraAltIcon)`
  fill: #426389;
`;

export const StyledData = styled.div`
  display: flex;

  > div {
    flex-basis: 50%;
  }
`;

export const StyledDescription = styled.div`
  border-radius: 4px;
  background-color: #f2f2f2;
  margin-top: 8px;
  padding: 12px 22px 12px 12px;
  display: block;
  box-sizing: border-box;
  position: relative;

  a {
    color: #000;
    display: block;
  }

  > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: #ccc;
    font-size: 20px;
  }
`;

export const CollapsibleWrapper = styled.div`
  width: 100%;

  > div {
    margin-top: 5px;
  }
`;

export const CollapseButton = styled.div`
  padding-top: 8px;
  width: 100%;
  cursor: pointer;
  text-align: center;
`;

export const ClassificationCodeContainer = styled.div`
  position: absolute;
  right: 0;
`;

export const ActionDoneInfo = styled.div`
  background: #f2f2f2;
  margin: 6px 0 4px;
  width: 100%;
  padding: 6px;
  color: #00bb7e;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;
