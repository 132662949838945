import { combineReducers } from 'redux';
import userReducer from './userSlice';
import clients from './clients';
import clientDataReducer from './clientData';
import clientVisitsDataReducer from './clientVisitsData';
import photosFromDevice from './photosFromDevice';
import productsDataReducer from './products';
import drafts from './drafts';
import app from './app';
import client from './client';
import sicCodes from './sicCodes';
import clientUI from './clientUI';
import video from './video';
import help from './helpSlice';

export default combineReducers({
  app,
  user: userReducer,
  client,
  clients,
  clientData: clientDataReducer,
  clientVisitsData: clientVisitsDataReducer,
  photosFromDevice,
  products: productsDataReducer,
  drafts,
  sicCodes,
  clientUI,
  video,
  help,
});
