import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDateWithMonthName } from 'frontend-components/lib/helpers';

const Container = styled.div`
  padding: 12px 16px 16px 16px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin: 0 -16px;
  border-bottom: 1px solid #d8d8d8;

  label {
    font-size: 12px;
    color: #666666;
    display: block;
    margin-bottom: 4px;
    line-height: 16px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  padding-top: 16px;

  & > div {
    text-align: right;
    width: 33%;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const Date = styled.span`
  padding-left: 8px;
  font-size: 12px;
`;

const DiscountSection = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TotalsWithVat = ({
  createdAt,
  currency,
  totalCost,
  dateLabel,
  netPrice,
  vatLabel,
  vat,
  discount,
}) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="totals">
      <DateContainer>
        <label>{dateLabel}</label>
        <Date>{formatDateWithMonthName(createdAt).toUpperCase()}</Date>
        {discount && discount !== '0' && (
          <DiscountSection data-testid="discount">
            <label>{t('discount_label')}</label>
            <BoldText>{`${discount}%`}</BoldText>
          </DiscountSection>
        )}
      </DateContainer>
      <PriceContainer>
        <div data-testid="net">
          <label>{t('net_price')}</label>
          <div>
            {currency} {netPrice}
          </div>
        </div>
        <div data-testid="vat">
          <label>{`${t('vat')} ${vatLabel}%`}</label>
          <div>
            {currency} {vat}
          </div>
        </div>
        <div data-testid="total">
          <label>{t('total_amount')}</label>
          <BoldText>
            {currency} {totalCost}
          </BoldText>
        </div>
      </PriceContainer>
    </Container>
  );
};

TotalsWithVat.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currency: PropTypes.string,
  totalCost: PropTypes.string,
  dateLabel: PropTypes.string,
  netPrice: PropTypes.string,
  vatLabel: PropTypes.string,
  vat: PropTypes.string,
  discount: PropTypes.string,
};

export default TotalsWithVat;
