import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { Button } from 'frontend-components';

import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import {
  getPackageLabel,
  packagingVariantProps,
  packagingDataProps,
  getPriceByQuantity,
  getQuantityThreshold,
} from '../../../helpers/packaging';

const DetailsContainer = styled.div`
  padding: 24px;

  :nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const PackagingContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const RemovePackagingButton = styled(IconButton)`
  margin-top: 8px;
  margin-right: -12px;

  .MuiSvgIcon-root {
    color: #999999;
  }
`;

const QuantityInput = styled(Input)`
  margin-right: 8px;
`;

const QuantityButton = styled(Button)`
  height: 48px;
  width: 64px;
  border-radius: 8px;
  margin: 0 0 0 8px;
  color: #e42313;
  border: solid 1px #ccc;

  &.MuiButton-root:hover {
    background-color: #f2f2f2;
  }

  &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
    display: flex;
    background-color: #f2f2f2;
    color: #999999;
  }
`;

const Details = ({
  detailsData,
  remove,
  setFieldValue,
  index,
  errors,
  handleFormikBlur,
  dropdownItems,
}) => {
  const { t } = useTranslation();
  const { quantity, code, price, currency } = detailsData;
  const variant = dropdownItems.find((item) => item.code === code);
  const { setCapacity, prices } = variant;
  const [quantityThreshold, setQuantityThreshold] = useState(() =>
    getQuantityThreshold(quantity, prices)
  );
  const [quantityDirty, setQuantityDirty] = useState(false);

  const addQuantity = () => {
    setFieldValue(
      `packagingDetails[${index}].quantity`,
      quantity + setCapacity
    );

    const localQuantityThreshold = getQuantityThreshold(
      quantity + setCapacity,
      prices
    );
    if (localQuantityThreshold !== quantityThreshold && quantity > 0) {
      setQuantityThreshold(localQuantityThreshold);
    }
    setFieldValue(
      `packagingDetails[${index}].price`,
      getPriceByQuantity(setCapacity + quantity, prices).price
    );
  };

  const removeQuantity = () => {
    setFieldValue(
      `packagingDetails[${index}].quantity`,
      Math.max(0, quantity - setCapacity)
    );

    const localQuantityThreshold = getQuantityThreshold(
      quantity - setCapacity,
      prices
    );
    if (
      localQuantityThreshold !== quantityThreshold &&
      quantity - setCapacity > 0
    ) {
      setQuantityThreshold(localQuantityThreshold);
    }
    setFieldValue(
      `packagingDetails[${index}].price`,
      getPriceByQuantity(quantity - setCapacity, prices).price
    );
  };

  return (
    <DetailsContainer>
      <PackagingContainer>
        <Select
          data-testid="packaging-dropdown"
          label={t('pck_details_packaging')}
          fullWidth
          value={code}
          onChange={(newCode) => {
            const selectedVariant = dropdownItems.find(
              ({ code }) => code === newCode
            );
            const { packageCapacity, packageName, setCapacity, prices } =
              selectedVariant;
            setFieldValue(
              `packagingDetails[${index}].label`,
              getPackageLabel(packageCapacity, packageName)
            );
            setFieldValue(`packagingDetails[${index}].code`, newCode);
            setFieldValue(`packagingDetails[${index}].quantity`, setCapacity);
            setFieldValue(
              `packagingDetails[${index}].packageCapacity`,
              packageCapacity
            );
            setFieldValue(
              `packagingDetails[${index}].packageType`,
              packageName
            );
            setFieldValue(
              `packagingDetails[${index}].packageUnit`,
              setCapacity
            );
            setFieldValue(
              `packagingDetails[${index}].price`,
              getPriceByQuantity(setCapacity, prices).price
            );

            const localQuantityThreshold = getQuantityThreshold(
              setCapacity,
              prices
            );
            if (localQuantityThreshold !== quantityThreshold) {
              setQuantityThreshold(localQuantityThreshold);
            }
          }}
        >
          {dropdownItems.map(({ code, packageName, packageCapacity }) => (
            <MenuItem key={code} value={code}>
              {getPackageLabel(packageCapacity, packageName)}
            </MenuItem>
          ))}
        </Select>
        <RemovePackagingButton
          onClick={() => remove(index)}
          data-testid="delete-packaging-button"
        >
          <DeleteIcon />
        </RemovePackagingButton>
      </PackagingContainer>
      <PackagingContainer>
        <QuantityInput
          id="quantity"
          label={t('pck_details_quantity')}
          value={quantity}
          fullWidth
          type="number"
          isMinusForbidden
          onchange={(quantity) => {
            setQuantityDirty(true);
            setFieldValue(
              `packagingDetails[${index}].quantity`,
              parseInt(quantity, 10) || 0
            );
          }}
          onBlur={() => {
            const localQuantityThreshold = getQuantityThreshold(
              quantity,
              prices
            );
            if (localQuantityThreshold !== quantityThreshold && quantity > 0) {
              setQuantityThreshold(localQuantityThreshold);
            }
            if (quantityDirty) {
              setFieldValue(
                `packagingDetails[${index}].price`,
                getPriceByQuantity(quantity, prices).price
              );
              setQuantityDirty(false);
            }
          }}
        />
        <QuantityButton
          onClick={removeQuantity}
          disabled={quantity <= 0}
          data-testid="decrementButton"
        >
          -{setCapacity}
        </QuantityButton>
        <QuantityButton onClick={addQuantity} data-testid="incrementButton">
          +{setCapacity}
        </QuantityButton>
      </PackagingContainer>
      <Input
        id="price"
        label={t('pck_details_price')}
        value={price}
        fullWidth
        type="number"
        isMinusForbidden
        leftText={currency}
        onchange={(price) => {
          setFieldValue(
            `packagingDetails[${index}].price`,
            price.replace(',', '.')
          );
        }}
        errorMessage={
          errors.packagingDetails &&
          errors.packagingDetails[index] &&
          errors.packagingDetails[index].price
        }
        onBlur={handleFormikBlur}
      />
      {quantityThreshold && (
        <FormHelperText data-testid="hint-message">
          {t('pck_details_price_info', {
            threshold: quantityThreshold,
          })}
        </FormHelperText>
      )}
    </DetailsContainer>
  );
};

Details.propTypes = {
  detailsData: PropTypes.shape(packagingDataProps),
  dropdownItems: PropTypes.arrayOf(PropTypes.shape(packagingVariantProps)),
  remove: PropTypes.func,
  setFieldValue: PropTypes.func,
  index: PropTypes.number,
  errors: PropTypes.shape({
    packagingDetails: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string,
      })
    ),
  }),
  handleFormikBlur: PropTypes.func,
};

export default Details;
