import SvgIcon from '@material-ui/core/SvgIcon';

function DropInIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2c3.86 0 7 3.14 7 7 0 5.25-7 13-7 13S5 14.25 5 9c0-3.86 3.14-7 7-7zm0 6a1 1 0 100 2 1 1 0 000-2zM9 8a1 1 0 100 2 1 1 0 000-2zm6 0a1 1 0 100 2 1 1 0 000-2z" />{' '}
    </SvgIcon>
  );
}

export default DropInIcon;
