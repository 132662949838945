import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import { Button } from 'frontend-components';
import Dialog from '../Dialog';

export const StyledButton = styled(Button)`
  width: 45%;
  max-width: 140px;

  @media (min-width: 450px) {
    margin-left: 24px !important;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;

  @media (min-width: 450px) {
    justify-content: flex-end;
  }

  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: inherit;
  }
`;

const DeleteDialog = ({
  title,
  message,
  open,
  setOpen,
  handleDelete,
  yesText,
  noText,
  hideDefaultText,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {message && <p>{message}</p>}
        {!hideDefaultText && <p>{t('delete_body_text')}</p>}
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          autoFocus
          aria-label="cancel"
          onClick={() => {
            setOpen(false);
          }}
          theme="secondary"
        >
          {noText || t('delete_negative')}
        </StyledButton>
        <StyledButton
          theme="primary"
          aria-label="delete"
          onClick={handleDelete}
          data-testid="confirm-delete"
        >
          {yesText || t('delete_positive')}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleDelete: PropTypes.func,
  hideDefaultText: PropTypes.bool,
};

export default DeleteDialog;
