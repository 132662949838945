import { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useFormikAutoSave from '../../components/FormikAutoSave/FormAutoSaveHook';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import AppBar from '../../components/AppBar/AppBar.connected';
import Input from '../../components/Input/Input';
import { clientListItemShape } from '../../helpers/clientDataPropTypes';

const ClientAddressContainer = styled.div`
  margin-bottom: 40px;

  & .MuiFormControl-root {
    margin: 24px 0 16px;
  }
`;

const ClientAddressView = ({
  updateClientDetails,
  detailsPath,
  clientListItem,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const visitAddress = (clientListItem && clientListItem.visitAddress) || {};

  const { handleSubmit, setFieldValue, values, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: {
      postcode: visitAddress ? visitAddress.postcode : '',
      street: visitAddress.street ? visitAddress.street : '',
      city: visitAddress.city ? visitAddress.city : '',
    },
    onSubmit: () => {
      if (dirty) {
        dispatch(
          updateClientDetails(clientId, {
            visitAddress: { ...values, name: clientListItem.visitAddress.name },
            lat: '',
            lng: '',
          })
        );
      }
    },
  });

  const { city, postcode, street } = values;

  const navigateToDetails = () => {
    history.push(detailsPath, {
      from: 'cliendAddress',
    });
  };

  useFormikAutoSave(values, handleSubmit, 300, dirty);

  return (
    <Container maxWidth="sm" data-testid="customer-address-view">
      <AppBar
        title={t('client_address')}
        displayBackButton
        backButtonAction={navigateToDetails}
        aside={
          <DeleteIcon
            aria-label="delete-address"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
          />
        }
        elevated
      />
      <ClientAddressContainer>
        <form onSubmit={handleSubmit}>
          <Input
            id="street"
            name="street"
            value={street}
            label={t('client_address_street')}
            fullWidth
            onchange={(value) => setFieldValue('street', value)}
            maxLength={255}
          />
          <Input
            id="postcode"
            name="postcode"
            value={postcode}
            label={t('client_address_postcode')}
            fullWidth
            onchange={(value) => setFieldValue('postcode', value)}
            maxLength={70}
          />
          <Input
            id="city"
            name="city"
            value={city}
            label={t('client_address_city')}
            fullWidth
            onchange={(value) => setFieldValue('city', value)}
            maxLength={255}
          />
        </form>
      </ClientAddressContainer>
      <DeleteDialog
        title={t('address_delete_title')}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        handleDelete={() => {
          dispatch(
            updateClientDetails(clientId, {
              name: clientListItem.visitAddress.name,
              visitAddress: {
                name: clientListItem.visitAddress.name,
                street: '',
                city: '',
                postcode: '',
              },
              lat: '',
              lng: '',
            })
          );

          navigateToDetails();
        }}
      />
    </Container>
  );
};

ClientAddressView.propTypes = {
  clientListItem: clientListItemShape,
  updateClientDetails: PropTypes.func,
  detailsPath: PropTypes.string,
};

export default ClientAddressView;
