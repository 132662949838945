import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Input from '../Input/Input';
import { SearchWrapper } from './Search';

const SearchField = ({ searchTerm, setSearchTerm }) => {
  const { t } = useTranslation();

  return (
    <SearchWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Input
        placeholder={t('list_search_label')}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        onIconClick={() => setSearchTerm('')}
        inputProps={{ 'aria-label': 'search-contact' }}
        autoFocus
        fullWidth
        rightIcon={searchTerm ? <ClearIcon /> : <SearchIcon />}
      />
    </SearchWrapper>
  );
};

SearchField.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default SearchField;
