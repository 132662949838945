import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'frontend-components';

import DemoedProductsList from '../../components/Lists/DemoedProductsList';

const Container = styled.div`
  margin-top: 16px;
`;

const AddDemoedProductSection = ({
  data,
  handleAddButton,
  disabled,
  selectButtonTitle,
  addProductButtonLabel,
}) => {
  const [demoProductExpanded, setDemoProductExpanded] = useState(true);

  return (
    <Container>
      {data ? (
        <DemoedProductsList
          expanded={demoProductExpanded}
          onChange={() => {
            setDemoProductExpanded(!demoProductExpanded);
          }}
          demoProducts={{ total: data.length, data }}
          displayPlusButton
          onPlusButtonClick={() => handleAddButton('demoedProduct')}
          title={selectButtonTitle(data.length)}
          boxShadow
          data-testid="demoproducts-collapsible"
          disabled={disabled}
        />
      ) : (
        <Button
          startIcon={<AddIcon />}
          theme="secondary"
          fullWidth
          onClick={() => handleAddButton('demoedProduct')}
          data-testid="add_demoed_product_btn"
          disabled={disabled}
        >
          {addProductButtonLabel}
        </Button>
      )}
    </Container>
  );
};

AddDemoedProductSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      presentedAt: PropTypes.string,
      reportId: PropTypes.string,
      reportType: PropTypes.string,
    })
  ),
  handleAddButton: PropTypes.func,
  disabled: PropTypes.bool,
  selectButtonTitle: PropTypes.func,
  addProductButtonLabel: PropTypes.string,
};

export default AddDemoedProductSection;
