import { createAction } from '@reduxjs/toolkit';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = createAction(ENQUEUE_SNACKBAR, (payload) => {
  const key = payload.notification.options && payload.notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      ...payload.notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
});

export const closeSnackbar = createAction(CLOSE_SNACKBAR, (key) => ({
  type: CLOSE_SNACKBAR,
  payload: {
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  },
}));

export const removeSnackbar = createAction(REMOVE_SNACKBAR, (key) => ({
  type: REMOVE_SNACKBAR,
  payload: {
    key,
  },
}));
