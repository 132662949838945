import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import languageDetectorService, {
  LANGUAGE_DETECTOR_NAME,
} from './services/languageDetector.service';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(languageDetectorService);

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: false,
    detection: {
      order: [LANGUAGE_DETECTOR_NAME, 'localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${
        process.env.REACT_APP_API_URL || 'https://api.pgslnx291.pgs-soft.com'
      }/open/translations/{{lng}}/qac`,
      parse: (data) => {
        const parsed = JSON.parse(data);
        const keys = Object.keys(parsed.messages);
        const values = Object.values(parsed.messages);
        keys.forEach((key) => {
          if (key.length > 30) {
            // eslint-disable-next-line no-console
            console.error(
              `'Translation key: ${key} is too long. Please make it maximum 30 chars. Now it has ${key.length} chars`
            );
          }
        });
        const keysWithoutNamespace = keys.map((key) => key.slice(4));
        const entries = keysWithoutNamespace.map((key, idx) => [
          key,
          values[idx],
        ]);
        const formattedData = Object.fromEntries(entries);
        return formattedData;
      },
    },
  });

export default i18n;
