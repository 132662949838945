import { useEffect, useLayoutEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import LoginView from './views/LoginView/LoginView';
import SelectCustomerView from './views/SelectCustomerView/SelectCustomerView';
import SearchCustomerView from './views/SearchCustomerView/SearchCustomerView';
import CustomerView from './views/CustomerView/CustomerView';
import ClientGalleryView from './views/GalleryView/ClientGalleryView';
import NewClientGalleryView from './views/GalleryView/NewClientGalleryView';
import ApplicationGalleryView from './views/GalleryView/ApplicationGalleryView';
import ClientPhotoView from './views/GalleryItemView/ClientGalleryItemView';
import ClientPhotoViewUpload from './views/GalleryItemView/ClientGalleryItemViewInProgress';
import NewClientGalleryItemView from './views/GalleryItemView/NewClientGalleryItemView';
import ApplicationView from './views/ApplicationView/ApplicationView.connected';
import AddContactPersonToApplicationView from './views/ContactPersonView/AddContactPersonToApplicationView';
import AddContactPersonToVisitView from './views/ContactPersonView/AddContactPersonToVisitView';
import AddContactPersonToCallView from './views/ContactPersonView/AddContactPersonToCallView';
import AddContactPersonToRemarkView from './views/ContactPersonView/AddContactPersonToRemarkView';
import AddContactPersonView from './views/ContactPersonView/AddContactPersonView';
import VisitNextActionView from './views/NextActionView/VisitNextActionView';
import CallNextActionView from './views/NextActionView/CallNextActionView';
import ApplicationNoteNextActionView from './views/NextActionView/ApplicationNoteNextActionView';
import RemarkNextActionView from './views/NextActionView/RemarkNextActionView';
import VisitProductsView from './views/ProductView/NotesProductsView/VisitProductsView';
import CallProductsView from './views/ProductView/NotesProductsView/CallProductsView';
import ApplicationNodeProductsView from './views/ProductView/NotesProductsView/ApplicationNoteProductsView';
import AddInterflonProductView from './views/ProductView/AddInterflonProduct/AddInterflonProductView';
import AddApplicationContactPersonView from './views/AddContactPersonView/AddApplicationContactPersonView.connected';
import AddVisitContactPersonView from './views/AddContactPersonView/AddVisitContactPersonView.connected';
import AddCallContactPersonView from './views/AddContactPersonView/AddCallContactPerson.connected';
import AddRemarkContactPersonView from './views/AddContactPersonView/AddRemarkContactPerson.connected';
import NewClientView from './views/ClientDetailsView/NewClientView';
import EditClientView from './views/ClientDetailsView/EditClientView';
import NewClientAddressView from './views/ClientAddressView/NewClientAddressView';
import EditClientAddressView from './views/ClientAddressView/EditClientAddressView';
import OrderHistoryView from './views/OrdersHistoryView/OrderHistoryView';
import QuotationDetailsView from './views/QuotationDetailsView/QuotationDetailsView';
import OrderDetailsView from './views/OrderDetailsView/OrderDetailsView';
import ProductDetailsView from './views/ProductDetailsView/ProductDetailsView';
import CallNoteView from './views/NoteView/CallNoteView';
import VisitNoteView from './views/NoteView/VisitNoteView';
import ApplicationNoteView from './views/NoteView/ApplicationNoteView';
import RemarkNoteView from './views/NoteView/RemarkNoteView';
import CreateNewOrderOrQuotation from './views/CreateNewOrderOrQuotation/CreateNewOrderOrQuotation';
import AddApplicationNoteContactPersonView from './views/AddContactPersonView/AddApplicationNoteContactPersonView.connected';
import AddContactPersonToApplicationNoteView from './views/ContactPersonView/AddContactPersonToApplicationNoteView';
import NewQuotationDetailsView from './views/CreateNewOrderOrQuotation/DetailsView/NewQuotationDetailsView';
import NewOrderDetailsView from './views/CreateNewOrderOrQuotation/DetailsView/NewOrderDetailsView';
import QuotationToOrderDetailsView from './views/CreateNewOrderOrQuotation/DetailsView/QuotationToOrderDetailsView';
import AddNewOrderContactPersonView from './views/AddContactPersonView/AddNewOrderContactPersonView.connected';
import AddNewQuotationContactPersonView from './views/AddContactPersonView/AddNewQuotationContactPersonView.connected';
import AddContactPersonToNewOrderView from './views/ContactPersonView/AddContactPersonToNewOrderView';
import AddContactPersonToNewQuotationView from './views/ContactPersonView/AddContactPersonToNewQuotationView';
import NewOrderSelectProductsView from './views/CreateNewOrderOrQuotation/SelectProducts/NewOrderSelectProductsView';
import NewQuotationSelectProductsView from './views/CreateNewOrderOrQuotation/SelectProducts/NewQuotationSelectProductsView';
import QuotationSummaryView from './views/CreateNewOrderOrQuotation/SummaryView/QuotationSummaryView';
import OrderSummaryView from './views/CreateNewOrderOrQuotation/SummaryView/OrderSummaryView';
import TermAndConditionsView from './views/CreateNewOrderOrQuotation/TermsAndConditions/TermsAndConditions';
import {
  TermsView,
  ConditionsView,
} from './views/CreateNewOrderOrQuotation/TermsAndConditionsContent/TermsAndConditionsContent';
import SignatureView from './views/CreateNewOrderOrQuotation/SignatureView/SignatureView';
import QuotationConfirmationView from './views/CreateNewOrderOrQuotation/ConfirmationView/QuotationConfirmationView';
import OrderConfirmationView from './views/CreateNewOrderOrQuotation/ConfirmationView/OrderConfirmationView';
import NewClientSicCodeView from './views/SicCodeView/NewClientSicCodeView';
import ClientSicCodeView from './views/SicCodeView/ClientSicCodeView';
import ErrorFallbackView from './views/ErrorFallbackView/ErrorFallbackView';
import CameraView from './views/CameraView/CameraView';
import VisitCalendarView from './views/CalendarView/VisitCalendarView';
import CallCalendarView from './views/CalendarView/CallCalendarView';
import ApplicationNoteCalendarView from './views/CalendarView/ApplicationNoteCalendarView';
import RemarkCalendarView from './views/CalendarView/RemarkCalendarView';
import { clearPhotoUploading } from './store/reducers/photosFromDevice';
import NewReportView from './views/NewReportView/NewReportView';
import EditReportView from './views/NewReportView/EditReportView';

const PrivateRoute = ({ children, ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const history = useHistory();

  const location = useLocation();
  const dispatch = useDispatch();

  const { uploaded } = useSelector((state) => ({
    uploaded: state.photosFromDevice.upload || [],
  }));

  useEffect(() => {
    if (location.pathname.indexOf('upload') !== -1) return;

    Object.entries(uploaded).map((item) => {
      const [id, data] = item;

      if (data?.uploaded) dispatch(clearPhotoUploading({ id }));

      return item;
    });
  }, [uploaded, location, dispatch]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <ErrorBoundary
            FallbackComponent={ErrorFallbackView}
            onReset={() => history.push('/')}
          >
            {children}
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

const Routes = () => {
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/login" component={LoginView} />
      <PrivateRoute exact path="/calendar">
        <VisitCalendarView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers">
        <SelectCustomerView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/search">
        <SearchCustomerView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId">
        <CustomerView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/client/create">
        <NewClientView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/client/create/address">
        <NewClientAddressView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/client/create/sic">
        <NewClientSicCodeView />
      </PrivateRoute>

      <PrivateRoute exact path="/customers/:clientId/client/create/gallery">
        <NewClientGalleryView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/client/create/gallery/:photoId"
      >
        <NewClientGalleryItemView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/details">
        <EditClientView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/details/address">
        <EditClientAddressView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/details/sic">
        <ClientSicCodeView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/gallery">
        <ClientGalleryView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/gallery/:photoId">
        <ClientPhotoView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/upload/:photoId">
        <ClientPhotoViewUpload />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/visits/:noteId/actions/:actionId"
      >
        <VisitNextActionView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/visits/:noteId/calendar">
        <VisitCalendarView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/calls/:noteId/actions/:actionId"
      >
        <CallNextActionView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/calls/:noteId/calendar">
        <CallCalendarView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/remarks/:noteId/actions/:actionId"
      >
        <RemarkNextActionView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/remarks/:noteId/calendar">
        <RemarkCalendarView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/visits/:noteId/demoedProduct"
      >
        <VisitProductsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/calls/:noteId/demoedProduct"
      >
        <CallProductsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/addInterflonProduct"
      >
        <AddInterflonProductView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/visits/:noteId/addContactPerson"
      >
        <AddVisitContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/calls/:noteId/addContactPerson"
      >
        <AddCallContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/remarks/:noteId/addContactPerson"
      >
        <AddRemarkContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/visits/:noteId/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToVisitView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/calls/:noteId/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToCallView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/remarks/:noteId/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToRemarkView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToApplicationNoteView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId"
      >
        <ApplicationView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/addContactPerson"
      >
        <AddApplicationContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToApplicationView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/gallery"
      >
        <ApplicationGalleryView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/gallery/:photoId"
      >
        <ClientPhotoView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/upload/:photoId"
      >
        <ClientPhotoViewUpload />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId"
      >
        <ApplicationNoteView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId/demoedProduct"
      >
        <ApplicationNodeProductsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId/actions/:actionId"
      >
        <ApplicationNoteNextActionView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId/calendar"
      >
        <ApplicationNoteCalendarView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/applications/:applicationId/notes/:noteId/addContactPerson"
      >
        <AddApplicationNoteContactPersonView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/contacts/:contactPersonId">
        <AddContactPersonView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/orderHistory">
        <OrderHistoryView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/orderHistory/quotation/:quotationId"
      >
        <QuotationDetailsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/orderHistory/quotation/:quotationId/create/order"
      >
        <QuotationToOrderDetailsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/orderHistory/order/:orderNumber"
      >
        <OrderDetailsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/orderHistory/product/:productGroupCode"
      >
        <ProductDetailsView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/visits/:noteId">
        <VisitNoteView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/calls/:noteId">
        <CallNoteView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/remarks/:noteId">
        <RemarkNoteView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create">
        <CreateNewOrderOrQuotation />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/quotation">
        <NewQuotationDetailsView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order">
        <NewOrderDetailsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/order/addContactPerson"
      >
        <AddNewOrderContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/quotation/addContactPerson"
      >
        <AddNewQuotationContactPersonView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/order/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToNewOrderView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/quotation/addContactPerson/:contactPersonId"
      >
        <AddContactPersonToNewQuotationView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/order/selectProducts"
      >
        <NewOrderSelectProductsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/quotation/selectProducts"
      >
        <NewQuotationSelectProductsView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order/summary">
        <OrderSummaryView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/quotation/summary">
        <QuotationSummaryView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order/terms">
        <TermAndConditionsView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order/terms/terms">
        <TermsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/order/terms/conditions"
      >
        <ConditionsView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order/signature">
        <SignatureView />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:clientId/create/order/confirmation">
        <OrderConfirmationView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/customers/:clientId/create/quotation/confirmation"
      >
        <QuotationConfirmationView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={[
          '/customers/:clientId/cameraView',
          '/customers/:clientId/applications/:applicationId/cameraView',
        ]}
      >
        <CameraView />
      </PrivateRoute>
      <PrivateRoute exact path={['/customers/:clientId/visit/:reportId']}>
        <NewReportView />
      </PrivateRoute>
      <PrivateRoute exact path={['/customers/:clientId/visit/edit/:reportId']}>
        <EditReportView />
      </PrivateRoute>
      <PrivateRoute path="/">
        <Redirect to="/customers" />
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
