function PhoneHorizontal(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={142} height={142} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M124 42a5 5 0 015 5v51a5 5 0 01-5 5H20a5 5 0 01-5-5V47a5 5 0 015-5h104zm0 2H20a3 3 0 00-2.995 2.824L17 47v51a3 3 0 002.824 2.995L20 101h104a3 3 0 002.995-2.824L127 98V47a3 3 0 00-2.824-2.995L124 44z"
          fill="#CCC"
          fillRule="nonzero"
        />
        <path
          d="M117 46a3 3 0 013 3v47a3 3 0 01-3 3H22a3 3 0 01-3-3V49a3 3 0 013-3h95zm0 2H22a1 1 0 00-.993.883L21 49v47a1 1 0 00.883.993L22 97h95a1 1 0 00.993-.883L118 96V49a1 1 0 00-.883-.993L117 48z"
          fill="#CCC"
          fillRule="nonzero"
        />
        <circle fill="#CCC" cx={123.5} cy={72.5} r={1.5} />
        <circle fill="#00BB7E" cx={72} cy={72} r={16} />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M78.811 66.775l1.378 1.45-10.707 10.171-5.69-5.689 1.415-1.414 4.311 4.31z"
        />
        <path
          d="M15.5 33v2a8.503 8.503 0 00-8.368 7H10l-4 5.5L2 42h3.106c.715-4.997 4.948-8.859 10.11-8.996L15.5 33zM127 112v-2a8.503 8.503 0 008.368-7H132.5l4-5.5 4 5.5h-3.106c-.715 4.997-4.948 8.859-10.11 8.996L127 112z"
          fill="#CCC"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default PhoneHorizontal;
