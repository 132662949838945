import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import { Divider } from 'frontend-components';
import AttachmentIcon from '@material-ui/icons/Attachment';
import FileIcon from '../FileIcon/FileIcon';
import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import LimitedList from '../LimitedList/LimitedList';

const Attachment = styled.div`
  display: flex;
  align-items: center;

  > svg {
    color: #999999;
    margin-right: 10px;
  }
`;

const FileName = styled.div`
  width: calc(100% - 34px);
`;

const AttachmentsList = ({
  expanded,
  onChange,
  attachments,
  'data-testid': testId,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Collapsible
      expanded={expanded}
      onChange={onChange}
      icon={<AttachmentIcon />}
      title={t('attachments')}
      itemsSize={attachments.total}
      collapsible={attachments.total > 0}
      data-testid={testId}
      disabled={disabled}
    >
      <LimitedList
        items={attachments.data}
        label={t('attachments')}
        renderItem={(item, index) => {
          const isLast = index === attachments.data.length - 1;
          const [fileExt] = item.name.split('.').slice(-1);

          return (
            <div key={item.id}>
              <ListItem
                key={item.id}
                button
                component="a"
                href={item.link}
                download
              >
                <ListItemText
                  primary={
                    <Attachment>
                      <FileIcon fileExt={fileExt} />
                      <FileName>
                        <Truncate ellipsis={`... .${fileExt}`}>
                          {item.name}
                        </Truncate>
                      </FileName>
                    </Attachment>
                  }
                />
              </ListItem>
              {!isLast && <Divider />}
            </div>
          );
        }}
      />
    </Collapsible>
  );
};

AttachmentsList.propTypes = {
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  attachments: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        name: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
};

export default AttachmentsList;
