import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { formatDateAndTime } from 'frontend-components/lib/helpers';

import {
  addNextActionToCallNote,
  addNextActionToClient,
} from '../../store/actions/nextActionActions';
import CalendarView from './CalendarView';
import { fetchClientsActions } from '../../store/reducers/clients';

const CallCalendarView = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { clientId, noteId } = useParams();

  useEffect(() => {
    dispatch(fetchClientsActions());
  }, [dispatch]);

  const { nextAction } = useSelector((state) => {
    return state.clientData.data[clientId].phoneCalls.data.find(
      ({ id }) => id === noteId
    );
  });

  const { name } = useSelector((state) => {
    return state.clientData.data[clientId];
  });

  const handleSelectDate = (date) => {
    dispatch(
      addNextActionToCallNote(noteId, clientId, {
        ...nextAction,
        doAt: formatDateAndTime(date),
      })
    );
    dispatch(
      addNextActionToClient(noteId, clientId, name, {
        ...nextAction,
        doAt: formatDateAndTime(date),
      })
    );
    history.push(location.state.from, {
      hasChanged: true,
    });
  };

  return (
    <CalendarView nextAction={nextAction} onSelectDate={handleSelectDate} />
  );
};

CallCalendarView.propTypes = {};

export default CallCalendarView;
