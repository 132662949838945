import PropTypes from 'prop-types';
import Product from '../Product';
import { isSearchTermLongEnough } from '../../../helpers/products';
import ProductCheckbox from '../ProductCheckbox';
import { productProps } from '../../../helpers/packaging';
import { demoProductsDataShape } from '../../../helpers/clientDataPropTypes';

const ProductsListInCategory = ({
  name,
  products,
  handleExpandedStateChange,
  isCategoryExpanded,
  searchTerm,
  demoedProducts,
  isSubCategory,
  howManyItemsAreCheckedByCategory,
  handleCheckboxChange,
  parentCategoryName = '',
}) => {
  return (
    <Product
      key={name}
      title={name}
      itemsSize={
        parentCategoryName
          ? howManyItemsAreCheckedByCategory(parentCategoryName, name)
          : howManyItemsAreCheckedByCategory(name)
      }
      isExpanded={
        !!isCategoryExpanded[name] || isSearchTermLongEnough(searchTerm)
      }
      onChange={handleExpandedStateChange}
      subCategory={isSubCategory}
    >
      {products.map((product) => {
        const { code, name } = product;
        return (
          <ProductCheckbox
            category={name}
            key={code}
            product={product}
            onChange={handleCheckboxChange}
            isProductDemoed={
              !!demoedProducts.find((item) => item.code === product.code)
            }
          />
        );
      })}
    </Product>
  );
};

ProductsListInCategory.propTypes = {
  name: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(productProps)),
  handleExpandedStateChange: PropTypes.func,
  isCategoryExpanded: PropTypes.shape({}),
  searchTerm: PropTypes.string,
  demoedProducts: demoProductsDataShape,
  isSubCategory: PropTypes.bool,
  howManyItemsAreCheckedByCategory: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  parentCategoryName: PropTypes.string,
};

export default ProductsListInCategory;
