import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

const StyledCheckbox = styled(MuiCheckbox)`
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
  }

  &.Mui-disabled .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.26);
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 14px;
    position: relative;
  }
`;

const Checkbox = ({
  checked = false,
  controlled = false,
  label = '',
  onChange,
  id,
  ...checkboxProps
}) => {
  const [checkedValue, setCheckedValue] = useState(checked);

  const handleChange = (event) => {
    const { checked } = event.target;

    setCheckedValue(checked);

    if (onChange) {
      onChange(checked);
    }
  };

  const checkboxCheckedValue = controlled ? checked : checkedValue;
  return label ? (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          id={id}
          checked={checkboxCheckedValue}
          onChange={handleChange}
          {...checkboxProps}
          disableRipple
        />
      }
      label={label}
    />
  ) : (
    <StyledCheckbox
      id={id}
      checked={checkboxCheckedValue}
      onChange={handleChange}
      {...checkboxProps}
      disableRipple
    />
  );
};

Checkbox.propTypes = {
  label: PropTypes.node,
  controlled: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
};

export default Checkbox;
