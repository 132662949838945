import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DetailsView from './DetailsView';
import {
  setNewOrder,
  removeNewOrder,
  deleteNewOrderContactFromCC,
} from '../../../store/reducers/drafts';
import withClientData from '../../../helpers/withClientData';
import { clearQrderOrQuotationExpandedPanel } from '../../../store/reducers/clientUI';

const NewOrderDetailsView = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newOrder } = useSelector((state) => state.drafts);
  const { visitAddress } = useSelector((state) => {
    return (
      state.clients.clients.find(({ id }) => id === clientId) ||
      state.clients.searchedClients.find(({ id }) => id === clientId) ||
      {}
    );
  });

  const updateStore = (values) => {
    dispatch(setNewOrder(values));
  };

  const clearStore = () => {
    dispatch(removeNewOrder());
    dispatch(clearQrderOrQuotationExpandedPanel({ clientId }));
  };

  const deleteContactFromCC = (contactId) => {
    dispatch(deleteNewOrderContactFromCC(contactId));
  };

  return (
    <DetailsView
      title="create_order_details"
      openContactsListPath={`/customers/${clientId}/create/order/addContactPerson`}
      updateStore={updateStore}
      clearStore={clearStore}
      draftData={newOrder}
      visitAddress={visitAddress}
      handleAddProductsButton={() =>
        history.push(`/customers/${clientId}/create/order/selectProducts`)
      }
      noteLabel="create_order_note_label"
      emailNoteLabel="create_order_email_label"
      internalNoteLabel="create_order_int_label"
      confirmationTitle={t('pck_order_confirm_title')}
      confirmationContent={t('pck_order_confirm_content')}
      deleteContactFromCC={deleteContactFromCC}
      testId="create-order-details"
    />
  );
};

export default withClientData(NewOrderDetailsView);
