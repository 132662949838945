import { useEffect, useState } from 'react';

const useDeviceOrientation = () => {
  const [device, setDevice] = useState(null);

  const getDevice = () => {
    if (!navigator.maxTouchPoints) {
      return 'desktop';
    }
    return window.innerWidth < window.innerHeight ? 'portrait' : 'landscape';
  };

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDevice());
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return device;
};

export default useDeviceOrientation;
