import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AppBar from '../../../components/AppBar/AppBar.connected';
import Product from '../Product';
import { setApplicationInterflonLubricant } from '../../../store/actions/applicationActions';
import { StyledContainer } from '../NotesProductsView/Products';
import withClientData from '../../../helpers/withClientData';
import {
  excludeMiscellaneous,
  isSearchTermLongEnough,
} from '../../../helpers/products';
import useProductsSearch from '../../../hooks/useProductsSearch';
import {
  SearchContainer,
  SearchInput,
  SearchIcon,
} from '../../../components/Search/Search';
import ProductsListInCategory from './ProductsListInCategory';
import NothingFound from '../../../components/NothingFound/NothingFound';

const CollapsibleContainer = styled.div`
  padding: ${({ $subCategory }) => ($subCategory ? '0' : '5px 0')};

  & .MuiButton-root {
    margin: 4px 0;
    padding: 10px;
    border-color: #fff;
    letter-spacing: normal;
    height: auto;
    text-transform: none;
    justify-content: flex-start;
  }
`;

const AddInterflonProductView = () => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCategoryExpanded, setIsCategoryExpanded] = useState({});
  const [isSubCategoryExpanded, setIsSubCategoryExpanded] = useState({});

  const productsData = useSelector((state) =>
    excludeMiscellaneous(state.products[clientId].data)
  );

  const {
    searchVisible,
    setSearchVisible,
    searchTerm,
    setSearchTerm,
    searchInputRef,
    title: searchOrTitle,
    filteredProductsData,
  } = useProductsSearch(productsData, t('app_after_add_product'));

  const handleProductClick = (productName) => {
    dispatch(
      setApplicationInterflonLubricant(applicationId, clientId, productName)
    );
    history.push(`/customers/${clientId}/applications/${applicationId}`, {
      from: 'addInterflonProduct',
    });
  };

  const handleExpandedStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsCategoryExpanded({ [title]: !isExpanded });
  };

  const handleExpandedSubCategoryStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsSubCategoryExpanded({ [title]: !isExpanded });
  };

  return (
    <StyledContainer maxWidth="sm">
      <AppBar
        title={searchOrTitle}
        titleClickAction={() => setSearchVisible(true)}
        content={
          searchVisible && (
            <SearchContainer maxWidth="sm">
              <SearchInput
                placeholder={t('sic_search_placeholder')}
                onChange={(event) => setSearchTerm(event.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                inputRef={searchInputRef}
                autoFocus
                value={searchTerm}
              />
            </SearchContainer>
          )
        }
        displayBackButton
        backButtonAction={() =>
          history.push(`/customers/${clientId}/applications/${applicationId}`, {
            from: 'addInterflonProduct',
          })
        }
        aside={
          searchTerm ? (
            <IconButton
              onClick={() => {
                setSearchVisible(false);
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setSearchVisible(true);
                searchInputRef.current && searchInputRef.current.focus();
              }}
            >
              <SearchIcon />
            </IconButton>
          )
        }
      />
      <CollapsibleContainer $subCategory>
        {filteredProductsData &&
          filteredProductsData.map((category) => {
            const { categoryName, products, subCategories } = category || null;

            return subCategories?.length ? (
              <Product
                key={categoryName}
                title={categoryName}
                isExpanded={
                  !!isCategoryExpanded[categoryName] ||
                  isSearchTermLongEnough(searchTerm)
                }
                onChange={handleExpandedStateChange}
              >
                {subCategories.map(
                  ({ subCategoryName, products: subProducts }) => (
                    <CollapsibleContainer key={subCategoryName} $subCategory>
                      <ProductsListInCategory
                        name={subCategoryName}
                        products={subProducts}
                        isCategoryExpanded={isSubCategoryExpanded}
                        handleExpandedStateChange={
                          handleExpandedSubCategoryStateChange
                        }
                        searchTerm={searchTerm}
                        handleProductClick={handleProductClick}
                        isSubCategory
                        parentCategoryName={categoryName}
                      />
                    </CollapsibleContainer>
                  )
                )}
              </Product>
            ) : (
              <ProductsListInCategory
                key={categoryName}
                name={categoryName}
                products={products}
                handleExpandedStateChange={handleExpandedStateChange}
                isCategoryExpanded={isCategoryExpanded}
                searchTerm={searchTerm}
                handleProductClick={handleProductClick}
                subCategory
              />
            );
          })}
      </CollapsibleContainer>
      {filteredProductsData.length === 0 && <NothingFound />}
    </StyledContainer>
  );
};

export default withClientData(AddInterflonProductView);
