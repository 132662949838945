import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: #00bb7e;
  color: #ffffff;

  &:hover {
    background-color: #00bb7e;
    color: #ffffff;
  }
`;

export const ClearIconButton = styled(IconButton)`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ContactName = styled.span`
  color: ${({ isDeleted }) => (isDeleted ? '#666666' : 'inherit')};
`;
