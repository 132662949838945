import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PhoneIcon from '@material-ui/icons/Phone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { APPLICATIONS_TYPES } from '../constants/applicationScreen';
import { REPORT_TYPES } from '../constants/noteTypes';
import OtherIcon from '../components/Icons/OtherIcon';
import { APPOINTMENT, DROP_IN, PHONE_CALL } from '../constants/nextAction';
import DropInIcon from '../components/Icons/DropInIcon';

export const getApplicationTypeName = (applicationType) => {
  return APPLICATIONS_TYPES.find(({ value }) => value === applicationType)
    ?.label;
};

export const getFilterIcon = (action) => {
  switch (action) {
    case REPORT_TYPES.VISIT:
      return <DriveEtaIcon key={action} />;
    case REPORT_TYPES.PHONE_CALL:
      return <PhoneIcon key={action} />;
    case REPORT_TYPES.REMARK:
      return <InsertDriveFileIcon key={action} />;
    case APPOINTMENT:
      return <DriveEtaIcon key={action} />;
    case PHONE_CALL:
      return <PhoneIcon key={action} />;
    case DROP_IN:
      return <DropInIcon key={action} />;
    default:
      return <OtherIcon key={action} />;
  }
};
