import UpdateIcon from '@material-ui/icons/Update';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import { Button } from 'frontend-components';

import { fetchClientDataById } from '../../store/actions/clientDataActions';
import { fetchProductsInCategories } from '../../store/reducers/products';
import { formatBytes } from '../../helpers/video';

const CustomersData = ({ clientData, clients }) => {
  const dispatch = useDispatch();
  const rollbar = useRollbar();

  const [isClientFetching, setIsClientFetching] = useState(false);
  const [storage, setStorage] = useState('');
  const { online } = useSelector((state) => state.offline);

  const calculateStorage = async () => {
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage -> Number of bytes used.
      // quota.quota -> Maximum number of bytes available.
      const percentageUsed = (quota.usage / quota.quota) * 100;
      // const remaining = quota.quota - quota.usage;
      setStorage(
        `${formatBytes(quota.usage)} / ${parseFloat(percentageUsed).toFixed(
          2
        )}%`
      );
    }
  };

  // const cacheImages = async (data) => {
  //   const photos = data?.payload?.photos.data || [];
  //
  //   const promises = photos.map((photo) => {
  //     return new Promise((resolve, reject) => {
  //       const img = new Image();
  //
  //       img.src = photo?.fileUrl;
  //       img.onload = resolve();
  //       img.onerror = reject();
  //     });
  //   });
  //
  //   await Promise.all(promises);
  // };

  const handleFetchClientsData = async () => {
    try {
      setIsClientFetching(true);
      let clientsRequestsBuffer = [];
      const clientsToFetch = clients.filter(
        ({ id }) => !clientData.loadingStatus[id]
      );

      for (let i = 0; i < clientsToFetch?.length; i++) {
        if (clientsToFetch[i]) {
          clientsRequestsBuffer.push(
            dispatch(fetchClientDataById({ clientId: clientsToFetch[i].id }))
          );

          // eslint-disable-next-line no-await-in-loop
          await Promise.all(clientsRequestsBuffer);
        }

        clientsRequestsBuffer = [];

        if (clientsToFetch[i]) {
          clientsRequestsBuffer.push(
            dispatch(
              fetchProductsInCategories({ clientId: clientsToFetch[i].id })
            )
          );

          // eslint-disable-next-line no-await-in-loop
          await Promise.all(clientsRequestsBuffer);
        }

        clientsRequestsBuffer = [];
      }
      calculateStorage();
      setIsClientFetching(false);
    } catch (e) {
      rollbar.error(e);
      setIsClientFetching(false);
    }
  };

  useEffect(() => {
    calculateStorage();
  }, []);

  return (
    <>
      {process.env.REACT_APP_ENV !== 'prod' && (
        <Button
          onClick={handleFetchClientsData}
          color="secondary"
          disabled={
            clients?.length === Object.keys(clientData.loadingStatus)?.length ||
            !online ||
            isClientFetching
          }
          startIcon={
            isClientFetching ? <CircularProgress size={14} /> : <UpdateIcon />
          }
        >
          {Object.keys(clientData?.loadingStatus)?.length}/{clients?.length}
          {storage && <> ({storage})</>}
        </Button>
      )}
    </>
  );
};

CustomersData.propTypes = {
  clientData: PropTypes.shape({
    loadingStatus: PropTypes.objectOf(PropTypes.string),
  }),
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default CustomersData;
