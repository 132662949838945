import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button } from 'frontend-components';

import { parseEscapes } from '../../../helpers/stringHelpers';

import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import MenuItem from '../../../components/Select/MenuItem';
import {
  APPLICATION_VIEW_TABS,
  APPLICATIONS_TYPES,
  LOAD_OPTIONS,
  VALIDATION_CONSTANTS,
  LOCATION_OPTIONS,
  HUMIDITY_OPTIONS,
  CHEM_INFLU_OPTIONS,
  EXPOSURE_OPTIONS,
  ZONE_OPTIONS,
  FOOD_GRADE_OPTIONS,
  BIODEGRADATION_OPTIONS,
  SAFETY_OPTIONS,
  MONITORING_OPTIONS,
  ISO_GRADE_OPTIONS,
  NLGI_GRADE_OPTIONS,
} from '../../../constants/applicationScreen';
import { FORM_DEBOUNCE_MS } from '../../../constants/forms';
import TextArea from '../../../components/TextArea/TextArea';
import Bearing from './Bearing';
import Gearbox from './Gearbox';
import Chain from './Chain';
import FormikAutoSave from '../../../components/FormikAutoSave/FormikAutoSave';
import {
  TechnicalTabContainer,
  ApplicationTypeSection,
  EnvContainer,
  AddEnvParam,
  TextAreaContainer,
  ApplicationName,
  TemperatureHeader,
  TemperatureContainer,
} from './TechnicalTab.styles';
import { changeFieldValueToNumber } from '../../../helpers/changeFieldValueToNumber';
import { applicationDataShape } from '../../../helpers/clientDataPropTypes';
import { getApplicationTypeName } from '../../../helpers/application';
import CollapsibleSection from '../../../components/CollapsibleSection/CollapsibleSection';

const { maxIntNum, maxTemp, minTemp, maxNoiseLevel, maxFloatNum } =
  VALIDATION_CONSTANTS;

const MAX_EXTRA_ENV_PARAMS = 3;
const showAddEnvButton = (environment) => {
  const extraParams = environment?.filter((env) => !env.parameter);
  return extraParams?.length < MAX_EXTRA_ENV_PARAMS;
};

const TechnicalTab = ({
  applicationData: { technical: appData, componentType, name },
  updateAppSection,
  disabled,
}) => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();

  const initialValues = {
    bearing: {
      type: (appData.bearing && appData.bearing.type) || '',
      innerSize: (appData.bearing && appData.bearing.innerSize) || '',
      outerSize: (appData.bearing && appData.bearing.outerSize) || '',
      maxRpm: (appData.bearing && appData.bearing.maxRpm) || '',
    },
    chain: {
      type: (appData.chain && appData.chain.type) || '',
      length: (appData.chain && appData.chain.length) || '',
      speed: (appData.chain && appData.chain.speed) || '',
    },
    gearbox: {
      type: (appData.gearbox && appData.gearbox.type) || '',
      mountingPosition:
        (appData.gearbox && appData.gearbox.mountingPosition) || '',
      diameterIncomingShaft:
        (appData.gearbox && appData.gearbox.diameterIncomingShaft) || '',
      rpmIncomingShaft:
        (appData.gearbox && appData.gearbox.rpmIncomingShaft) || '',
      lubricationType:
        (appData.gearbox && appData.gearbox.lubricationType) || '',
      oilVolume: (appData.gearbox && appData.gearbox.oilVolume) || '',
      greaseFitting: (appData.gearbox && appData.gearbox.greaseFitting) || '',
      oilRecommended: (appData.gearbox && appData.gearbox.oilRecommended) || '',
      filter: (appData.gearbox && appData.gearbox.filter) || '',
      filterSize: (appData.gearbox && appData.gearbox.filterSize) || '',
      ventilationShaft:
        (appData.gearbox && appData.gearbox.ventilationShaft) || '',
      leakages: (appData.gearbox && appData.gearbox.leakages) || '',
      leakagesLoss: (appData.gearbox && appData.gearbox.leakagesLoss) || '',
      otherIssues: (appData.gearbox && appData.gearbox.otherIssues) || '',
      noiseLevel: (appData.gearbox && appData.gearbox.noiseLevel) || '',
    },
    other: {
      type: (appData.other && appData.other.type) || '',
      description: (appData.other && appData.other.description) || '',
    },
    componentsNumber: appData.componentsNumber || '',
    componentLoad: appData.componentLoad || '',
    environment: appData.environment || [],
    powerConsumption: appData.powerConsumption || '',
    directTemperature: {
      lowest: appData.directTemperature?.lowest || '',
      average: appData.directTemperature?.average || '',
      highest: appData.directTemperature?.highest || '',
    },
    productionTemperature: {
      lowest: appData.productionTemperature?.lowest || '',
      average: appData.productionTemperature?.average || '',
      highest: appData.productionTemperature?.highest || '',
    },
    componentMonitoring: appData.componentMonitoring || '',
    componentIso: appData.componentIso || '',
    componentNlgi: appData.componentNlgi || '',
    componentExtra: appData.componentExtra || '',
  };

  const integerValidation = Yup.number()
    .typeError(t('validation_app_uint'))
    .positive(t('validation_app_uint'))
    .integer(t('validation_app_uint'))
    .max(maxIntNum, t('validation_app_uint_range'));

  const temperatureValidation = Yup.number()
    .typeError(t('validation_app_tempC'))
    .min(minTemp, t('validation_app_tempC'))
    .max(maxTemp, t('validation_app_tempC'));

  const noiseLevelValidation = Yup.number()
    .typeError(t('validation_app_noise_range'))
    .positive(t('validation_app_noise_range'))
    .max(maxNoiseLevel, t('validation_app_noise_range'));

  const floatValidation = Yup.number()
    .typeError(t('validation_app_positive'))
    .positive(t('validation_app_positive'))
    .max(maxFloatNum, t('validation_app_float_range'));

  const validationSchema = Yup.object({
    bearing: Yup.object({
      innerSize: integerValidation,
      outerSize: integerValidation,
      maxRpm: integerValidation,
    }),
    chain: Yup.object({
      length: integerValidation,
      speed: floatValidation,
    }),
    gearbox: Yup.object({
      diameterIncomingShaft: integerValidation,
      rpmIncomingShaft: integerValidation,
      oilVolume: integerValidation,
      filterSize: integerValidation,
      leakagesLoss: integerValidation,
      noiseLevel: noiseLevelValidation,
    }),
    componentsNumber: integerValidation,
    directTemperature: Yup.object({
      lowest: temperatureValidation,
      average: temperatureValidation,
      highest: temperatureValidation,
    }),
    productionTemperature: Yup.object({
      lowest: temperatureValidation,
      average: temperatureValidation,
      highest: temperatureValidation,
    }),
    powerConsumption: floatValidation,
  });

  const updateForm = (values) => {
    updateAppSection(
      applicationId,
      clientId,
      values,
      APPLICATION_VIEW_TABS.TECHNICAL
    );
  };

  const appType = getApplicationTypeName(componentType);

  return (
    <TechnicalTabContainer data-testid="application-technical">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => updateForm(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => {
          const {
            bearing,
            chain,
            gearbox,
            other,
            componentsNumber,
            componentLoad,
            environment,
            powerConsumption,
            directTemperature,
            productionTemperature,
            componentMonitoring,
            componentIso,
            componentNlgi,
            componentExtra,
          } = values;

          return (
            <Form>
              <ApplicationName component={componentType}>
                {name} {componentType && `(${t(appType)})`}
              </ApplicationName>
              {componentType && (
                <ApplicationTypeSection>
                  <Container maxWidth="sm">
                    <>
                      {componentType === APPLICATIONS_TYPES[0].value && (
                        <Bearing
                          values={bearing}
                          setFieldValue={setFieldValue}
                          errors={errors && errors.bearing}
                          disabled={disabled}
                        />
                      )}
                      {componentType === APPLICATIONS_TYPES[1].value && (
                        <Chain
                          values={chain}
                          setFieldValue={setFieldValue}
                          errors={errors && errors.chain}
                          disabled={disabled}
                        />
                      )}
                      {componentType === APPLICATIONS_TYPES[2].value && (
                        <Gearbox
                          values={gearbox}
                          setFieldValue={setFieldValue}
                          errors={errors && errors.gearbox}
                          disabled={disabled}
                        />
                      )}
                      {componentType === APPLICATIONS_TYPES[3].value && (
                        <>
                          <Input
                            id="otherType"
                            label={t('app_other_type')}
                            onchange={(e) => {
                              setFieldValue('other.type', e);
                            }}
                            value={other.type}
                            fullWidth
                            maxLength={200}
                            disabled={disabled}
                          />
                          <TextAreaContainer>
                            <TextArea
                              id="description"
                              label={t('app_other_description')}
                              placeholder={t('app_other_description')}
                              fullWidth
                              onChange={(e) => {
                                setFieldValue(
                                  'other.description',
                                  e.target.value
                                );
                              }}
                              value={other.description}
                              maxLength={2000}
                              disabled={disabled}
                            />
                          </TextAreaContainer>
                        </>
                      )}
                    </>
                  </Container>
                </ApplicationTypeSection>
              )}
              <Container maxWidth="sm">
                <CollapsibleSection title={t('app_general')}>
                  <Input
                    id="componentsNumber"
                    label={t('app_number')}
                    onchange={(e) => {
                      setFieldValue(
                        'componentsNumber',
                        changeFieldValueToNumber(e)
                      );
                    }}
                    value={componentsNumber}
                    fullWidth
                    type="number"
                    errorMessage={errors && errors.componentsNumber}
                    disabled={disabled}
                  />
                  <Select
                    id="componentLoad"
                    data-testid="componentLoad"
                    label={t('app_load')}
                    fullWidth
                    value={componentLoad}
                    onChange={(load) => {
                      setFieldValue('componentLoad', load);
                    }}
                    disabled={disabled}
                  >
                    {LOAD_OPTIONS.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Input
                    id="powerConsumption"
                    label={t('app_power_consumption')}
                    onchange={(e) => {
                      setFieldValue(
                        'powerConsumption',
                        changeFieldValueToNumber(e, true)
                      );
                    }}
                    value={powerConsumption}
                    fullWidth
                    type="number"
                    rightText={t('app_kilowatt')}
                    isMinusForbidden={false}
                    errorMessage={errors && errors.powerConsumption}
                    disabled={disabled}
                  />
                </CollapsibleSection>
                <CollapsibleSection title={t('app_environment')}>
                  <FieldArray name="environment">
                    {({ remove, push, replace }) => {
                      const updateItem = (parameter, value) => {
                        const index = environment.findIndex(
                          (env) => env.parameter === parameter
                        );
                        const obj = {
                          parameter,
                          value,
                        };
                        if (index !== -1) {
                          replace(index, obj);
                        } else {
                          push(obj);
                        }
                      };
                      const getInitialValue = (parameter) => {
                        return environment.find(
                          (env) => env.parameter === parameter
                        )?.value;
                      };
                      const getValueMultiple = (parameter) => {
                        const val = getInitialValue(parameter);
                        if (val) {
                          return val.split(',');
                        }
                        return [];
                      };
                      return (
                        <>
                          <Select
                            key="location"
                            id="location"
                            label={t(`app_location`)}
                            fullWidth
                            initialValue={getInitialValue('location')}
                            onChange={(value) => {
                              updateItem('location', value);
                            }}
                            disabled={disabled}
                          >
                            {LOCATION_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            multiple
                            id="humidity"
                            label={t('app_humidity')}
                            fullWidth
                            value={getValueMultiple('humidity')}
                            onChange={(value) => {
                              updateItem('humidity', value.join(','));
                            }}
                            disabled={disabled}
                          >
                            {HUMIDITY_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            multiple
                            id="chemicals"
                            label={t('app_chem_influ')}
                            fullWidth
                            value={getValueMultiple('chemicals')}
                            onChange={(value) => {
                              updateItem('chemicals', value.join(','));
                            }}
                            disabled={disabled}
                          >
                            {CHEM_INFLU_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            multiple
                            id="exposure"
                            label={t('app_exposure')}
                            fullWidth
                            value={getValueMultiple('exposure')}
                            onChange={(value) => {
                              updateItem('exposure', value.join(','));
                            }}
                            disabled={disabled}
                          >
                            {EXPOSURE_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            id="zone"
                            label={t('app_zone')}
                            fullWidth
                            initialValue={getInitialValue('zone')}
                            onChange={(value) => {
                              updateItem('zone', value);
                            }}
                            disabled={disabled}
                          >
                            {ZONE_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            id="food-grade"
                            label={t('app_food_grade')}
                            fullWidth
                            initialValue={getInitialValue('food-grade')}
                            onChange={(value) => {
                              updateItem('food-grade', value);
                            }}
                            disabled={disabled}
                          >
                            {FOOD_GRADE_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            id="biodegradation"
                            label={t('app_biodegradation')}
                            fullWidth
                            initialValue={getInitialValue('biodegradation')}
                            onChange={(value) => {
                              updateItem('biodegradation', value);
                            }}
                            disabled={disabled}
                          >
                            {BIODEGRADATION_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            id="safety"
                            label={t('app_safety')}
                            fullWidth
                            initialValue={getInitialValue('safety')}
                            onChange={(value) => {
                              updateItem('safety', value);
                            }}
                            disabled={disabled}
                          >
                            {SAFETY_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {t(label)}
                              </MenuItem>
                            ))}
                          </Select>
                          {environment.map(
                            (env, index) =>
                              !env.parameter && (
                                // eslint-disable-next-line react/no-array-index-key
                                <EnvContainer key={`environment.${index}`}>
                                  <Input
                                    id="environment"
                                    label={t('app_env_param')}
                                    onchange={(env) => {
                                      setFieldValue(
                                        `environment[${index}].value`,
                                        env
                                      );
                                    }}
                                    value={environment[index].value}
                                    fullWidth
                                    disabled={disabled}
                                    maxLength={100}
                                  />
                                  {environment.length > 0 && (
                                    <IconButton
                                      onClick={() => remove(index)}
                                      disabled={disabled}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </EnvContainer>
                              )
                          )}
                          {showAddEnvButton(environment) && (
                            <AddEnvParam>
                              <Button
                                data-testid="addEnvParameter"
                                theme="secondary"
                                startIcon={<AddIcon />}
                                fullWidth
                                onClick={() =>
                                  push({ parameter: '', value: '' })
                                }
                                disabled={disabled}
                              >
                                {t('app_env_param_add')}
                              </Button>
                            </AddEnvParam>
                          )}
                        </>
                      );
                    }}
                  </FieldArray>
                </CollapsibleSection>
                <CollapsibleSection
                  title={parseEscapes(t('app_temperature_dg'))}
                >
                  <TemperatureHeader>
                    {t('app_direct_environment')}
                  </TemperatureHeader>
                  <TemperatureContainer>
                    <Input
                      id="directTemperatureLowest"
                      label={t('app_lowest_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'directTemperature.lowest',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={directTemperature.lowest}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.directTemperature?.lowest}
                    />
                    <Input
                      id="directTemperatureAverage"
                      label={t('app_avg_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'directTemperature.average',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={directTemperature.average}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.directTemperature?.average}
                    />
                    <Input
                      id="directTemperatureHighest"
                      label={t('app_highest_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'directTemperature.highest',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={directTemperature.highest}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.directTemperature?.highest}
                    />
                  </TemperatureContainer>
                  <TemperatureHeader>
                    {t('app_machine_during_prod')}
                  </TemperatureHeader>
                  <TemperatureContainer>
                    <Input
                      id="productionTemperatureLowest"
                      label={t('app_lowest_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'productionTemperature.lowest',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={productionTemperature.lowest}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.productionTemperature?.lowest}
                    />
                    <Input
                      id="productionTemperatureAverage"
                      label={t('app_avg_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'productionTemperature.average',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={productionTemperature.average}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.productionTemperature?.average}
                    />
                    <Input
                      id="productionTemperatureHighest"
                      label={t('app_highest_temp')}
                      variant="outlined"
                      type="number"
                      onchange={(e) => {
                        setFieldValue(
                          'productionTemperature.highest',
                          changeFieldValueToNumber(e, true)
                        );
                      }}
                      value={productionTemperature.highest}
                      isMinusForbidden={false}
                      maxLength={200}
                      disabled={disabled}
                      errorMessage={errors?.productionTemperature?.highest}
                    />
                  </TemperatureContainer>
                </CollapsibleSection>
                <CollapsibleSection title={t('app_other')}>
                  <Select
                    multiple
                    id="componentMonitoring"
                    label={t('app_monitoring')}
                    fullWidth
                    value={
                      componentMonitoring ? componentMonitoring.split(',') : []
                    }
                    onChange={(value) => {
                      const newValue = value.join(',');
                      setFieldValue('componentMonitoring', newValue);
                    }}
                    disabled={disabled}
                  >
                    {MONITORING_OPTIONS.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    id="componentIso"
                    label={t('app_iso_grade')}
                    fullWidth
                    initialValue={componentIso}
                    onChange={(value) => {
                      setFieldValue('componentIso', value);
                    }}
                    disabled={disabled}
                  >
                    {ISO_GRADE_OPTIONS.map(({ value }) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    id="componentNlgi"
                    label={t('app_nlgi_grade')}
                    fullWidth
                    initialValue={componentNlgi}
                    onChange={(value) => {
                      setFieldValue('componentNlgi', value);
                    }}
                    disabled={disabled}
                  >
                    {NLGI_GRADE_OPTIONS.map(({ value }) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextAreaContainer>
                    <TextArea
                      id="componentExtra"
                      label={t('app_extra')}
                      placeholder={t('app_extra')}
                      fullWidth
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e) => {
                        setFieldValue('componentExtra', e.target.value);
                      }}
                      value={componentExtra}
                      maxLength={256}
                      errorMessage=""
                      disabled={disabled}
                    />
                  </TextAreaContainer>
                </CollapsibleSection>
              </Container>
              <FormikAutoSave debounceMs={FORM_DEBOUNCE_MS} />
            </Form>
          );
        }}
      </Formik>
    </TechnicalTabContainer>
  );
};

TechnicalTab.propTypes = {
  applicationData: applicationDataShape,
  updateAppSection: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TechnicalTab;
