function ConfirmationImage(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="221" height="221" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-77-164h375v812H-77z" />
        <path fill="#FFF" d="M-77-60h375v708H-77z" />
        <g>
          <circle fill="#F2F2F2" cx="110.5" cy="110.5" r="106.5" />
          <path
            stroke="#E42313"
            strokeWidth="2"
            fill="#FFF"
            d="M65 78.154L79.08 64h59.84L153 78.154V156H65z"
          />
          <path
            d="M139.336 63L154 77.741V157H64V77.741L78.664 63h60.672zM152 79H66v76h86V79zm-24.704 25.29l1.408 1.42-23.924 23.698-11.484-11.376 1.408-1.421 10.076 9.981 22.516-22.302zm41.997 4.003l1.414 1.414-2.043 2.043 2.043 2.043-1.414 1.414-2.043-2.043-2.043 2.043-1.414-1.414 2.043-2.043-2.043-2.043 1.414-1.414 2.043 2.043 2.043-2.043zM37.5 108a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM87 95v2H73v-2h14zm13-7v2H73v-2h27zm38.504-23H79.495L67.556 77h82.886l-11.938-12zM169.5 48a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 2a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM72.293 35.293l1.414 1.414-2.043 2.043 2.043 2.043-1.414 1.414-2.043-2.043-2.043 2.043-1.414-1.414 2.043-2.043-2.043-2.043 1.414-1.414 2.043 2.043 2.043-2.043z"
            fill="#E42313"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}

export default ConfirmationImage;
