import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useBodyBgcChange } from 'frontend-components';
import AppBar from '../AppBar/AppBar.connected';
import OfflineIcon from '../Icons/OfflineIcon';

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px - 84px);
`;

const Message = styled.div`
  padding: 18px;
  border-radius: 8px;
  background-color: #303030;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-family: 'Noto Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  bottom: 24px;
  right: 16px;
  left: 16px;
`;

const NoClientData = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams();
  const client = useSelector((state) =>
    state.clients.clients.find((client) => client.id === clientId)
  );
  useBodyBgcChange();

  return (
    <Container maxWidth="sm" data-testid="create-order-or-quotation">
      <AppBar
        title={client?.visitAddress?.name}
        displayBackButton
        backButtonAction={() => history.push(`/customers/`)}
      />
      <CenteredContainer>
        <OfflineIcon />
      </CenteredContainer>
      <Message>{t('no_client_data_message')}</Message>
    </Container>
  );
};

export default NoClientData;
