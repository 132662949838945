import GalleryItemView from './GalleryItemView';
import withClientData from '../../helpers/withClientData';
import {
  deletePhoto,
  editPhoto,
  setAsEntryPhoto,
} from '../../store/actions/photoActions';
import { editVideo, deleteVideo } from '../../store/reducers/video';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ClientGalleryItemView = ({ clientData }) => {
  return (
    <GalleryItemView
      clientData={clientData}
      deletePhoto={deletePhoto}
      deleteVideo={deleteVideo}
      editPhoto={editPhoto}
      editVideo={editVideo}
      setAsEntryPhoto={setAsEntryPhoto}
    />
  );
};

ClientGalleryItemView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ClientGalleryItemView);
