import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '../Dialog';
import DialogHeader from '../DialogHeader';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-elevation24 {
    padding: 16px 0;
  }

  & .MuiDialogContent-root {
    padding: 0 14px;
    margin: 0 4px;
  }

  & .MuiDialog-paper {
    width: 100%;
    margin: 15px;

    & .MuiListItem-secondaryAction {
      min-height: 72px;
      padding: 10px 0;
    }
  }
`;

const ListDialog = ({
  titleIcon,
  title,
  open,
  setOpen,
  children,
  'data-testid': testId,
}) => {
  return (
    <StyledDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      data-testid={testId}
    >
      <DialogHeader
        title={title}
        handleClose={() => setOpen(false)}
        titleIcon={titleIcon}
      />
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

ListDialog.propTypes = {
  titleIcon: PropTypes.element,
  title: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
  'data-testid': PropTypes.string,
};

export default ListDialog;
