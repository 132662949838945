import { removeOpenActionByNote } from './notes';

export const APPLICATION_SECTION_NAMES = {
  CONTACTS: 'contacts',
  NOTES: 'notes',
};

export function removeOpenActionByApplication(application, clientData) {
  clientData.applicationNotes.data
    .filter((note) => note.applicationId === application.id)
    .forEach((note) => removeOpenActionByNote(note, clientData));
}

export function markApplicationEdited(note, clientData) {
  const { applicationId } = note;
  const application = clientData.applications.data.find(
    ({ id }) => id === applicationId
  );
  if (application) {
    application.edited = true;
  }
}

export const updateApplicationSection = (
  clientData,
  applicationId,
  sectionId,
  sectionName
) => {
  const application = clientData.applications.data.find(
    (application) => application.id === applicationId
  );
  if (application) {
    application[sectionName]
      ? application[sectionName].push(sectionId)
      : (application[sectionName] = [sectionId]);
    application.edited = true;
  }
};
