import { createAction } from '@reduxjs/toolkit';
import { addMonths, startOfMonth } from 'date-fns';
import { getPlannedDate } from 'frontend-components/lib/helpers';

import { fetchClientDataById } from './clientDataActions';
import { fetchClientsActions } from '../reducers/clients';

export const createEmptyVisitNote = createAction(
  'clientData/createEmptyVisitNote',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const createEmptyCallNote = createAction(
  'clientData/createEmptyCallNote',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const createEmptyRemarkNote = createAction(
  'clientData/createEmptyRemarkNote',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const deleteVisitNoteStore = createAction(
  'clientData/deleteVisitNoteStore',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const deleteCallNoteStore = createAction(
  'clientData/deleteCallNoteStore',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const deleteRemarkNoteStore = createAction(
  'clientData/deleteRemarkNoteStore',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const updateNoteStore = createAction(
  'clientData/updateVisitNoteStore',
  function prepare(noteId, values) {
    return {
      payload: { noteId, values },
    };
  }
);

export const updateCallNoteStore = createAction(
  'clientData/updateCallNoteStore',
  function prepare(noteId, values) {
    return {
      payload: { noteId, values },
    };
  }
);

export const updateRemarkNoteStore = createAction(
  'clientData/updateRemarkNoteStore',
  function prepare(noteId, values) {
    return {
      payload: { noteId, values },
    };
  }
);

export const setVisitNoteContactPerson = createAction(
  'clientData/setVisitNoteContactPerson',
  function prepare(contactPersonId, noteId, clientId) {
    return {
      payload: { contactPersonId, noteId, clientId },
    };
  }
);

export const setCallNoteContactPerson = createAction(
  'clientData/setCallNoteContactPerson',
  function prepare(contactPersonId, noteId, clientId) {
    return {
      payload: { contactPersonId, noteId, clientId },
    };
  }
);

export const setRemarkNoteContactPerson = createAction(
  'clientData/setRemarkNoteContactPerson',
  function prepare(contactPersonId, noteId, clientId) {
    return {
      payload: { contactPersonId, noteId, clientId },
    };
  }
);

export const postVisit = (visitId, visitData, clientId) => ({
  type: 'visit/post',
  payload: visitData,
  meta: {
    visitId,
    clientId,
    offline: {
      effect: {
        url: `/qac/visit/${visitId}`,
        method: 'POST',
        data: visitData,
      },
      commit: {
        type: 'visit/post/commit',
        meta: {
          visitId,
          clientId,
          after: [fetchClientDataById, fetchClientsActions],
          afterArgs: [
            [{ clientId }],
            [
              {
                from: getPlannedDate(startOfMonth(addMonths(new Date(), -1))),
              },
            ],
          ],
        },
      },
      rollback: { type: 'visit/post/rollback', meta: { visitId } },
    },
  },
});

export const putVisit = (visitId, visitData, clientId) => ({
  type: 'visit/put',
  payload: visitData,
  meta: {
    offline: {
      effect: {
        url: `/qac/visit/${visitId}`,
        method: 'PUT',
        data: visitData,
      },
      commit: {
        type: 'visit/put/commit',
        meta: {
          visitId,
          clientId,
          after: [fetchClientDataById, fetchClientsActions],
          afterArgs: [
            [{ clientId }],
            [
              {
                from: getPlannedDate(startOfMonth(addMonths(new Date(), -1))),
              },
            ],
          ],
        },
      },
      rollback: { type: 'visit/put/rollback', meta: { visitId } },
    },
  },
});

/* prettier-ignore */
export const deleteVisitById = createAction(
    'visit/delete',
    function prepare(visitId, clientId) {
        return {
            payload: { visitId, clientId },
            meta: {
                offline: {
                    effect: {
                        url: `/qac/visit/${visitId}`,
                        method: 'DELETE',
                    },
                    commit: {
                        type: 'visit/delete/commit',
                        meta: {
                            visitId,
                            clientId,
                          after: [fetchClientDataById, fetchClientsActions],
                          afterArgs: [
                            [{ clientId }],
                            [
                              {
                                from: getPlannedDate(startOfMonth(addMonths(new Date(), -1)))
                              },
                            ],
                          ],
                        },
                    },
                    rollback: { type: 'visit/delete/rollback', meta: { visitId } },
                },
            },
        };
    }
);

export const updateFileDescriptionStore = createAction(
  'visit/updateFileDescriptionStore',
  function prepare(payload) {
    return {
      payload,
    };
  }
);

export const deleteAttachmentByIdStore = createAction(
  'visit/deleteAttachmentByIdStore',
  function prepare(payload) {
    return {
      payload,
    };
  }
);
