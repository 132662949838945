import { useSelector } from 'react-redux';

export default function useTags() {
  const { beforeTag, afterTag, overviewTag } = useSelector((state) => ({
    beforeTag:
      state.user.data && state.user.data.tags && state.user.data.tags.length > 0
        ? state.user.data.tags.find((tag) => tag.name === 'qac.tag-before')
        : undefined,
    afterTag:
      state.user.data && state.user.data.tags && state.user.data.tags.length > 0
        ? state.user.data.tags.find((tag) => tag.name === 'qac.tag-after')
        : undefined,
    overviewTag:
      state.user.data && state.user.data.tags && state.user.data.tags.length > 0
        ? state.user.data.tags.find((tag) => tag.name === 'qac.tag-overview')
        : undefined,
  }));

  return {
    beforeTag,
    afterTag,
    overviewTag,
  };
}
