import SvgIcon from '@material-ui/core/SvgIcon';

function OrdersIcon(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <path
          id="path-1"
          d="M15 384a8 8 0 018-8h329a8 8 0 018 8v252a8 8 0 01-8 8H23a8 8 0 01-8-8V384z"
        />
        <filter
          id="filter-2"
          width="107.5%"
          height="109.7%"
          x="-3.8%"
          y="-4.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-31 -392)">
          <path fill="#FFF" d="M0 0H375V812H0z" />
          <path fill="#F2F2F2" d="M0 104H375V812H0z" />
          <g>
            <use fill="#000" filter="url(#filter-2)" xlinkHref="#path-1" />
            <use fill="#FFF" xlinkHref="#path-1" />
          </g>
          <g fill="#999" transform="translate(31 392)">
            <path d="M4 7h16v2H4V7zm0 6h16v-2H4v2zm0 4h7v-2H4v2zm0 4h7v-2H4v2zm11.41-2.83L14 16.75l-1.41 1.41L15.41 21 20 16.42 18.58 15l-3.17 3.17zM4 3v2h16V3H4z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default OrdersIcon;
