export const filterGalleryItemsByIds = (list, ids = []) => {
  const stringIds = ids.map((id) => id.toString());
  return list.filter((photo) => stringIds.includes(photo.id));
};

export const filterGalleryItemsByTagId = (list, tagId) => {
  return list.filter(
    (photo) =>
      photo.tags &&
      photo.tags.map((tag) => tag.id.toString()).includes(tagId.toString())
  );
};

export const findPhotoInApplications = (applicationsList = [], photoId) => {
  return (
    applicationsList.find(
      (app) => app.photos && app.photos.includes(photoId)
    ) || {}
  );
};
