import SvgIcon from '@material-ui/core/SvgIcon';

function StatusWaiting(props) {
  return (
    <SvgIcon {...props}>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="16.0.photo_gallery-v2"
          transform="translate(-21.000000, -714.000000)"
        >
          <g id="Group-3" transform="translate(21.000000, 714.000000)">
            <circle id="Oval" fill="#FFFFFF" cx="12" cy="12" r="12" />
            <path
              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z"
              id="Oval"
              fill="#E5E5E5"
            />
            <polygon
              id="Path"
              fill="#999999"
              transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
              points="16 12 14 12 14 8 10 8 10 12 8 12 12 16"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default StatusWaiting;
