import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

import {
  fetchTAAllowed,
  fetchDelegationAllowed,
  fetchCurrentlyLoggedUser,
} from '../actions/userActions';

const cookies = new Cookies();

const DelegatedAdapter = createEntityAdapter();

function filterOutUnecessaryTAAllowed(taAllowed) {
  const nonTaIDs = ['HO'];
  return (
    taAllowed && taAllowed.filter((item) => nonTaIDs.indexOf(item.id) === -1)
  );
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    /*
      we assume token is valid so if it exists user is logged in
      later token will be validated by API
    */
    isLoggedIn: Boolean(cookies.get('token')),
    accessDenied: false,
    accessDeniedDisplayed: false,
    delegatedTA: DelegatedAdapter.getInitialState(),
    allowed: [],
    data: null,
    authErrorDisplayed: false,
  },
  reducers: {
    logIn: (state) => {
      state.isLoggedIn = true;
      state.accessDenied = false;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
    },
    setAccessDenied: (state, action) => {
      state.accessDenied = action.payload;
    },
    setAccessDeniedDisplayed: (state, action) => {
      state.accessDeniedDisplayed = action.payload;
    },
    setAuthErrorDisplayed: (state, action) => {
      state.authErrorDisplayed = action.payload;
    },
  },
  extraReducers: {
    [fetchDelegationAllowed.fulfilled]: (state, action) => {
      DelegatedAdapter.setAll(
        state.delegatedTA,
        filterOutUnecessaryTAAllowed(action.payload.data)
      );
    },
    [fetchTAAllowed.fulfilled]: (state, action) => {
      state.allowed = action.payload.data;
    },
    [fetchCurrentlyLoggedUser.pending]: (state) => {
      state.loading = true;
    },
    [fetchCurrentlyLoggedUser.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    },
  },
});

export const {
  selectById: selectTAById,
  selectIds: selectTAIds,
  selectEntities: selectTAEntities,
  selectAll: selectAllTA,
  selectTotal: selectTotalTA,
} = DelegatedAdapter.getSelectors((state) => state.user.delegatedTA);

export const {
  logIn,
  logOut,
  setAccessDenied,
  setAccessDeniedDisplayed,
  setAuthErrorDisplayed,
} = userSlice.actions;

export default userSlice.reducer;
