import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddContactPersonView from './AddContactPersonView';
import withClientData from '../../helpers/withClientData';
import {
  setNewQuotationContactPerson,
  setNewQuotationContactsCC,
} from '../../store/reducers/drafts';
import useCheckLocationState from '../../hooks/useCheckLocationState';
import { updateQrderOrQuotationExpandedPanel } from '../../store/reducers/clientUI';
import { ORDER_OR_QUOTATION_PANEL_TYPE } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const AddNewQuotationContactPersonView = ({ clientData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const isFromSendCopyTo = useCheckLocationState('sendCopyTo');
  const handleNewQuotationContactsCC = (contactPersonId) => {
    dispatch(setNewQuotationContactsCC(contactPersonId));
    dispatch(
      updateQrderOrQuotationExpandedPanel({
        clientId,
        expandedPanel: ORDER_OR_QUOTATION_PANEL_TYPE.CC,
      })
    );
  };

  const { contactToExclude } = useSelector((state) => ({
    contactToExclude: state.drafts.newQuotation.contactPersonId,
  }));

  return (
    <AddContactPersonView
      clientData={clientData}
      onContactSelected={(contactPersonId) => {
        isFromSendCopyTo
          ? handleNewQuotationContactsCC(contactPersonId)
          : dispatch(setNewQuotationContactPerson(contactPersonId));
        history.goBack();
      }}
      onAddContactClicked={() => {
        history.push(
          `/customers/${clientId}/create/quotation/addContactPerson/${uuidv4()}`,
          { from: isFromSendCopyTo ? 'sendCopyTo' : '' }
        );
      }}
      goBackAction={() =>
        history.push(`/customers/${clientId}/create/quotation`)
      }
      showContactsOnlyWithEmails={isFromSendCopyTo}
      displayEmail
      contactToExclude={contactToExclude}
    />
  );
};

AddNewQuotationContactPersonView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(AddNewQuotationContactPersonView);
