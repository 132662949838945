import { useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { blockForm } from '../../store/reducers/clientUI';

const useFormikAutoSave = (
  values,
  handleSubmit,
  debounceMs,
  dirty,
  isValid = true
) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => handleSubmit(), debounceMs),
    [handleSubmit, debounceMs]
  );

  useEffect(() => {
    dirty && debouncedSubmit();
  }, [debouncedSubmit, values, dirty]);

  useEffect(() => {
    dispatch(blockForm(!isValid));
  }, [dispatch, isValid]);
};

export default useFormikAutoSave;
