export const findPhoto = (
  clientPhotos = [],
  photosFromDevice = [],
  photoId
) => {
  const clientPhoto = clientPhotos.find(
    ({ id }) => id && id.toString() === photoId
  );
  const photoFromDevice = photosFromDevice.find(
    ({ id }) => id && id.toString() === photoId
  );

  if (clientPhoto) {
    return clientPhoto;
  }

  if (photoFromDevice) {
    return photoFromDevice;
  }

  return {};
};
