import PropTypes from 'prop-types';
import styled from 'styled-components';
import { default as MuiCard } from '@material-ui/core/Card/Card';

export const FooterContainer = styled(MuiCard)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: center;
`;

const FixedFooter = ({ children }) => {
  return (
    <FooterContainer data-testid="fixed-footer">{children}</FooterContainer>
  );
};

FixedFooter.propTypes = {
  children: PropTypes.element,
};

export default FixedFooter;
