import isEmpty from 'lodash/isEmpty';

const period = (period) => {
  if (['year', 'years'].includes(period)) {
    return 365;
  }
  if (['month', 'months'].includes(period)) {
    return 30;
  }
  if (['week', 'weeks'].includes(period)) {
    return 7;
  }
  return 1;
};

export const calculateFieldValue = (appData, type) => {
  if (isEmpty(appData)) return '';
  const {
    lubricationFrequencyPeriod: beforeLubPeriod,
    lubricationFrequency: beforeLubFreq,
    price: beforePrice,
    amountPerLubrication: beforeAmountPerLub,
    avgComponentLifetime: beforeComponentLifetime,
    componentCost,
    avgComponentLifetimePeriod: beforeComponentLifetimePeriod,
    downtimeToReplace,
    peopleToReplace,
    manualApplicationTime: beforeApplicationTime,
  } = appData.before;
  const {
    lubricationFrequencyPeriod: afterLubPeriod,
    lubricationFrequency: afterLubFreq,
    price: afterPrice,
    amountPerLubrication: afterAmountPerLub,
    avgComponentLifetime: afterComponentLifeTime,
    additionalSavings,
    avgComponentLifetimePeriod: afterComponentLifetimePeriod,
    manualApplicationTime: afterApplicationTime,
  } = appData.after;

  const Year = 365;
  const minutesInDay = 60 * 24;

  let result;
  if (type === 'savings') {
    result =
      (Year / period(beforeLubPeriod)) *
        beforeLubFreq *
        (beforePrice * (beforeAmountPerLub / 1000)) -
      (Year / period(afterLubPeriod)) *
        afterLubFreq *
        (afterPrice * (afterAmountPerLub / 1000)) +
      (Year /
        (period(beforeComponentLifetimePeriod) * beforeComponentLifetime) -
        Year /
          (period(afterComponentLifetimePeriod) * afterComponentLifeTime)) *
        componentCost +
      (additionalSavings && parseFloat(additionalSavings));
  } else if (type === 'uptimeGain') {
    result =
      (Year / period(beforeComponentLifetimePeriod) / beforeComponentLifetime) *
        (downtimeToReplace / 24) -
      (Year / period(afterComponentLifetimePeriod) / afterComponentLifeTime) *
        (downtimeToReplace / 24);
  } else if (type === 'laborTime') {
    result =
      ((Year /
        (period(beforeComponentLifetimePeriod) * beforeComponentLifetime)) *
        (downtimeToReplace / 24) -
        (Year /
          (period(afterComponentLifetimePeriod) * afterComponentLifeTime)) *
          (downtimeToReplace / 24)) *
        peopleToReplace +
      (Year / period(beforeLubPeriod)) *
        beforeLubFreq *
        (beforeApplicationTime / minutesInDay) -
      (Year / period(afterLubPeriod)) *
        afterLubFreq *
        (afterApplicationTime / minutesInDay);
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloat(result)) || !isFinite(result)) {
    result = 0;
  }

  return result;
};
