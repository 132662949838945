import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setRemarkNoteContactPerson } from '../../store/actions/notesActions';
import AddContactPersonView from './AddContactPersonView';
import withClientData from '../../helpers/withClientData';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const AddRemarkContactPersonView = ({ clientData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { noteId, clientId } = useParams();

  return (
    <AddContactPersonView
      clientData={clientData}
      goBackAction={() => {
        history.push(`/customers/${clientId}/remarks/${noteId}`);
      }}
      onContactSelected={(contactPersonId) => {
        dispatch(setRemarkNoteContactPerson(contactPersonId, noteId, clientId));
        history.push(`/customers/${clientId}/remarks/${noteId}`, {
          from: 'addContactPerson',
        });
      }}
      onAddContactClicked={() => {
        history.push(
          `/customers/${clientId}/remarks/${noteId}/addContactPerson/${uuidv4()}`
        );
      }}
    />
  );
};

AddRemarkContactPersonView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(AddRemarkContactPersonView);
