import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactPersonView from './ContactPersonView';
import {
  createContactPerson,
  editContactPerson,
  addContactPersonToApplication,
} from '../../store/actions/contactPersonActions';
import { updateApplicationExpandedPanel } from '../../store/reducers/clientUI';
import { APPLICATION_LIST_TYPES } from '../../constants/lists';

const AddContactPersonToApplicationView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { applicationId, clientId, contactPersonId } = useParams();
  const fromApplicationView = true;

  return (
    <ContactPersonView
      goBackAction={() => {
        history.push(
          `/customers/${clientId}/applications/${applicationId}/addContactPerson`
        );
      }}
      onSubmit={(values, isExistingContact) => {
        const newValues = {
          ...values,
          clientId,
        };
        if (isExistingContact) {
          dispatch(editContactPerson(contactPersonId, newValues, clientId));
        } else {
          dispatch(
            createContactPerson(
              contactPersonId,
              newValues,
              clientId,
              fromApplicationView
            )
          );
          dispatch(
            addContactPersonToApplication(
              clientId,
              applicationId,
              contactPersonId
            )
          );
          dispatch(
            updateApplicationExpandedPanel({
              clientId,
              expandedPanel: APPLICATION_LIST_TYPES.CONATCTS,
            })
          );
        }

        history.push(`/customers/${clientId}/applications/${applicationId}`);
      }}
    />
  );
};

export default AddContactPersonToApplicationView;
