import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from './AppBar';
import { openHelpDialog } from '../../store/reducers/helpSlice';

const AppBarConnected = (props) => {
  const dispatch = useDispatch();
  const { isDataLoading, online, help } = useSelector((state) => ({
    help: state.help,
    online: state.offline.online,
    isDataLoading: state.app.dataLoading,
  }));

  const setHelpOpen = () => {
    dispatch(openHelpDialog());
  };

  return (
    <AppBar
      isDataLoading={isDataLoading}
      online={online}
      help={help}
      setHelpOpen={setHelpOpen}
      {...props}
    />
  );
};

AppBarConnected.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  aside: PropTypes.node,
  elevated: PropTypes.bool,
  displayBackButton: PropTypes.bool,
  backButtonAction: PropTypes.func,
};

export default AppBarConnected;
