import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Collapsible from '../../components/Collapsible/Collapsible';

const StyledCollapsible = styled(Collapsible)`
  border-radius: 8px;
  margin: 8px auto;

  box-shadow: ${({ $subCategory }) =>
    $subCategory
      ? '0 1px 4px 0 rgba(0,0,0,0.2)'
      : '0 2px 8px 0 rgba(0,0,0,0.2)'};
`;

const Product = memo(
  ({ title, itemsSize, children, isExpanded, onChange, subCategory }) => (
    <StyledCollapsible
      expanded={isExpanded}
      onChange={(event) => onChange(title, isExpanded, event)}
      title={title}
      displayPlusButton={false}
      itemsSize={itemsSize}
      collapsible
      data-testid="demo-products-category-list"
      removePlusButtonEmptySpace
      $subCategory={subCategory}
    >
      {children}
    </StyledCollapsible>
  )
);

Product.propTypes = {
  title: PropTypes.string,
  itemsSize: PropTypes.number,
  children: PropTypes.node,
  isExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  subCategory: PropTypes.bool,
};

export default Product;
