import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DialogHeader from '../../components/Dialog/DialogHeader';
import { StyledDialogActions } from '../../components/Dialog/DeleteDialog/DeleteDialog';
import CustomButton from '../../components/Button/Button';
import Dialog from '../../components/Dialog/Dialog';

const NewReportDialog = ({
  openReportDialog,
  newReportHandle,
  sameReportHandle,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog aria-labelledby="report-dialog" open={openReportDialog}>
      <DialogHeader title={t('new_report_title')} showCloseButton={false} />
      <DialogContent>
        <p>{t('new_report_text')}</p>
      </DialogContent>
      <StyledDialogActions>
        <CustomButton
          onClick={newReportHandle}
          theme="secondary"
          data-testid="new-report"
        >
          {t('new_report')}
        </CustomButton>
        <CustomButton
          onClick={sameReportHandle}
          theme="secondary"
          data-testid="same-report"
        >
          {t('same_report')}
        </CustomButton>
      </StyledDialogActions>
    </Dialog>
  );
};

NewReportDialog.propTypes = {
  openReportDialog: PropTypes.bool,
  newReportHandle: PropTypes.func,
  sameReportHandle: PropTypes.func,
};

export default NewReportDialog;
