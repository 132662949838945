import { Button } from 'frontend-components';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  && {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '45%')};
    max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '140px')};
    text-transform: none;
    font-weight: 400;
    letter-spacing: normal;
    box-shadow: none;
    background: ${({ theme }) => (theme === 'primary' ? '#E42313' : '#fff')};
  }
`;

export default CustomButton;
