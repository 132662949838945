import { createSlice } from '@reduxjs/toolkit';
import { getPlannedDate } from 'frontend-components/lib/helpers';
import {
  cancelVisitById,
  fetchVisitsByDateAndClientId,
} from '../actions/visitsActions';
import { addAttachmentImage } from '../actions/photoActions';
import { addAttachmentVideo } from './video';

const clientVisitsDataSlice = createSlice({
  name: 'clientVisitsData',
  initialState: {
    data: {},
    loadingStatus: {},
  },
  reducers: {
    createEmptyVisit: (state, action) => {
      const { clientId, reportId } = action.payload;

      state.data[clientId].visits.unshift({
        reportId,
        clientId,
        createdAt: getPlannedDate(new Date()),
        edited: false,
        attachments: [],
      });
    },
    deleteVisitByIdStore: (state, action) => {
      const { clientId, reportId } = action.payload;

      state.data[clientId].visits = state.data[clientId].visits.filter(
        (visit) => visit.reportId !== reportId
      );
    },
    deleteAttachmentById: (state, action) => {
      const { clientId, reportId, attachmentId } = action.payload;

      const visit = state.data[clientId].visits.find(
        (visit) => visit.reportId === reportId
      );

      if (visit) {
        const index = visit.attachments.findIndex(
          (attachment) => attachment.id.toString() === attachmentId
        );

        if (index !== -1) visit.attachments.splice(index, 1);
      }
    },
    updateFileDescription: (state, action) => {
      const { clientId, reportId, fileId, description } = action.payload;

      const file = state.data[clientId].visits
        .find((visit) => visit.reportId === reportId)
        .attachments.find((attachment) => attachment.id === fileId);

      if (file) file.description = description;
    },
  },
  extraReducers: {
    [fetchVisitsByDateAndClientId.pending]: (state, action) => {
      const { clientId } = action.meta.arg;
      state.loadingStatus[clientId] = 'fetching';
    },
    [fetchVisitsByDateAndClientId.fulfilled]: (state, action) => {
      const { clientId } = action.meta.arg;
      state.data[clientId] = {
        visits: [],
      };
      state.data[clientId]?.visits.unshift(action.payload);
      state.loadingStatus[clientId] = 'fetched';
    },
    [fetchVisitsByDateAndClientId.rejected]: (state, action) => {
      const { clientId } = action.meta.arg;

      if (!state.data[clientId] || action.payload?.status === 404) {
        state.data[clientId] = {
          visits: [],
        };
      }
      state.loadingStatus[clientId] = 'fetched';
    },
    [addAttachmentImage]: (state, action) => {
      const { clientId, reportId, photo, callSource } = action.payload;
      if (callSource === 'newReport') {
        state.data[clientId].visits
          .find((visit) => visit.reportId === reportId)
          .attachments.push(photo);
      }
    },
    [addAttachmentVideo]: (state, action) => {
      const { clientId, reportId, data, callSource } = action.payload;
      if (callSource === 'newReport') {
        state.data[clientId].visits
          .find((visit) => visit.reportId === reportId)
          .attachments.push(data);
      }
    },
    [cancelVisitById]: (state, action) => {
      const { clientId, reportId, callSource } = action.payload;
      if (callSource === 'newReport') {
        state.data[clientId].visits = state.data[clientId].visits.filter(
          (visit) => visit.reportId !== reportId
        );
      }
    },
  },
});

export const {
  createEmptyVisit,
  deleteVisitByIdStore,
  updateFileDescription,
  deleteAttachmentById,
} = clientVisitsDataSlice.actions;

export default clientVisitsDataSlice.reducer;
