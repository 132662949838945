import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PeopleIcon from '@material-ui/icons/People';
import PhoneIcon from '@material-ui/icons/Phone';
import ClearIcon from '@material-ui/icons/Clear';
import { v4 as uuidv4 } from 'uuid';
import { Divider } from 'frontend-components';
import {
  getContactPhoneNumber,
  getContactsByPhone,
  getDefinedPhones,
} from 'frontend-components/lib/helpers';

import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import LimitedList from '../LimitedList/LimitedList';
import GeneralPhoneNumberListItem from '../GeneralPhoneNumberListItem/GeneralPhoneNumberListItem';
import ListDialog from '../Dialog/ListDialog/ListDialog';
import ContactsDialogList from './ContactsDialogList';
import {
  StyledIconButton,
  ClearIconButton,
  ContactName,
} from './ContactPeopleList.styles';
import CreateCallReportDialog from '../Dialog/CreateCallReportDialog/CreateCallReportDialog';
import { NOTE_TYPES } from '../../constants/noteTypes';
import { SHOW_DIALOG_DELAY } from '../../constants/callReportDialog';
import useCreateCallReportDialog from '../../hooks/useCreateCallReportDialog';
import { searchPhaseInObject } from '../../helpers/search';
import NothingFound from '../NothingFound/NothingFound';
import SearchField from '../Search/SearchField';

const StyledSecondaryLine = styled.span`
  display: block;
`;

const ContactPeopleList = ({
  expanded,
  withRemoveButton,
  onChange,
  onPlusButtonClick,
  onRemoveClick,
  contactPersons,
  filterDeletedContacts = false,
  title,
  'data-testid': testId,
  disabled,
  showEmail,
  blockEditingContactOnClick,
  generalPhoneNumber,
  showSearch,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();

  const [filteredContacts, setFilteredContacts] = useState({
    total: 0,
    data: [],
  });
  const [showPhonesDialog, setShowPhonesDialog] = useState(false);
  const [contactsDialogData, setContactsDialogData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const showGeneralNumber =
    generalPhoneNumber &&
    (!(searchTerm.trim().length > 2) ||
      (searchTerm.trim() &&
        searchPhaseInObject(searchTerm, {
          name: t('contact_list_general_phone'),
        }).length > 0));

  const isExpanded =
    expanded &&
    (filteredContacts.total > 0 || searchTerm !== '' || !!generalPhoneNumber);

  useEffect(() => {
    const searchContactPerson = (contact) => {
      if (searchTerm.trim().length < 3) return [];

      return searchPhaseInObject(searchTerm, {
        name: `${contact.firstName} ${contact.lastName}`,
        firstName: contact.firstName,
        lastName: contact.lastName,
        jobTitle: contact.jobTitle,
      }).length;
    };

    const filteredContactsData = contactPersons.data.filter(
      (contact) =>
        (!filterDeletedContacts || contact.isDeleted === 0) &&
        searchContactPerson(contact)
    );
    setFilteredContacts({
      total: filteredContactsData.length,
      data: filteredContactsData,
    });
  }, [contactPersons, filterDeletedContacts, searchTerm]);

  useEffect(() => {
    setSearchTerm('');
  }, [expanded]);

  const {
    showCreateCallReportDialog,
    setShowCreateCallReportDialog,
    callReportSelectedContact,
    setCallReportSelectedContact,
  } = useCreateCallReportDialog();

  return (
    <>
      <Collapsible
        expanded={isExpanded}
        onChange={onChange}
        icon={<PeopleIcon />}
        title={title || t('contacts')}
        itemsSize={filteredContacts.total}
        displayPlusButton
        onPlusButtonClick={onPlusButtonClick}
        collapsible={filteredContacts.total > 0 || !!generalPhoneNumber}
        data-testid={testId}
        disabled={disabled}
      >
        {showSearch && (
          <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
        <LimitedList
          items={filteredContacts.data}
          label={t('contacts')}
          topItem={
            <>
              {showGeneralNumber && (
                <GeneralPhoneNumberListItem
                  generalPhoneNumber={generalPhoneNumber}
                  showDivider={filteredContacts?.total > 0}
                  onPhoneClick={() => {
                    setTimeout(() => {
                      setShowCreateCallReportDialog(true);
                      setCallReportSelectedContact('');
                    }, SHOW_DIALOG_DELAY);
                  }}
                />
              )}
            </>
          }
          renderItem={(item, index) => {
            const isLast = index === filteredContacts.data.length - 1;
            const contactPhoneNumber = getContactPhoneNumber(item.phoneList);
            const prefix =
              item.isDeleted === 1
                ? `[${t('contact_deleted').toUpperCase()}] `
                : '';
            const handlePhoneButtonClick = (e) => {
              e.stopPropagation();
              const phones = getContactsByPhone([
                {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  phoneList: item.phoneList,
                },
              ]);
              setContactsDialogData(phones);
              setShowPhonesDialog(true);
            };

            const definedPhones = getDefinedPhones(item.phoneList);

            return (
              <div key={item.id}>
                <ListItem
                  key={item.id}
                  button
                  $withRightIcon
                  onClick={() => {
                    if (blockEditingContactOnClick) return;
                    if (item.isDeleted !== 1) {
                      history.push(
                        `/customers/${clientId}/contacts/${item.id}`
                      );
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <ContactName isDeleted={item.isDeleted === 1}>
                        {`${prefix}${item.firstName} ${item.lastName}`}
                      </ContactName>
                    }
                    secondary={
                      <>
                        <StyledSecondaryLine>
                          {item.jobTitle}
                        </StyledSecondaryLine>
                        <StyledSecondaryLine>
                          {showEmail ? item.emailsList[0] : contactPhoneNumber}
                        </StyledSecondaryLine>
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    {definedPhones.length > 1 && (
                      <StyledIconButton
                        onClick={handlePhoneButtonClick}
                        aria-label="phone"
                      >
                        <PhoneIcon />
                      </StyledIconButton>
                    )}
                    {definedPhones.length === 1 && contactPhoneNumber && (
                      <StyledIconButton
                        href={`tel:${contactPhoneNumber}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setTimeout(() => {
                            setCallReportSelectedContact(contactPhoneNumber);
                            setShowCreateCallReportDialog(true);
                          }, SHOW_DIALOG_DELAY);
                        }}
                        edge="start"
                        aria-label="phone"
                      >
                        <PhoneIcon />
                      </StyledIconButton>
                    )}
                    {withRemoveButton && (
                      <ClearIconButton
                        onClick={(e) => {
                          e.stopPropagation();

                          if (onRemoveClick) {
                            onRemoveClick(item.id);
                          }
                        }}
                        disabled={disabled}
                      >
                        <ClearIcon />
                      </ClearIconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {!isLast && <Divider />}
              </div>
            );
          }}
        />
        {searchTerm && filteredContacts.total === 0 && !showGeneralNumber && (
          <NothingFound size="sm" />
        )}
      </Collapsible>
      <ListDialog
        titleIcon={<PeopleIcon />}
        title={t('app_contact_phones')}
        open={showPhonesDialog}
        setOpen={setShowPhonesDialog}
        data-testid="phones-dialog"
      >
        <ContactsDialogList
          contactsDialogData={contactsDialogData}
          setShowCreateCallReportDialog={setShowCreateCallReportDialog}
          setShowContactsDialog={setShowPhonesDialog}
          setCallReportSelectedContact={setCallReportSelectedContact}
        />
      </ListDialog>
      <CreateCallReportDialog
        open={showCreateCallReportDialog}
        setOpen={setShowCreateCallReportDialog}
        createCallReport={() =>
          history.push(
            `/customers/${clientId}/${NOTE_TYPES.CALLS}/${uuidv4()}`,
            {
              callDialogContactPhoneNumber: callReportSelectedContact,
            }
          )
        }
      />
    </>
  );
};

ContactPeopleList.propTypes = {
  withRemoveButton: PropTypes.bool,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  onPlusButtonClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  filterDeletedContacts: PropTypes.bool,
  contactPersons: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        note: PropTypes.string,
        phoneList: PropTypes.arrayOf(
          PropTypes.shape({
            phone: PropTypes.string,
            type: PropTypes.string,
          })
        ),
        emailsList: PropTypes.arrayOf(PropTypes.string),
        updatedAt: PropTypes.string,
      })
    ),
  }),
  title: PropTypes.string,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  showEmail: PropTypes.bool,
  blockEditingContactOnClick: PropTypes.bool,
  generalPhoneNumber: PropTypes.string,
  showSearch: PropTypes.bool,
};

export default ContactPeopleList;
