function NothingFound() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="168">
      <path
        fill="#CCC"
        d="M35.293 137.293l1.414 1.414L34.414 141l2.293 2.293-1.414 1.414L33 142.414l-2.293 2.293-1.414-1.414L31.586 141l-2.293-2.293 1.414-1.414L33 139.586l2.293-2.293zM30 22a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6zm115 41a7 7 0 110 14 7 7 0 010-14zm0 2a5 5 0 100 10 5 5 0 000-10zM69.5 34C91.868 34 110 52.132 110 74.5c0 7.665-2.13 14.832-5.829 20.943l31.952 31.952a9.5 9.5 0 01-13.241 13.624l-.194-.189-31.881-31.881A40.31 40.31 0 0169.5 115C47.132 115 29 96.868 29 74.5S47.132 34 69.5 34zm33.567 63.167a40.725 40.725 0 01-10.552 10.662l31.587 31.587a7.5 7.5 0 0010.776-10.432l-.17-.175-31.64-31.642zM69.5 36C48.237 36 31 53.237 31 74.5S48.237 113 69.5 113 108 95.763 108 74.5 90.763 36 69.5 36zm0 5C88.002 41 103 55.998 103 74.5c0 18.502-14.998 33.5-33.5 33.5C50.998 108 36 93.002 36 74.5 36 55.998 50.998 41 69.5 41zm0 2C52.103 43 38 57.103 38 74.5S52.103 106 69.5 106 101 91.897 101 74.5 86.897 43 69.5 43z"
      />
    </svg>
  );
}

export default NothingFound;
