import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getVisitsByDateAndClientId } from '../../services/visits.service';

export const fetchVisitsByDateAndClientId = createAsyncThunk(
  'visits/fetchVisitsByDateAndClientId',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getVisitsByDateAndClientId(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postVisitAttachments = (visitId, visitData, clientId) => ({
  type: 'visit/postAttachments',
  payload: visitData,
  meta: {
    visitId,
    clientId,
    offline: {
      effect: {
        url: `/qac/visit-attachments/${visitId}`,
        method: 'PUT',
        data: visitData,
      },
      commit: {
        type: 'visit/postAttachments/commit',
        meta: {
          visitId,
          clientId,
        },
      },
      rollback: { type: 'visit/postAttachments/rollback', meta: { visitId } },
    },
  },
});

export const cancelVisitById = createAction(
  'visit/cancel',
  function prepare(reportId, clientId, reportData, callSource) {
    return {
      payload: { reportId, clientId, reportData, callSource },
      meta: {
        offline: {
          effect: {
            url: `/qac/visit/cancel/${reportId}`,
            method: 'POST',
            data: reportData,
          },
          commit: {
            type: 'visit/cancel/commit',
            meta: {
              reportId,
              clientId,
            },
          },
          rollback: { type: 'visit/cancel/rollback', meta: { reportId } },
        },
      },
    };
  }
);
