import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import MenuItem from '../../../components/Select/MenuItem';
import { CHAIN_TYPES } from '../../../constants/applicationScreen';
import { changeFieldValueToNumber } from '../../../helpers/changeFieldValueToNumber';

const Chain = ({ values, setFieldValue, errors, disabled }) => {
  const { t } = useTranslation();

  const { type, length, speed } = values;

  return (
    <>
      <Select
        id="chainType"
        data-testid="chainType"
        label={t('app_chain_type')}
        fullWidth
        value={type}
        onChange={(type) => {
          setFieldValue('chain.type', type);
        }}
        disabled={disabled}
      >
        {CHAIN_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <Input
        id="length"
        label={t('app_chain_length')}
        onchange={(e) => {
          setFieldValue('chain.length', changeFieldValueToNumber(e));
        }}
        value={length}
        fullWidth
        rightText={t('app_size_mm')}
        type="number"
        errorMessage={errors && errors.length}
        disabled={disabled}
      />
      <Input
        id="speed"
        label={t('app_chain_speed')}
        onchange={(e) => {
          setFieldValue('chain.speed', changeFieldValueToNumber(e));
        }}
        value={speed}
        fullWidth
        rightText={t('app_chain_speed_unit')}
        type="number"
        errorMessage={errors && errors.speed}
        disabled={disabled}
      />
    </>
  );
};

Chain.propTypes = {
  values: PropTypes.shape({
    type: PropTypes.string,
    length: PropTypes.string,
    speed: PropTypes.string,
  }),

  setFieldValue: PropTypes.func,
  errors: PropTypes.shape({
    type: PropTypes.string,
    length: PropTypes.string,
    speed: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default Chain;
