import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SanitizedHTML from 'react-sanitized-html';
import sanitizeHtml from 'sanitize-html';
import AppBar from '../../../components/AppBar/AppBar.connected';

const Content = styled.article`
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: anywhere;

  h1 {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 40px;
  }

  p {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 34px;
  }
`;

const TermsAndContitionsContent = ({ title, content }) => {
  const { clientId } = useParams();
  const history = useHistory();

  return (
    <Container maxWidth="sm">
      <AppBar
        title={title}
        displayBackButton
        backButtonAction={() => {
          history.push(`/customers/${clientId}/create/order/terms`);
        }}
      />
      <Content data-testid="terms-and-conditions-content">
        <SanitizedHTML
          html={content}
          allowedAttributes={{
            ...sanitizeHtml.defaults.allowedAttributes,
            '*': ['style'],
          }}
        />
      </Content>
    </Container>
  );
};

TermsAndContitionsContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export const TermsView = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { termsContent } = useSelector(
    (state) => state.clientData.data[clientId]
  );

  return (
    <TermsAndContitionsContent
      title={t('terms_terms')}
      content={termsContent}
    />
  );
};

export const ConditionsView = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { conditionsContent } = useSelector(
    (state) => state.clientData.data[clientId]
  );

  return (
    <TermsAndContitionsContent
      title={t('terms_conditions')}
      content={conditionsContent}
    />
  );
};
