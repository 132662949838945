import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import { Button } from 'frontend-components';

import { AddButtonContainer } from '../../views/CreateNewOrderOrQuotation/DetailsView/DetailsView';
import Checkbox from '../Checkbox/Checkbox';

const StyledTip = styled.div`
  font-size: 14px;

  & .MuiPaper-rounded {
    border-radius: 8px 8px 0 0;
  }
  & .MuiPaper-root {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    background-color: #ffffff;
    border-bottom: 8px solid #e42313;
    color: #000000;
    bottom: 0;
    left: 0;
    max-height: 72vh;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  & .MuiIcon-root {
    align-self: flex-end;
    margin: 21px 21px 0 0;
    color: #666666;
  }
`;

const StyledRedIcon = styled(Icon)`
  && {
    color: #e42313;
  }
`;

const TipContainer = styled.div`
  position: relative;
  margin-top: 3px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-flow: row nowrap;
  & .MuiIcon-root {
    font-size: 24px;
    align-self: flex-start;
    margin: 0 18px 0 0;
  }
`;

const TipContent = styled.div`
  width: 100%;
  title {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

const ScrollableContainer = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 40vh;
  padding-bottom: 40px;
  max-width: 95%;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Tip = ({
  title,
  text,
  showTip,
  setShowTip,
  showButton,
  buttonLabel,
  onButtonClick,
  showCheckbox,
  onCheckboxClick,
  icon = 'how_to_reg',
  'data-testid': testId,
}) => {
  const { t } = useTranslation();

  return (
    <StyledTip data-testid={testId || 'tip'}>
      <Slide direction="up" in={showTip} mountOnEnter unmountOnExit>
        <Paper elevation={0} square={false}>
          <Icon
            data-testid="close-tip-button"
            color="primary"
            onClick={() => {
              setShowTip(!showTip);
            }}
          >
            close
          </Icon>
          <TipContainer>
            <StyledRedIcon>{icon}</StyledRedIcon>
            <TipContent>
              <title>{title}</title>
              <ScrollableContainer>
                <p>{text}</p>
              </ScrollableContainer>
              {showCheckbox && (
                <div>
                  <Checkbox
                    label={t('tip_do_not_show_again')}
                    onChange={(checkedOrNot) => onCheckboxClick(checkedOrNot)}
                  />
                </div>
              )}
              {showButton && (
                <AddButtonContainer>
                  <Button
                    aria-label="add-products"
                    onClick={onButtonClick}
                    theme="primary"
                  >
                    {buttonLabel}
                  </Button>
                </AddButtonContainer>
              )}
            </TipContent>
          </TipContainer>
        </Paper>
      </Slide>
    </StyledTip>
  );
};

Tip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showTip: PropTypes.bool,
  setShowTip: PropTypes.func,
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  icon: PropTypes.string,
  'data-testid': PropTypes.string,
};

export default Tip;
