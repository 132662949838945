import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { endOfToday, endOfTomorrow } from 'date-fns';
import {
  getDateRange,
  getPlannedDate,
  isValidDateObject,
} from 'frontend-components/lib/helpers';
import Container from '@material-ui/core/Container';

import DayFilter, { DAY_FILTER_VALUES } from './DayFilter';
import { ActionBar } from './SelectCustomerView.styles';
import IconFilters from '../../components/IconFilters/IconFilters';
import {
  APPOINTMENT,
  DROP_IN,
  OTHER,
  PHONE_CALL,
} from '../../constants/nextAction';
import DateRange from '../../components/DateRange/DateRange';
import CustomersData from './CustomersData';
import CustomersList from './CustomersList';
import NoPlannedClients from './NoPlannedClients';
import NoFilteredClients from './NoFilteredClients';
import { filterPlannedActions } from '../../helpers/actions';
import { fetchProductsInCategories } from '../../store/reducers/products';
import { fetchClientDataById } from '../../store/actions/clientDataActions';
import { fetchNextActionsList } from '../../store/reducers/clients';

const NextActions = ({
  setContactsDialogData,
  setShowContactsDialog,
  setGeneralPhoneNumber,
  showCreateCallReportDialog,
  setShowCreateCallReportDialog,
  callReportSelectedContact,
  callReportClientId,
  setCallReportClientId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { clients, plannedNextActions } = useSelector((state) => state.clients);
  const { clientData } = useSelector((state) => state);
  const { online } = useSelector((state) => state.offline);
  const dayFilterFromDefault = sessionStorage.getItem('dayFilterFromDefault')
    ? new Date(sessionStorage.getItem('dayFilterFromDefault'))
    : new Date(getDateRange(5).fromDate);
  const dayFilterToDefault = sessionStorage.getItem('dayFilterToDefault')
    ? new Date(sessionStorage.getItem('dayFilterToDefault'))
    : new Date(endOfTomorrow());
  const { tileLines, showDepartment } = useSelector(
    (state) => state.user.data.miaOptions
  );

  const [actionFilter, setActionFilter] = useState();
  const [dayFilter, setDayFilter] = useState(DAY_FILTER_VALUES.TODAY);
  const [dayFilterFrom, setDayFilterFrom] = useState(dayFilterFromDefault);
  const [dayFilterTo, setDayFilterTo] = useState(dayFilterToDefault);
  const [filteredNextActions, setFilteredNextActions] = useState([]);

  const hasNextActions = plannedNextActions && plannedNextActions.length > 0;
  const hasFilteredNextActions =
    filteredNextActions && filteredNextActions.length > 0;
  const dateAYearAgo = new Date(getDateRange(5).fromDate);

  useEffect(() => {
    dispatch(
      fetchNextActionsList({
        from: getPlannedDate(
          new Date(
            Math.min(dateAYearAgo.getTime(), dayFilterFromDefault.getTime())
          )
        ),
        to: getPlannedDate(
          new Date(
            Math.max(endOfToday().getTime(), dayFilterToDefault.getTime())
          )
        ),
        force: true,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    sessionStorage.setItem('dayFilterFromDefault', dayFilterFrom);
    sessionStorage.setItem('dayFilterToDefault', dayFilterTo);

    if (
      dayFilter === DAY_FILTER_VALUES.ALL &&
      isValidDateObject(dayFilterFrom) &&
      isValidDateObject(dayFilterTo) &&
      (dayFilterFrom < dayFilterFromDefault || dayFilterTo > dayFilterToDefault)
    )
      dispatch(
        fetchNextActionsList({
          from: getPlannedDate(dayFilterFrom),
          to: getPlannedDate(dayFilterTo),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayFilterFrom, dayFilterTo]);

  useEffect(() => {
    setFilteredNextActions(
      filterPlannedActions(
        plannedNextActions,
        actionFilter,
        dayFilter,
        dayFilterFrom,
        dayFilterTo
      )
    );
  }, [plannedNextActions, actionFilter, dayFilter, dayFilterFrom, dayFilterTo]);

  return (
    <>
      <DayFilter onChange={(filter) => setDayFilter(filter)} />
      <ActionBar>
        <IconFilters
          items={{
            [APPOINTMENT]: false,
            [DROP_IN]: false,
            [PHONE_CALL]: false,
            [OTHER]: false,
          }}
          onChange={(filter) => setActionFilter(filter)}
        />
        {dayFilter === DAY_FILTER_VALUES.ALL && (
          <Container>
            <DateRange
              dateFrom={dayFilterFrom}
              dateTo={dayFilterTo}
              setDateFrom={setDayFilterFrom}
              setDateTo={setDayFilterTo}
            />
          </Container>
        )}
        <CustomersData clientData={clientData} clients={clients} />
      </ActionBar>
      {hasNextActions ? (
        <CustomersList
          plannedForToday
          clientData={clientData && clientData.data}
          data-testid="planned-for-today-clients"
          items={filteredNextActions}
          path="/customers"
          setContactsDialogData={setContactsDialogData}
          setShowContactsDialog={setShowContactsDialog}
          online={online}
          onLinkClick={(clientId) => {
            dispatch(fetchProductsInCategories({ clientId }));
            dispatch(fetchClientDataById({ clientId }));
          }}
          tileLines={tileLines}
          showDepartment={showDepartment}
          setGeneralPhoneNumber={setGeneralPhoneNumber}
          showCreateCallReportDialog={showCreateCallReportDialog}
          setShowCreateCallReportDialog={setShowCreateCallReportDialog}
          callReportSelectedContact={callReportSelectedContact}
          callReportClientId={callReportClientId}
          setCallReportClientId={setCallReportClientId}
          filterBy={dayFilter}
        />
      ) : (
        <NoPlannedClients
          description={
            dayFilter === DAY_FILTER_VALUES.TODAY
              ? t('no_planned_clients')
              : t('filter_clients_empty')
          }
          search={t('no_planned_clients_search')}
        />
      )}
      {hasNextActions && !hasFilteredNextActions && (
        <NoFilteredClients description={t('filter_clients_empty')} />
      )}
    </>
  );
};

NextActions.propTypes = {
  setContactsDialogData: PropTypes.func,
  setShowContactsDialog: PropTypes.func,
  setGeneralPhoneNumber: PropTypes.func,
  setShowCreateCallReportDialog: PropTypes.func,
  showCreateCallReportDialog: PropTypes.bool,
  callReportSelectedContact: PropTypes.string,
  callReportClientId: PropTypes.string,
  setCallReportClientId: PropTypes.func,
};

export default NextActions;
