import { useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { getFilterIcon } from '../../helpers/application';

const Container = styled.div`
  margin: 8px 0;
  display: inline-flex;

  .MuiCheckbox-root {
    border-radius: 8px;
    border: 2px solid #ffffff;
    color: #999999;
    margin-right: 8px;
  }

  .Mui-checked.MuiCheckbox-root {
    border: 2px solid #f16944;
    box-shadow: 0 2px 8px rgba(228, 35, 19, 0.2);
  }

  .Mui-checked .MuiSvgIcon-root {
    color: #e42313;
  }
`;

const IconFilters = ({ items, onChange }) => {
  const [filterState, setFilterState] = useState(items);

  const handleChange = (event) => {
    const newActionFilterState = {
      ...filterState,
      [event.target.name]: event.target.checked,
    };
    setFilterState(newActionFilterState);
    onChange && onChange(newActionFilterState);
  };

  return (
    <Container
      data-testid="action-filters"
      onClick={(e) => e.stopPropagation()}
    >
      {Object.keys(items).map((item) => {
        return (
          <Checkbox
            key={item}
            name={item}
            icon={getFilterIcon(item)}
            checkedIcon={getFilterIcon(item)}
            onChange={handleChange}
            checked={filterState[item]}
          />
        );
      })}
    </Container>
  );
};

IconFilters.propTypes = {
  items: PropTypes.objectOf(PropTypes.bool),
  onChange: PropTypes.func,
};

export default IconFilters;
