import { useState, useEffect, useRef } from 'react';
import { getFilteredProductsData } from '../helpers/products';

function useProductsSearch(productsData, title) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchVisible, searchInputRef]);

  const filteredProductsData = getFilteredProductsData(
    productsData,
    searchTerm
  );

  return {
    searchVisible,
    setSearchVisible,
    searchTerm,
    setSearchTerm,
    searchInputRef,
    title: searchVisible ? null : title,
    filteredProductsData,
  };
}

export default useProductsSearch;
