import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import VideoIcon from '../Icons/Video';
import { THUMB_RETRY_TIMEOUT } from '../../constants/video';

export const RectVideo = styled.div`
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
`;

export const SquareVideo = styled.div`
  float: left;
  width: 30%;
  margin: 1.66%;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    background: #e5e5e5;
  }
`;

const Image = styled.img`
  position: absolute;
  top: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  bottom: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  left: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  right: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  width: ${({ $withGap }) => ($withGap ? 'calc(100% - 2px)' : '100%')};
  height: ${({ $withGap }) => ($withGap ? 'calc(100% - 2px)' : '100%')};
  object-fit: cover;
  object-position: center;

  &.hidden {
    opacity: 0;
  }
`;

const Icon = styled(VideoIcon)`
  z-index: -1;
  opacity: 0;
`;

const Background = styled.div`
  background: #e5e5e5;
  position: absolute;
  top: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  bottom: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  left: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  right: ${({ $withGap }) => ($withGap ? '1px' : '0')};
  width: ${({ $withGap }) => ($withGap ? 'calc(100% - 2px)' : '100%')};
  height: ${({ $withGap }) => ($withGap ? 'calc(100% - 2px)' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-colorPrimary {
    color: #ffffff;
  }
`;

const VideoThumbnail = ({ onClick, thumbnail, withGap }) => {
  const [thumbLoaded, setThumbLoaded] = useState(false);
  const [thumbFailed, setThumbFailed] = useState(false);
  const [retryNumber, setRetryNumber] = useState(0);

  const handleLoadingThumbnailSuccess = () => {
    setThumbLoaded(true);
    setThumbFailed(false);
  };

  const handleLoadingThumbnailError = () => {
    setThumbLoaded(false);
    setThumbFailed(true);
  };

  useEffect(() => {
    let timeout;
    if (thumbFailed) {
      timeout = setTimeout(() => {
        setRetryNumber(retryNumber + 1);
      }, THUMB_RETRY_TIMEOUT);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [retryNumber, setRetryNumber, thumbFailed]);

  return (
    <>
      <Background $withGap={withGap}>
        {!thumbLoaded && <CircularProgress size={26} thickness={6} />}
      </Background>
      <Image
        src={`${thumbnail}?${retryNumber}`}
        onClick={onClick}
        onLoad={() => handleLoadingThumbnailSuccess()}
        onError={() => handleLoadingThumbnailError()}
        className={`box ${thumbLoaded ? '' : ' hidden'}`}
        $withGap={withGap}
      />
      <Icon />
    </>
  );
};

VideoThumbnail.propTypes = {
  onClick: PropTypes.func,
  withGap: PropTypes.bool,
  thumbnail: PropTypes.string,
};

export default VideoThumbnail;
