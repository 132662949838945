import SvgIcon from '@material-ui/core/SvgIcon';

function ClassificationA(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <rect id="path-1" x="15" y="305" width="345" height="125" rx="8" />
        <filter
          x="-3.8%"
          y="-8.8%"
          width="107.5%"
          height="120.8%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.0.1.select_customer-icons"
          transform="translate(-320.000000, -329.000000)"
        >
          <g
            id="Icon-/-New-/-My-interflon"
            transform="translate(320.000000, 329.000000)"
            fill="#999999"
          >
            <path
              d="M18,2 C20.209139,2 22,3.790861 22,6 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L18,2 Z M12.8183594,7.046875 L10.9179688,7.046875 L7.21289062,17 L9.39355469,17 L10.0771484,14.9492188 L13.6728516,14.9492188 L14.3632812,17 L16.5439453,17 L12.8183594,7.046875 Z M11.8681641,9.5625 L13.1191406,13.2880859 L10.6308594,13.2880859 L11.8681641,9.5625 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ClassificationA;
