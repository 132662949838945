import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider } from 'frontend-components';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0 8px;
`;

const HeaderText = styled.p`
  height: 16px;
  color: #666666;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;

const ProductListHeader = ({ title, price }) => (
  <>
    <HeaderContainer>
      <HeaderText>{title}</HeaderText>
      <HeaderText>{price}</HeaderText>
    </HeaderContainer>
    <Divider />
  </>
);

ProductListHeader.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
};

export default ProductListHeader;
