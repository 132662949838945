export const LANGUAGE_DETECTOR_NAME = 'qacLanguageDetector';

const FALLBACK_LANGUAGE = 'en';

const CUSTOM_LANGUAGE_CODE = new Map([
  ['cs', 'cz'],
  ['id', 'ri'],
  ['da', 'dk'],
  ['nb', 'no'],
  ['el', 'gr'],
  ['sv', 'se'],
  ['ko', 'kr'],
  ['vi', 'vn'],
  ['uk', 'ua'],
  ['de-AT', 'de'],
  ['de-LI', 'de'],
  ['de-DE', 'de'],
  ['de-CH', 'de'],
  ['en-GB', 'en'],
  ['en-US', 'en'],
  ['en-CA', 'en'],
  ['en-AU', 'en'],
  ['en-IN', 'en'],
  ['en-NZ', 'en'],
  ['en-ZA', 'en'],
  ['fr-CA', 'fr'],
  ['fr-FR', 'fr'],
  ['it-CH', 'it'],
  ['it-IT', 'it'],
  ['es-CO', 'es'],
  ['es-419', 'es'],
  ['es-AR', 'es'],
  ['es-CL', 'es'],
  ['es-ES', 'es'],
  ['es-HN', 'es'],
  ['pt-BR', 'bz'],
  ['pt-PT', 'pt'],
  ['zh-CN', 'cn'],
  ['zh-TW', 'cn'],
]);

export default {
  name: LANGUAGE_DETECTOR_NAME,
  lookup() {
    const { language } = navigator;
    if (CUSTOM_LANGUAGE_CODE.has(language)) {
      return CUSTOM_LANGUAGE_CODE.get(language);
    }
    return language || FALLBACK_LANGUAGE;
  },
};
