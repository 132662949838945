import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSicCodes } from '../../services/siccodes.service';

export const formatSicGroupName = (name) =>
  name[0] + name.slice(1).toLowerCase();

export const fetchSicCodes = createAsyncThunk('sicCodes/fetch', async () => {
  return getSicCodes();
});

const sicCodesSlice = createSlice({
  name: 'sicCodes',
  initialState: {
    sicCodesData: [],
    sicByCode: {},
    loadingStatus: '',
  },
  reducers: {},
  extraReducers: {
    [fetchSicCodes.pending]: (state) => {
      state.sicCodesData = [];
      state.loadingStatus = 'fetching';
    },
    [fetchSicCodes.fulfilled]: (state, action) => {
      state.sicCodesData = action.payload;
      state.sicByCode = action.payload
        .map(({ code, name, children }) => [
          { code, name: formatSicGroupName(name) },
          ...children,
        ])
        .flat()
        .reduce((acc, curr) => {
          const { code } = curr;
          acc[code] = curr;
          return acc;
        }, {});
      state.loadingStatus = 'fetched';
    },
    [fetchSicCodes.rejected]: (state) => {
      state.loadingStatus = 'not-fetched';
    },
  },
});

export default sicCodesSlice.reducer;
