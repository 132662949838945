import uniqBy from 'lodash/uniqBy';
import { REPORT_TYPES } from '../constants/noteTypes';
import { MIN_SEARCH_TERM_LENGTH } from '../constants/products';

const getNestedProducts = (categories) =>
  categories.map((category) => {
    if (category.subCategories?.length) {
      return category.subCategories
        .map((subCategory) => subCategory.products)
        .flat();
    }
    return category.products;
  });

export const getAllProductsByGroupCode = (categories) => {
  const products = getNestedProducts(categories);

  return products.flat().reduce((acc, curr) => {
    acc.set(curr.code, curr);
    return acc;
  }, new Map());
};

export const getVariantsByCode = (categories) => {
  const products = getNestedProducts(categories);

  return products
    .flat()
    .map(({ variants }) => variants)
    .flat()
    .filter(Boolean)
    .reduce((acc, curr) => {
      acc.set(curr.code, curr);
      return acc;
    }, new Map());
};

export const createSuggestedProduct = (allProducts, noteId, code) => {
  return {
    id: code,
    name: allProducts.get(code)?.name,
    presentedAt: '',
    reportId: noteId,
    reportType: REPORT_TYPES.PHONE_CALL,
  };
};

export const getSuggestedProductsSectionData = (data, noteId, allProducts) => {
  if (data && data.suggestedProducts && data.suggestedProducts.length > 0) {
    const suggestedCodes = data.suggestedProducts.map((p) => p.code);
    return suggestedCodes.map((code) =>
      createSuggestedProduct(allProducts, noteId, code)
    );
  }
  return null;
};

export const getDemoedProductsSectionData = (clientData, data) => {
  const clientDemoedProducts = clientData.demoProducts.data;
  if (
    data &&
    data.demoProducts.length > 0 &&
    clientDemoedProducts &&
    clientDemoedProducts.length > 0
  ) {
    const demoProductCodes = data.demoProducts.map((p) => p.code);
    const demoedProducts = uniqBy(
      clientDemoedProducts.filter((product) =>
        demoProductCodes.includes(product.id)
      ),
      (p) => p.id
    );
    return demoedProducts;
  }
  return null;
};

export const excludeMiscellaneous = (categories) =>
  categories.filter(({ isMisc }) => !isMisc);

export const filterName = (name, term) =>
  name.toUpperCase().includes(term.toUpperCase());

const filterProducts = (categoryName, products, term) =>
  filterName(categoryName, term)
    ? products
    : products.filter(({ name, code }) => {
        return code.toString().includes(term) || filterName(name, term);
      });

export function getFilteredProductsData(data, term) {
  if (term.length < MIN_SEARCH_TERM_LENGTH) {
    return data;
  }
  const filteredCategories = data.map((category) => {
    if (category.subCategories?.length) {
      return {
        ...category,
        subCategories: filterName(category.categoryName, term)
          ? category.subCategories
          : category.subCategories.map((subCategory) => {
              return {
                ...subCategory,
                products: filterProducts(
                  subCategory.subCategoryName,
                  subCategory.products,
                  term
                ),
              };
            }),
      };
    }

    return {
      ...category,
      products: filterProducts(category.categoryName, category.products, term),
    };
  });

  return filteredCategories.filter(
    (category) =>
      category.products?.length > 0 ||
      category.subCategories?.filter(
        (subCategory) => subCategory.products.length
      ).length > 0
  );
}

export const isSearchTermLongEnough = (searchTerm) =>
  searchTerm.length >= MIN_SEARCH_TERM_LENGTH;
