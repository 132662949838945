import * as Yup from 'yup';

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const websiteRegExp =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/;

export const phoneValidation = (t) =>
  Yup.string()
    .matches(phoneRegExp, t('contact_phone_validation'))
    .min(9, t('contact_phone_too_short'))
    .max(18, t('contact_phone_too_long'))
    .nullable(true);

export const emailValidation = (t) =>
  Yup.string().matches(emailRegExp, t('contact_email_format')).nullable(true);

export const websiteValidation = (t) =>
  Yup.string().matches(websiteRegExp, t('website_format'));
