import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Products from './Products';
import withClientData from '../../../helpers/withClientData';
import {
  addProductToVisit,
  removeDemoedProduct,
  addDemoedProductsPerNote,
} from '../../../store/actions/demoProductsActions';
import { excludeMiscellaneous } from '../../../helpers/products';
import { clientDataShape } from '../../../helpers/clientDataPropTypes';

const VisitProductsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, noteId } = useParams();

  const productsFromStore = clientData.visits.data.find(
    (visit) => visit.id === noteId
  ).demoProducts;

  const productsData = useSelector((state) =>
    excludeMiscellaneous(state.products[clientId].data)
  );

  return (
    <Products
      title={t('demo_products_title')}
      productsFromStore={productsFromStore}
      productsData={productsData}
      addProduct={addProductToVisit}
      removeProduct={removeDemoedProduct}
      addProductsPerNote={addDemoedProductsPerNote}
    />
  );
};

VisitProductsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(VisitProductsView);
