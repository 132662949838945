import styled from 'styled-components';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 15px;

  [theme='primary'].MuiButton-root.Mui-disabled {
    display: flex;
  }
`;

export const TotalContainer = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AmountTitle = styled.p`
  margin: 0;
  color: #666666;
  font-size: 12px;
  line-height: 16px;
`;

export const AmountValue = styled.p`
  margin: 4px 0 0;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
`;
