import Search from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

export const SearchContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

export const SearchInput = styled(InputBase)`
  width: 100%;

  input {
    color: #ffffff;
    padding: 15px 0;
  }
`;

export const SearchIcon = styled(Search)`
  color: #ffffff;
  font-size: 24px;
`;

export const SearchWrapper = styled.div`
  margin: 20px 0;
`;
