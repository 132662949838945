import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PhoneIcon from '@material-ui/icons/Phone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';

const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 35px;
`;

const ButtonMenu = ({ anchor, menuOptions, onMenuSelect, onClose }) => {
  const MenuIcons = {
    DriveEtaIcon,
    PhoneIcon,
    InsertDriveFileIcon,
  };

  return (
    <Menu
      id="customized-menu"
      anchorEl={anchor}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuOptions.map(({ value, label, icon }) => {
        const IconComponent = MenuIcons[icon];

        return (
          <MenuItem
            key={value}
            onClick={(e) => {
              e.stopPropagation();
              onMenuSelect(value);
            }}
            data-testid={`menu-item-${value}`}
          >
            <ListItemIconStyled>
              <IconComponent fontSize="small" />
            </ListItemIconStyled>
            <ListItemText primary={label} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

ButtonMenu.propTypes = {
  anchor: PropTypes.instanceOf(Object),
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  onMenuSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default ButtonMenu;
