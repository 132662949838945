import SvgIcon from '@material-ui/core/SvgIcon';

function StatusOffline(props) {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx="24" cy="24" r="23" stroke="#FF9D29" strokeWidth="2" />
      <g>
        <path
          d="M34.99 21C31.15 17.16 25.8 15.76 20.84 16.78L23.36 19.3C26.83 19.13 30.35 20.35 32.99 23L34.99 21ZM30.99 25C29.7 23.71 28.15 22.87 26.5 22.44L30.03 25.97L30.99 25ZM14 15.05L17.07 18.1C15.6 18.82 14.22 19.78 13 21L14.99 23C16.23 21.76 17.66 20.84 19.19 20.23L21.43 22.47C19.81 22.89 18.27 23.73 17 25V25.01L18.99 27C20.35 25.64 22.13 24.96 23.91 24.94L30.98 32L32.25 30.74L15.29 13.79L14 15.05ZM21 29L24 32L27 29C25.35 27.34 22.66 27.34 21 29Z"
          fill="#FF9D29"
        />
      </g>
    </SvgIcon>
  );
}

export default StatusOffline;
