import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'frontend-components';

import Dialog from '../Dialog';
import DialogHeader from '../DialogHeader';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 0;
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
`;

const OrderConfirmationDialog = ({
  open,
  setOpen,
  'data-testid': testId,
  finishAndSendAction,
  finishWithoutSendingAction,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      data-testid={testId}
      fullWidth
    >
      <DialogHeader
        title={t('confirmation')}
        handleClose={() => setOpen(false)}
      />
      <ButtonsContainer>
        {!!finishAndSendAction && (
          <StyledButton
            autoFocus
            aria-label="finish-and-send"
            onClick={finishAndSendAction}
            theme="primary"
            fullWidth
          >
            {t('orderFinishAndSend')}
          </StyledButton>
        )}
        <StyledButton
          theme={finishAndSendAction ? 'secondary' : 'primary'}
          aria-label="delete"
          onClick={finishWithoutSendingAction}
          data-testid="finish-without-sending"
          fullWidth
        >
          {t('orderFinishWithoutSending')}
        </StyledButton>
        <StyledButton
          theme="secondary"
          aria-label="delete"
          onClick={() => setOpen(false)}
          data-testid="cancel"
          fullWidth
        >
          {t('orderConfirmDialogCancel')}
        </StyledButton>
      </ButtonsContainer>
    </Dialog>
  );
};

OrderConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  'data-testid': PropTypes.string,
  finishAndSendAction: PropTypes.func,
  finishWithoutSendingAction: PropTypes.func,
};

export default OrderConfirmationDialog;
