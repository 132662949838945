import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import ClearIcon from '@material-ui/icons/Clear';
import debounce from 'lodash/debounce';
import { CancelToken } from 'axios';
import AppBar from '../../components/AppBar/AppBar.connected';
import CustomersList from '../SelectCustomerView/CustomersList';
import ListDialog from '../../components/Dialog/ListDialog/ListDialog';
import ContactsDialogList from '../../components/Lists/ContactsDialogList';
import { fetchClientDataById } from '../../store/actions/clientDataActions';
import { getClientsSearch } from '../../services/clients.service';
import { setSearchedClients } from '../../store/reducers/clients';
import { fetchProductsInCategories } from '../../store/reducers/products';
import Select from '../../components/Select/Select';
import MenuItem from '../../components/Select/MenuItem';
import { prepareClientOwnerOptions } from '../../helpers/search';
import NothingFound from '../../components/NothingFound/NothingFound';
import { filterSearchResultsOffline } from '../../helpers/clients';
import {
  SearchIcon,
  Input,
  SearchContainer,
  DarkDivider,
  Filters,
} from './SearchCustomer.styles';
import useCreateCallReportDialog from '../../hooks/useCreateCallReportDialog';
import { DEBOUNCE_TIME, MIN_SEARCH_LENGTH } from '../../constants/search';

const CANCEL_SEARCH_MESSAGE = 'search cancelled';

let cancelTokenSource;

const debouncedSearch = debounce((query, dispatch, owner, setNothingFound) => {
  cancelTokenSource = CancelToken.source();

  getClientsSearch(encodeURIComponent(query), cancelTokenSource.token, owner)
    .then((response) => {
      const clients = response.data;
      dispatch(setSearchedClients(clients));
      setNothingFound(clients.length === 0);
    })
    .catch(() => {
      dispatch(setSearchedClients([]));
    });
}, DEBOUNCE_TIME);

const SearchCustomerView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);

  const { allowed, data: loggedTAData } = useSelector((state) => state.user);
  const { showDepartment } = useSelector((state) => state.user.data.miaOptions);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOwner, setSearchOwner] = useState(loggedTAData.id);
  const [searchOwnerOptions, setSearchOwnerOptions] = useState([]);
  const [showContactsDialog, setShowContactsDialog] = useState(false);
  const [contactsDialogData, setContactsDialogData] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [generalPhoneNumber, setGeneralPhoneNumber] = useState('');

  const { clients, searchedClients } = useSelector((state) => state.clients);
  const { online } = useSelector((state) => state.offline);

  const search = useCallback(() => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel(CANCEL_SEARCH_MESSAGE);
    }

    if (searchTerm.length >= MIN_SEARCH_LENGTH) {
      debouncedSearch(searchTerm, dispatch, searchOwner, setNothingFound);
    } else {
      dispatch(setSearchedClients([]));
      setNothingFound(false);
    }
  }, [searchTerm, dispatch, searchOwner]);

  useEffect(() => {
    online && search();
  }, [search, online]);

  useEffect(() => {
    setSearchOwnerOptions(prepareClientOwnerOptions(allowed, loggedTAData));
  }, [allowed, loggedTAData]);

  const filteredClients = online
    ? searchedClients
    : filterSearchResultsOffline(
        clients,
        searchTerm,
        searchOwner,
        loggedTAData.id
      );
  const nothingFoundOffline = !online && filteredClients.length === 0;

  const {
    showCreateCallReportDialog,
    setShowCreateCallReportDialog,
    callReportSelectedContact,
    setCallReportSelectedContact,
    callReportClientId,
    setCallReportClientId,
  } = useCreateCallReportDialog();

  const { clientData } = useSelector((state) => state);

  return (
    <Container maxWidth="sm">
      <AppBar
        displayBackButton
        content={
          <SearchContainer maxWidth="sm">
            <Input
              autoFocus
              placeholder={t('client_search_placeholder')}
              onChange={(event) => {
                dispatch(setSearchedClients([]));
                setSearchedClients(false);
                setSearchTerm(event.target.value);
              }}
              inputProps={{ 'aria-label': 'search' }}
              inputRef={searchInputRef}
              value={searchTerm}
            />
          </SearchContainer>
        }
        aside={
          searchTerm ? (
            <IconButton
              onClick={() => {
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                searchInputRef.current.focus();
                online && searchTerm && search();
              }}
            >
              <SearchIcon />
            </IconButton>
          )
        }
      />
      <DarkDivider />
      <Filters>
        {searchOwnerOptions.length && (
          <Select
            id="owner"
            data-testid="owner"
            label={t('searchClientOwner')}
            initialValue={searchOwner}
            value={searchOwner}
            fullWidth
            PaperProps={{
              style: {
                maxHeight: 'calc(100% - 160px)',
              },
            }}
            onChange={(owner) => {
              setSearchOwner(owner);
            }}
          >
            {searchOwnerOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
        )}
      </Filters>
      <CustomersList
        data-testid="searched-clients"
        clientData={clientData && clientData.data}
        items={filteredClients}
        path="/customers"
        setContactsDialogData={setContactsDialogData}
        setShowContactsDialog={setShowContactsDialog}
        onLinkClick={(clientId) => {
          dispatch(fetchProductsInCategories({ clientId }));
          dispatch(fetchClientDataById({ clientId }));
        }}
        titleLines={2}
        showDepartment={showDepartment}
        showCustomerNumber
        setGeneralPhoneNumber={setGeneralPhoneNumber}
        showCreateCallReportDialog={showCreateCallReportDialog}
        setShowCreateCallReportDialog={setShowCreateCallReportDialog}
        callReportSelectedContact={callReportSelectedContact}
        online={online}
        callReportClientId={callReportClientId}
        setCallReportClientId={setCallReportClientId}
      />
      {(nothingFound || nothingFoundOffline) && (
        <NothingFound
          reason={nothingFoundOffline ? t('nothingFoundOffline') : ''}
        />
      )}
      <ListDialog
        titleIcon={<PeopleIcon />}
        title={t('app_contacts')}
        open={showContactsDialog}
        setOpen={setShowContactsDialog}
        data-testid="contacts-dialog"
      >
        <ContactsDialogList
          contactsDialogData={contactsDialogData}
          generalPhoneNumber={generalPhoneNumber}
          setShowCreateCallReportDialog={setShowCreateCallReportDialog}
          setShowContactsDialog={setShowContactsDialog}
          setCallReportSelectedContact={setCallReportSelectedContact}
        />
      </ListDialog>
    </Container>
  );
};

export default SearchCustomerView;
