export function removeOpenActionByNote(note, clientData) {
  if (note.nextAction) {
    const actionIndex = clientData.openActions.data.findIndex(
      (action) => note.nextAction.id === action.id
    );
    if (actionIndex !== -1) {
      clientData.openActions.data.splice(actionIndex, 1);
      clientData.openActions.total = clientData.openActions.data.length;
    }
  }
}

export const deleteNote = (notes, noteId, clientData) => {
  const note = notes.data.find(({ id }) => id === noteId);
  if (note) {
    notes.data = notes.data.filter((note) => note.id !== noteId);
    notes.total = notes.data.length;
    removeOpenActionByNote(note, clientData);
  }
};

export const updateNote = (notes, noteId, values) => {
  const index = notes.data.findIndex((note) => note.id === noteId);
  if (index !== -1) {
    notes.data[index] = {
      id: noteId,
      ...values,
      edited: true,
      owned: notes.data[index].owned,
    };
  }
};

export const addNextActionToNote = (
  notes,
  noteId,
  nextAction,
  notMarkNoteAsEdited
) => {
  const note = notes.data.find(
    (note) => note.id === noteId || note.id === nextAction.id
  );
  if (note) {
    note.nextAction = nextAction;
    if (!notMarkNoteAsEdited) {
      note.edited = true;
    }
  }
};

export const addDemoProductsToNote = (notes, noteId, demoedProducts) => {
  const note = notes.data.find((note) => note.id === noteId);
  if (note) {
    note.demoProducts = demoedProducts.map((product) => {
      return {
        code: product.code,
      };
    });
    note.edited = true;
  }
};
