import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { cancelTokensMap } from '../../helpers/cancelTokens';
import { setPhotoUploadingCancelled } from '../../store/reducers/photosFromDevice';
import { clearPhotoInApplication } from '../../store/reducers/clientData';
import UploadStatusIcon from './UploadStatusIcon';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  margin-bottom: 18px;
`;

const FileWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 31.3333%;
  padding-top: 31.3333%;
  height: auto;
  margin: 1%;
  overflow: hidden;
`;

const StyledImg = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #cccccc;
  width: auto;
  height: 100%;
`;

const StyledVideo = styled.video`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #cccccc;
  width: 100%;
  height: 100%;
`;

const StyledCancel = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  height: 32px;
  width: 32px;
  background: #fff;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  fill: #999;
`;

const StyledUploadStatusIcon = styled(UploadStatusIcon)`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
`;

const StyledTextArea = styled.textarea`
  width: 66%;
  padding: 8px;
  color: #000000;
  background: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: 'Noto Sans', sans-serif;
`;

const FilesItem = ({ file, onDelete, withDescription, onChange, disabled }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();
  const [statusInfo, setStatusInfo] = useState({});

  const { upload: photosUpload } = useSelector(
    (state) => state.photosFromDevice
  );
  const { upload: videoUpload } = useSelector((state) => state.video);

  const handlePhotoCancel = (id) => {
    if (cancelTokensMap.has(id)) {
      cancelTokensMap.get(id).cancel('');
      cancelTokensMap.delete(id);
      dispatch(setPhotoUploadingCancelled({ clientId, id }));

      if (applicationId) {
        dispatch(
          clearPhotoInApplication({
            photoId: id,
            clientId,
            applicationId,
          })
        );
      }
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    handlePhotoCancel(file?.data?.id);
    onDelete(file);
  };

  const handleChange = (e) => {
    onChange(file, e.target.value);
  };

  useEffect(() => {
    if (!photosUpload[file?.data?.id] && !videoUpload[file?.data?.id]) return;

    setStatusInfo(photosUpload[file?.data?.id] || videoUpload[file?.data?.id]);
  }, [photosUpload, videoUpload, file?.data?.id]);

  const wrapper = (
    <FileWrapper key={`file-${file?.data?.id}`}>
      <StyledUploadStatusIcon status={statusInfo} id={file?.data?.id} />
      {file?.data.mediaType === 'video' ? (
        <StyledVideo
          key={file?.data?.id}
          id={`video-${file?.data?.fileName}`}
          src={file?.data?.link}
          controls
          controlsList="nodownload"
        />
      ) : (
        <StyledImg
          key={file?.data?.id}
          src={file?.data?.link}
          alt="captured photo"
        />
      )}
      {!statusInfo?.uploaded && (
        <StyledCancel onClick={(e) => handleCancel(e)}>
          <StyledDeleteIcon />
        </StyledCancel>
      )}
    </FileWrapper>
  );

  if (withDescription) {
    return (
      <Container>
        {wrapper}
        <StyledTextArea
          placeholder={t('type_description')}
          onChange={handleChange}
          disabled={disabled}
          maxLength={250}
        />
      </Container>
    );
  }
  return wrapper;
};

FilesItem.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onDelete: PropTypes.func,
  withDescription: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FilesItem;
