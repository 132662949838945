import { EMPTY, OPTION_ALL_KEY } from '../constants/search';

export function prepareClientOwnerOptions(allowed, loggedTAData) {
  return [
    { value: EMPTY, label: OPTION_ALL_KEY },
    ...allowed
      .map(({ id, name }) => {
        return { value: id, label: `${name} (${id})` };
      })
      .sort((a, b) => {
        if (a.value === loggedTAData.id) {
          return -1;
        }
        if (b.value === loggedTAData.id) {
          return 1;
        }
        return a.label - b.label;
      }),
  ];
}

export const searchPhaseInObject = (phase, obj) => {
  return Object.entries(obj).filter(([, value]) => {
    return value.toLowerCase().includes(phase.trim().toLowerCase());
  });
};
