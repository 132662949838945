import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import localForage from 'localforage';
import rootReducer from './reducers';
import ApiService from '../services/api.service';
import reduxOfflineThunkMiddleware from './middlewares/reduxOfflineThunkMiddleware';

const effect = (effect) => ApiService(effect);
const discard = (error) => {
  const { request, response } = error;
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  if (response.status === 401) return false;

  return response.status >= 400 && response.status < 500;
};

const configureStoreWithPersistCallback = (persistCallback) =>
  configureStore({
    reducer: rootReducer,
    enhancers: (defaultEnhancers) => [
      offline({
        ...offlineConfig,
        persistOptions: {
          storage: localForage,
        },
        persistCallback,
        effect,
        discard,
      }),
      ...defaultEnhancers,
    ],
    middleware: getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(reduxOfflineThunkMiddleware),
  });

export default configureStoreWithPersistCallback;
