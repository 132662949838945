import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { Button, useBodyBgcChange } from 'frontend-components';

import ErrorImage from '../../components/Icons/ErrorImage';
import AppBar from '../../components/AppBar/AppBar.connected';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  white-space: pre-line; //break line if there is '/n' inside translation

  & .MuiButtonBase-root {
    margin-top: 24px;
    padding: 14px 42px;
  }
`;

const ErrorTitle = styled.h2`
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin: 24px 0 0;
  font-weight: normal;
  color: black;
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 12px 0 0;
  color: black;
`;

const ErrorFallbackView = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();
  useBodyBgcChange();

  return (
    <Container maxWidth="sm">
      <AppBar />
      <ContentContainer>
        <ErrorImage />
        <ErrorTitle>{t('error_page_title')}</ErrorTitle>
        <ErrorMessage>{t('error_page_info')}</ErrorMessage>
        <Button
          onClick={() => {
            resetErrorBoundary();
          }}
          theme="primary"
        >
          {t('error_page_refresh')}
        </Button>
      </ContentContainer>
    </Container>
  );
};

ErrorFallbackView.propTypes = {
  error: PropTypes.shape({}),
  resetErrorBoundary: PropTypes.func,
};

export default ErrorFallbackView;
