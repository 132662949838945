import styled from 'styled-components';
import { Divider } from 'frontend-components';
import PropTypes from 'prop-types';
import { formatDateWithMonthName } from 'frontend-components/lib/helpers';

import ListItem from '../List/ListItem';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;
  column-gap: 8px;
  font-size: 14px;
  line-height: 20px;

  .center {
    display: flex;
    align-items: center;
  }
`;

const ProductListItem = styled(ListItem)`
  padding: 16px 0;
`;

const ProductName = styled.div`
  color: #000000;
  margin-bottom: 4px;
`;

const ProductDetails = styled.div`
  color: #666666;
  min-height: 20px;
`;

const ProductQuantity = styled.div`
  display: flex;
  align-items: center;
  color: #666666;
  justify-content: flex-end;
`;

const ProductPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000000;
  white-space: nowrap;
`;

const shouldDisplayComma = (packageType) =>
  packageType ? `, ${packageType}` : '';

const formatPackageDetails = ({ packageType, packageCapacity }) => {
  if (!packageType) {
    return '';
  }

  return `${packageCapacity.trim()}${shouldDisplayComma(packageType)}`;
};

const Product = ({
  name,
  packageDetails,
  quantity,
  currency,
  price,
  orderedAt,
}) => {
  return (
    <>
      <ProductListItem data-testid="product-item">
        <Container>
          <div>
            <ProductName>
              {name || formatDateWithMonthName(orderedAt).toUpperCase()}
            </ProductName>
            <ProductDetails>
              {formatPackageDetails(packageDetails)}
            </ProductDetails>
          </div>
          <ProductQuantity className="center">{`${quantity}x`}</ProductQuantity>
          <ProductPrice>
            {currency} {price}
          </ProductPrice>
        </Container>
      </ProductListItem>
      <Divider />
    </>
  );
};

Product.propTypes = {
  name: PropTypes.string,
  packageDetails: PropTypes.shape({
    packageUnit: PropTypes.number,
    packageType: PropTypes.string,
    packageCapacity: PropTypes.string,
  }),
  quantity: PropTypes.number,
  currency: PropTypes.string,
  price: PropTypes.string,
  orderedAt: PropTypes.string,
};

export default Product;
