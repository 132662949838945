import SvgIcon from '@material-ui/core/SvgIcon';

function DiscountIcon(props) {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-336-64H39v812h-375z" />
        <path d="M-336-64H39V40h-375z" fill="#E42313" />
        <path
          d="M11 2c.489 0 .938.174 1.285.465l.125.115 9 9c.36.36.59.87.59 1.42 0 .489-.174.938-.472 1.285l-.118.125-7 7c-.36.37-.86.59-1.41.59a1.99 1.99 0 01-1.285-.465l-.125-.115-9-9a1.996 1.996 0 01-.582-1.239L2 11V4c0-1.05.82-1.918 1.851-1.994L4 2h7zm3.567 10.509c-.638 0-1.157.188-1.558.564-.357.334-.555.763-.594 1.286l-.008.2v.506c0 .625.198 1.126.595 1.504.396.379.923.568 1.58.568.65 0 1.173-.188 1.564-.564.349-.334.542-.761.581-1.281l.007-.2v-.491c0-.652-.199-1.164-.598-1.535-.399-.372-.921-.557-1.569-.557zm-.464-4.553l-4.86 7.78.963.519 4.86-7.78-.963-.519zm.464 5.694c.256 0 .46.084.616.25a.88.88 0 01.226.527l.006.133v.52c0 .614-.278.922-.834.922a.802.802 0 01-.615-.267.9.9 0 01-.24-.52l-.006-.122v-.513c0-.287.077-.514.232-.68a.801.801 0 01.615-.25zM9.803 6.903c-.643 0-1.162.19-1.559.571-.352.338-.548.768-.587 1.288l-.008.199v.485c0 .638.2 1.145.599 1.521.398.376.921.564 1.568.564.643 0 1.163-.187 1.559-.56.352-.332.548-.76.587-1.283l.008-.2v-.493c0-.642-.199-1.152-.595-1.528-.396-.376-.92-.564-1.572-.564zm0 1.142c.264 0 .471.084.622.253.125.14.198.317.22.53l.005.133v.506c0 .282-.075.508-.225.677-.15.168-.353.252-.609.252a.812.812 0 01-.618-.249.873.873 0 01-.23-.527l-.006-.133v-.499c0-.296.077-.527.232-.694a.793.793 0 01.609-.25zM5.5 4C4.67 4 4 4.67 4 5.5S4.67 7 5.5 7 7 6.33 7 5.5 6.33 4 5.5 4z"
          fill="#FFF"
        />
      </g>
    </SvgIcon>
  );
}

export default DiscountIcon;
