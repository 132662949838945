import styled from 'styled-components';
import PropTypes from 'prop-types';
import { packagingDataProps } from '../../../helpers/packaging';

const SelectedProductDetails = styled.div`
  margin: 16px 0 0 12px;
  width: 90%;
`;

const SelectedProducts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const ProductItem = styled.span`
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 5px;
`;

const ProductItemPrice = styled.span`
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 5px;
`;

const PackagingDetails = ({ packagingDetails }) => (
  <SelectedProductDetails>
    {packagingDetails.map(({ label, quantity, price, currency }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <SelectedProducts key={index} data-testid="product-packaging">
        <ProductItem>{label}</ProductItem>
        <ProductItem>{quantity}x</ProductItem>
        <ProductItemPrice>
          {currency} {price}
        </ProductItemPrice>
      </SelectedProducts>
    ))}
  </SelectedProductDetails>
);

PackagingDetails.propTypes = {
  packagingDetails: PropTypes.arrayOf(PropTypes.shape(packagingDataProps)),
};

export default PackagingDetails;
