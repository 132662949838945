import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AppBar from '../../../components/AppBar/AppBar.connected';
import Product from '../../ProductView/Product';
import PackagingDetailsDialog from '../PackagingDetailsDialog/PackagingDetailsDialog';
import SelectedProductsSummary from './SelectedProductsSummary';
import {
  calculateTotalAmount,
  countAllSubProducts,
  countProducts,
  transformUnitPrices,
} from '../../../helpers/packaging';
import useProductsSearch from '../../../hooks/useProductsSearch';
import { isSearchTermLongEnough } from '../../../helpers/products';
import {
  SearchContainer,
  SearchInput,
  SearchIcon,
} from '../../../components/Search/Search';
import ProductsInCategory from './ProductsInCategory';
import NothingFound from '../../../components/NothingFound/NothingFound';

const ViewContainer = styled(Container)`
  margin-bottom: 144px;

  & .MuiButton-root {
    position: relative;
  }

  & .MuiButton-root:hover {
    background-color: #f2f2f2;
  }

  && .MuiButton-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 90%;
  }

  && .MuiButton-endIcon {
    position: absolute;
    right: 13px;
    top: 8px;
  }
`;

const CollapsibleContainer = styled.div`
  padding: ${({ $subCategory }) => ($subCategory ? '0' : '5px 0')};

  & .MuiButton-root {
    margin: 0 0 4px 0;
    padding: 10px;
    border-color: #fff;
    letter-spacing: normal;
    height: auto;
    text-transform: none;
    justify-content: flex-start;
  }
`;

const SelectProducts = ({
  packagingData,
  handleBackButton,
  updatePackaging,
  handleSeeSummary,
  updateNetPrice,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const [isCategoryExpanded, setIsCategoryExpanded] = useState({});
  const [isSubCategoryExpanded, setIsSubCategoryExpanded] = useState({});
  const [product, setProduct] = useState(null);
  const [packagingDialogOpen, setPackagingDialogOpen] = useState(false);

  const productsData = useSelector((state) => state.products[clientId].data);
  const currency = useSelector(
    (state) => state.clientData.data[clientId].currency
  );
  const {
    searchVisible,
    setSearchVisible,
    searchTerm,
    setSearchTerm,
    searchInputRef,
    title: searchOrTitle,
    filteredProductsData,
  } = useProductsSearch(productsData, t('select_products'));

  const { totalAmount, total } = calculateTotalAmount(
    packagingData,
    productsData
  );

  const handleExpandedStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsCategoryExpanded({ [title]: !isExpanded });
  };

  const handleExpandedSubCategoryStateChange = (title, isExpanded, e) => {
    e.stopPropagation();
    setIsSubCategoryExpanded({ [title]: !isExpanded });
  };

  const handleProductClick = (e, product) => {
    e.stopPropagation();
    setProduct(product);
    setPackagingDialogOpen(true);
  };

  const handleSummaryButtonClick = () => {
    handleSeeSummary();
    dispatch(updateNetPrice(totalAmount));
  };

  return (
    <ViewContainer maxWidth="sm" data-testid="create-quotation-details">
      <AppBar
        title={searchOrTitle}
        titleClickAction={() => setSearchVisible(true)}
        content={
          searchVisible && (
            <SearchContainer maxWidth="sm">
              <SearchInput
                placeholder={t('sic_search_placeholder')}
                onChange={(event) => setSearchTerm(event.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                inputRef={searchInputRef}
                autoFocus
                value={searchTerm}
              />
            </SearchContainer>
          )
        }
        displayBackButton
        backButtonAction={handleBackButton}
        aside={
          searchTerm ? (
            <IconButton
              onClick={() => {
                setSearchVisible(false);
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton
              data-testid="search-icon"
              onClick={() => {
                setSearchVisible(true);
                searchInputRef.current && searchInputRef.current.focus();
              }}
            >
              <SearchIcon />
            </IconButton>
          )
        }
      />
      <CollapsibleContainer>
        {filteredProductsData &&
          filteredProductsData.map((category) => {
            const { categoryName, products, subCategories } = category || null;
            const countCategoryProducts = countProducts(
              products,
              packagingData
            );
            const countAllSubCategoriesProducts = countAllSubProducts(
              subCategories,
              packagingData
            );

            return subCategories?.length ? (
              <Product
                key={categoryName}
                title={categoryName}
                onChange={handleExpandedStateChange}
                isExpanded={
                  !!isCategoryExpanded[categoryName] ||
                  isSearchTermLongEnough(searchTerm)
                }
                itemsSize={countAllSubCategoriesProducts}
              >
                {subCategories.map(
                  ({ subCategoryName, products: subProducts }) => {
                    const countSubProducts = countProducts(
                      subProducts,
                      packagingData
                    );
                    return (
                      <CollapsibleContainer key={subCategoryName} $subCategory>
                        <ProductsInCategory
                          name={subCategoryName}
                          products={subProducts}
                          handleExpandedStateChange={
                            handleExpandedSubCategoryStateChange
                          }
                          isCategoryExpanded={isSubCategoryExpanded}
                          searchTerm={searchTerm}
                          count={countSubProducts}
                          handleProductClick={handleProductClick}
                          packagingData={packagingData}
                          isSubCategory
                        />
                      </CollapsibleContainer>
                    );
                  }
                )}
              </Product>
            ) : (
              <ProductsInCategory
                key={categoryName}
                name={categoryName}
                products={products}
                handleExpandedStateChange={handleExpandedStateChange}
                isCategoryExpanded={isCategoryExpanded}
                searchTerm={searchTerm}
                count={countCategoryProducts}
                handleProductClick={handleProductClick}
                packagingData={packagingData}
              />
            );
          })}
      </CollapsibleContainer>
      {filteredProductsData.length === 0 && <NothingFound />}
      {product && (
        <PackagingDetailsDialog
          data={packagingData && packagingData[product.code]}
          open={packagingDialogOpen}
          setOpen={setPackagingDialogOpen}
          product={product}
          done={(packagingData, product) => {
            setPackagingDialogOpen(false);

            dispatch(
              updatePackaging({
                productCode: product.code,
                packagingData: transformUnitPrices(packagingData),
              })
            );
          }}
          currency={currency}
          packagingData={packagingData}
          productsData={productsData}
        />
      )}
      <SelectedProductsSummary
        total={total}
        totalAmount={`${currency} ${totalAmount}`}
        handleSeeSummary={handleSummaryButtonClick}
      />
    </ViewContainer>
  );
};

SelectProducts.propTypes = {
  handleBackButton: PropTypes.func,
  updatePackaging: PropTypes.func,
  packagingData: PropTypes.shape({}),
  handleSeeSummary: PropTypes.func,
  updateNetPrice: PropTypes.func,
};

export default SelectProducts;
