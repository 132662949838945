import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sortByDate } from 'frontend-components/lib/helpers';

import GalleryItemList from './GalleryItemList';
import {
  filterGalleryItemsByTagId,
  filterGalleryItemsByIds,
} from '../../helpers/galleryItems';

const GalleryItemListConnected = ({
  title,
  expanded,
  onChange,
  galleryItems,
  defaultTag,
  filterByIds,
  'data-testid': testId,
  disabled,
  onAddPhoto,
  onUploadProgress,
  onPlusButtonClick,
  valid = true,
  onInvalid,
  onGalleryButtonClick,
}) => {
  const { clientId } = useParams();
  const [combinedGalleryItems, setCombinedGalleryItems] =
    useState(galleryItems);

  const [filteredGalleryItems, setFilteredGalleryItems] = useState({
    total: 0,
    data: [],
  });

  const { photosFromDevice, videos } = useSelector((state) => ({
    photosFromDevice: state.photosFromDevice.addedPhotos[clientId],
    videos: state.video.upload,
  }));

  useEffect(() => {
    const clientVideos = Object.values(videos).filter(
      ({ client }) => client === clientId
    );
    if (photosFromDevice || clientVideos) {
      const combinedGalleryItemsData = [
        ...(photosFromDevice?.length ? photosFromDevice : []),
        ...(clientVideos?.length ? clientVideos : []),
        ...sortByDate([...galleryItems.data]),
      ];

      setCombinedGalleryItems({
        total: combinedGalleryItemsData.length,
        data: combinedGalleryItemsData,
      });
    } else {
      setCombinedGalleryItems(galleryItems);
    }
  }, [galleryItems, photosFromDevice, videos, clientId]);

  useEffect(() => {
    let newGalleryItems = combinedGalleryItems.data;

    if (filterByIds) {
      newGalleryItems = filterGalleryItemsByIds(newGalleryItems, filterByIds);
    }

    if (defaultTag) {
      newGalleryItems = filterGalleryItemsByTagId(newGalleryItems, defaultTag);
    }

    setFilteredGalleryItems({
      data: newGalleryItems,
      total: newGalleryItems.length,
    });
  }, [combinedGalleryItems, defaultTag, filterByIds]);

  return (
    <GalleryItemList
      title={title}
      expanded={expanded}
      onChange={onChange}
      galleryItems={filteredGalleryItems}
      defaultTag={defaultTag}
      data-testid={testId}
      applicationGalleryItemsNumber={filterByIds && filterByIds.length}
      disabled={disabled}
      onAddPhoto={onAddPhoto}
      onUploadProgress={onUploadProgress}
      onPlusButtonClick={onPlusButtonClick}
      valid={valid}
      onInvalid={onInvalid}
      onGalleryButtonClick={onGalleryButtonClick}
    />
  );
};

GalleryItemListConnected.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  galleryItems: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        fileUrl: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  defaultTag: PropTypes.string,
  filterByIds: PropTypes.arrayOf(PropTypes.string),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  onAddPhoto: PropTypes.func,
  onUploadProgress: PropTypes.func,
  onPlusButtonClick: PropTypes.func,
  valid: PropTypes.bool,
  onInvalid: PropTypes.func,
  onGalleryButtonClick: PropTypes.func,
};

export default GalleryItemListConnected;
