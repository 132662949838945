import { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import NearMeIcon from '@material-ui/icons/NearMe';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import WebsiteIcon from '@material-ui/icons/Public';
import * as Yup from 'yup';
import {
  Divider,
  Button,
  AsideButton,
  usePermissions,
} from 'frontend-components';
import { scrollToError } from 'frontend-components/lib/helpers';

import AppBar from '../../components/AppBar/AppBar.connected';
import Input from '../../components/Input/Input';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import Select from '../../components/Select/Select';
import MenuItem from '../../components/Select/MenuItem';
import useFormikAutoSave from '../../components/FormikAutoSave/FormAutoSaveHook';
import {
  deleteClient,
  fetchClientListData,
} from '../../store/reducers/clients';
import { deleteClientData } from '../../store/actions/clientDetailsActions';
import { navigateToInMaps } from '../../helpers/maps';
import ClassificationCode, {
  CLASSIFICATION_CODE_OPTIONS,
  EMPTY_VALUE,
} from '../../components/ClassificationCode/ClassificationCode';
import { fallbackImg } from '../../helpers/uploadPhoto';
import EntrancePhotoIcon from '../../components/Icons/EntrancePhotoIcon';
import {
  StyledForm,
  AddButton,
  ContactDetailsContainer,
  ClassificationCodeContainer,
  AddressCard,
  AddressTitle,
  AddressPlaceIcon,
  AddressText,
  AddressContent,
  NavigateMeButton,
  EditAddressButton,
  EntrancePhotoContainer,
  EntrancePhotoContent,
  EntranceImg,
  StyledWebsiteIcon,
} from './ClientDetails.styles';
import {
  getValuesWithName,
  getNameErrorMessage,
  clientNameValid,
} from '../../helpers/clients';
import useCreatedLocally from '../../hooks/useCreatedLocally';
import {
  clientDataShape,
  clientListItemShape,
} from '../../helpers/clientDataPropTypes';
import { formatSicGroupName } from '../../store/reducers/sicCodes';
import {
  emailValidation,
  phoneValidation,
  websiteValidation,
} from '../../helpers/validations';

const ClientDetails = ({
  clientData,
  clientListItemData,
  updateClientDetails,
  updateClientName,
  isNew,
  editAddressPath,
  editSicCodePath,
  title,
  navigateToGallery,
  backButtonAction,
  disableGallery = false,
  setValidate = () => null,
  validate = false,
}) => {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { disabled } = usePermissions(clientData.access, clientData.owned);

  const sicValue = clientListItemData?.sicCode
    ? `${formatSicGroupName(clientListItemData.sicDescription)} - ${
        clientListItemData.sicCode
      }`
    : '';
  const visitAddress = clientListItemData.visitAddress || {};
  const clientAddress = [
    visitAddress.street,
    visitAddress.postcode,
    visitAddress.city,
  ]
    .filter(Boolean)
    .join(', ');
  const isAddressEmpty = !clientAddress;
  const hasEntryPhoto = !!clientListItemData.entry;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const showNavigationButton =
    !!(clientListItemData.lat && clientListItemData.lng) || !isAddressEmpty;
  const createdLocally = useCreatedLocally(clientId);

  const { handleSubmit, setFieldValue, values, dirty, errors, isValid } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: clientListItemData ? clientListItemData.visitAddress.name : '',
        sic: sicValue,
        department: clientListItemData.department
          ? clientListItemData.department
          : '',
        subDepartment: clientListItemData.subDepartment
          ? clientListItemData.subDepartment
          : '',
        classification: clientListItemData.classification,
        generalPhoneNumber: clientListItemData
          ? clientListItemData.generalPhoneNumber
          : '',
        email: clientListItemData ? clientListItemData.email : '',
        website: clientListItemData ? clientListItemData.website : '',
      },
      validationSchema: Yup.object().shape({
        generalPhoneNumber: phoneValidation(t),
        email: emailValidation(t),
        website: websiteValidation(t),
      }),
      validateOnChange: false,
      onSubmit: () => {
        if (dirty) {
          dispatch(
            updateClientDetails(
              clientId,
              getValuesWithName(values, clientListItemData)
            )
          );
          dispatch(updateClientName(clientId, values.name));
        }
      },
    });

  const {
    name,
    sic,
    department,
    subDepartment,
    classification,
    generalPhoneNumber,
    email,
    website,
  } = values;
  const { clientNumber } = clientListItemData;

  useFormikAutoSave(values, handleSubmit, 500, dirty, isValid);

  const [isNameMissing, setIsNameMissing] = useState(false);
  const [isNameLengthError, setIsNameLengthError] = useState(false);
  const [isSicCodeMissing, setIsSicCodeNameMissing] = useState(false);
  const [coords, setCoords] = useState(null);

  useEffect(() => {
    name.length && setIsNameMissing(false);
    clientNameValid(name) && setIsNameLengthError(false);
  }, [name, setIsNameMissing]);

  useEffect(() => {
    sic.length && setIsSicCodeNameMissing(false);
  }, [sic, setIsSicCodeNameMissing]);

  useEffect(() => {
    if (!validate) return;
    !sic.length && setIsSicCodeNameMissing(true);
    !name.length && setIsNameMissing(true);
    !clientNameValid(name) && setIsNameLengthError(true);
    setValidate(false);
  }, [name, sic, validate, setValidate]);

  useEffect(() => {
    const skipFetch = ['cliendAddress', 'clientSicCode', 'website'];
    if (
      !createdLocally &&
      (!location.state || !skipFetch.includes(location.state.from))
    ) {
      dispatch(fetchClientListData());
    }
  }, [dispatch, location, createdLocally]);

  const handleImageError = (e, link) => {
    e.target.onerror = null;
    e.target.src = link || fallbackImg;
  };

  const { isFormBlocked } = useSelector((state) => state.clientUI);

  return (
    <Container maxWidth="sm" data-testid="customer-details-view">
      <AppBar
        title={title}
        displayBackButton
        backButtonAction={() => {
          if (isFormBlocked) {
            scrollToError();
            return;
          }
          backButtonAction(
            clientListItemData,
            name,
            setIsNameMissing,
            setIsNameLengthError,
            sic,
            setIsSicCodeNameMissing
          );
        }}
        aside={
          !isNew && (
            <AsideButton
              aria-label="delete-client"
              onClick={() => {
                navigator.geolocation.getCurrentPosition(({ coords }) => {
                  setCoords(coords);
                });
                setShowDeleteDialog(true);
              }}
              disabled={disabled}
            >
              <DeleteIcon />
            </AsideButton>
          )
        }
        elevated
      />
      <ContactDetailsContainer>
        <StyledForm onSubmit={handleSubmit}>
          <ClassificationCodeContainer>
            <ClassificationCode code={classification} />
          </ClassificationCodeContainer>
          {clientNumber && (
            <Input
              id="clientNumber"
              name="clientNumber"
              value={clientNumber}
              label={t('client_number')}
              fullWidth
              disabled
              readOnly
              multiline
            />
          )}
          <Input
            id="name"
            name="name"
            value={name}
            label={t('client_details_name')}
            fullWidth
            onchange={(value) => setFieldValue('name', value)}
            errorMessage={t(
              getNameErrorMessage(isNameMissing, isNameLengthError)
            )}
            maxLength={100}
            disabled={disabled}
            multiline
          />
          <AddressCard>
            <AddressTitle>
              <AddressPlaceIcon />
              <AddressText>{t('client_details_address')}</AddressText>
              {showNavigationButton && !isAddressEmpty && (
                <NavigateMeButton
                  aria-label="navigate"
                  onClick={() => {
                    navigateToInMaps(
                      clientListItemData.lat,
                      clientListItemData.lng,
                      clientAddress
                    );
                  }}
                >
                  <NearMeIcon />
                  {t('client_details_navigate')}
                </NavigateMeButton>
              )}
              {isAddressEmpty && (
                <AddButton
                  aria-label="create-address"
                  onClick={() => {
                    history.push(editAddressPath);
                  }}
                  disabled={disabled}
                >
                  <AddIcon />
                </AddButton>
              )}
            </AddressTitle>
            {!isAddressEmpty && (
              <>
                <AddressContent data-testid="clientAddress">
                  {clientAddress}
                  <EditAddressButton
                    aria-label="edit-address"
                    onClick={() => {
                      history.push(editAddressPath);
                    }}
                    disabled={disabled}
                  >
                    <EditIcon />
                  </EditAddressButton>
                </AddressContent>
                <Divider />
              </>
            )}
            <Divider />
            <EntrancePhotoContainer>
              {hasEntryPhoto ? (
                <EntrancePhotoContent>
                  <span>
                    <EntrancePhotoIcon />
                    {t('entrance_photo')}
                  </span>
                  {disabled || disableGallery ? (
                    <EntranceImg
                      src={clientListItemData.entry || fallbackImg}
                      alt={t('entrance_photo')}
                    />
                  ) : (
                    <EntranceImg
                      onClick={navigateToGallery}
                      src={clientListItemData.entry || fallbackImg}
                      onError={(e) =>
                        handleImageError(e, clientListItemData.entry)
                      }
                      alt={t('entrance_photo')}
                    />
                  )}
                </EntrancePhotoContent>
              ) : (
                <Button
                  data-testid="add-entrance-photo"
                  theme="secondary"
                  startIcon={<AddIcon />}
                  fullWidth
                  onClick={navigateToGallery}
                  disabled={disabled || disableGallery}
                >
                  {t('add_entrance_photo_button')}
                </Button>
              )}
            </EntrancePhotoContainer>
          </AddressCard>
          <Input
            id="generalPhoneNumber"
            name="generalPhoneNumber"
            label={t('client_details_phone')}
            fullWidth
            value={generalPhoneNumber}
            onchange={(value) => setFieldValue('generalPhoneNumber', value)}
            maxLength={100}
            rightIcon={<PhoneIcon />}
            disabled={disabled}
            errorMessage={errors.generalPhoneNumber}
          />
          <Input
            id="email"
            name="email"
            label={t('client_details_email')}
            value={email}
            rightIcon={<EmailIcon />}
            fullWidth
            onchange={(value) => setFieldValue('email', value)}
            disabled={disabled}
            errorMessage={errors.email}
          />
          <Input
            id="website"
            name="website"
            label={t('client_details_website')}
            value={website}
            rightIcon={
              website ? (
                <a href={`//${website}`} target="_blank" rel="noreferrer">
                  <StyledWebsiteIcon />
                </a>
              ) : (
                <WebsiteIcon />
              )
            }
            fullWidth
            onchange={(value) => setFieldValue('website', value)}
            rightIconDisabled={!website && disabled}
            disabled={disabled}
            errorMessage={errors.website}
          />
          <Input
            id="sic"
            name="sic"
            label={t('client_details_sic')}
            fullWidth
            value={sic}
            onchange={(value) => setFieldValue('sic', value)}
            maxLength={255}
            multiline
            rightIcon={<ChevronRightIcon />}
            // changed onClick to onMouseDown to prevent scrolling page on iOS
            onMouseDown={() => !disabled && history.push(editSicCodePath)}
            disabled={disabled}
            errorMessage={isSicCodeMissing ? t('validation_sic') : ''}
          />
          <Select
            id="classification"
            data-testid="classification"
            label={t('classification_label')}
            value={classification || EMPTY_VALUE}
            fullWidth
            onChange={(newClassification) => {
              setFieldValue('classification', newClassification);
            }}
            disabled={disabled}
          >
            {CLASSIFICATION_CODE_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
          <Input
            id="department"
            name="department"
            label={t('client_details_department')}
            fullWidth
            value={department}
            onchange={(value) => setFieldValue('department', value)}
            maxLength={100}
            disabled={disabled}
          />
          <Input
            id="subDepartment"
            name="subDepartment"
            label={t('client_details_sub_dep')}
            fullWidth
            value={subDepartment}
            onchange={(value) => setFieldValue('subDepartment', value)}
            maxLength={100}
            disabled={disabled}
          />
        </StyledForm>
      </ContactDetailsContainer>
      <DeleteDialog
        title={t('client_delete_title')}
        message={t('client_delete_message')}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        handleDelete={() => {
          dispatch(deleteClientData(clientId));
          dispatch(deleteClient(clientId, coords));
          history.push('/customers');
        }}
      />
    </Container>
  );
};

ClientDetails.propTypes = {
  clientData: clientDataShape,
  clientListItemData: clientListItemShape,
  updateClientDetails: PropTypes.func,
  updateClientName: PropTypes.func,
  isNew: PropTypes.bool,
  editAddressPath: PropTypes.string,
  editSicCodePath: PropTypes.string,
  title: PropTypes.string,
  navigateToGallery: PropTypes.func,
  backButtonAction: PropTypes.func,
  disableGallery: PropTypes.bool,
  setValidate: PropTypes.func,
  validate: PropTypes.bool,
};

export default ClientDetails;
