import { useParams } from 'react-router-dom';
import GalleryView from './GalleryView';
import withClientData from '../../helpers/withClientData';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { GALLERY_KEY } from '../../constants/video';

const ClientGalleryView = ({ clientData }) => {
  const { clientId } = useParams();
  return (
    <GalleryView
      clientData={clientData}
      galleryPath={`/customers/${clientId}`}
      backPath={`/customers/${clientId}`}
      fromKey={GALLERY_KEY}
    />
  );
};

ClientGalleryView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ClientGalleryView);
