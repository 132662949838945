import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { DateTile, Divider } from 'frontend-components';

import List from '../../components/List/List';
import ListItemText from '../../components/List/ListItemText';
import {
  TabContainer,
  SideItems,
  ItemsSize,
  OrderListItem,
} from './Tabs.styles';
import { ordersHistoryShape } from '../../helpers/clientDataPropTypes';

const OrdersTab = ({ ordersData }) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();

  return (
    <Container maxWidth="sm">
      <TabContainer>
        <List data-testid="orders-list">
          {ordersData &&
            ordersData.map((order, index) => {
              if (!order.products.length) return null;

              const secondaryLabel = `${order.products[0].currency} ${order.totalCost}`;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={order.number + index}>
                  <OrderListItem
                    button
                    onClick={() => {
                      history.push(
                        `/customers/${clientId}/orderHistory/order/${order.number}`
                      );
                    }}
                    data-testid="order-list-item"
                  >
                    <ListItemText
                      primary={`${t('invoice_number')}${order.invoice}`}
                      secondary={secondaryLabel}
                    />
                    <SideItems>
                      <ItemsSize>{order.products.length}</ItemsSize>
                      <DateTile value={order.createdAt} />
                    </SideItems>
                  </OrderListItem>
                  <Divider />
                </div>
              );
            })}
        </List>
      </TabContainer>
    </Container>
  );
};

OrdersTab.propTypes = {
  ordersData: ordersHistoryShape,
};

export default OrdersTab;
