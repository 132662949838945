import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Button } from 'frontend-components';

import Collapsible, { CollapsibleContent } from '../Collapsible/Collapsible';
import { fallbackImg } from '../../helpers/uploadPhoto';
import { isVideoType } from '../../helpers/video';
import VideoThumbnail, { RectVideo } from '../VideoThumbnail/VideoThumbnail';
import UploadStatusIcon from '../ImageUploader/UploadStatusIcon';
import { VIDEO } from '../../constants/photo';

const GridContainer = styled.div`
  padding-top: 20px;

  .MuiGridList-root {
    overflow: hidden;
  }

  .MuiGridList-root > div {
    position: relative;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledUploadStatusIcon = styled(UploadStatusIcon)`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
`;

const GalleryItemList = ({
  title = null,
  expanded,
  onChange,
  galleryItems,
  defaultTag,
  'data-testid': testId,
  disabled,
  onAddPhoto,
  onPlusButtonClick,
  valid,
  onInvalid,
  onGalleryButtonClick,
}) => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();
  const history = useHistory();
  const [sortedGalleryItems, setSortedGalleryItems] = useState([]);
  const { upload } = useSelector((state) => state.photosFromDevice);
  const { uploadVideo } = useSelector((state) => ({
    uploadVideo: state.video.upload,
  }));

  useEffect(() => {
    const galleryItemsWithUpdatedAt = galleryItems.data.map((photo) => ({
      ...photo,
      createdAt: photo?.createdAt || new Date(0).toISOString(),
      uploadStatus: upload[photo.id] || uploadVideo[photo.id],
    }));
    setSortedGalleryItems(galleryItemsWithUpdatedAt);
  }, [galleryItems, upload, uploadVideo]);

  const handleImageError = (e, link) => {
    e.target.onerror = null;
    e.target.src = link || fallbackImg;
  };

  const handleThumbnailClick = (id, status) => {
    if (!status?.uploaded && status?.mediaType === VIDEO) return;
    if (applicationId) {
      history.push(
        validate(id)
          ? `/customers/${clientId}/applications/${applicationId}/upload/${id}`
          : `/customers/${clientId}/applications/${applicationId}/gallery/${id}`,
        {
          from: defaultTag,
        }
      );
    } else {
      history.push(
        validate(id)
          ? `/customers/${clientId}/upload/${id}`
          : `/customers/${clientId}/gallery/${id}`
      );
    }
  };

  return (
    <Collapsible
      expanded={expanded && galleryItems.total > 0}
      onChange={onChange}
      icon={<CameraAltIcon />}
      title={title || t('photos')}
      itemsSize={galleryItems.total}
      collapsible={galleryItems.total > 0}
      clientId={clientId}
      data-testid={testId}
      displayPlusButton
      onPlusButtonClick={() => {
        if (!valid && onInvalid) {
          onInvalid();
          return;
        }
        onPlusButtonClick && onPlusButtonClick();
        onAddPhoto && onAddPhoto();
      }}
      disabled={disabled}
    >
      <GridContainer>
        <ImageList
          cols={3}
          gap={2}
          rowHeight={102}
          onClick={(e) => e.stopPropagation()}
        >
          {sortedGalleryItems
            .slice(0, 6)
            .map(
              ({
                id,
                fileUrl,
                link,
                name,
                mediaType,
                thumbnailUrl,
                uploadStatus,
              }) => {
                if (isVideoType(mediaType)) {
                  return (
                    <RectVideo key={id}>
                      <StyledUploadStatusIcon id={id} status={uploadStatus} />
                      <VideoThumbnail
                        onClick={() => {
                          handleThumbnailClick(id, uploadStatus);
                        }}
                        thumbnail={thumbnailUrl}
                        withGap
                      />
                    </RectVideo>
                  );
                }
                return (
                  <ImageListItem key={id}>
                    <StyledUploadStatusIcon id={id} status={uploadStatus} />
                    <img
                      src={fileUrl || link || fallbackImg}
                      alt={name}
                      onClick={() => handleThumbnailClick(id, uploadStatus)}
                      onError={(e) => handleImageError(e, link)}
                    />
                  </ImageListItem>
                );
              }
            )}
        </ImageList>
      </GridContainer>
      <CollapsibleContent>
        <ButtonContainer>
          <Button
            theme="secondary"
            size="small"
            onClick={onGalleryButtonClick}
            aria-label="gallery-see"
          >
            {t('see_more')}
          </Button>
        </ButtonContainer>
      </CollapsibleContent>
    </Collapsible>
  );
};

GalleryItemList.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  galleryItems: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fileUrl: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  defaultTag: PropTypes.string,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  onAddPhoto: PropTypes.func,
  onPlusButtonClick: PropTypes.func,
  valid: PropTypes.bool,
  onInvalid: PropTypes.func,
  onGalleryButtonClick: PropTypes.func,
};

export default GalleryItemList;
