import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import { Form, Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button } from 'frontend-components';

import {
  APPLICATION_METHODS,
  APPLICATION_VIEW_TABS,
  APPLICATIONS_TYPES,
  CLEAN_BEFORE_OPTIONS,
  COMPONENTS_LIFETIME_PERIODS,
  LUBRICATION_PERIODS,
  MACHINE_RUNNING_OPTIONS,
  VALIDATION_CONSTANTS,
} from '../../constants/applicationScreen';
import { FORM_DEBOUNCE_MS } from '../../constants/forms';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import MenuItem from '../../components/Select/MenuItem';
import FormikAutoSave from '../../components/FormikAutoSave/FormikAutoSave';
import CostSavings from './CostSavings';
import { calculateFieldValue } from './calculateFieldValue';
import { changeFieldValueToNumber } from '../../helpers/changeFieldValueToNumber';
import { applicationDataShape } from '../../helpers/clientDataPropTypes';
import { getApplicationTypeName } from '../../helpers/application';

const GreySection = styled.div`
  margin: ${({ savings }) => (savings ? '32px 0 0' : ' -15px 0 0')};
  background-color: #f2f2f2;
  padding-bottom: 30px;
  margin-top: ${({ savings }) => savings && '32px'};
`;

const ApplicationName = styled.div`
  padding: 35px 15px 5px;
  text-transform: uppercase;
  max-width: 600px;
  max-width: 600px;
  margin: 0 auto;
  word-break: break-word;

  @media (min-width: 600px) {
    padding: 35px 24px 5px;
  }
`;

const AfterTabContainer = styled.div`
  & .MuiFormControl-root {
    margin: 20px 0 16px;
  }
`;

const TwoInputsInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > .MuiFormControl-root:first-child {
    margin-right: 30px;

    & .MuiFormLabel-root {
      max-width: 150px;
      margin-top: -16px;

      &.MuiInputLabel-shrink {
        margin-top: -10px;
      }
    }
  }
`;

const AddProduct = styled.div`
  margin: 22px 0 0;
`;

const SectionInfo = styled.div`
  display: inline-block;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  margin: 20px 0 4px;
`;

const { maxFloatNum, maxIntNum, minTemp, maxTemp, maxNoiseLevel } =
  VALIDATION_CONSTANTS;

const AfterTab = ({
  applicationData,
  updateAppSection,
  disabled,
  currency,
}) => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();
  const history = useHistory();
  const [appData, setAppData] = useState(applicationData || {});
  const [appDataAfter, setAppDataAfter] = useState(
    (applicationData && applicationData[APPLICATION_VIEW_TABS.AFTER]) || {
      previousLubricants: [],
    }
  );
  const [isCleanBeforeVisible, setIsCleanBeforeVisible] = useState(false);

  useEffect(() => {
    if (!applicationData) return;

    setAppDataAfter(applicationData[APPLICATION_VIEW_TABS.AFTER]);
    setAppData(applicationData);
  }, [applicationData, applicationId, clientId]);

  useEffect(() => {
    setIsCleanBeforeVisible(appDataAfter.cleanBefore === 'yes');
  }, [appDataAfter]);

  const initialValues = {
    lubricant: appDataAfter.lubricant || '',
    price: appDataAfter.price || '',
    lubricationFrequency: appDataAfter.lubricationFrequency || '',
    lubricationFrequencyPeriod: appDataAfter.lubricationFrequencyPeriod || '',
    amountPerLubrication: appDataAfter.amountPerLubrication || '',
    manualApplication: appDataAfter.manualApplication || '',
    manualApplicationTime: appDataAfter.manualApplicationTime || '',
    machineRunning: appDataAfter.machineRunning || '',
    cleanBefore: appDataAfter.cleanBefore || '',
    cleanBeforeTime: appDataAfter.cleanBeforeTime || '',
    avgComponentLifetime: appDataAfter.avgComponentLifetime || '',
    avgComponentLifetimePeriod: appDataAfter.avgComponentLifetimePeriod || '',
    noiseLevel: appDataAfter.noiseLevel || '',
    additionalSavings: appDataAfter.additionalSavings || '',
    powerConsumption: appDataAfter.powerConsumption || '',
    costSavings:
      appData.costSavings || calculateFieldValue(appData, 'savings') || '',
    uptimeGain:
      appData.uptimeGain || calculateFieldValue(appData, 'uptimeGain') || '',
    laborTimeSaved:
      appData.laborTimeSaved || calculateFieldValue(appData, 'laborTime') || '',
    componentTemperature:
      (appDataAfter.componentTemperature === 0 && '0') ||
      appDataAfter.componentTemperature ||
      '',
  };

  const updateForm = (values) => {
    updateAppSection(
      applicationId,
      clientId,
      values,
      APPLICATION_VIEW_TABS.AFTER
    );
  };

  const floatValidation = Yup.number()
    .typeError(t('validation_app_positive'))
    .positive(t('validation_app_positive'))
    .max(maxFloatNum, t('validation_app_float_range'));

  const integerValidation = Yup.number()
    .typeError(t('validation_app_uint'))
    .positive(t('validation_app_uint'))
    .integer(t('validation_app_uint'))
    .max(maxIntNum, t('validation_app_uint_range'));

  const temperatureValidation = Yup.number()
    .typeError(t('validation_app_tempC'))
    .min(minTemp, t('validation_app_tempC'))
    .max(maxTemp, t('validation_app_tempC'));

  const noiseLevelValidation = Yup.number()
    .typeError(t('validation_app_noise_range'))
    .positive(t('validation_app_noise_range'))
    .max(maxNoiseLevel, t('validation_app_noise_range'));

  const validationSchema = Yup.object({
    price: floatValidation,
    lubricationFrequency: integerValidation,
    amountPerLubrication: integerValidation,
    manualApplicationTime: integerValidation,
    cleanBeforeTime: integerValidation,
    avgComponentLifetime: integerValidation,
    additionalSavings: floatValidation,
    componentTemperature: temperatureValidation,
    noiseLevel: noiseLevelValidation,
    powerConsumption: floatValidation,
  });

  const appType = getApplicationTypeName(applicationData.componentType);

  return (
    <AfterTabContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => updateForm(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => {
          const {
            lubricant,
            price,
            lubricationFrequency,
            lubricationFrequencyPeriod,
            amountPerLubrication,
            manualApplication,
            manualApplicationTime,
            machineRunning,
            cleanBefore,
            cleanBeforeTime,
            avgComponentLifetime,
            avgComponentLifetimePeriod,
            additionalSavings,
            noiseLevel,
            costSavings,
            uptimeGain,
            laborTimeSaved,
            componentTemperature,
            powerConsumption,
          } = values;

          return (
            <Form>
              <GreySection>
                <ApplicationName>
                  {applicationData.name} {appType && `(${t(appType)})`}
                </ApplicationName>
                <Container maxWidth="sm">
                  <Input
                    id="lubricant"
                    label={t('app_after_product')}
                    onchange={(e) => {
                      setFieldValue('lubricant', e);
                    }}
                    value={lubricant}
                    onFocus={() =>
                      history.push(
                        `/customers/${clientId}/applications/${applicationId}/addInterflonProduct`
                      )
                    }
                    rightIcon={
                      lubricant ? (
                        <CloseIcon data-testid="removeInterflonProduct" />
                      ) : null
                    }
                    onIconClick={() => setFieldValue('lubricant', '')}
                    fullWidth
                    disabled={disabled}
                  />
                  {lubricant === '' && (
                    <AddProduct>
                      <Button
                        data-testid="addInterflonProduct"
                        theme="secondary"
                        startIcon={<AddIcon />}
                        fullWidth
                        onClick={() =>
                          history.push(
                            `/customers/${clientId}/applications/${applicationId}/addInterflonProduct`
                          )
                        }
                        bgcolor="grey"
                        disabled={disabled}
                      >
                        {t('app_after_add_product')}
                      </Button>
                    </AddProduct>
                  )}
                  <Input
                    id="price"
                    label={t('app_after_price')}
                    onchange={(e) => {
                      setFieldValue('price', changeFieldValueToNumber(e));
                    }}
                    value={price}
                    fullWidth
                    type="number"
                    rightText={`${currency}/1l`}
                    errorMessage={errors && errors.price}
                    disabled={disabled}
                  />
                  <TwoInputsInRow>
                    <Input
                      id="lubricationFrequency"
                      label={t('app_after_freq')}
                      onchange={(e) => {
                        setFieldValue(
                          'lubricationFrequency',
                          changeFieldValueToNumber(e)
                        );
                      }}
                      value={lubricationFrequency}
                      type="number"
                      fullWidth
                      errorMessage={errors && errors.lubricationFrequency}
                      disabled={disabled}
                    />
                    <Select
                      id="lubricationFrequencyPeriod"
                      data-testid="lubricationFrequencyPeriod"
                      label={t('app_after_period')}
                      value={lubricationFrequencyPeriod}
                      fullWidth
                      onChange={(period) => {
                        setFieldValue('lubricationFrequencyPeriod', period);
                      }}
                      disabled={disabled}
                    >
                      {LUBRICATION_PERIODS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </TwoInputsInRow>
                  <Input
                    id="amountPerLubrication"
                    label={t('app_after_amount')}
                    onchange={(e) => {
                      setFieldValue(
                        'amountPerLubrication',
                        changeFieldValueToNumber(e)
                      );
                    }}
                    value={amountPerLubrication}
                    type="number"
                    fullWidth
                    rightText={t('app_ml')}
                    errorMessage={errors && errors.amountPerLubrication}
                    disabled={disabled}
                  />
                </Container>
              </GreySection>
              <Container maxWidth="sm">
                <Select
                  id="applicationMethod"
                  data-testid="applicationMethod"
                  label={t('app_after_app_method')}
                  value={manualApplication}
                  fullWidth
                  onChange={(manualApplication) => {
                    setFieldValue('manualApplication', manualApplication);
                  }}
                  disabled={disabled}
                >
                  {APPLICATION_METHODS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {t(label)}
                    </MenuItem>
                  ))}
                </Select>
                {manualApplication === APPLICATION_METHODS[0].value && (
                  <>
                    <Input
                      id="manualApplicationTime"
                      label={t('app_after_time')}
                      onchange={(e) => {
                        setFieldValue(
                          'manualApplicationTime',
                          changeFieldValueToNumber(e)
                        );
                      }}
                      value={manualApplicationTime}
                      type="number"
                      fullWidth
                      rightText={t('app_minutes')}
                      errorMessage={errors && errors.manualApplicationTime}
                      disabled={disabled}
                    />
                    <Select
                      id="machineRunning"
                      data-testid="machineRunning"
                      label={t('app_after_machine_state')}
                      value={machineRunning}
                      fullWidth
                      onChange={(machineState) => {
                        setFieldValue('machineRunning', machineState);
                      }}
                      disabled={disabled}
                    >
                      {MACHINE_RUNNING_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      id="cleanBefore"
                      data-testid="cleanBefore"
                      label={t('app_after_clean')}
                      value={cleanBefore}
                      fullWidth
                      onChange={(cleanBefore) => {
                        setIsCleanBeforeVisible(cleanBefore === 'yes');
                        setFieldValue('cleanBefore', cleanBefore);
                      }}
                      disabled={disabled}
                    >
                      {CLEAN_BEFORE_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {t(label)}
                        </MenuItem>
                      ))}
                    </Select>
                    {isCleanBeforeVisible && (
                      <Input
                        id="cleanBeforeTime"
                        label={t('app_after_clean_time')}
                        onchange={(e) => {
                          setFieldValue(
                            'cleanBeforeTime',
                            changeFieldValueToNumber(e)
                          );
                        }}
                        value={cleanBeforeTime}
                        type="number"
                        fullWidth
                        rightText={t('app_minutes')}
                        errorMessage={errors && errors.cleanBeforeTime}
                        disabled={disabled}
                      />
                    )}
                  </>
                )}
                <TwoInputsInRow>
                  <Input
                    id="avgComponentLifetime"
                    label={t('app_after_avg_lifetime')}
                    onchange={(e) => {
                      setFieldValue(
                        'avgComponentLifetime',
                        changeFieldValueToNumber(e)
                      );
                    }}
                    value={avgComponentLifetime}
                    type="number"
                    fullWidth
                    errorMessage={errors && errors.avgComponentLifetime}
                    disabled={disabled}
                  />
                  <Select
                    id="avgComponentLifetimePeriod"
                    data-testid="avgComponentLifetimePeriod"
                    label={t('after_avg_lifetime_unit')}
                    value={avgComponentLifetimePeriod}
                    fullWidth
                    onChange={(period) => {
                      setFieldValue('avgComponentLifetimePeriod', period);
                    }}
                    disabled={disabled}
                  >
                    {COMPONENTS_LIFETIME_PERIODS.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </TwoInputsInRow>
                <Input
                  id="componentTemperature"
                  label={t('app_temperature')}
                  onchange={(e) => {
                    setFieldValue(
                      'componentTemperature',
                      changeFieldValueToNumber(e, true)
                    );
                  }}
                  value={componentTemperature}
                  fullWidth
                  type="number"
                  rightText={t('app_temp_scale')}
                  isMinusForbidden={false}
                  errorMessage={errors && errors.componentTemperature}
                  disabled={disabled}
                />
                <Input
                  id="powerConsumption"
                  label={t('app_power_consumption')}
                  onchange={(e) => {
                    setFieldValue(
                      'powerConsumption',
                      changeFieldValueToNumber(e, true)
                    );
                  }}
                  value={powerConsumption}
                  fullWidth
                  type="number"
                  rightText={t('app_kilowatt')}
                  isMinusForbidden={false}
                  errorMessage={errors && errors.powerConsumption}
                  disabled={disabled}
                />
                {applicationData.technical.componentType ===
                  APPLICATIONS_TYPES[2].value && (
                  <Input
                    id="noiseLevel"
                    label={t('app_gbox_noise_level')}
                    onchange={(e) => {
                      setFieldValue('noiseLevel', changeFieldValueToNumber(e));
                    }}
                    value={noiseLevel}
                    fullWidth
                    rightText={t('app_dB')}
                    type="number"
                    errorMessage={errors && errors.noiseLevel}
                    disabled={disabled}
                  />
                )}
                <Input
                  id="additionalSavings"
                  label={t('after_additional_savings')}
                  onchange={(e) => {
                    setFieldValue(
                      'additionalSavings',
                      changeFieldValueToNumber(e)
                    );
                  }}
                  value={additionalSavings}
                  fullWidth
                  rightText={`${currency}/${t(`app_year`)}`}
                  type="number"
                  errorMessage={errors && errors.additionalSavings}
                  disabled={disabled}
                />
              </Container>
              <GreySection savings>
                <Container maxWidth="sm">
                  <SectionInfo>{t('fill_data_info')}</SectionInfo>
                  <CostSavings
                    costSavings={costSavings}
                    uptimeGain={uptimeGain}
                    laborTimeSaved={laborTimeSaved}
                    currency={currency}
                  />
                </Container>
              </GreySection>
              <FormikAutoSave debounceMs={FORM_DEBOUNCE_MS} />
            </Form>
          );
        }}
      </Formik>
    </AfterTabContainer>
  );
};

AfterTab.propTypes = {
  applicationData: applicationDataShape,
  updateAppSection: PropTypes.func,
  disabled: PropTypes.bool,
  currency: PropTypes.string,
};

export default AfterTab;
