import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import { addPhoto } from '../../store/actions/photoActions';
import {
  addVideo,
  setUploadingStart,
  addVideoUpload,
} from '../../store/reducers/video';
import { setPhotoUploadingStart } from '../../store/reducers/photosFromDevice';
import { updateUploaderBackDialogDoNotShowAgain } from '../../store/reducers/clientUI';
import FilesUploader from '../../components/ImageUploader/FilesUploader';
import FilesList from '../../components/ImageUploader/FilesList';
import UploaderTip from '../../components/ImageUploader/UploaderTip';
import { cancelTokensMap } from '../../helpers/cancelTokens';
import withClientData from '../../helpers/withClientData';
import Dialog from '../../components/Dialog/Dialog';
import DialogHeader from '../../components/Dialog/DialogHeader';
import { StyledDialogActions } from '../../components/Dialog/DeleteDialog/DeleteDialog';
import AutoUploaderTip from '../../components/ImageUploader/AutoUploaderTip';
import Checkbox from '../../components/Checkbox/Checkbox';

const CameraView = ({ clientData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, applicationId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tagId = searchParams.get('tagId');

  const [allFiles, setAllFiles] = useState([]);
  const [backDialogCheckbox, setBackDialogCheckbox] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const { photos } =
    clientData.applications.data.find(({ id }) => applicationId === id) || {};

  const { tag } = useSelector((state) => ({
    tag:
      state.user.data && state.user.data.tags && state.user.data.tags.length > 0
        ? state.user.data.tags.find((tag) => tag.id.toString() === tagId)
        : undefined,
  }));
  const { online } = useSelector((state) => state.offline);

  const { upload: photosUpload } = useSelector(
    (state) => state.photosFromDevice
  );
  const { upload: videoUpload } = useSelector((state) => state.video);
  const { uploaderBackDialogDoNotShowAgain } = useSelector(
    (state) => state.clientUI
  );

  const backButtonAction = () => {
    const notUploadedFiles = allFiles.filter((file) => {
      return !file?.uploded;
    });

    if (notUploadedFiles.length > 0 && !uploaderBackDialogDoNotShowAgain) {
      setOpenConfirmationDialog(true);
    } else {
      history.goBack();
    }
  };

  const handleVideoUploadProgress = (progressEvent, videoData) => {
    dispatch(
      setUploadingStart({
        id: videoData.data.id,
        clientId,
      })
    );
  };

  const handleImageUploadProgress = (progressEvent, imageData) => {
    if (online)
      dispatch(
        setPhotoUploadingStart({
          photoId: imageData.photo.id,
        })
      );
  };

  const uploadPhotoFile = ({ data, source }) => {
    dispatch(
      addPhoto(clientId, applicationId, data, source, handleImageUploadProgress)
    );
    cancelTokensMap.set(data.id, source);
  };

  const uploadVideo = ({ data, source, file }) => {
    dispatch(
      addVideo(
        clientId,
        applicationId,
        data,
        handleVideoUploadProgress,
        source,
        file
      )
    );

    dispatch(addVideoUpload({ data, clientId }));
    cancelTokensMap.set(data.id, source);
  };

  const onDeleteHandle = (file) => {
    setAllFiles(allFiles.filter(({ data: { id } }) => id !== file?.data?.id));
  };

  const closeConfirmationDialog = () => {
    dispatch(updateUploaderBackDialogDoNotShowAgain(backDialogCheckbox));
    setOpenConfirmationDialog(false);
  };

  const handleLeave = () => {
    dispatch(updateUploaderBackDialogDoNotShowAgain(backDialogCheckbox));
    history.goBack();
  };

  const handleAddFile = (newFiles) => {
    setAllFiles((prevState) => [...newFiles, ...prevState]);

    newFiles.forEach((newFile) => {
      if (newFile?.data.mediaType === 'video') {
        uploadVideo(newFile);
      } else {
        uploadPhotoFile(newFile);
      }
    });
  };

  useEffect(() => {
    allFiles.map((file) => {
      if (
        photosUpload[file.data.id]?.uploaded ||
        videoUpload[file.data.id]?.uploaded
      )
        file.uploded = true;
      return { ...file };
    });
  }, [allFiles, photosUpload, videoUpload]);

  return (
    <Container maxWidth="sm">
      <AppBar
        title={t('photo_gallery_take_a_photo')}
        displayBackButton
        elevated
        backButtonAction={backButtonAction}
        hiddenHorizontaly
      />
      <FilesUploader
        allFiles={allFiles}
        uploadedFiles={photos?.length || 0}
        handleAddFile={handleAddFile}
        tag={tag}
      />
      <FilesList
        files={allFiles}
        onDelete={onDeleteHandle}
        // onClick={handlePhotoClick}
      />
      <UploaderTip />
      <AutoUploaderTip />
      {/* <FilesDownloadTip /> */}
      <Dialog
        onClose={() => {
          setOpenConfirmationDialog(false);
        }}
        aria-labelledby="confirm-dialog"
        open={openConfirmationDialog}
      >
        <DialogHeader handleClose={() => setOpenConfirmationDialog(false)} />
        <DialogTitle onClose={closeConfirmationDialog}>
          {t('camera_confirm_title')}
        </DialogTitle>
        <DialogContent>
          <p>{t('camera_confirm_text')}</p>
          <div>
            <Checkbox
              label={t('tip_do_not_show_again')}
              onChange={(checkedOrNot) => setBackDialogCheckbox(checkedOrNot)}
            />
          </div>
        </DialogContent>
        <StyledDialogActions>
          <Button
            autoFocus
            onClick={closeConfirmationDialog}
            theme="secondary"
            data-testid="confirm-stay"
          >
            {t('camera_confirm_stay')}
          </Button>
          <Button
            onClick={handleLeave}
            theme="primary"
            data-testid="confirm-leave"
          >
            {t('camera_confirm_leave')}
          </Button>
        </StyledDialogActions>
      </Dialog>
    </Container>
  );
};

CameraView.propTypes = {
  clientData: PropTypes.shape({
    applications: PropTypes.shape({
      total: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default withClientData(CameraView);
