import { LinearProgress } from 'frontend-components';
import Splash from '../components/Splash/Splash';
import NoClientData from '../components/NoClientData/NoClientData';

import {
  useFetchClientDataIfNeeded,
  useFetchProductsIfNeeded,
} from './customHooks';

const defaultOptions = {
  forceFetch: false,
};

const withClientData =
  (BaseComponent, { forceFetch } = defaultOptions) =>
  ({ ...props }) => {
    const { data, loadingStatus: clientDataLoadingStatus } =
      useFetchClientDataIfNeeded(forceFetch);
    const { products, loadingStatus: productsLoadingStatus } =
      useFetchProductsIfNeeded();

    if (data && products) {
      return <BaseComponent {...props} clientData={data} />;
    }

    if (
      clientDataLoadingStatus === 'not-fetched' &&
      productsLoadingStatus === 'not-fetched'
    ) {
      return <NoClientData />;
    }

    return (
      <>
        <Splash />
        <LinearProgress />
      </>
    );
  };

export default withClientData;
