import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import SicCodeGroup from './SicCodeGroup';
import {
  fetchSicCodes,
  formatSicGroupName,
} from '../../store/reducers/sicCodes';
import {
  SearchContainer,
  SearchInput,
  SearchIcon,
} from '../../components/Search/Search';
import { filterName } from '../../helpers/products';
import NothingFound from '../../components/NothingFound/NothingFound';

const CollapsibleContainer = styled.div`
  padding: 5px 0;

  & .MuiButton-root {
    margin: 4px 0;
    border-color: #fff;
    letter-spacing: normal;
    height: auto;
    text-transform: none;
    justify-content: flex-start;
    text-align: left;
    line-height: 20px;
  }

  & .MuiSvgIcon-root {
    color: #999999;
  }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const SicCodeButton = styled(Button)`
  &.MuiButton-root {
    padding: 10px 12px;
  }
`;

const MIN_SEARCH_TERM_LENGTH = 2;

function getFilteredSicCodes(allSicCodes, term) {
  if (term.length < MIN_SEARCH_TERM_LENGTH) {
    return allSicCodes;
  }

  const filteredCategories = allSicCodes.map((category) => ({
    ...category,
    children:
      category.code === term || filterName(category.name, term)
        ? category.children
        : category.children.filter(
            ({ name, code }) =>
              code.includes(term) ||
              name.toUpperCase().includes(term.toUpperCase())
          ),
  }));

  return filteredCategories.filter(({ children }) => children.length > 0);
}

const SicCodes = ({
  clientListItem,
  clientDetailsPath,
  updateClientDetails,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCategoryExpanded, setIsCategoryExpanded] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const searchInputRef = useRef(null);

  const { sicCodesData, sicByCode, loadingStatus } = useSelector(
    (state) => state.sicCodes
  );

  const navigateToClientDetails = useCallback(
    () =>
      history.push(clientDetailsPath, {
        from: 'clientSicCode',
      }),
    [history, clientDetailsPath]
  );

  const handleSicCodeClick = useCallback(
    (code) => {
      const { name } = sicByCode[code];
      dispatch(
        updateClientDetails(clientId, {
          ...clientListItem,
          sicCode: code,
          sicDescription: name,
        })
      );
      navigateToClientDetails();
    },
    [
      clientId,
      sicByCode,
      clientListItem,
      dispatch,
      navigateToClientDetails,
      updateClientDetails,
    ]
  );

  const handleExpandedStateChange = (groupCode, isExpanded) => {
    setIsCategoryExpanded({ [groupCode]: !isExpanded });
  };

  useEffect(() => {
    if (loadingStatus === '' || loadingStatus === 'not-fetched') {
      dispatch(fetchSicCodes());
    }
  }, [dispatch, loadingStatus]);

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchVisible, searchInputRef]);

  const filteredSicCodes = useMemo(() => {
    const filteredSicCodes = getFilteredSicCodes(sicCodesData, searchTerm);
    return (
      <>
        <CollapsibleContainer>
          {filteredSicCodes &&
            filteredSicCodes.map(({ name, code, children }) => {
              const title = `${name.toLowerCase()} ${code}`;
              const sicCodesWithGroupCode = [
                { code, name: formatSicGroupName(name) },
                ...children,
              ];
              return (
                <SicCodeGroup
                  key={code}
                  title={title}
                  itemsSize={children.length}
                  onChange={handleExpandedStateChange}
                  isExpanded={
                    !!isCategoryExpanded[title] ||
                    searchTerm.length >= MIN_SEARCH_TERM_LENGTH
                  }
                >
                  {sicCodesWithGroupCode.map(({ code, name }) => (
                    <SicCodeButton
                      data-testid="sic-code-button"
                      key={code}
                      theme="secondary"
                      fullWidth
                      bgcolor="grey"
                      onClick={() => handleSicCodeClick(code)}
                      endIcon={<AddIcon />}
                    >
                      {name} - {code}
                    </SicCodeButton>
                  ))}
                </SicCodeGroup>
              );
            })}
        </CollapsibleContainer>
        {filteredSicCodes.length === 0 && <NothingFound />}
      </>
    );
  }, [searchTerm, isCategoryExpanded, handleSicCodeClick, sicCodesData]);

  return (
    <StyledContainer maxWidth="sm">
      <AppBar
        title={searchVisible ? null : t('select_sic_code')}
        titleClickAction={() => setSearchVisible(true)}
        content={
          searchVisible && (
            <SearchContainer maxWidth="sm">
              <SearchInput
                placeholder={t('sic_search_placeholder')}
                onChange={(event) => setSearchTerm(event.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                inputRef={searchInputRef}
                autoFocus
                value={searchTerm}
              />
            </SearchContainer>
          )
        }
        displayBackButton
        backButtonAction={navigateToClientDetails}
        aside={
          searchTerm ? (
            <IconButton
              onClick={() => {
                setSearchVisible(false);
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setSearchVisible(true);
                searchInputRef.current && searchInputRef.current.focus();
              }}
              data-testid="search-icon"
            >
              <SearchIcon />
            </IconButton>
          )
        }
      />
      {filteredSicCodes}
    </StyledContainer>
  );
};

SicCodes.propTypes = {
  clientListItem: PropTypes.shape({}),
  clientDetailsPath: PropTypes.string,
  updateClientDetails: PropTypes.func,
};

export default SicCodes;
