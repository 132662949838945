import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';
import AppBar from '../../components/AppBar/AppBar.connected';
import withClientData from '../../helpers/withClientData';
import Client from '../../components/Client/Client';
import Totals from '../../components/Totals/Totals';
import NoData from '../../components/NoData/NoData';
import { TAB_ORDERS } from '../../constants/orders';
import ProductListHeader from '../../components/ProductsListHeader/ProductListHeader';
import ProductsList from '../../components/Lists/ProductsList';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const OrderDetailsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, orderNumber } = useParams();
  const history = useHistory();
  const { name, orderHistory } = clientData;

  const order =
    orderHistory &&
    orderHistory.orders &&
    orderHistory.orders.find((order) => order.number === orderNumber);

  const backButtonAction = () => {
    history.push(`/customers/${clientId}/orderHistory`, { from: TAB_ORDERS });
  };

  return (
    <Container maxWidth="sm" data-testid="order-details">
      <AppBar
        title={t('order_title')}
        displayBackButton
        backButtonAction={backButtonAction}
      />
      {order ? (
        <>
          <Client name={name} />
          <Totals
            createdAt={order.createdAt}
            currency={order.currency}
            totalCost={order.totalCost}
            dateLabel={t('order_total_date')}
          />
          <ProductListHeader
            title={t('order_subtitle')}
            price={t('unit_price')}
          />
          {order.products && <ProductsList products={order.products} />}
        </>
      ) : (
        <NoData noDataMessage={t('order_no_data')} />
      )}
    </Container>
  );
};

OrderDetailsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(OrderDetailsView);
