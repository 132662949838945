import { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Checkbox from '../../components/Checkbox/Checkbox';

const CheckboxContainer = styled.div`
  background: #f2f2f2;
  margin: 5px;
  padding: 0 10px;
  border-radius: 8px;

  ${({ isProductDemoed }) =>
    isProductDemoed &&
    css`
      background: rgba(241, 105, 68, 0.2);
      color: #000;

      .MuiSvgIcon-root {
        color: #f22c18;
      }
    `}
`;

const ProductCheckbox = memo(
  ({ product, onChange, isProductDemoed, category }) => (
    <CheckboxContainer
      onClick={(event) => event.stopPropagation()}
      isProductDemoed={isProductDemoed}
      data-testid="demo-products-category-item"
    >
      <Checkbox
        checked={isProductDemoed}
        controlled
        label={product.name}
        onChange={(checkedOrNot) => onChange(product, checkedOrNot, category)}
      />
    </CheckboxContainer>
  )
);

ProductCheckbox.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isProductDemoed: PropTypes.bool,
  category: PropTypes.string,
};

export default ProductCheckbox;
