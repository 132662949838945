export const NOTE_TYPES = {
  VISITS: 'visits',
  CALLS: 'calls',
  APPLICATIONS: 'applications',
  REPORTS: 'reports',
  REMARKS: 'remarks',
};

export const isVisitNote = (noteType) => noteType === NOTE_TYPES.VISITS;

export const REPORT_TYPES = {
  VISIT: 'visit',
  PHONE_CALL: 'phoneCall',
  APPLICATION_NOTE: 'applicationNote',
  REMARK: 'remark',
};

export const NOTE_BY_REPORT_TYPE = {
  [REPORT_TYPES.VISIT]: NOTE_TYPES.VISITS,
  [REPORT_TYPES.PHONE_CALL]: NOTE_TYPES.CALLS,
  [REPORT_TYPES.APPLICATION_NOTE]: NOTE_TYPES.APPLICATIONS,
  [REPORT_TYPES.REMARK]: NOTE_TYPES.REMARKS,
};
