import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { isToday, isValid } from 'date-fns';
import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  Switch,
  AsideButton,
  usePermissions,
  Label,
  Button,
} from 'frontend-components';
import { formatDate } from 'frontend-components/lib/helpers';

import AddIcon from '@material-ui/icons/Add';
import AddContactPersonSection from './AddContactPersonSection';
import AddDemoedProductSection from './AddDemoedProductSection';
import AddNextActionSection from './AddNextActionSection';
import {
  removeDemoedProduct,
  updateDemoedProductsDate,
} from '../../store/actions/demoProductsActions';
import AppBar from '../../components/AppBar/AppBar.connected';
import DatePicker from '../../components/DatePicker/DatePicker';
import Checkbox from '../../components/Checkbox/Checkbox';
import TextArea from '../../components/TextArea/TextArea';
import { StyledVisitNoteView } from './StyledVisitNoteView';
import ShowNextActionDialog from './ShowNextActionDialog';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import { pickerMinDate, pickerMaxDate } from '../../constants/fixedDates';
import {
  setNewEmptyVisit,
  removeNewEmptyVisit,
} from '../../store/reducers/drafts';
import {
  DraftSwitch,
  DraftMarker,
  DraftCard,
} from '../../components/DraftMarker/DraftMarker';
import useCreatedLocally from '../../hooks/useCreatedLocally';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { deleteClientFromPlannedClients } from '../../store/reducers/clients';
import { setCallNoteContactPerson } from '../../store/actions/notesActions';
import { removeHTMLTags } from '../../helpers/removeHTML';

const VisitNoteContainer = styled(Container)`
  margin-bottom: 110px;
`;

const NoteText = styled.div`
  margin-bottom: 24px;

  label {
    font-size: 12px;
  }

  div {
    margin-top: 4px;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const prepareNoteForNewClient = (values, clientId, prepareNotePayload) => {
  const noteWithNextActionId = prepareNotePayload(values, clientId);
  noteWithNextActionId.nextAction.id = values.nextAction.id;
  noteWithNextActionId.nextAction.reportType = values.nextAction.reportType;

  return noteWithNextActionId;
};

const NoteView = ({
  clientData,
  data,
  productSectionData,
  title,
  postNote,
  putNote,
  deleteNoteTitle,
  deleteNoteStore,
  deleteNote,
  createEmptyNote,
  updateNote,
  noteEditionDone,
  datePickerTestId,
  getNoteDate,
  selectButtonTitle,
  addProductButtonLabel,
  prepareNotePayload,
  backURL,
  gotoCreateView,
  hasNoShow,
  hasDraftSwitch,
  renderUpperSection,
  suggestedProducts,
  productsData,
  testId = 'visit',
  hasProducts,
  showAddPhoto,
}) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [showNextActionDialog, setShowNextActionDialog] = useState(false);
  const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false);
  const [isNewNote, setIsNewNote] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { noteId, clientId } = useParams();
  const createdLocally = useCreatedLocally(noteId);
  const postedNotes = useSelector(
    (state) => state.drafts.postedNotes[clientId]
  );
  const [isNoteMissing, setIsNoteMissing] = useState(false);
  const { newEmptyVisit } = useSelector((state) => state.drafts);
  const [removeNote, setNoteRemove] = useState(false);

  const { disabled } = usePermissions(clientData.access, data && data.owned);
  const { disabled: disabledNextAction } = usePermissions(clientData.access);
  const [wasBackButtonClicked, setBackButtonClicked] = useState(false);

  const { online } = useSelector((state) => state.offline);

  useEffect(() => {
    if (newEmptyVisit === noteId) {
      setIsNewNote(true);
    }
  }, [newEmptyVisit, setIsNewNote, noteId]);

  useEffect(() => {
    if (typeof data === 'undefined' && !isDeleted && !isNewNote) {
      createEmptyNote(noteId, clientId);
      dispatch(setNewEmptyVisit(noteId));
    }
    if (typeof data === 'undefined' && createdLocally && !isNewNote) {
      dispatch(setNewEmptyVisit(noteId));
    }
  }, [
    clientId,
    data,
    dispatch,
    isDeleted,
    noteId,
    isNewNote,
    createdLocally,
    createEmptyNote,
  ]);

  const {
    handleSubmit,
    values,
    setFieldValue,
    dirty,
    errors,
    isValid: isValidFormik,
  } = useFormik({
    initialValues: {
      noteDate: data ? formatDate(getNoteDate(data)) : formatDate(new Date()),
      note: data ? data.note : '',
      isNoShow: data ? data.isNoShow : false,
      isDraft: data ? data.isDraft : true,
      contactPersonId: data ? data.contactPersonId : '',
      editable: data ? data.editable : true,
      nextAction: data ? data.nextAction : null,
      demoProducts: productsData || [],
    },
    validationSchema: Yup.object({
      noteDate: Yup.date()
        .typeError(t('validation_date_format'))
        .min(pickerMinDate, t('validation_date_range'))
        .max(pickerMaxDate, t('validation_date_range')),
    }),
    onSubmit: () => {
      if (
        removeNote ||
        (!(data.edited || dirty) && createdLocally && !data.attached)
      ) {
        deleteNoteStore(noteId, clientId);
      } else if ((data.edited || dirty) && !data.attached) {
        const note = prepareNotePayload(values, clientId);
        const noteWithNextActionId = values.nextAction
          ? prepareNoteForNewClient(values, clientId, prepareNotePayload)
          : note;
        createdLocally && (!postedNotes || !postedNotes[noteId])
          ? postNote(noteId, note, clientId, noteWithNextActionId)
          : putNote(noteId, note, clientId, noteWithNextActionId);
      } else if ((data.edited || dirty) && data.attached) {
        const note = prepareNotePayload(values, clientId);
        const noteWithNextActionId = values.nextAction
          ? prepareNoteForNewClient(values, clientId, prepareNotePayload)
          : note;

        putNote(noteId, note, clientId, noteWithNextActionId);
      }
      dispatch(removeNewEmptyVisit());
      history.push(backURL);

      noteEditionDone && dispatch(noteEditionDone(noteId, clientId));
    },
  });
  const {
    noteDate,
    demoProducts,
    contactPersonId,
    nextAction,
    isNoShow,
    note,
    isDraft,
  } = values;

  const backButtonAction = () => {
    if (disabled) history.push(backURL);

    if (!isValidFormik || !data) return;
    setBackButtonClicked(true);

    if (
      isNewNote &&
      !demoProducts.length &&
      !contactPersonId &&
      !nextAction &&
      !isNoShow &&
      !note &&
      (isDraft || data.remarkDate)
    ) {
      setNoteRemove(true);
      handleSubmit();
    }
    if ((data.edited || dirty) && !note) {
      setIsNoteMissing(true);
      return;
    }
    if (
      (data.edited || dirty) &&
      (nextAction === null || Object.keys(nextAction).length === 0)
    ) {
      setShowNextActionDialog(true);
    } else {
      handleSubmit();
    }
  };

  // set contact from create call report dialog
  const { callDialogContactPhoneNumber } = location.state || '';
  const getContactFromCallReport = () => {
    const contactData = clientData.contactPersons.data.find((contact) =>
      contact.phoneList.find(
        ({ phone }) => phone === callDialogContactPhoneNumber
      )
    );

    return contactData.id;
  };
  const [callDialogContactPersonId, setCallDialogContactPersonId] =
    useState('');

  useEffect(() => {
    if (callDialogContactPhoneNumber) {
      const contactId = getContactFromCallReport();
      setCallDialogContactPersonId(contactId);
      dispatch(setCallNoteContactPerson(contactId, noteId, clientId));
      setFieldValue('contactPersonId', contactId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callDialogContactPhoneNumber]);

  const deleteContactPerson = () => {
    const newValues = { ...values };
    newValues.contactPersonId = '';
    updateNote(newValues);
    setCallDialogContactPersonId('');
    setFieldValue('contactPersonId', '');
    setFieldValue('isDraft', true);
  };

  const handleAddButton = (actionType) => {
    dirty && updateNote(values);
    gotoCreateView(actionType);
  };

  const handleAddPhoto = () => {
    history.push(`/customers/${clientId}/visit/edit/${noteId}`);
  };

  const handleDeleteNote = () => {
    setIsDeleted(true);

    if (demoProducts.length && !suggestedProducts) {
      demoProducts.forEach((product) => {
        dispatch(
          removeDemoedProduct(noteId, clientId, {
            id: product.code,
          })
        );
      });
    }
    deleteNoteStore(noteId, clientId);
    deleteNote(noteId, clientId);
    if (!online && nextAction?.doAt && isToday(new Date(nextAction.doAt))) {
      dispatch(deleteClientFromPlannedClients(clientId));
    }
    history.push(backURL);
  };

  useEffect(() => {
    note.length && isNoteMissing && setIsNoteMissing(false);
  }, [note, isNoteMissing, setIsNoteMissing]);

  // remove new empty note after clicking browser or device back button
  useEffect(() => {
    return () => {
      if (
        isNewNote &&
        !wasBackButtonClicked &&
        history.location.pathname === backURL
      ) {
        deleteNoteStore(noteId, clientId);
      }
    };
  }, [
    history.location,
    isNewNote,
    wasBackButtonClicked,
    clientId,
    backURL,
    noteId,
    deleteNoteStore,
  ]);

  useEffect(() => {
    if (
      (location.state?.from === 'addContactPerson' ||
        location.state?.callDialogContactPhoneNumber) &&
      contactPersonId
    ) {
      setFieldValue('isDraft', false);
      history.replace();
    }
  }, [contactPersonId, setFieldValue, location, history]);

  return (
    <VisitNoteContainer maxWidth="sm" data-testid={testId}>
      <AppBar
        title={title}
        displayBackButton
        backButtonAction={backButtonAction}
        aside={
          !isNewNote && (
            <AsideButton
              data-testid="delete-button"
              onClick={() => {
                setShowDeleteNoteDialog(true);
              }}
              disabled={disabled}
            >
              <DeleteIcon />
            </AsideButton>
          )
        }
      />
      <StyledVisitNoteView>
        {renderUpperSection && renderUpperSection()}
        <form>
          <DatePicker
            value={noteDate}
            id="noteDate"
            data-testid={datePickerTestId}
            onChange={(noteDate) => {
              if (isValid(noteDate)) {
                setFieldValue('noteDate', formatDate(noteDate, true));
                demoProducts.length &&
                  !suggestedProducts &&
                  demoProducts.forEach(({ code }) => {
                    dispatch(
                      updateDemoedProductsDate(
                        formatDate(noteDate, true),
                        noteId,
                        clientId,
                        code
                      )
                    );
                  });
              } else {
                setFieldValue('noteDate', 'invalid date');
              }
            }}
            fullWidth
            label={t('note_date')}
            errors={errors.noteDate}
            errorMessage={errors.noteDate || ''}
            disabled={disabled}
          />
          {hasNoShow && (
            <Checkbox
              label={t('note_hide')}
              id="isNoShow"
              onChange={(val) => {
                setFieldValue('isNoShow', val);
              }}
              checked={isNoShow}
              disabled={disabled}
            />
          )}
          {data?.editable !== false ? (
            <TextArea
              id="note"
              label={t('note_content')}
              placeholder={t('note_placeholder')}
              fullWidth
              onChange={(e) => {
                setFieldValue('note', e.target.value);
              }}
              value={note}
              errorMessage={isNoteMissing ? t('validation_visit_note') : ''}
              disabled={disabled}
            />
          ) : (
            <NoteText>
              <Label className="label">{t('note_content')}</Label>
              <div>{removeHTMLTags(data.note)}</div>
            </NoteText>
          )}
          {showAddPhoto && (
            <StyledButton
              startIcon={<AddIcon />}
              theme="secondary"
              fullWidth
              onClick={handleAddPhoto}
              disabled={disabled}
            >
              {t('note_add_images')}
            </StyledButton>
          )}
          <AddContactPersonSection
            data={data}
            deleteContactPerson={deleteContactPerson}
            handleAddButton={handleAddButton}
            disabled={disabled}
            callDialogContactPersonId={callDialogContactPersonId}
          />
          {hasProducts && (
            <AddDemoedProductSection
              handleAddButton={handleAddButton}
              disabled={disabled}
              selectButtonTitle={selectButtonTitle}
              addProductButtonLabel={addProductButtonLabel}
              data={productSectionData}
            />
          )}
          <AddNextActionSection
            nextActions={{
              total: nextAction ? 1 : 0,
              data: nextAction ? [nextAction] : [],
            }}
            handleAddButton={handleAddButton}
            editNextAction={(action) => {
              dirty && updateNote(values);
              history.push(gotoCreateView(`actions/${action.id}`));
            }}
            onCheckboxClick={() => {
              if (disabled) return;
              const nextActionFlipped = {
                ...nextAction,
                actionDone: !nextAction.actionDone,
              };
              setFieldValue('nextAction', nextActionFlipped);
            }}
            disabled={disabledNextAction}
          />
          {hasDraftSwitch && (
            <DraftCard>
              <DraftSwitch>
                <DraftMarker $withLabel />
                <Switch
                  id="isDraft"
                  label={t('note_finished')}
                  checked={isDraft}
                  setChecked={(value) => {
                    setFieldValue('isDraft', value);
                  }}
                  disabled={
                    note.length === 0 ||
                    contactPersonId.length === 0 ||
                    disabled
                  }
                />
              </DraftSwitch>
            </DraftCard>
          )}
        </form>
      </StyledVisitNoteView>
      <ShowNextActionDialog
        data-testid="report_next_action_dialog"
        open={showNextActionDialog}
        setOpen={setShowNextActionDialog}
        handleSubmit={handleSubmit}
      />
      <DeleteDialog
        data-testid="report_delete_dialog"
        title={deleteNoteTitle}
        open={showDeleteNoteDialog}
        setOpen={setShowDeleteNoteDialog}
        handleDelete={handleDeleteNote}
      />
    </VisitNoteContainer>
  );
};

NoteView.propTypes = {
  clientData: clientDataShape,
  data: PropTypes.shape({
    note: PropTypes.string,
    isNoShow: PropTypes.bool,
    isDraft: PropTypes.bool,
    editable: PropTypes.bool,
    contactPersonId: PropTypes.string,
    nextAction: PropTypes.shape({}),
    demoProducts: PropTypes.arrayOf(PropTypes.shape({})),
    edited: PropTypes.bool,
    attached: PropTypes.bool,
    owned: PropTypes.bool,
  }),
  productSectionData: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  backURL: PropTypes.string,
  postNote: PropTypes.func,
  putNote: PropTypes.func,
  deleteNoteTitle: PropTypes.string,
  deleteNoteStore: PropTypes.func,
  deleteNote: PropTypes.func,
  createEmptyNote: PropTypes.func,
  updateNote: PropTypes.func,
  datePickerTestId: PropTypes.string,
  getNoteDate: PropTypes.func,
  selectButtonTitle: PropTypes.func,
  addProductButtonLabel: PropTypes.string,
  prepareNotePayload: PropTypes.func,
  gotoCreateView: PropTypes.func,
  hasDraftSwitch: PropTypes.bool,
  noteEditionDone: PropTypes.func,
  hasNoShow: PropTypes.bool,
  renderUpperSection: PropTypes.func,
  suggestedProducts: PropTypes.bool,
  productsData: PropTypes.arrayOf(PropTypes.shape({})),
  testId: PropTypes.string,
  hasProducts: PropTypes.bool,
  showAddPhoto: PropTypes.bool,
};

export default NoteView;
