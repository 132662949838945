import { useEffect, useState } from 'react';

import {
  FEATURE_ORDERS_AND_QUOTATIONS,
  ENVIRONMENT,
} from '../constants/features';

const features = {
  [FEATURE_ORDERS_AND_QUOTATIONS]: {
    [ENVIRONMENT.PRODUCTION]: true,
    [ENVIRONMENT.UAT]: true,
    [ENVIRONMENT.LOCAL]: true,
    [ENVIRONMENT.DEV]: true,
  },
};

const useFeature = (featureName) => {
  const [available, setAvailable] = useState(false);
  const { hostname } = window.location;

  useEffect(() => {
    setAvailable(features[featureName] && features[featureName][hostname]);
  }, [setAvailable, featureName, hostname]);

  return available;
};

export default useFeature;
