import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SicCodes from './SicCodes';
import { updateNewClientDetails } from '../../store/reducers/client';

const NewClientSicCodeView = () => {
  const { clientId } = useParams();
  const { newClientListItem } = useSelector((state) => state.client);
  return (
    <SicCodes
      clientListItem={newClientListItem}
      clientDetailsPath={`/customers/${clientId}/client/create`}
      updateClientDetails={updateNewClientDetails}
    />
  );
};

export default NewClientSicCodeView;
