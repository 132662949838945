import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'frontend-components';
import { parseHistoryItems } from 'frontend-components/lib/helpers';

import Collapsible, { CollapsibleContent } from '../Collapsible/Collapsible';
import OrdersIcon from '../Icons/OrdersIcon';
import BarChart from '../BarChart/BarChart';
import useFeature from '../../hooks/useFeature';
import { FEATURE_ORDERS_AND_QUOTATIONS } from '../../constants/features';

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const OrdersList = ({
  title = null,
  expanded,
  onChange,
  ordersChartData,
  'data-testid': testId,
  disabled,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams();
  const ordersAndQuotationsAvailable = useFeature(
    FEATURE_ORDERS_AND_QUOTATIONS
  );

  const goToOrdersView = () =>
    history.push(`/customers/${clientId}/orderHistory`);

  return (
    <Collapsible
      expanded={expanded}
      onChange={onChange}
      icon={<OrdersIcon />}
      title={title || t('orders')}
      collapsible={!!ordersChartData?.data}
      onPlusButtonClick={() => history.push(`/customers/${clientId}/create`)}
      data-testid={testId}
      disabled={disabled}
      displayPlusButton={ordersAndQuotationsAvailable}
    >
      <CollapsibleContent>
        {ordersChartData?.data?.length ? (
          <BarChart
            chartData={parseHistoryItems(ordersChartData.data)}
            handleClick={goToOrdersView}
          />
        ) : (
          <p>{t('orders_no_data')}</p>
        )}
        <ButtonContainer>
          <Button
            theme="secondary"
            size="small"
            onClick={goToOrdersView}
            data-testid="see-history"
          >
            {t('see_history')}
          </Button>
        </ButtonContainer>
      </CollapsibleContent>
    </Collapsible>
  );
};

OrdersList.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  ordersChartData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        month: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
};

export default OrdersList;
