import ArrowRight from '@material-ui/icons/ArrowRight';
import styled from 'styled-components';

const VideoIcon = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 30%);
  color: #b7b7ba;
  pointer-events: none;
`;

function Icon() {
  return (
    <VideoIcon>
      <ArrowRight style={{ fontSize: 20 }} />
    </VideoIcon>
  );
}

export default Icon;
