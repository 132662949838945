import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateAutoUploaderTipDialogDoNotShowAgain } from '../../store/reducers/clientUI';
import Tip from '../Tip/Tip';

const AutoUploaderTip = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showAutoUploaderTipDialog, setShowAutoUploaderTipDialog] =
    useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { autoUploaderTipDialogDoNotShowAgain } = useSelector(
    (state) => state.clientUI
  );

  useEffect(() => {
    if (!autoUploaderTipDialogDoNotShowAgain) {
      setShowAutoUploaderTipDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    dispatch(updateAutoUploaderTipDialogDoNotShowAgain(doNotShowAgain));
    setShowAutoUploaderTipDialog(false);
  };

  return (
    <Tip
      title={t('file_upload_tip_title')}
      text={t('file_upload_tip')}
      showTip={showAutoUploaderTipDialog}
      setShowTip={handleButtonClick}
      showButton
      buttonLabel={t('file_upload_tip_button')}
      onButtonClick={handleButtonClick}
      showCheckbox
      onCheckboxClick={(checkedOrNot) => {
        setDoNotShowAgain(checkedOrNot);
      }}
      icon="info"
    />
  );
};

export default AutoUploaderTip;
