import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddContactPersonView from './AddContactPersonView';
import withClientData from '../../helpers/withClientData';
import {
  setNewOrderContactPerson,
  setNewOrderContactsCC,
} from '../../store/reducers/drafts';
import useCheckLocationState from '../../hooks/useCheckLocationState';
import { updateQrderOrQuotationExpandedPanel } from '../../store/reducers/clientUI';
import { ORDER_OR_QUOTATION_PANEL_TYPE } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const AddNewOrderContactPersonView = ({ clientData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const isFromSendCopyTo = useCheckLocationState('sendCopyTo');

  const { contactToExclude } = useSelector((state) => ({
    contactToExclude: state.drafts.newOrder.contactPersonId,
  }));

  const handleNewOrderContactsCC = (contactPersonId) => {
    dispatch(setNewOrderContactsCC(contactPersonId));
    dispatch(
      updateQrderOrQuotationExpandedPanel({
        clientId,
        expandedPanel: ORDER_OR_QUOTATION_PANEL_TYPE.CC,
      })
    );
  };

  return (
    <AddContactPersonView
      clientData={clientData}
      onContactSelected={(contactPersonId) => {
        isFromSendCopyTo
          ? handleNewOrderContactsCC(contactPersonId)
          : dispatch(setNewOrderContactPerson(contactPersonId));
        history.goBack();
      }}
      onAddContactClicked={() => {
        history.push(
          `/customers/${clientId}/create/order/addContactPerson/${uuidv4()}`,
          { from: isFromSendCopyTo ? 'sendCopyTo' : '' }
        );
      }}
      goBackAction={() => history.push(`/customers/${clientId}/create/order`)}
      showContactsOnlyWithEmails={isFromSendCopyTo}
      displayEmail
      contactToExclude={contactToExclude}
    />
  );
};

AddNewOrderContactPersonView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(AddNewOrderContactPersonView);
