function OrderIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M7 14l8-8h34l8 8v44H7z" />
        <path
          d="M49.414 5L58 13.586V59H6V13.586L14.586 5h34.828zM56 15H8v42h48V15zM42.292 28.294l1.416 1.412-13.684 13.71-6.732-6.745 1.416-1.413 5.316 5.326 12.268-12.29zM20 23v2h-8v-2h8zm7-4v2H12v-2h15zM48.585 7H15.414l-6.001 6h45.172l-6-6z"
          fill="#E42313"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default OrderIcon;
