import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SnackBar from '../SnackBar/SnackBar';

const NewAppVersionNotification = ({ open }) => {
  const { t } = useTranslation();
  const [showSnackBar, setShowSnackBar] = useState(false);

  useEffect(() => {
    setShowSnackBar(open);
  }, [open]);

  const reloadPage = () => {
    setShowSnackBar(false);
    window.location.reload(true);
  };

  return (
    <SnackBar
      open={showSnackBar}
      message={t('new_version_notification')}
      handleButtonClick={reloadPage}
      btnText={t('reload')}
    />
  );
};

NewAppVersionNotification.propTypes = {
  open: PropTypes.bool,
};

export default NewAppVersionNotification;
