import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EventIcon from '@material-ui/icons/Event';
import { isValidDate } from 'frontend-components/lib/helpers';

import Input from '../Input/Input';

const CalendarPicker = ({
  label,
  fullWidth,
  id,
  value,
  onClick,
  onChange,
  errors,
  disabled,
}) => {
  const [dateValue, setDateValue] = useState(value);

  const handleDateChange = (date) => {
    let finalDate = date;
    const currentDate = date.split(' ')[0];
    if (currentDate.includes('/')) {
      finalDate = currentDate.split('/').reverse().join('-');
    }
    setDateValue(date);

    if (onChange) {
      onChange(isValidDate(finalDate) ? new Date(finalDate) : finalDate);
    }
  };

  const formatDate = (date) => {
    if (date === 'invalid date') return dateValue;

    const currentDate = date.split('T')[0].split(' ')[0];
    if (currentDate.includes('-')) {
      return currentDate.split('-').reverse().join('/');
    }
    return date;
  };

  useEffect(() => {
    setDateValue(formatDate(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      id={id}
      label={label}
      onchange={handleDateChange}
      value={dateValue}
      fullWidth={fullWidth}
      rightIcon={<EventIcon data-testid="calendar" />}
      rightIconSize="medium"
      onIconClick={onClick}
      errorMessage={errors}
      disabled={disabled}
      type="date"
    />
  );
};

CalendarPicker.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  id: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CalendarPicker;
