import SvgIcon from '@material-ui/core/SvgIcon';

function FileWord(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M14,2 L20,8 L20,20 C20,21.05 19.1799587,21.9177686 18.1486946,21.9944872 L18,22 L5.99,22 C4.94,22 4.08134298,21.1799587 4.00545267,20.1486946 L4,20 L4.01,4 C4.01,2.95 4.82092975,2.0822314 5.85136551,2.00551277 L6,2 L14,2 Z M9.0234375,11.0375 L7.11484375,11.0375 L8.81015625,19 L10.7953125,19 L11.8398438,14.4609375 L12.90625,19 L14.896875,19 L16.5867188,11.0375 L14.6835938,11.0375 L13.7703125,16.0304688 L12.6601563,11.0375 L11.0304688,11.0375 L9.93671875,16.0304688 L9.0234375,11.0375 Z M13,3.5 L13,9 L18.5,9 L13,3.5 Z"
      />
    </SvgIcon>
  );
}

export default FileWord;
