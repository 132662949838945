import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: #f2f2f2;
  }
`;

export default StyledMenuItem;
