import PropTypes from 'prop-types';
import {
  NoClientsDescription,
  NoPlannedClientsContainer,
} from './SelectCustomerView.styles';

const NoFilteredClients = ({ description = '' }) => {
  return (
    <NoPlannedClientsContainer>
      <NoClientsDescription>{description}</NoClientsDescription>
    </NoPlannedClientsContainer>
  );
};

NoFilteredClients.propTypes = {
  description: PropTypes.string,
};

export default NoFilteredClients;
