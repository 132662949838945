import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from 'uuid';
import withClientData from '../../helpers/withClientData';
import NoteView from './NoteView';
import {
  createEmptyVisitNote,
  updateNoteStore,
  deleteVisitNoteStore,
  deleteVisitById,
  putVisit,
  postVisit,
} from '../../store/actions/notesActions';
import { getDemoedProductsSectionData } from '../../helpers/products';
import { prepareVisitNotePayload } from '../../helpers/prepNotePayload';
import { NOTE_TYPES } from '../../constants/noteTypes';
import { updateExpandedPanel } from '../../store/reducers/clientUI';
import { LIST_TYPES } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const VisitNoteView = ({ clientData }) => {
  const { noteId, clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [productSectionData, setProductSectionData] = useState([]);

  const handlePostNote = (
    noteId,
    visitWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateNoteStore(noteId, noteWithNextActionId));
    dispatch(postVisit(noteId, visitWithClientId, clientId));
    dispatch(
      updateExpandedPanel({ clientId, expandedPanel: LIST_TYPES.REPORTS })
    );
  };

  const handlePutNote = (
    noteId,
    visitWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateNoteStore(noteId, noteWithNextActionId));
    dispatch(putVisit(noteId, visitWithClientId, clientId));
  };

  const handleDeleteNoteStore = (noteId, clientId) => {
    dispatch(deleteVisitNoteStore(noteId, clientId));
  };

  const handleDeleteNote = (noteId, clientId) => {
    dispatch(deleteVisitById(noteId, clientId));
  };

  const handleCreateEmptyNote = (noteId, clientId) => {
    dispatch(createEmptyVisitNote(noteId, clientId));
  };

  const getNoteDate = (data) => data.visitDate;

  const handleUpdateNote = (values) => {
    const noteWithClientId = {
      ...values,
      visitDate: values.noteDate,
      clientId,
    };
    delete noteWithClientId.noteDate;

    dispatch(updateNoteStore(noteId, noteWithClientId));
  };

  const selectButtonTitle = (productsLength) => {
    return productsLength ? t('note_demo') : t('note_add_demoed');
  };

  const addProductButtonLabel = t('note_add_demoed');

  const data = clientData.visits.data.find((visit) => visit.id === noteId);

  useEffect(() => {
    setProductSectionData(getDemoedProductsSectionData(clientData, data));
  }, [data, clientData]);

  const gotoCreateView = (actionType) => {
    history.push(
      `/customers/${clientId}/${NOTE_TYPES.VISITS}/${noteId}/${actionType}`
    );
  };

  return (
    <NoteView
      clientData={clientData}
      data={data}
      productSectionData={productSectionData}
      title={t('note_title')}
      backURL={`/customers/${clientId}`}
      postNote={handlePostNote}
      putNote={handlePutNote}
      deleteNoteTitle={t('visit_note_delete_title')}
      deleteNoteStore={handleDeleteNoteStore}
      deleteNote={handleDeleteNote}
      createEmptyNote={handleCreateEmptyNote}
      updateNote={handleUpdateNote}
      datePickerTestId="visit_date_picker"
      getNoteDate={getNoteDate}
      selectButtonTitle={selectButtonTitle}
      addProductButtonLabel={addProductButtonLabel}
      prepareNotePayload={prepareVisitNotePayload}
      gotoCreateView={gotoCreateView}
      hasNoShow
      hasDraftSwitch
      productsData={data && data.demoProducts}
      hasProducts
      showAddPhoto={!validate(noteId)}
    />
  );
};

VisitNoteView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(VisitNoteView);
