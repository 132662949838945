import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Products from './Products';
import withClientData from '../../../helpers/withClientData';
import {
  addProductToApplicationNote,
  removeDemoedProductFromApplicationNote,
  addDemoedProductsPerApplicationNote,
} from '../../../store/actions/applicationNoteActions';
import { excludeMiscellaneous } from '../../../helpers/products';
import { clientDataShape } from '../../../helpers/clientDataPropTypes';

const ApplicationNoteProductsView = ({ clientData }) => {
  const { t } = useTranslation();
  const { clientId, noteId } = useParams();

  const productsFromStore = clientData.applicationNotes.data.find(
    (applicationNote) => applicationNote.id === noteId
  ).demoProducts;

  const productsData = useSelector((state) =>
    excludeMiscellaneous(state.products[clientId].data)
  );

  return (
    <Products
      title={t('demo_products_title')}
      productsData={productsData}
      productsFromStore={productsFromStore}
      addProduct={addProductToApplicationNote}
      removeProduct={removeDemoedProductFromApplicationNote}
      addProductsPerNote={addDemoedProductsPerApplicationNote}
    />
  );
};

ApplicationNoteProductsView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ApplicationNoteProductsView);
