import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { validate } from 'uuid';
import { LinearProgress } from 'frontend-components';
import { groupByDate } from 'frontend-components/lib/helpers';

import { fallbackImg } from '../../helpers/uploadPhoto';
import {
  filterGalleryItemsByTagId,
  filterGalleryItemsByIds,
} from '../../helpers/galleryItems';
import { isVideoType } from '../../helpers/video';
import VideoThumbnail, { SquareVideo } from '../VideoThumbnail/VideoThumbnail';
import UploadStatusIcon from '../ImageUploader/UploadStatusIcon';

const PhotosDate = styled.p`
  color: #666666;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin: 0 1.66% 2px;
`;

const GroupedPhotos = styled.div`
  margin-bottom: 30px;
`;

const PhotosContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const SquarePhoto = styled.div`
  float: left;
  width: 30%;
  margin: 1.66%;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const StyledUploadStatusIcon = styled(UploadStatusIcon)`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
`;

const Gallery = ({ photos, applications, loading, tagId, galleryPath }) => {
  const [groupedPhotos, setGroupedPhotos] = useState([]);
  const { applicationId } = useParams();
  const history = useHistory();

  const { uploaded } = useSelector((state) => ({
    uploaded: state.photosFromDevice.upload || [],
  }));
  const { uploadVideo } = useSelector((state) => ({
    uploadVideo: state.video.upload,
  }));

  const groupPhotosByDate = (photos) => {
    const photosWithUpdatedAt = photos.map((photo) => ({
      ...photo,
      createdAt: photo.createdAt || new Date(0).toISOString(),
    }));
    return groupByDate(photosWithUpdatedAt, (photos) => photos.createdAt);
  };

  useEffect(() => {
    if (applications && applicationId) {
      const foundedAppData = applications.find(
        (application) => application.id === applicationId
      );
      if (!foundedAppData) {
        return;
      }

      const filteredPhotos = filterGalleryItemsByTagId(
        filterGalleryItemsByIds(photos, foundedAppData.photos),
        tagId
      );

      setGroupedPhotos(groupPhotosByDate(filteredPhotos));
    } else {
      setGroupedPhotos(groupPhotosByDate(photos));
    }
  }, [applications, applicationId, photos, tagId]);

  const handleImageError = (e, link) => {
    e.target.onerror = null;
    e.target.src = link || fallbackImg;
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <PhotosContainer data-testid="gallery">
          {[...groupedPhotos].map((result) => (
            <GroupedPhotos key={result[0]}>
              <PhotosDate data-testid="photos-date">{result[0]}</PhotosDate>
              {result[1].map(
                ({
                  id,
                  fileUrl,
                  link,
                  description,
                  mediaType,
                  thumbnailUrl,
                }) => {
                  if (isVideoType(mediaType)) {
                    return (
                      <SquareVideo key={id}>
                        <StyledUploadStatusIcon
                          status={uploadVideo[id]}
                          id={id}
                        />
                        <VideoThumbnail
                          onClick={() => {
                            !validate(id) &&
                              history.push(`${galleryPath}/gallery/${id}`, {
                                from: tagId,
                              });
                          }}
                          thumbnail={thumbnailUrl}
                        />
                      </SquareVideo>
                    );
                  }
                  return (
                    <SquarePhoto key={id}>
                      <StyledUploadStatusIcon status={uploaded[id]} id={id} />
                      <img
                        src={fileUrl || link || fallbackImg}
                        alt={description}
                        onClick={() =>
                          history.push(
                            validate(id)
                              ? `${galleryPath}/upload/${id}`
                              : `${galleryPath}/gallery/${id}`,
                            {
                              from: tagId,
                            }
                          )
                        }
                        onError={(e) => handleImageError(e, link)}
                      />
                    </SquarePhoto>
                  );
                }
              )}
            </GroupedPhotos>
          ))}
        </PhotosContainer>
      )}
    </>
  );
};

Gallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
      fileUrl: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  tagId: PropTypes.string,
  galleryPath: PropTypes.string,
  applications: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Gallery;
