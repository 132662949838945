import { createSlice, createAction } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import { formatDate } from 'frontend-components/lib/helpers';

import { fetchClientDataById } from '../actions/clientDataActions';
import { fetchCurrentlyLoggedUser } from '../actions/userActions';
import { VIDEO } from '../../constants/photo';

export const ACTION_ADD_VIDEO_COMMIT = 'videos/addVideo/commit';

export const addVideo = createAction(
  'videos/addVideo',
  function prepare(
    clientId,
    applicationId,
    data,
    onUploadProgress,
    source,
    file
  ) {
    let isStarted = false;
    const payload = {
      clientId,
      applicationId,
      data,
    };

    const formData = serialize(
      {
        tags: data?.tags,
        description: '',
        clientId,
      },
      { indices: true }
    );
    formData.append('movie', file);

    return {
      payload,
      meta: {
        offline: {
          effect: {
            url: `/qac/video/${data.id}`,
            method: 'POST',
            data: formData,
            onUploadProgress: (event) => {
              !isStarted &&
                onUploadProgress &&
                onUploadProgress(event, payload);
              isStarted = true;
            },
            cancelToken: source.token,
            headers: { 'Content-Type': 'multipart/form-data' },
          },
          commit: {
            type: ACTION_ADD_VIDEO_COMMIT,
            meta: {
              clientId,
              applicationId,
              data,
              after: applicationId ? undefined : fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'videos/addVideo/rollback',
            meta: { clientId, applicationId, data },
          },
        },
      },
    };
  }
);

export const addAttachmentVideo = createAction(
  'videos/addAttachmentVideo',
  function prepare(
    clientId,
    reportId,
    reportDate,
    data,
    onUploadProgress,
    source,
    file,
    callSource
  ) {
    let isStarted = false;
    const payload = {
      clientId,
      reportId,
      data,
      callSource,
    };

    const formData = serialize(
      {
        visitDate: reportDate,
        clientId,
      },
      { indices: true }
    );
    formData.append('movie', file);

    return {
      payload,
      meta: {
        offline: {
          effect: {
            url: `/qac/attachment/video/${data.id}/${reportId}`,
            method: 'POST',
            data: formData,
            onUploadProgress: (event) => {
              !isStarted &&
                onUploadProgress &&
                onUploadProgress(event, payload);
              isStarted = true;
            },
            cancelToken: source.token,
            headers: { 'Content-Type': 'multipart/form-data' },
          },
          commit: {
            type: ACTION_ADD_VIDEO_COMMIT,
            meta: {
              clientId,
              reportId,
              data,
              // after: applicationId ? undefined : fetchClientDataById,
              // afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'videos/addVideo/rollback',
            meta: { clientId, reportId, data },
          },
        },
      },
    };
  }
);

export const editVideo = createAction(
  'videos/editVideo',
  function prepare(clientId, applicationId, videoId, video) {
    return {
      payload: {
        clientId,
        applicationId,
        videoId,
        video,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/video/${videoId}`,
            method: 'PUT',
            data: {
              clientId,
              description: video.description,
              tags: video.tags,
            },
          },
          commit: {
            type: 'videos/editVideo/commit',
            meta: {
              clientId,
              applicationId,
              video,
              after: applicationId
                ? [fetchCurrentlyLoggedUser]
                : [fetchClientDataById, fetchCurrentlyLoggedUser],
              afterArgs: [[{ clientId }], []],
            },
          },
          rollback: {
            type: 'videos/editVideo/rollback',
            meta: { clientId, video },
          },
        },
      },
    };
  }
);

export const deleteVideo = createAction(
  'videos/deleteVideo',
  function prepare(clientId, applicationId, videoId) {
    return {
      payload: {
        clientId,
        applicationId,
        videoId,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/video/${videoId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'videos/deleteVideo/commit',
            meta: {
              clientId,
              videoId,
              applicationId,
              after: applicationId ? undefined : fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'videos/deleteVideo/rollback',
            meta: { clientId, videoId },
          },
        },
      },
    };
  }
);

const videoSlice = createSlice({
  name: 'videos',
  initialState: { loading: false, upload: {}, idByUuid: {} },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    addVideoUpload: (state, action) => {
      const { data, clientId } = action.payload;
      state.upload[data.id] = {
        id: data.id,
        client: clientId,
        tags: data.tags,
        createdAt: formatDate(new Date()),
        updatedAt: formatDate(new Date()),
        mediaType: VIDEO,
        uploading: false,
        uploaded: false,
        cancelled: false,
        failed: false,
      };
    },
    setUploadingStart: (state, action) => {
      const { id } = action.payload;
      state.upload[id] = {
        ...state.upload[id],
        uploading: true,
        uploaded: false,
        cancelled: false,
        failed: false,
      };
    },
    setUploadingDone: (state, action) => {
      const { id } = action.payload;
      state.upload[id].uploaded = true;
      state.upload[id].uploading = false;
      state.upload[id].failed = false;
    },
    setUploadingCancelled: (state, action) => {
      const { id } = action.payload;
      if (state.upload[id]) {
        state.upload[id].cancelled = true;
        state.upload[id].uploading = false;
      }
    },
    setUploadingFailed: (state, action) => {
      const { id } = action.payload;
      state.upload[id].uploaded = false;
      state.upload[id].uploading = false;
      state.upload[id].failed = true;
    },
    clearUploadingProgress: (state) => {
      state.upload = {};
      state.idByUuid = {};
    },
    clearIdByUuid: (state, action) => {
      delete state.idByUuid[action.applicationId];
    },
  },
  extraReducers: {
    'applicationReport/post/commit': (state, action) => {
      state.idByUuid[action.meta.applicationId] = action.payload.id;
    },
    'clientData/fetchById/fulfilled': (state, action) => {
      const { clientId } = { clientId: action.payload.id };
      Object.values(state.upload).map(({ id, client, uploaded }) => {
        if (uploaded && client === clientId) delete state.upload[id];
        return id;
      });
    },
    [ACTION_ADD_VIDEO_COMMIT]: (state, action) => {
      const videoId = action.meta.data.id;

      // remove this video from upload - it will be added into clientData slice
      delete state.upload[videoId];
    },
  },
});

export const {
  setLoading,
  addVideoUpload,
  setUploadingStart,
  setUploadingDone,
  clearUploadingProgress,
  clearIdByUuid,
  setUploadingCancelled,
  setUploadingFailed,
} = videoSlice.actions;
export default videoSlice.reducer;
