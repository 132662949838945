import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Divider } from 'frontend-components';
import { parseHistoryItems } from 'frontend-components/lib/helpers';

import { TabContainer } from './Tabs.styles';
import BarChart from '../../components/BarChart/BarChart';
import List from '../../components/List/List';
import ListItem from '../../components/List/ListItem';
import ListItemText from '../../components/List/ListItemText';
import { productsHistoryShape } from '../../helpers/clientDataPropTypes';

const ProductContainer = styled.div`
  .MuiListItem-root {
    flex-direction: column;
    padding: 24px 0 14px;
  }

  .MuiListItemText-root {
    width: 100%;
  }

  .MuiBadge-root {
    display: flex;
    width: 100%;
  }

  .MuiBadge-badge {
    width: 20px;
    height: 20px;
    transform: scale(1) translate(0, 0);
    font-size: 14px;
    font-weight: bold;
  }

  .MuiBadge-invisible {
    transform: scale(0) translate(0, 0);
  }
`;

const Subtitle = styled.p`
  color: #666666;
  text-align: left;
  margin: 0 0 10px 0;
  width: 100%;
`;

const ProductsOrderedTab = ({ productsData }) => {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container maxWidth="sm">
      <TabContainer $marginTop="-8px">
        <List data-testid="products-list">
          {productsData &&
            productsData.map((product, index) => {
              const { name, chartData, groupCode } = product;
              const isLastItem = index === productsData.length - 1;
              const lastYearChartData = parseHistoryItems(chartData);
              const totalValue = lastYearChartData.reduce(
                (acc, curr) => acc + parseFloat(curr.value),
                0
              );
              const hasChartData = chartData.length && totalValue > 0;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ProductContainer key={`${groupCode}-${index}`}>
                  <ListItem
                    button
                    onClick={() =>
                      history.push(
                        `/customers/${clientId}/orderHistory/product/${groupCode}`
                      )
                    }
                  >
                    <Badge
                      color="primary"
                      badgeContent="!"
                      invisible={hasChartData}
                    >
                      <ListItemText primary={name || groupCode} />
                    </Badge>
                    {!hasChartData && (
                      <Subtitle>{t('not_ordered_for_1_year')}</Subtitle>
                    )}
                    <BarChart chartData={lastYearChartData} />
                  </ListItem>
                  {!isLastItem && <Divider />}
                </ProductContainer>
              );
            })}
        </List>
      </TabContainer>
    </Container>
  );
};

ProductsOrderedTab.propTypes = {
  productsData: productsHistoryShape,
};

export default ProductsOrderedTab;
