import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'frontend-components';

import Dialog from '../../../components/Dialog/Dialog';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-elevation24 {
    padding: 0;
  }

  .MuiDialogTitle-root {
    padding: 24px 24px 0 24px;
  }

  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: inherit;
  }
`;

export const StyledButton = styled(Button)`
  width: 45%;
  max-width: 140px;

  @media (min-width: 450px) {
    margin-left: 24px !important;
  }
`;

export const StyledDialogActions = styled.div`
  justify-content: space-between;
  padding: 24px 24px 32px;
  display: flex;

  @media (min-width: 450px) {
    justify-content: flex-end;
  }
`;

export const TotalsSection = styled(DialogActions)`
  &.MuiDialogActions-spacing {
    padding: 24px 15px 32px;
    display: flex;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  }
  & > div {
    width: 100%;
  }
`;

export const Title = styled.p`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0 0 4px 0;
`;

export const ProductName = styled.p`
  color: #666666;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`;

export const AddPackagingButtonContainer = styled.div`
  padding: 24px 24px 0;
`;

export const HeaderWrapper = styled.div`
  padding: 24px;
`;
