import SvgIcon from '@material-ui/core/SvgIcon';

function StatusDone(props) {
  return (
    <SvgIcon {...props}>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="16.0.photo_gallery-v2"
          transform="translate(-256.000000, -714.000000)"
        >
          <g
            id="check_circle_black_24dp"
            transform="translate(256.000000, 714.000000)"
          >
            <circle id="Oval" fill="#FFFFFF" cx="12" cy="12" r="12" />
            <path
              d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
              id="Shape"
              fill="#00BB7E"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default StatusDone;
