import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Lazy, Pagination, Virtual } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/lazy/lazy.min.css';
import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Button, AsideButton, usePermissions } from 'frontend-components';
import { sortByDate } from 'frontend-components/lib/helpers';

import AppBar from '../../components/AppBar/AppBar.connected';
import Input from '../../components/Input/Input';
import { findPhoto } from '../../helpers/findPhoto';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import Chip from '../../components/Chips/Chip';
import { fallbackImg } from '../../helpers/uploadPhoto';
import {
  filterGalleryItemsByIds,
  filterGalleryItemsByTagId,
  findPhotoInApplications,
} from '../../helpers/galleryItems';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { isVideoType } from '../../helpers/video';

const ImageWrapper = styled.div`
  position: relative;
  margin: 20px 0 39px;
  min-height: 200px;
  text-align: center;
`;

const ImageCounter = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 4px;
  background-color: #000000;
  opacity: 0.4;
  color: #fff;
  padding: 6px;
  font-size: 12px;
  z-index: 10;
`;

const StyledSwiper = styled(Swiper)`
  padding-bottom: 20px;

  .swiper-pagination {
    bottom: 5px;
  }

  .swiper-pagination-bullet-active {
    background-color: #e42313;
  }

  .swiper-lazy-preloader {
    border-color: #eee;
    border-top-color: transparent;
  }

  .swiper-slide-active .swiper-lazy-preloader {
    animation: swiper-preloader-spin 1s infinite linear;
  }
`;

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  min-height: 200px;
`;

const VideoContainer = styled.div`
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const TagsSection = styled.div`
  margin: 44px 0 20px;
`;

const ButtonContainer = styled.div`
  padding-bottom: 20px;

  .MuiButtonBase-root {
    margin-bottom: 16px;
  }
`;

const photoSettings = {
  details: 'photo_details',
  deleteTitle: 'photo_delete_title',
  deleteMessageFromApp: 'photo_delete_message_ar',
};

const videoSettings = {
  details: 'video_details',
  deleteTitle: 'video_delete_title',
  deleteMessageFromApp: 'video_delete_message_ar',
};

const GalleryItemView = ({
  clientData,
  deletePhoto,
  deleteVideo = () => {},
  editPhoto,
  editVideo = () => {},
  setAsEntryPhoto,
}) => {
  const { t } = useTranslation();
  const { photoId, clientId, applicationId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [photo, setPhoto] = useState({});
  const [photoIndex, setPhotoIndex] = useState(null);
  const [photosList, setPhotosList] = useState([]);
  const [photosPrev, setPhotoPrev] = useState(null);
  const [photosNext, setPhotoNext] = useState(null);
  const [showDeletePhotoDialog, setShowDeletePhotoDialog] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [photoUsedInApplicationReport, setPhotoUsedInApplicationReport] =
    useState({});

  const { disabled, readOnly } = usePermissions(
    clientData.access,
    photo && photo.owned
  );

  const prepareTags = (tags) =>
    tags && tags.length > 0
      ? tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
        }))
      : [];

  const translateTagName = (name) => t(name.replace('qac.', ''));

  const { userTags } = useSelector((state) => ({
    userTags: prepareTags(state.user.data.tags),
  }));

  const { photosFromDevice, uploaded } = useSelector((state) => ({
    photosFromDevice: state.photosFromDevice.addedPhotos[clientId] || [],
    uploaded: state.photosFromDevice.upload || [],
  }));

  const settings = !isVideo ? photoSettings : videoSettings;
  const showPhoto = photo && !isVideo;

  useEffect(() => {
    const photosAndVideos = [...photosFromDevice];

    const id = photo.id || photoId;

    if (clientData) {
      setPhoto(findPhoto(clientData.photos.data, photosAndVideos, id));
      const applicationPhotos = findPhotoInApplications(
        clientData.applications.data,
        id
      );
      if (!isEmpty(applicationPhotos)) {
        setPhotoUsedInApplicationReport(
          findPhotoInApplications(clientData.applications.data, id)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded, clientData?.photos?.data]);

  useEffect(() => {
    !isEmpty(photo) && setIsVideo(isVideoType(photo.mediaType));
  }, [photo]);

  useEffect(() => {
    if (!clientData) return;

    if (!applicationId) {
      setPhotosList([
        ...photosFromDevice,
        ...sortByDate([...clientData.photos.data]),
      ]);
    } else {
      const photos = clientData.applications.data.find(
        ({ id }) => id === applicationId
      )?.photos;
      const filteredPhotos = filterGalleryItemsByTagId(
        filterGalleryItemsByIds(
          [...clientData.photos.data, ...photosFromDevice],
          photos
        ),
        location?.state?.from
      );

      setPhotosList(sortByDate(filteredPhotos));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, clientId, applicationId, photo, location]);

  useEffect(() => {
    if (isEmpty(photo)) return;
    const photoIndex = photosList.findIndex(({ id }) => id === photo.id);

    setPhotoIndex(photoIndex);
    setPhotoPrev(photosList[photoIndex - 1]);
    setPhotoNext(photosList[photoIndex + 1]);
  }, [photo, photosList]);

  const { handleSubmit, submitForm, handleBlur, setFieldValue, values, dirty } =
    useFormik({
      initialValues: {
        id: photo.id || '',
        description: photo.description || '',
        tags: prepareTags(photo.tags),
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        const payload = {
          ...values,
          clientId,
        };
        dispatch(
          isVideo
            ? editVideo(clientId, applicationId, photo.id, payload)
            : editPhoto(clientId, applicationId, photo.id, payload)
        );
      },
    });

  const handleRedirect = () => {
    const photoId = photosNext?.id || photosPrev?.id;

    if (photoId) {
      history.replace(
        `/customers/${clientId}${
          applicationId ? `/applications/${applicationId}` : ''
        }/gallery/${photoId}`,
        {
          from: location?.state?.from,
        }
      );
      const index = photosList.findIndex(({ id }) => id === photoId);
      setPhotoIndex(index);
      setPhoto(photosList[index]);
      setShowDeletePhotoDialog(false);
    } else {
      history.goBack();
    }
  };

  const handleDeletePhoto = () => {
    dispatch(deletePhoto(clientId, applicationId, photo.id));
    handleRedirect();
  };

  const handleDeleteVideo = () => {
    dispatch(deleteVideo(clientId, applicationId, photo.id));
    handleRedirect();
  };

  const backButtonAction = () => {
    if (dirty) {
      submitForm().then(() => history.goBack());
    } else {
      history.goBack();
    }
  };

  const handleImageError = (e, link) => {
    e.target.onerror = null;
    e.target.src = link || fallbackImg;
  };

  const { description, tags } = values;

  const handleCarouselSwipe = (swiper) => {
    if (dirty) {
      submitForm().then(() => {
        const index = swiper.activeIndex;
        setPhotoIndex(index);
        setPhoto(photosList[index]);

        window.history.replaceState(
          null,
          'Gallery',
          `/customers/${clientId}${
            applicationId ? `/applications/${applicationId}` : ''
          }/gallery/${photosList[index].id}`
        );
      });
    } else {
      const index = swiper.activeIndex;
      setPhotoIndex(index);
      setPhoto(photosList[index]);

      window.history.replaceState(
        null,
        'New Page Title',
        `/customers/${clientId}${
          applicationId ? `/applications/${applicationId}` : ''
        }/gallery/${photosList[index].id}`
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <AppBar
        title={t(settings.details)}
        displayBackButton
        backButtonAction={backButtonAction}
        elevated
        aside={
          <AsideButton
            onClick={() => {
              setShowDeletePhotoDialog(true);
            }}
            data-testid="delete-button"
            disabled={disabled || readOnly}
          >
            <DeleteIcon />
          </AsideButton>
        }
      />
      <ImageWrapper>
        {photosList.length > 1 && (
          <ImageCounter>
            {photoIndex + 1}/{photosList.length}
          </ImageCounter>
        )}
        {photoIndex !== null && photoIndex >= 0 && (
          <StyledSwiper
            pagination={{
              dynamicBullets: true,
            }}
            autoHeight
            preloadImages
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 2,
            }}
            modules={[Lazy, Pagination, Virtual]}
            initialSlide={photoIndex}
            onSlideChange={(swiper) => handleCarouselSwipe(swiper)}
            virtual
          >
            {photosList.map((photo, index) => {
              return (
                <SwiperSlide key={photo.id} virtualIndex={index}>
                  {!isVideoType(photo.mediaType) && (
                    <>
                      <StyledImg
                        data-src={photo.fileUrl || photo.link || fallbackImg}
                        onError={(e) => {
                          document.getElementsByClassName(
                            'swiper-wrapper'
                          )[0].style.height = '300px';
                          handleImageError(e, photo.link);
                        }}
                        className="swiper-lazy"
                      />
                      <div className="swiper-lazy-preloader" />
                    </>
                  )}
                  {isVideoType(photo.mediaType) && (
                    <VideoContainer>
                      {photo?.fileUrl && (
                        <>
                          <video
                            preload="metadata"
                            controls
                            className="swiper-lazy"
                          >
                            <source src={`${photo.fileUrl}#t=0.1`} />
                          </video>
                          <div className="swiper-lazy-preloader" />
                        </>
                      )}
                    </VideoContainer>
                  )}
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        )}
      </ImageWrapper>

      <form onSubmit={handleSubmit}>
        <Input
          id="description"
          label={t('photo_description')}
          fullWidth
          value={description}
          onchange={(typedIn) => {
            setFieldValue('description', typedIn);
          }}
          onBlur={handleBlur}
          data-testid="photo-description"
          disabled={disabled || readOnly}
        />
        <TagsSection>
          <Autocomplete
            multiple
            getOptionLabel={(option) => translateTagName(option.name)}
            options={differenceWith(userTags, tags, isEqual)}
            freeSolo
            filterSelectedOptions
            value={tags}
            onChange={(event, value) => {
              const valuesWithIds = value.map((tag) => {
                if (typeof tag === 'object') {
                  return tag;
                }

                // if the tag entered manually matches one of user tags we want to use the same ID to not create duplicates
                const existingTag = userTags.find(
                  (userTag) =>
                    translateTagName(userTag.name).toUpperCase() ===
                    tag.toUpperCase()
                );

                return {
                  name: existingTag ? existingTag.name : tag,
                  id: existingTag ? existingTag.id : uuidv4(),
                };
              });

              setFieldValue(
                'tags',
                uniqBy(valuesWithIds, (v) => v.name)
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  label={translateTagName(option.name)}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('photo_tags')}
                data-testid="photo-tags"
              />
            )}
            disabled={disabled || readOnly}
          />
        </TagsSection>
      </form>
      <ButtonContainer>
        {showPhoto && (
          <Button
            theme="secondary"
            fullWidth
            onClick={() => dispatch(setAsEntryPhoto(clientId, photo.id))}
            disabled={disabled || readOnly}
          >
            {t('entry_from_customer')}
          </Button>
        )}
      </ButtonContainer>
      <DeleteDialog
        title={t(settings.deleteTitle)}
        message={
          !applicationId && photoUsedInApplicationReport.name
            ? t(settings.deleteMessageFromApp, {
                reportName: photoUsedInApplicationReport.name,
              })
            : null
        }
        open={showDeletePhotoDialog}
        setOpen={setShowDeletePhotoDialog}
        handleDelete={isVideo ? handleDeleteVideo : handleDeletePhoto}
      />
    </Container>
  );
};

GalleryItemView.propTypes = {
  clientData: clientDataShape,
  deletePhoto: PropTypes.func,
  deleteVideo: PropTypes.func,
  editPhoto: PropTypes.func,
  editVideo: PropTypes.func,
  setAsEntryPhoto: PropTypes.func,
};

export default GalleryItemView;
