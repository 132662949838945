import PropTypes from 'prop-types';

const { arrayOf, bool, number, shape, string, oneOfType } = PropTypes;

export const stringOrNumber = oneOfType([string, number]);

const addressShape = shape({
  city: string,
  extraInfo: string,
  postcode: string,
  street: string,
});

const attachmentsShape = shape({
  data: arrayOf(
    shape({
      id: string,
      link: string,
      name: string,
    })
  ),
  total: number,
});

const nextActionShape = shape({
  action: string,
  actionDone: bool,
  delegatedId: string,
  doAt: string,
  duration: number,
  id: string,
  type: string,
});

export const applicationNotesShape = shape({
  data: arrayOf(
    shape({
      applicationId: string,
      clientId: string,
      contactPersonId: string,
      createdAt: string,
      demoProducts: arrayOf(
        shape({
          code: string,
        })
      ),
      id: string,
      nextAction: nextActionShape,
      note: string,
      noteDate: string,
    })
  ),
  total: number,
});

const productShape = shape({
  code: string,
  currency: string,
  groupCode: string,
  id: number,
  name: string,
  orderedAt: string,
  packageCapacity: string,
  packageType: string,
  price: string,
  quantity: number,
});

const chartDataShape = shape({
  month: string,
  value: string,
});

const afterTabShape = shape({
  additionalSavings: stringOrNumber,
  amountPerLubrication: stringOrNumber,
  avgComponentLifetime: stringOrNumber,
  avgComponentLifetimePeriod: string,
  cleanBefore: string,
  cleanBeforeTime: stringOrNumber,
  componentTemperature: stringOrNumber,
  lubricant: string,
  lubricationFrequency: stringOrNumber,
  lubricationFrequencyPeriod: string,
  machineRunning: string,
  manualApplication: string,
  manualApplicationTime: stringOrNumber,
  noiseLevel: stringOrNumber,
  powerConsumption: stringOrNumber,
  price: stringOrNumber,
});

const beforeTabShape = shape({
  amountPerLubrication: stringOrNumber,
  avgComponentLifetime: stringOrNumber,
  avgComponentLifetimePeriod: string,
  cleanBefore: string,
  cleanBeforeTime: stringOrNumber,
  componentCost: stringOrNumber,
  downtimeToReplace: stringOrNumber,
  lubricant: string,
  lubricationFrequency: stringOrNumber,
  lubricationFrequencyPeriod: string,
  machineRunning: string,
  manualApplication: string,
  manualApplicationTime: stringOrNumber,
  peopleToReplace: stringOrNumber,
  previousLubricants: arrayOf(string),
  price: stringOrNumber,
  viscosity: string,
});

const technicalTabShape = shape({
  componentLoad: string,
  componentParams: shape({
    diameterIncomingShaft: stringOrNumber,
    filter: bool,
    filterSize: stringOrNumber,
    greaseFitting: string,
    leakages: bool,
    leakagesLoss: stringOrNumber,
    lubricationType: string,
    mountingPosition: string,
    oilRecommended: string,
    oilVolume: stringOrNumber,
    otherIssues: string,
    rpmIncomingShaft: stringOrNumber,
    type: string,
    ventilationShaft: bool,
  }),
  componentTemperature: stringOrNumber,
  componentType: string,
  componentsNumber: stringOrNumber,
  environment: arrayOf(
    shape({
      parameter: string,
      value: stringOrNumber,
    })
  ),
  machineBrand: string,
  machineModel: string,
  powerConsumption: stringOrNumber,
  problemDescription: string,
  directTemperature: shape({
    lowest: stringOrNumber,
    average: stringOrNumber,
    highest: stringOrNumber,
  }),
  productionTemperature: shape({
    lowest: stringOrNumber,
    average: stringOrNumber,
    highest: stringOrNumber,
  }),
  componentMonitoring: stringOrNumber,
  componentIso: stringOrNumber,
  componentNlgi: stringOrNumber,
  componentExtra: stringOrNumber,
});

export const applicationDataShape = shape({
  after: afterTabShape,
  before: beforeTabShape,
  clientId: string,
  contacts: arrayOf(string),
  createdAt: string,
  description: string,
  id: string,
  isDraft: bool,
  name: string,
  notes: arrayOf(string),
  photos: arrayOf(string),
  technical: technicalTabShape,
  updatedAt: string,
  isConfidential: bool,
});

const applicationsShape = shape({
  applications: shape({
    data: arrayOf(applicationDataShape),
    total: number,
  }),
});

export const noteDataShape = shape({
  clientId: string,
  contactPersonId: string,
  createdAt: string,
  demoProducts: arrayOf(
    shape({
      code: string,
    })
  ),
  id: string,
  isDraft: bool,
  isNoShow: bool,
  nextAction: nextActionShape,
  note: string,
  visitDate: string,
});

const visitNotesShape = shape({
  data: arrayOf(noteDataShape),
  total: number,
});

export const contactPersonDataShape = shape({
  addressingForm: string,
  department: string,
  emailsList: arrayOf(string),
  firstName: string,
  honorific: number,
  id: string,
  isDeleted: number,
  jobTitle: string,
  lastName: string,
  note: string,
  phoneList: arrayOf(
    shape({
      phone: string,
      type: string,
    })
  ),
});

const contactPersonShape = shape({
  data: arrayOf(contactPersonDataShape),
  total: number,
});

export const demoProductsDataShape = arrayOf(
  shape({
    id: string,
    name: string,
    presentedAt: string,
    reportId: string,
    reportType: string,
  })
);

const demoProductsShape = shape({
  data: arrayOf(
    shape({
      id: string,
      name: string,
      presentedAt: string,
      reportId: string,
      reportType: string,
    })
  ),
  total: number,
});

export const openActionsShape = shape({
  data: arrayOf(
    shape({
      action: string,
      actionDone: bool,
      delegatedId: string,
      doAt: string,
      duration: number,
      id: string,
      reportType: string,
      type: string,
    })
  ),
  total: number,
});

const phoneCallsShape = shape({
  data: arrayOf(
    shape({
      clientId: string,
      contactPersonId: string,
      createdAt: string,
      id: string,
      isDraft: bool,
      nextAction: nextActionShape,
      note: string,
      phoneCallDate: string,
      suggestedProducts: arrayOf(
        shape({
          code: string,
        })
      ),
    })
  ),
  total: number,
});

export const photosDataShape = shape({
  description: string,
  fileUrl: string,
  id: string,
  tags: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  updatedAt: string,
});

const photosShape = shape({
  data: arrayOf(photosDataShape),
  total: number,
});

export const ordersHistoryShape = arrayOf(
  shape({
    createdAt: string,
    currency: string,
    number: string,
    products: arrayOf(productShape),
    totalCost: string,
  })
);

export const productsHistoryShape = arrayOf(
  shape({
    chartData: arrayOf(chartDataShape),
    currency: string,
    groupCode: string,
    name: string,
    products: arrayOf(productShape),
  })
);

export const quotationsHistoryShape = arrayOf(
  shape({
    contactPersonId: string,
    createdAt: string,
    currency: string,
    id: string,
    products: arrayOf(productShape),
    totalCost: string,
    discount: string,
    deliveryCost: string,
    emailNote: string,
    internalNote: string,
    owned: bool,
  })
);

export const clientDataShape = shape({
  access: string,
  addresses: shape({
    delivery: addressShape,
    invoice: addressShape,
  }),
  applicationNotes: applicationNotesShape,
  applications: applicationsShape,
  attachments: attachmentsShape,
  contactPersons: contactPersonShape,
  currency: string,
  demoProducts: demoProductsShape,
  id: string,
  name: string,
  openActions: openActionsShape,
  orderHistory: shape({
    orders: ordersHistoryShape,
    products: productsHistoryShape,
    quotations: quotationsHistoryShape,
  }),
  orders: shape({
    data: arrayOf(chartDataShape),
  }),
  owned: bool,
  phoneCalls: phoneCallsShape,
  photos: photosShape,
  visits: visitNotesShape,
  vatRate: string,
  deliveryCost: string,
  freeDeliveryCostFrom: string,
  vatOverDeliveryCost: bool,
});

export const clientListItemShape = shape({
  areaCode: string,
  avatar: string,
  classification: string,
  contactPersons: arrayOf(contactPersonDataShape),
  department: string,
  distance: string,
  entry: string,
  id: string,
  lat: number,
  lng: number,
  note: string,
  owned: bool,
  sicCode: string,
  sicDescription: string,
  subDepartment: string,
  updatedAt: string,
  visitAddress: shape({ name: string }),
  generalPhoneNumber: string,
  email: string,
  website: string,
});

export const clientListItemDataShape = shape({
  areaCode: string,
  avatar: string,
  classification: string,
  contactPersons: [],
  department: string,
  distance: string,
  entry: string,
  id: string,
  lat: string,
  lng: string,
  note: string,
  owned: bool,
  sicCode: string,
  sicDescription: string,
  subDepartment: string,
  updatedAt: string,
  visitAddress: shape({ name: string }),
  generalPhoneNumber: string,
  email: string,
});

export const clientActionsListItemShape = shape({
  actionDone: bool,
  actionType: string,
  applicationReportId: string,
  client: clientListItemDataShape,
  contactPersonId: string,
  description: string,
  doAt: string,
  endTime: string,
  id: string,
  reportType: string,
  startTime: string,
});
