import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { compareDesc } from 'date-fns';
import { usePermissions } from 'frontend-components';

import ApplicationView from './ApplicationView';
import {
  createNewApplication,
  deleteApplication,
  deleteApplicationLocally,
  postApplicationReport,
  putApplicationReport,
  updateApplication,
  updateApplicationsSection,
} from '../../store/actions/applicationActions';
import withClientData from '../../helpers/withClientData';
import { deleteApplicationContactPerson } from '../../store/actions/contactPersonActions';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { updateExpandedPanel } from '../../store/reducers/clientUI';
import { removeCurrentApplication } from '../../store/reducers/drafts';
import { LIST_TYPES } from '../../constants/lists';
import useTags from '../../hooks/useTags';

const getAppNotes = (appNotesData, applicationId) => {
  const notes = appNotesData
    .filter((note) => applicationId === note.applicationId)
    .sort((a, b) => compareDesc(new Date(a.noteDate), new Date(b.noteDate)));
  return {
    total: notes.length,
    data: notes,
  };
};

const ApplicationViewConnected = ({ clientData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { applicationId } = useParams();
  const [applicationNotes, setApplicationNotes] = useState({});
  const { currentApplication } = useSelector((state) => ({
    currentApplication: state.drafts.currentApplication,
  }));

  useEffect(() => {
    if (!clientData.applicationNotes) return;
    const appNotes = getAppNotes(
      clientData.applicationNotes.data,
      applicationId
    );
    setApplicationNotes(appNotes);
  }, [applicationId, clientData]);

  const getApplicationNumber = () => {
    const alreadyCreatedApps = clientData.applications.data.filter(
      (application) => application.createdLocally
    ).length;
    return clientData.arNumber + alreadyCreatedApps || 1 + alreadyCreatedApps;
  };

  const mapApplicationId = (id) => {
    return currentApplication.uuId === id
      ? currentApplication.targetId.toString()
      : undefined;
  };

  const applicationData = clientData.applications.data.find(
    ({ id }) => applicationId === id
  );

  const { disabled, readOnly } = usePermissions(
    clientData.access,
    applicationData && applicationData.owned
  );

  const postAppReport = (applicationId, dataToSend, clientId) => {
    dispatch(postApplicationReport(applicationId, dataToSend, clientId));
    dispatch(
      updateExpandedPanel({ clientId, expandedPanel: LIST_TYPES.APPLICATIONS })
    );
  };

  const putAppReport = (applicationId, dataToSend, clientId) =>
    dispatch(putApplicationReport(applicationId, dataToSend, clientId));

  const deleteApp = (applicationId, clientId) => {
    dispatch(deleteApplication(applicationId, clientId));
  };

  const deleteAppLocally = (applicationId, clientId) =>
    dispatch(deleteApplicationLocally(applicationId, clientId));

  const createNewApp = (applicationId, clientId, defaultName) =>
    dispatch(createNewApplication(applicationId, clientId, defaultName));

  const updateApp = (applicationId, clientId, values) =>
    dispatch(updateApplication(applicationId, clientId, values));

  const deleteAppContactPerson = (contactPersonId, applicationId, clientId) =>
    dispatch(
      deleteApplicationContactPerson(contactPersonId, applicationId, clientId)
    );

  const updateAppSection = (applicationId, clientId, values, tabName) =>
    dispatch(
      updateApplicationsSection(applicationId, clientId, values, tabName)
    );

  const { beforeTag, afterTag, overviewTag } = useTags();

  useEffect(() => {
    if (!applicationData && !!mapApplicationId(applicationId)) {
      history.replace(
        `/customers/${clientData.id}/applications/${mapApplicationId(
          applicationId
        )}`
      );
      dispatch(removeCurrentApplication());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData]);

  return (
    <ApplicationView
      applicationData={applicationData}
      contactPersonsData={clientData.contactPersons}
      galleryItemsData={clientData.photos}
      postAppReport={postAppReport}
      putAppReport={putAppReport}
      deleteApp={deleteApp}
      deleteAppLocally={deleteAppLocally}
      createNewApp={createNewApp}
      updateApp={updateApp}
      deleteAppContactPerson={deleteAppContactPerson}
      beforeTag={beforeTag}
      afterTag={afterTag}
      updateAppSection={updateAppSection}
      overviewTag={overviewTag}
      disabled={disabled || readOnly}
      applicationNotes={applicationNotes}
      getApplicationNumber={getApplicationNumber}
      currency={clientData.currency}
    />
  );
};

ApplicationViewConnected.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ApplicationViewConnected);
