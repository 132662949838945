import { useParams } from 'react-router-dom';
import withClientData from '../../helpers/withClientData';
import NextActionView from './NextActionView';
import { NOTE_TYPES, REPORT_TYPES } from '../../constants/noteTypes';
import {
  addNextActionToApplicationNote,
  deleteNextActionFromApplicationNote,
} from '../../store/actions/applicationNoteActions';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ApplicationNoteNextActionView = ({ clientData }) => {
  const { clientId, applicationId, noteId } = useParams();

  return (
    <NextActionView
      clientData={clientData}
      data={clientData.applicationNotes.data}
      reportType={REPORT_TYPES.APPLICATION_NOTE}
      addNextActionToNote={addNextActionToApplicationNote}
      deleteNextActionFromNote={deleteNextActionFromApplicationNote}
      noteType={NOTE_TYPES.APPLICATIONS}
      backPath={`/customers/${clientId}/applications/${applicationId}/notes/${noteId}`}
      calendarPath={`/customers/${clientId}/applications/${applicationId}/notes/${noteId}/calendar`}
    />
  );
};

ApplicationNoteNextActionView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ApplicationNoteNextActionView);
