import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const AppMatomoProvider = ({ children }) => {
  const [instance, setInstance] = useState(null);
  const urlBase = process.env.REACT_APP_PIWIK_MATOMO_URL;
  const siteId = process.env.REACT_APP_PIWIK_MATOMO_SITE_ID;
  useEffect(() => {
    if (urlBase && siteId) {
      const matomoInstance = createInstance({
        urlBase,
        siteId,
      });
      setInstance(matomoInstance);
    }
  }, [urlBase, siteId]);

  if (instance) {
    return <MatomoProvider value={instance}>{children}</MatomoProvider>;
  }
  return children;
};

AppMatomoProvider.propTypes = {
  children: PropTypes.node,
};

export default AppMatomoProvider;
