import { useParams } from 'react-router-dom';
import withClientData from '../../helpers/withClientData';
import NextActionView from './NextActionView';
import { NOTE_TYPES, REPORT_TYPES } from '../../constants/noteTypes';
import {
  addNextActionToRemarkNote,
  deleteNextActionFromRemarkNote,
} from '../../store/actions/nextActionActions';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const RemarkNextActionView = ({ clientData }) => {
  const { clientId, noteId } = useParams();

  return (
    <NextActionView
      clientData={clientData}
      data={clientData.remarks.data}
      reportType={REPORT_TYPES.REMARK}
      addNextActionToNote={addNextActionToRemarkNote}
      deleteNextActionFromNote={deleteNextActionFromRemarkNote}
      noteType={NOTE_TYPES.REMARKS}
      backPath={`/customers/${clientId}/remarks/${noteId}/`}
      calendarPath={`/customers/${clientId}/remarks/${noteId}/calendar`}
    />
  );
};

RemarkNextActionView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(RemarkNextActionView);
