import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPlannedDate } from 'frontend-components';
import styled from 'styled-components';
import { validate, v4 as uuidv4 } from 'uuid';
import { useRollbar } from '@rollbar/react';

import AppBar from '../../components/AppBar/AppBar.connected';
import CustomButton from '../../components/Button/Button';
import {
  setPhotoUploadingCancelled,
  setPhotoUploadingStart,
} from '../../store/reducers/photosFromDevice';
import {
  setUploadingStart,
  setUploadingCancelled,
  addVideoUpload,
  addAttachmentVideo,
} from '../../store/reducers/video';
import FilesUploaderDCA from '../../components/ImageUploader/FilesUploaderDCA';
import FilesList from '../../components/ImageUploader/FilesList';
import UploaderTip from '../../components/ImageUploader/UploaderTip';
import AutoUploaderTip from '../../components/ImageUploader/AutoUploaderTip';
import { cancelTokensMap } from '../../helpers/cancelTokens';
import withClientVisitsData from '../../helpers/withClientVisitsData';

import {
  createEmptyVisit,
  deleteVisitByIdStore,
  updateFileDescription,
  deleteAttachmentById,
} from '../../store/reducers/clientVisitsData';

import {
  offlineReportStatus,
  progressReportStatus,
  clearReportStatus,
} from '../../store/reducers/clientUI';
import {
  cancelVisitById,
  postVisitAttachments,
} from '../../store/actions/visitsActions';
import NewReportDialog from './NewReportDialog';
import CancelReportDialog from './CancelReportDialog';
import ConfirmReportDialog from './ConfirmReportDialog';
import CameraIOSTip from '../../components/ImageUploader/CameraIOSTip';
import { MAXIMUM_FILES_COUNT_DCA } from '../../constants/photo';
import { addAttachmentImage } from '../../store/actions/photoActions';

const StyledContainer = styled(Container)`
  padding-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  border-top: 1px solid #ddd;
  padding: 16px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const CircularProgressButton = styled(CircularProgress)`
  position: absolute;
  left: 10px;
`;

const NewReportView = ({ clientVisitsData: clientData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const rollbar = useRollbar();
  const { clientId, reportId } = useParams();

  const [client, setClient] = useState([]);
  const [todayReport, setTodayReport] = useState({});
  const [reportData, setReportData] = useState({});
  const [allFiles, setAllFiles] = useState([]);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [isReportCreated, setIsReportCreated] = useState(!validate(reportId));

  const { online } = useSelector((state) => state.offline);
  const { clients } = useSelector((state) => state.clients);
  const { reportStatus } = useSelector((state) => state.clientUI);

  const { upload: photosUpload } = useSelector(
    (state) => state.photosFromDevice
  );
  const { upload: videoUpload } = useSelector((state) => state.video);

  const cancelFilesUploading = () => {
    allFiles.forEach(({ data }) => {
      if (cancelTokensMap.has(data.id)) {
        cancelTokensMap.get(data.id).cancel('');
        cancelTokensMap.delete(data.id);

        if (data.mediaType === 'video') {
          dispatch(setUploadingCancelled({ id: data.id }));
        } else {
          dispatch(setPhotoUploadingCancelled({ clientId, id: data.id }));
        }
      }
    });
  };

  const handleNewReportClick = () => {
    if (!validate(reportId)) {
      const id = uuidv4();
      dispatch(createEmptyVisit({ clientId, reportId: id }));
      history.replace(`/customers/${clientId}/visit/${id}`);
    } else {
      dispatch(createEmptyVisit({ clientId, reportId }));
    }
    setTodayReport({});
    setOpenReportDialog(false);
  };

  const handleSameReportClick = () => {
    if (todayReport)
      history.replace(`/customers/${clientId}/visit/${todayReport.reportId}`);
    setOpenReportDialog(false);
  };

  const handleCancelReport = (removeAttachments) => {
    if (removeAttachments) cancelFilesUploading();
    const data = {
      isNew:
        validate(reportId) && reportData.attachments.length === allFiles.length,
      removeAttachments,
      attachments: allFiles.map(({ data }) => data.id),
    };

    if (isReportCreated || !removeAttachments)
      dispatch(cancelVisitById(reportId, clientId, data, 'newReport'));
    setOpenCancelDialog(false);
    history.goBack();
  };

  const handleLeaveReport = () => {
    dispatch(clearReportStatus());
    if (reportStatus !== 'fail') history.push('/customers');
  };

  const handleBtnCancelReportClick = () => {
    if (allFiles.length > 0) {
      setOpenCancelDialog(true);
    } else {
      dispatch(deleteVisitByIdStore({ clientId, reportId }));
      history.goBack();
    }
  };

  const handleBtnSaveReportClick = () => {
    const visitData = {
      attachments: reportData.attachments.map(({ id, description }) => ({
        id,
        description,
      })),
    };

    dispatch(postVisitAttachments(reportId, visitData, clientId));
    dispatch(online ? progressReportStatus() : offlineReportStatus());
  };

  const handleVideoUploadProgress = (progressEvent, videoData) => {
    dispatch(
      setUploadingStart({
        id: videoData.data.id,
        clientId,
      })
    );
  };

  const handleImageUploadProgress = (progressEvent, imageData) => {
    if (online)
      dispatch(
        setPhotoUploadingStart({
          photoId: imageData.photo.id,
        })
      );
  };

  const uploadVideo = ({ data, source, file }) => {
    const reportDate = getPlannedDate(new Date()).toString();
    dispatch(
      addAttachmentVideo(
        clientId,
        reportId,
        reportDate,
        data,
        handleVideoUploadProgress,
        source,
        file,
        'newReport'
      )
    );

    dispatch(addVideoUpload({ data, clientId }));
    cancelTokensMap.set(data.id, source);
  };

  const uploadPhotoFile = ({ data, source }) => {
    const reportDate = getPlannedDate(new Date()).toString();

    rollbar.info('upload-data', data.link);

    dispatch(
      addAttachmentImage(
        clientId,
        reportId,
        reportDate,
        data,
        source,
        handleImageUploadProgress,
        'newReport'
      )
    );
    cancelTokensMap.set(data.id, source);
  };

  const handleAddFile = (newFiles) => {
    setAllFiles((prevState) => [...newFiles, ...prevState]);

    newFiles.forEach((newFile) => {
      if (newFile?.data.mediaType === 'video') {
        uploadVideo(newFile);
      } else {
        uploadPhotoFile(newFile);
      }
    });
  };

  const handleChangeFile = (file, text) => {
    dispatch(
      updateFileDescription({
        clientId,
        reportId,
        fileId: file.data.id,
        description: text,
      })
    );
  };

  const handleDeleteFile = (file) => {
    dispatch(
      deleteAttachmentById({ clientId, reportId, attachmentId: file.data.id })
    );
    setAllFiles(allFiles.filter(({ data: { id } }) => id !== file?.data?.id));
  };

  useEffect(() => {
    return dispatch(clearReportStatus());
  }, [dispatch]);

  useEffect(() => {
    const dateB = getPlannedDate(new Date()).toString();
    const todayVisit = clientData.visits.find(({ createdAt }) => {
      const dateA = getPlannedDate(createdAt).toString();
      return dateA === dateB;
    });

    if (
      !todayVisit ||
      (todayVisit &&
        todayVisit.attachments?.length >= MAXIMUM_FILES_COUNT_DCA &&
        todayVisit.reportId !== reportId)
    ) {
      dispatch(createEmptyVisit({ clientId, reportId }));
    } else if (todayVisit.reportId === reportId) {
      setTodayReport(todayVisit);
    } else if (todayVisit.reportId !== reportId) {
      setTodayReport(todayVisit);
      setOpenReportDialog(true);
    }
  }, [clientId, reportId, clientData, dispatch]);

  useEffect(() => {
    setClient(clients.find(({ id }) => id === clientId));
  }, [clients, clientId]);

  useEffect(() => {
    setReportData(
      clientData.visits.find((visit) => visit.reportId === reportId)
    );
  }, [clientData, reportId]);

  useEffect(() => {
    allFiles.map((file) => {
      if (photosUpload[file.data.id]?.uploaded) file.uploded = true;
      return { ...file };
    });
  }, [allFiles, photosUpload]);

  useEffect(() => {
    allFiles.forEach(({ data }) => {
      if (photosUpload[data?.id]?.uploaded || videoUpload[data?.id]?.uploaded) {
        setIsReportCreated(true);
      }
    });
  }, [photosUpload, videoUpload, allFiles]);

  return (
    <>
      <StyledContainer maxWidth="lg">
        <AppBar title={client?.visitAddress?.name} elevated hiddenHorizontaly />
        <FilesUploaderDCA
          allFiles={allFiles}
          reportFiles={todayReport?.attachments?.length || 0}
          uploadedFiles={allFiles.length}
          handleAddFile={handleAddFile}
          disabled={reportStatus === 'in-progress'}
          clientName={client?.visitAddress?.name}
          isDCA
        />
        <FilesList
          files={allFiles}
          onDelete={handleDeleteFile}
          onChange={handleChangeFile}
          disabled={reportStatus === 'in-progress'}
          withDescription
        />

        <UploaderTip />
        <AutoUploaderTip />
        <CameraIOSTip />

        <NewReportDialog
          openReportDialog={openReportDialog}
          newReportHandle={handleNewReportClick}
          sameReportHandle={handleSameReportClick}
        />
        <CancelReportDialog
          openCancelDialog={openCancelDialog}
          cancelReportHandle={handleCancelReport}
          closeHandle={() => setOpenCancelDialog(false)}
        />
        <ConfirmReportDialog leaveReportHandle={handleLeaveReport} />
      </StyledContainer>
      <ButtonsWrapper>
        <CustomButton
          theme="secondary"
          data-testid="report-cancel"
          onClick={handleBtnCancelReportClick}
        >
          {t('report_cancel')}
        </CustomButton>
        <CustomButton
          theme="primary"
          data-testid="report-save"
          disabled={!allFiles.length || reportStatus === 'in-progress'}
          onClick={handleBtnSaveReportClick}
        >
          {reportStatus === 'in-progress' && (
            <CircularProgressButton size={20} color="inherit" />
          )}
          {t('report_save')}
        </CustomButton>
      </ButtonsWrapper>
    </>
  );
};

NewReportView.propTypes = {
  clientVisitsData: PropTypes.shape({
    visits: PropTypes.arrayOf(
      PropTypes.shape({
        reportId: PropTypes.string,
      })
    ),
  }),
};

export default withClientVisitsData(NewReportView, { forceFetch: true });
