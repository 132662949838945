import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import AppBar from '../../components/AppBar/AppBar.connected';
import Calendar from '../../components/Calendar/Calendar';

const CalendarView = ({ nextAction, onSelectDate }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const openActions = useSelector((state) => {
    return state.clients.clientsActions.data;
  });

  const backButtonAction = () => {
    history.goBack();
  };

  return (
    <>
      <Container maxWidth="sm">
        <AppBar
          title={t('calendar_title')}
          displayBackButton
          backButtonAction={backButtonAction}
        />
        <Calendar
          currentEvent={nextAction}
          events={openActions}
          onSelectDate={onSelectDate}
          defaultDate={nextAction?.doAt}
        />
      </Container>
    </>
  );
};

CalendarView.propTypes = {
  nextAction: PropTypes.shape({
    doAt: PropTypes.string,
  }),
  onSelectDate: PropTypes.func,
};

export default CalendarView;
