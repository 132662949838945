import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMinutes, format } from 'date-fns';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TodayIcon from '@material-ui/icons/Today';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'frontend-components';

import FullCalendarTheme from './Calendar.style';
import EventDialog from './EventDialog';
import { calendarMinDate, calendarMaxDate } from '../../constants/fixedDates';
import {
  APPOINTMENT,
  CALENDAR_VIEW_WEEK,
  CALENDAR_VIEW_MONTH,
  DROP_IN,
  OTHER,
} from '../../constants/nextAction';
import Tip from '../Tip/Tip';
import {
  hideCalendarMonthTip,
  hideCalendarWeekTip,
} from '../../store/reducers/app';

const CalendarSwitcher = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border-radius: 8px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: ${({ view }) => (view === 'dayGridMonth' ? 0 : '50%')};
    top: 0;
    height: 100%;
    width: 50%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.15s ease-in-out;
  }

  && button {
    border: none;
    background: transparent;
    width: 50%;
  }
`;

const CalendarNavigation = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;

const CalendarButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  width: 80%;
`;

const Calendar = ({ events, currentEvent, onSelectDate, defaultDate }) => {
  const calendar = useRef();
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [viewType, setViewType] = useState('dayGridMonth');
  const [calendarTitle, setCalendarTitle] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mappedEvents, setMappedEvents] = useState([]);
  const { showCalendarMonthTip, showCalendarWeekTip } = useSelector(
    (state) => state.app
  );

  const goNext = () => {
    calendar.current.getApi().next();
  };

  const goPrev = () => {
    calendar.current.getApi().prev();
  };

  const changeView = (view, dateOrRange) => {
    setViewType(view);
    calendar.current.getApi().changeView(view, dateOrRange);
  };

  const handleDateClick = (info) => {
    if (info.view.type === CALENDAR_VIEW_MONTH) {
      changeView(CALENDAR_VIEW_WEEK, info.dateStr);
    } else if (info.view.type === CALENDAR_VIEW_WEEK) {
      onSelectDate(info.date);
    }
  };

  const handleEventClick = (info) => {
    const event = mappedEvents.find(({ id }) => id === info.event.id);
    if (info.view.type === CALENDAR_VIEW_MONTH) {
      changeView(CALENDAR_VIEW_WEEK, event.start);
    } else if (info.view.type === CALENDAR_VIEW_WEEK) {
      setDialogOpen(true);
      setSelectedEvent(event);
    }
  };

  const onDialogClose = () => {
    setDialogOpen(false);
    setSelectedEvent(null);
  };

  const getMinHour = () => {
    const startDate =
      defaultDate && currentEvent.type !== OTHER
        ? new Date(defaultDate)
        : new Date();
    return Math.max(startDate.getHours() - 2, 0);
  };

  const mapActions = (actions) => {
    return actions
      .filter((action) => {
        return (
          action.actionType === DROP_IN || action.actionType === APPOINTMENT
        );
      })
      .map((action) => {
        return {
          id: action.id,
          title: action.clientName,
          type: action.actionType,
          description: action.description,
          start: action.doAt,
          end: format(
            addMinutes(new Date(action.doAt), action.duration),
            'yyyy-MM-dd HH:mm:ss'
          ),
          classNames: [
            'mia-event',
            action.id === currentEvent.id ? 'mia-event-current' : '',
          ],
        };
      });
  };

  useEffect(() => {
    setMappedEvents(mapActions(events));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <>
      <CalendarNavigation>
        <Button
          onClick={() => {
            changeView(CALENDAR_VIEW_WEEK, new Date());
          }}
          startIcon={<TodayIcon />}
          theme="secondary"
        >
          {t('calendar_today')}
        </Button>
        <CalendarButtons>
          <IconButton size="small" theme="primary" onClick={goPrev}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          {calendarTitle}
          <IconButton size="small" theme="primary" onClick={goNext}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </CalendarButtons>
      </CalendarNavigation>

      <CalendarSwitcher view={viewType}>
        <Button
          size="small"
          theme="secondary"
          onClick={() => changeView(CALENDAR_VIEW_MONTH)}
        >
          {t('calendar_month')}
        </Button>
        <Button
          size="small"
          theme="secondary"
          onClick={() => {
            changeView(CALENDAR_VIEW_WEEK);
          }}
        >
          {t('calendar_week')}
        </Button>
      </CalendarSwitcher>

      <FullCalendarTheme data-testid="calendar_wrapper">
        <FullCalendar
          ref={calendar}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={viewType}
          headerToolbar={{
            start: '',
            center: '',
            right: '',
          }}
          height="calc(100vh - 220px)"
          dateClick={handleDateClick}
          initialDate={defaultDate}
          events={mappedEvents}
          datesSet={(event) => {
            setCalendarTitle(event.view.title);
          }}
          firstDay={1}
          allDaySlot={false}
          eventClick={handleEventClick}
          scrollTimeReset={false}
          scrollTime={`${getMinHour()}:00`}
          businessHours={{
            startTime: '08:00',
            endTime: '18:00',
          }}
          slotLabelFormat={[
            {
              hour: 'numeric',
              minute: '2-digit',
              hour12: false,
            },
          ]}
          validRange={{
            start: calendarMinDate,
            end: calendarMaxDate,
          }}
          views={{
            timeGridWeek: {
              dayHeaderContent: ({ date }) => {
                return {
                  html: `<div>${date.toLocaleDateString(language, {
                    weekday: 'short',
                  })}<br/>${date.getDate()}</div>`,
                };
              },
            },
            dayGridMonth: {
              dayHeaderContent: ({ date }) => {
                return {
                  html: `<div>${date.toLocaleDateString(language, {
                    weekday: 'short',
                  })}</div>`,
                };
              },
            },
          }}
        />
      </FullCalendarTheme>

      {selectedEvent && (
        <EventDialog
          onClose={onDialogClose}
          open={dialogOpen}
          event={selectedEvent}
        />
      )}
      {viewType === CALENDAR_VIEW_MONTH && (
        <Tip
          title={t('calendar_month_tip_title')}
          text={t('calendar_month_tip')}
          showTip={showCalendarMonthTip}
          setShowTip={() => {
            dispatch(hideCalendarMonthTip());
          }}
        />
      )}
      {viewType === CALENDAR_VIEW_WEEK && (
        <Tip
          title={t('calendar_week_tip_title')}
          text={t('calendar_week_tip')}
          showTip={showCalendarWeekTip}
          setShowTip={() => {
            dispatch(hideCalendarWeekTip());
          }}
        />
      )}
    </>
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      type: PropTypes.string,
      doAt: PropTypes.string,
      duration: PropTypes.number,
    })
  ),
  currentEvent: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  onSelectDate: PropTypes.func,
  defaultDate: PropTypes.string,
};

export default Calendar;
