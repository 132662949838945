import SvgIcon from '@material-ui/core/SvgIcon';

function ClassificationC(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <rect id="path-1" x="0" y="0" width="345" height="96" rx="8" />
        <filter
          x="-3.8%"
          y="-11.5%"
          width="107.5%"
          height="127.1%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M8,0 L367,0 C371.418278,2.96939504e-14 375,3.581722 375,8 L375,164 L375,164 L0,164 L0,8 C-1.42926142e-15,3.581722 3.581722,-7.65539184e-17 8,0 Z"
          id="path-3"
        />
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.0.1.select_customer-icons"
          transform="translate(-320.000000, -626.000000)"
        >
          <g id="Group-3" transform="translate(15.000000, 590.000000)">
            <g
              id="Icon-/-New-/-My-interflon"
              transform="translate(305.000000, 36.000000)"
              fill="#999999"
            >
              <path
                d="M18,2 C20.209139,2 22,3.790861 22,6 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L18,2 Z M12.0185547,6.91015625 C11.1891276,6.91015625 10.4611003,7.10498047 9.83447266,7.49462891 C9.20784505,7.88427734 8.72591146,8.4436849 8.38867188,9.17285156 C8.08890336,9.82099971 7.92236529,10.5591684 7.88905768,11.3873577 L7.8828125,11.7021484 L7.8828125,12.3105469 C7.8828125,13.8098958 8.24853516,14.9890951 8.97998047,15.8481445 C9.71142578,16.707194 10.7151693,17.1367188 11.9912109,17.1367188 C13.1578776,17.1367188 14.097819,16.8291016 14.8110352,16.2138672 C15.4693885,15.6459585 15.8568931,14.8838931 15.973549,13.9276708 L15.9970703,13.6845703 L13.9462891,13.6845703 C13.9007161,14.2906901 13.7218424,14.738444 13.409668,15.027832 C13.0974935,15.3172201 12.6246745,15.4619141 11.9912109,15.4619141 C11.289388,15.4619141 10.7755534,15.2135417 10.449707,14.7167969 C10.1510145,14.2614475 9.98922277,13.5629318 9.96433173,12.6212497 L9.9609375,12.3583984 L9.9609375,11.6064453 C9.97005208,10.5764974 10.1443685,9.81656901 10.4838867,9.32666016 C10.8234049,8.8367513 11.3349609,8.59179688 12.0185547,8.59179688 C12.6474609,8.59179688 13.1157227,8.73649089 13.4233398,9.02587891 C13.6967773,9.2831127 13.8684916,9.67987839 13.9384826,10.216176 L13.9599609,10.4238281 L16.0107422,10.4238281 C15.9013672,9.31184896 15.4957682,8.44824219 14.7939453,7.83300781 C14.0921224,7.21777344 13.1669922,6.91015625 12.0185547,6.91015625 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ClassificationC;
