import { createAction } from '@reduxjs/toolkit';
import { enqueueSnackbar } from './notificationActions';
import { fetchClientDataById } from './clientDataActions';
import { fetchCurrentlyLoggedUser } from './userActions';

export const ACTION_ADD_PHOTO_COMMIT = 'photosFromDevice/addPhoto/commit';

export const deletePhoto = createAction(
  'photosFromDevice/deletePhoto',
  function prepare(clientId, applicationId, photoId) {
    return {
      payload: {
        clientId,
        applicationId,
        photoId,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/photo-gallery/${photoId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'photosFromDevice/deletePhoto/commit',
            meta: {
              clientId,
              photoId,
              applicationId,
              after: applicationId ? undefined : fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'photosFromDevice/deletePhoto/rollback',
            meta: { clientId, photoId },
          },
        },
      },
    };
  }
);

export const addPhoto = createAction(
  'photosFromDevice/addPhoto',
  function prepare(clientId, applicationId, photo, source, onUploadProgress) {
    const payload = {
      clientId,
      applicationId,
      photo,
    };

    const { link: _, ...photoWithoutContent } = photo;
    let isStarted = false;

    return {
      payload,
      meta: {
        offline: {
          effect: {
            url: `/qac/photo-gallery/${photo.id}`,
            method: 'POST',
            data: {
              clientId,
              fileName: photo.fileName,
              description: photo.description,
              tags: photo.tags,
              imageBinary: photo.link,
            },
            onUploadProgress: (event) => {
              !isStarted &&
                onUploadProgress &&
                onUploadProgress(event, payload);
              isStarted = true;
            },
            cancelToken: source?.token,
          },
          commit: {
            type: ACTION_ADD_PHOTO_COMMIT,
            meta: {
              clientId,
              applicationId,
              photo: photoWithoutContent,
              after: applicationId ? undefined : fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'photosFromDevice/addPhoto/rollback',
            meta: { clientId, applicationId, photo },
          },
        },
      },
    };
  }
);

export const editPhoto = createAction(
  'photosFromDevice/editPhoto',
  function prepare(clientId, applicationId, photoId, photo) {
    return {
      payload: {
        clientId,
        applicationId,
        photoId,
        photo,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/photo-gallery/${photoId}`,
            method: 'PUT',
            data: {
              clientId,
              description: photo.description,
              tags: photo.tags,
            },
          },
          commit: {
            type: 'photosFromDevice/editPhoto/commit',
            meta: {
              clientId,
              applicationId,
              photo,
              after: applicationId
                ? [fetchCurrentlyLoggedUser]
                : [fetchClientDataById, fetchCurrentlyLoggedUser],
              afterArgs: [[{ clientId }], []],
            },
          },
          rollback: {
            type: 'photosFromDevice/editPhoto/rollback',
            meta: { clientId, photo },
          },
        },
      },
    };
  }
);

export const setAsEntryPhoto = createAction(
  'photosFromDevice/setAsEntryPhoto',
  function prepare(clientId, photoId) {
    return {
      payload: {
        clientId,
        photoId,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/set-client-photo/entry/${clientId}/${photoId}`,
            method: 'GET',
          },
          commit: {
            type: 'photosFromDevice/setAsEntryPhoto/commit',
            meta: {
              clientId,
              photoId,
              after: enqueueSnackbar,
              afterArgs: [
                {
                  notification: {
                    message: 'entry_customer_success',
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: 'success',
                    },
                  },
                },
              ],
            },
          },
          rollback: {
            type: 'photosFromDevice/setAsEntryPhoto/rollback',
            meta: {
              clientId,
              photoId,
            },
          },
        },
      },
    };
  }
);

export const setLoading = createAction(
  'photosFromDevice/setLoading',
  function prepare(loading) {
    return {
      payload: {
        loading,
      },
    };
  }
);

export const addAttachmentImage = createAction(
  'photosFromDevice/addAttachmentImage',
  function prepare(
    clientId,
    reportId,
    reportDate,
    photo,
    source,
    onUploadProgress,
    callSource
  ) {
    const payload = {
      clientId,
      reportId,
      photo,
      visitDate: reportDate,
      callSource,
    };

    const { link: _, ...photoWithoutContent } = photo;
    let isStarted = false;

    return {
      payload,
      meta: {
        offline: {
          effect: {
            url: `/qac/attachment/photo/${photo.id}/${reportId}`,
            method: 'POST',
            data: {
              clientId,
              fileName: photo.fileName,
              imageBinary: photo.link,
              visitDate: reportDate,
            },
            onUploadProgress: (event) => {
              !isStarted &&
                onUploadProgress &&
                onUploadProgress(event, payload);
              isStarted = true;
            },
            cancelToken: source?.token,
          },
          commit: {
            type: ACTION_ADD_PHOTO_COMMIT,
            meta: {
              clientId,
              reportId,
              photo: photoWithoutContent,
            },
          },
          rollback: {
            type: 'photosFromDevice/addAttachmentImage/rollback',
            meta: { clientId, reportId, photo },
          },
        },
      },
    };
  }
);
