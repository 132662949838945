import styled from 'styled-components';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { pickerMaxDate, pickerMinDate } from '../../constants/fixedDates';

const StyledDatePicker = styled(KeyboardDatePicker)`
  input {
    font-size: 16px;
  }

  & .MuiFormLabel-root {
    font-size: 16px;
  }

  & .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.38);
  }

  .Mui-disabled .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.26);
  }

  & .Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }
`;

const DatePicker = ({
  label,
  fullWidth,
  id,
  value,
  onChange,
  errorMessage = '',
  margin = 'normal',
  ...datePickerProps
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        id={id}
        fullWidth={fullWidth}
        margin={margin}
        label={label}
        format="dd/MM/yyyy"
        value={value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        minDate={pickerMinDate}
        maxDate={pickerMaxDate}
        helperText={errorMessage.length ? errorMessage : null}
        {...datePickerProps}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  id: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  margin: PropTypes.string,
};

export default DatePicker;
