import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import withClientData from '../../helpers/withClientData';
import NoteView from './NoteView';
import {
  postApplicationNote,
  updateApplicationNoteStore,
  createEmptyApplicationNote,
  putApplicationNote,
  deleteApplicationNoteStore,
  deleteApplicationNoteById,
  applicationNoteEditionDone,
} from '../../store/actions/applicationNoteActions';
import { getDemoedProductsSectionData } from '../../helpers/products';
import { prepareApplicationNotePayload } from '../../helpers/prepNotePayload';
import { NOTE_TYPES } from '../../constants/noteTypes';
import Input from '../../components/Input/Input';
import { updateApplicationExpandedPanel } from '../../store/reducers/clientUI';
import { APPLICATION_LIST_TYPES } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ApplicationName = styled.div`
  margin: 24px 0 -8px 0;
`;

const ApplicationNoteView = ({ clientData }) => {
  const { noteId, clientId, applicationId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const handlePostNote = (noteId, noteData, clientId, noteWithNextActionId) => {
    dispatch(updateApplicationNoteStore(noteId, noteWithNextActionId));
    dispatch(postApplicationNote(noteId, noteData, clientId));
    dispatch(
      updateApplicationExpandedPanel({
        clientId,
        expandedPanel: APPLICATION_LIST_TYPES.NOTES,
      })
    );
  };

  const handlePutNote = (noteId, noteData, clientId, noteWithNextActionId) => {
    dispatch(updateApplicationNoteStore(noteId, noteWithNextActionId));
    dispatch(putApplicationNote(noteId, noteData, clientId));
  };

  const handleDeleteNoteStore = (noteId, clientId) => {
    dispatch(deleteApplicationNoteStore(noteId, clientId));
  };

  const handleDeleteNote = (noteId, clientId) => {
    dispatch(deleteApplicationNoteById(noteId, clientId));
  };

  const handleCreateEmptyNote = (noteId, clientId) => {
    dispatch(createEmptyApplicationNote(noteId, clientId, applicationId));
  };

  const getNoteDate = (data) => data.noteDate;

  const handleUpdateNote = (values) => {
    const noteWithClientId = {
      ...values,
      clientId,
    };
    dispatch(updateApplicationNoteStore(noteId, noteWithClientId));
  };

  const selectButtonTitle = (productsLength) => {
    return productsLength
      ? t('application_note_demo')
      : t('application_note_demoed');
  };

  const addProductButtonLabel = t('application_note_demoed');

  const applicationNoteData =
    clientData.applicationNotes &&
    clientData.applicationNotes.data.find(
      (applicationNote) => applicationNote.id === noteId
    );

  const applicationData =
    clientData.applications &&
    clientData.applications.data.find(
      (application) => application.id === applicationId
    );
  const applicationName = applicationData ? applicationData.name : '';

  const productSectionData = getDemoedProductsSectionData(
    clientData,
    applicationNoteData
  );

  const gotoCreateView = (actionType) => {
    history.push(
      `/customers/${clientId}/${NOTE_TYPES.APPLICATIONS}/${applicationId}/notes/${noteId}/${actionType}`
    );
  };

  return (
    <NoteView
      clientData={clientData}
      data={applicationNoteData}
      productSectionData={productSectionData}
      title={t('application_note_title')}
      backURL={`/customers/${clientId}/${NOTE_TYPES.APPLICATIONS}/${applicationId}`}
      postNote={handlePostNote}
      putNote={handlePutNote}
      deleteNoteTitle={t('app_note_delete_title')}
      deleteNoteStore={handleDeleteNoteStore}
      deleteNote={handleDeleteNote}
      createEmptyNote={handleCreateEmptyNote}
      updateNote={handleUpdateNote}
      noteEditionDone={applicationNoteEditionDone}
      datePickerTestId="application_note_date_picker"
      getNoteDate={getNoteDate}
      selectButtonTitle={selectButtonTitle}
      addProductButtonLabel={addProductButtonLabel}
      prepareNotePayload={(values, clientId) => {
        return prepareApplicationNotePayload(
          { ...values, applicationId },
          clientId
        );
      }}
      gotoCreateView={gotoCreateView}
      renderUpperSection={() => {
        return (
          <ApplicationName>
            <Input
              id="applicationName"
              label={t('app_name')}
              value={applicationName}
              fullWidth
              disabled
            />
          </ApplicationName>
        );
      }}
      productsData={applicationNoteData && applicationNoteData.demoProducts}
      testId="application-note"
      hasProducts
    />
  );
};

ApplicationNoteView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ApplicationNoteView);
