import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'frontend-components';

import FixedFooter from '../../../components/FixedFooter/FixedFooter';
import {
  SummaryContainer,
  TotalContainer,
  AmountTitle,
  AmountValue,
} from './SelectedProductsSummary.styles';

const SelectedProductsSummary = ({ totalAmount, handleSeeSummary, total }) => {
  const { t } = useTranslation();

  return (
    <FixedFooter>
      <SummaryContainer>
        <TotalContainer>
          <AmountTitle>{t('total_amount')}</AmountTitle>
          <AmountValue>{totalAmount}</AmountValue>
        </TotalContainer>
        <Button
          aria-label="add-products"
          onClick={handleSeeSummary}
          theme="primary"
          disabled={total === 0}
        >
          {t('see_summary')}
        </Button>
      </SummaryContainer>
    </FixedFooter>
  );
};

SelectedProductsSummary.propTypes = {
  totalAmount: PropTypes.string,
  total: PropTypes.number,
  handleSeeSummary: PropTypes.func,
};

export default SelectedProductsSummary;
