import SvgIcon from '@material-ui/core/SvgIcon';

function ClassificationB(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <rect id="path-1" x="0" y="0" width="345" height="96" rx="8" />
        <filter
          x="-3.8%"
          y="-11.5%"
          width="107.5%"
          height="127.1%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.0.1.select_customer-icons"
          transform="translate(-320.000000, -474.000000)"
        >
          <g id="Group-4" transform="translate(15.000000, 438.000000)">
            <g
              id="Icon-/-New-/-My-interflon"
              transform="translate(305.000000, 36.000000)"
              fill="#999999"
            >
              <path
                d="M18,2 C20.209139,2 22,3.790861 22,6 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L18,2 Z M11.7827148,7.046875 L8.29638672,7.046875 L8.29638672,17 L12.1655273,17 C13.2957357,16.9908854 14.1661784,16.7402344 14.7768555,16.2480469 C15.3875326,15.7558594 15.6928711,15.03125 15.6928711,14.0742188 C15.6928711,13.5136719 15.5458984,13.0397135 15.2519531,12.6523438 C14.9580078,12.264974 14.5558268,12.007487 14.0454102,11.8798828 C14.4920247,11.7067057 14.8406576,11.4344076 15.0913086,11.0629883 C15.3419596,10.691569 15.4672852,10.2620443 15.4672852,9.77441406 C15.4672852,8.8811849 15.1551107,8.20328776 14.5307617,7.74072266 C13.9584418,7.31670464 13.1410409,7.08702822 12.078559,7.05169339 L11.7827148,7.046875 Z M12.2270508,12.6660156 C13.1704102,12.6796875 13.6420898,13.1422526 13.6420898,14.0537109 C13.6420898,14.4593099 13.5065104,14.777181 13.2353516,15.0073242 C12.9641927,15.2374674 12.5870768,15.3525391 12.1040039,15.3525391 L10.347168,15.3525391 L10.347168,12.6660156 L12.2270508,12.6660156 Z M11.7827148,8.70800781 C12.347819,8.70800781 12.7613932,8.80712891 13.0234375,9.00537109 C13.2854818,9.20361328 13.4165039,9.52832031 13.4165039,9.97949219 C13.4165039,10.7861328 12.8992513,11.1985677 11.8647461,11.2167969 L10.347168,11.2167969 L10.347168,8.70800781 L11.7827148,8.70800781 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ClassificationB;
