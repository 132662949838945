import styled from 'styled-components';
import Webcam from 'react-webcam';

export const StyledFilesUploader = styled.div`
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    flex-direction: row;
  }
`;

export const StyledWebcamWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  overflow: hidden;
  margin-bottom: 15px;
  height: calc(100vh - 320px);
  width: 100%;

  @media (orientation: landscape) {
    height: calc(100vh - 120px);
  }
`;

export const StyledWebcam = styled(Webcam)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  max-height: 100%;
  max-width: 100%;
`;

export const StyledError = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #efefef;
  padding: 20px;
`;

export const StyledTimerWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
