import SvgIcon from '@material-ui/core/SvgIcon';

function OilIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 22c3.86 0 7-3.14 7-7 0-5.25-7-13-7-13S5 9.75 5 15c0 3.86 3.14 7 7 7z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default OilIcon;
