export const APPOINTMENT = 'appointment';
export const PHONE_CALL = 'phonecall';
export const DROP_IN = 'visitwithout';
export const OTHER = 'other';

export const NEXT_ACTION_TYPE = [
  { value: APPOINTMENT, label: 'follow_up_appointment' },
  { value: PHONE_CALL, label: 'action_follow_up_call' },
  { value: DROP_IN, label: 'action_follow_up_drop_in' },
  { value: OTHER, label: 'action_follow_up_other' },
];

export const NEXT_ACTION_TYPES = NEXT_ACTION_TYPE.map((type) => type.value);

export const NEXT_ACTION_DURATION = [
  { value: 5, label: 'next_action_5_min' },
  { value: 15, label: 'next_action_15_min' },
  { value: 30, label: 'next_action_30_min' },
  { value: 60, label: 'next_action_1h' },
  { value: 90, label: 'next_action_1_5h' },
  { value: 120, label: 'next_action_2h' },
  { value: 150, label: 'next_action_2_5h' },
  { value: 180, label: 'next_action_3h' },
];
export const NEXT_ACTION_PATH = [
  {
    type: 'visit',
    path: 'visits',
  },
  {
    type: 'phoneCall',
    path: 'calls',
  },
  {
    type: 'applicationNote',
    path: 'notes',
  },
  {
    type: 'note',
    path: 'remarks',
  },
];

export const ACTION_TYPE_WITH_START_TIME = [DROP_IN, APPOINTMENT, PHONE_CALL];

export const ACTION_TYPE_WITH_DURATION = [DROP_IN, APPOINTMENT];

export const NEXT_ACTION_DURATION_DEFAULT_BY_TYPE = {
  [DROP_IN]: 30,
  [APPOINTMENT]: 60,
  [PHONE_CALL]: null,
  [OTHER]: null,
};

export const CALENDAR_VIEW_WEEK = 'timeGridWeek';
export const CALENDAR_VIEW_MONTH = 'dayGridMonth';
