import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBar from '../../components/AppBar/AppBar.connected';
import QuotationIcon from '../../components/Icons/QuotationIcon';
import OrderIcon from '../../components/Icons/OrderIcon';
import {
  createNewOrder,
  createNewQuotation,
} from '../../store/reducers/drafts';

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const CreateNewButton = styled.div`
  width: 227px;
  height: 227px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin-top: 33px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonTitle = styled.p`
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  margin: 32px 0 24px;
`;

const CreateNewOrderOrQuotation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId } = useParams();

  return (
    <Container maxWidth="sm" data-testid="create-order-or-quotation">
      <AppBar
        title={t('create_new')}
        displayBackButton
        backButtonAction={() => history.push(`/customers/${clientId}`)}
      />
      <ButtonsContainer data-testid="create-order-or-quotation-buttons">
        <CreateNewButton
          onClick={() => {
            dispatch(createNewQuotation());
            history.push(`/customers/${clientId}/create/quotation`);
          }}
        >
          <IconWrapper>
            <QuotationIcon />
          </IconWrapper>
          <ButtonTitle>{t('create_quotation_button')}</ButtonTitle>
        </CreateNewButton>
        <CreateNewButton
          onClick={() => {
            dispatch(createNewOrder());
            history.push(`/customers/${clientId}/create/order`);
          }}
        >
          <IconWrapper>
            <OrderIcon />
          </IconWrapper>
          <ButtonTitle>{t('create_order_button')}</ButtonTitle>
        </CreateNewButton>
      </ButtonsContainer>
    </Container>
  );
};

export default CreateNewOrderOrQuotation;
