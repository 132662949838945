import { useSelector } from 'react-redux';
import GalleryItemView from './GalleryItemView';
import {
  deletePhoto,
  editPhoto,
  setAsEntryPhoto,
} from '../../store/actions/photoActions';

const NewClientGalleryItemView = () => {
  const { newClientData } = useSelector((state) => state.client);
  return (
    <GalleryItemView
      clientData={newClientData}
      deletePhoto={deletePhoto}
      editPhoto={editPhoto}
      setAsEntryPhoto={setAsEntryPhoto}
    />
  );
};

export default NewClientGalleryItemView;
