import PropTypes from 'prop-types';
import styled from 'styled-components';
import FilesItem from './FilesItem';

const ImagesWrapper = styled.div`
  padding: 0;
  margin-bottom: 30px;
`;

const FilesList = ({
  files,
  onDelete,
  withDescription,
  onChange,
  disabled,
}) => {
  return (
    <div>
      {files.length > 0 && (
        <ImagesWrapper>
          {files?.map((src) => (
            <FilesItem
              key={`file-${src?.data?.id}`}
              file={src}
              onDelete={(file) => onDelete(file)}
              withDescription={withDescription}
              onChange={onChange}
              disabled={disabled}
            />
          ))}
        </ImagesWrapper>
      )}
    </div>
  );
};

FilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
  onDelete: PropTypes.func,
  withDescription: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FilesList;
