import { createSlice } from '@reduxjs/toolkit';
import {
  ACTION_ADD_PHOTO_COMMIT,
  addPhoto,
  editPhoto,
  deletePhoto,
  setLoading,
} from '../actions/photoActions';

const photosFromDeviceSlice = createSlice({
  name: 'photosFromDevice',
  initialState: { addedPhotos: {}, loading: false, upload: {} },
  reducers: {
    setPhotoUploadingStart: (state, action) => {
      const { photoId } = action.payload;

      state.upload[photoId] = {
        id: photoId,
        uploading: true,
        uploaded: false,
        cancelled: false,
        failed: false,
      };
    },
    setPhotoUploadingDone: (state, action) => {
      const { id } = action.payload;
      state.upload[id] = {
        id,
        uploading: false,
        uploaded: true,
        cancelled: false,
        failed: false,
      };
    },
    setPhotoUploadingCancelled: (state, action) => {
      const { clientId, id } = action.payload;
      if (state.upload[id]) state.upload[id].cancelled = true;
      if (state.addedPhotos[clientId]) {
        const index = state.addedPhotos[clientId].findIndex(
          (photo) => photo.id.toString() === id
        );
        if (index !== -1) state.addedPhotos[clientId].splice(index, 1);
      }
    },
    setPhotoUploadingFailed: (state, action) => {
      const { id } = action.payload;
      if (state.upload[id]) {
        state.upload[id].uploaded = false;
        state.upload[id].uploading = false;
        state.upload[id].failed = true;
      }
    },
    clearPhotoUploading: (state, action) => {
      const { id } = action.payload;
      delete state.upload[id];
    },
    clearPhotoUploadingProgress: (state) => {
      state.upload = {};
      state.idByUuid = {};
    },
    clearPhoto: (state, action) => {
      const { clientId, id } = action.payload;
      if (state.upload[id]) delete state.upload[id];
      if (state.addedPhotos[clientId]) {
        const index = state.addedPhotos[clientId].findIndex(
          (photo) => photo.id.toString() === id
        );
        if (index !== -1) state.addedPhotos[clientId].splice(index, 1);
      }
    },
    clearPhotos: (state, action) => {
      const { clientId } = action.payload;

      delete state.addedPhotos[clientId];
      state.loading = false;
      state.upload = {};
      state.idByUuid = {};
    },
  },
  extraReducers: {
    [ACTION_ADD_PHOTO_COMMIT]: (state, action) => {
      const photoId = action.meta.photo.id;
      const { clientId } = action.meta;
      const { fileUrl } = action.payload;
      const indexAddedPhotos = state.addedPhotos[clientId].findIndex(
        (photo) => photo.id === photoId
      );
      // remove this photo from photosFromDevice - it will be added into clientData slice
      if (indexAddedPhotos !== -1)
        state.addedPhotos[clientId].splice(indexAddedPhotos, 1);

      if (state.upload[photoId]) {
        state.upload[photoId].fileUrl = fileUrl;
      }
    },
    [addPhoto]: (state, action) => {
      const { clientId, photo } = action.payload;

      if (state.addedPhotos[clientId]) {
        const index = state.addedPhotos[clientId].findIndex((addedPhoto) => {
          return addedPhoto.id === photo.id;
        });
        if (index === -1) state.addedPhotos[clientId].push(photo);
      } else {
        state.addedPhotos[clientId] = [photo];
      }
    },
    [editPhoto]: (state, action) => {
      const { clientId, photoId, photo } = action.payload;

      if (state.addedPhotos[clientId]) {
        const index = state.addedPhotos[clientId].findIndex(
          (photo) => photo.id.toString() === photoId
        );

        if (index !== -1) {
          const currentPhoto = state.addedPhotos[clientId][index];
          state.addedPhotos[clientId][index] = {
            ...currentPhoto,
            description: photo.description,
            tags: photo.tags,
          };
        }
      }
    },
    [deletePhoto]: (state, action) => {
      const { clientId, photoId } = action.payload;
      if (state.addedPhotos[clientId]) {
        const index = state.addedPhotos[clientId].findIndex(
          (photo) => photo.id.toString() === photoId
        );
        if (index !== -1) state.addedPhotos[clientId].splice(index, 1);
      }
    },
    [setLoading]: (state, action) => {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const {
  setPhotoUploadingStart,
  setPhotoUploadingDone,
  setPhotoUploadingCancelled,
  setPhotoUploadingFailed,
  clearPhotoUploadingProgress,
  clearPhotoUploading,
  clearPhoto,
  clearPhotos,
} = photosFromDeviceSlice.actions;
export default photosFromDeviceSlice.reducer;
