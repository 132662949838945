import PropTypes from 'prop-types';
import { LeftAlignButton } from 'frontend-components';

import Product from '../Product';
import { isSearchTermLongEnough } from '../../../helpers/products';
import { productProps } from '../../../helpers/packaging';

const ProductsListInCategory = ({
  name,
  products,
  handleExpandedStateChange,
  isCategoryExpanded,
  searchTerm,
  handleProductClick,
  isSubCategory,
}) => {
  return (
    <Product
      key={name}
      title={name}
      isExpanded={
        !!isCategoryExpanded[name] || isSearchTermLongEnough(searchTerm)
      }
      onChange={handleExpandedStateChange}
      subCategory={isSubCategory}
    >
      {products.map((product) => {
        const { code, name } = product;
        return (
          <LeftAlignButton
            key={code}
            theme="secondary"
            fullWidth
            bgcolor="grey"
            onClick={() => handleProductClick(name)}
          >
            {name}
          </LeftAlignButton>
        );
      })}
    </Product>
  );
};

ProductsListInCategory.propTypes = {
  name: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(productProps)),
  handleExpandedStateChange: PropTypes.func,
  isCategoryExpanded: PropTypes.shape({}),
  searchTerm: PropTypes.string,
  isSubCategory: PropTypes.bool,
  handleProductClick: PropTypes.func,
};

export default ProductsListInCategory;
