import Person from '@material-ui/icons/Person';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';

const PersonIcon = styled(Person)`
  color: rgba(0, 0, 0, 0.38);
  margin: 0 12px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-popupIndicator,
  .MuiAutocomplete-clearIndicator {
    width: 44px;
    height: 44px;
    margin-right: 0;
    padding: 0;
    top: -8px;
  }

  .MuiAutocomplete-popupIndicator {
    transform: rotate(0);
  }

  .Mui-disabled .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.26);
  }
`;

const ContactAutocomplete = (props) => (
  <StyledAutocomplete {...props} popupIcon={<PersonIcon />} />
);

export default StyledAutocomplete;
export { ContactAutocomplete };
