import { createAction } from '@reduxjs/toolkit';

export const postApplicationNote = (noteId, noteData, clientId) => ({
  type: 'application-note/post',
  payload: noteData,
  meta: {
    noteId,
    clientId,
    offline: {
      effect: {
        url: `/qac/application-note/${noteId}`,
        method: 'POST',
        data: noteData,
      },
      commit: {
        type: 'application-note/post/commit',
        meta: {
          noteId,
          clientId,
        },
      },
      rollback: {
        type: 'application-note/post/rollback',
        meta: { noteId },
      },
    },
  },
});

export const putApplicationNote = (noteId, noteData, clientId) => ({
  type: 'application-note/put',
  payload: noteData,
  meta: {
    offline: {
      effect: {
        url: `/qac/application-note/${noteId}`,
        method: 'PUT',
        data: noteData,
      },
      commit: {
        type: 'application-note/put/commit',
        meta: {
          noteId,
          clientId,
        },
      },
      rollback: {
        type: 'application-note/put/rollback',
        meta: { noteId },
      },
    },
  },
});

export const deleteApplicationNoteById = createAction(
  'application-note/delete',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
      meta: {
        offline: {
          effect: {
            url: `/qac/application-note/${noteId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'application-note/delete/commit',
            meta: {
              noteId,
              clientId,
            },
          },
          rollback: {
            type: 'application-note/delete/rollback',
            meta: { noteId },
          },
        },
      },
    };
  }
);

export const createEmptyApplicationNote = createAction(
  'clientData/createEmptyApplicationNote',
  function prepare(noteId, clientId, applicationId) {
    return {
      payload: { noteId, clientId, applicationId },
    };
  }
);

export const updateApplicationNoteStore = createAction(
  'clientData/updateApplicationNoteStore',
  function prepare(noteId, values) {
    return {
      payload: { noteId, values },
    };
  }
);

export const attachApplicationNote = createAction(
  'clientData/attachApplicationNote',
  function prepare(noteId, clientId, applicationId) {
    return { noteId, applicationId, clientId };
  }
);

export const deleteApplicationNoteStore = createAction(
  'clientData/deleteApplicationNoteStore',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);

export const addNextActionToApplicationNote = createAction(
  'clientData/addNextActionToApplicationNote',
  function prepare(noteId, clientId, nextAction, notMarkNoteAsEdited) {
    return {
      payload: {
        noteId,
        clientId,
        nextAction,
        notMarkNoteAsEdited,
      },
    };
  }
);

export const deleteNextActionFromApplicationNote = createAction(
  'clientData/deleteNextActionFromApplicationNote',
  function prepare(noteId, clientId) {
    return {
      payload: {
        noteId,
        clientId,
      },
    };
  }
);

export const addProductToApplicationNote = createAction(
  'clientData/addProductToApplicationNote',
  function prepare(noteId, clientId, demoedProduct) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProduct,
      },
    };
  }
);

export const removeDemoedProductFromApplicationNote = createAction(
  'clientData/removeDemoedProductFromApplicationNote',
  function prepare(noteId, clientId, demoedProduct) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProduct,
      },
    };
  }
);

export const addDemoedProductsPerApplicationNote = createAction(
  'clientData/addDemoedProductsPerApplicationNote',
  function prepare(noteId, clientId, demoedProducts) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProducts,
      },
    };
  }
);

export const setApplicationNoteContactPerson = createAction(
  'clientData/setApplicationNoteContactPerson',
  function prepare(contactPersonId, clientId, noteId) {
    return {
      payload: { contactPersonId, clientId, noteId },
    };
  }
);

export const applicationNoteEditionDone = createAction(
  'clientData/applicationNoteEditionDone',
  function prepare(noteId, clientId) {
    return {
      payload: { noteId, clientId },
    };
  }
);
