import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Button } from 'frontend-components';

import Product from '../../ProductView/Product';
import { isSearchTermLongEnough } from '../../../helpers/products';
import PackagingDetails from './PackagingDetails';
import { packagingDataProps, productProps } from '../../../helpers/packaging';

const ProductsInCategory = ({
  name,
  products,
  handleExpandedStateChange,
  isCategoryExpanded,
  searchTerm,
  count,
  handleProductClick,
  packagingData,
  isSubCategory,
}) => {
  return (
    <Product
      key={name}
      title={name}
      onChange={handleExpandedStateChange}
      isExpanded={
        !!isCategoryExpanded[name] || isSearchTermLongEnough(searchTerm)
      }
      itemsSize={count}
      subCategory={isSubCategory}
    >
      {products.map((product) => {
        const { code, name } = product;
        return (
          <Button
            key={code}
            theme="secondary"
            fullWidth
            bgcolor="grey"
            endIcon={<AddIcon />}
            onClick={(e) => handleProductClick(e, product)}
            data-testid="product-in-category"
          >
            {name}
            {packagingData &&
              packagingData[code] &&
              packagingData[code].length > 0 && (
                <PackagingDetails packagingDetails={packagingData[code]} />
              )}
          </Button>
        );
      })}
    </Product>
  );
};

ProductsInCategory.propTypes = {
  name: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(productProps)),
  handleExpandedStateChange: PropTypes.func,
  isCategoryExpanded: PropTypes.shape({}),
  searchTerm: PropTypes.string,
  count: PropTypes.number,
  handleProductClick: PropTypes.func,
  packagingData: PropTypes.shape(packagingDataProps),
  isSubCategory: PropTypes.bool,
};

export default ProductsInCategory;
