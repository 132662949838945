import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setApplicationContactPerson } from '../../store/actions/contactPersonActions';
import AddContactPersonView from './AddContactPersonView';
import withClientData from '../../helpers/withClientData';
import { updateApplicationExpandedPanel } from '../../store/reducers/clientUI';
import { APPLICATION_LIST_TYPES } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const AddApplicationContactPersonView = ({ clientData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { applicationId, clientId } = useParams();

  return (
    <AddContactPersonView
      goBackAction={() => {
        history.push(`/customers/${clientId}/applications/${applicationId}`);
      }}
      clientData={clientData}
      onContactSelected={(contactPersonId) => {
        dispatch(
          setApplicationContactPerson(contactPersonId, applicationId, clientId)
        );
        dispatch(
          updateApplicationExpandedPanel({
            clientId,
            expandedPanel: APPLICATION_LIST_TYPES.CONATCTS,
          })
        );
        history.push(`/customers/${clientId}/applications/${applicationId}`);
      }}
      onAddContactClicked={() => {
        history.push(
          `/customers/${clientId}/applications/${applicationId}/addContactPerson/${uuidv4()}`
        );
      }}
    />
  );
};

AddApplicationContactPersonView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(AddApplicationContactPersonView);
