import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import InputIcon from '@material-ui/icons/Input';
import { DateTile, Divider } from 'frontend-components';
import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import LimitedList from '../LimitedList/LimitedList';
import { DraftMarker } from '../DraftMarker/DraftMarker';
import {
  HUMIDITY_OPTIONS,
  CHEM_INFLU_OPTIONS,
  EXPOSURE_OPTIONS,
} from '../../constants/applicationScreen';

const AVAILABLE_SECONDARY_PARAMETERS = {
  humidity: HUMIDITY_OPTIONS,
  chemicals: CHEM_INFLU_OPTIONS,
  exposure: EXPOSURE_OPTIONS,
};

const ApplicationsList = ({
  expanded,
  onChange,
  applications,
  'data-testid': testId,
  disabled,
  onPlusButtonClick,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();

  const getTranslatedLabels = (parameter) => {
    const availableLabels = AVAILABLE_SECONDARY_PARAMETERS[parameter.parameter];
    return parameter.value
      .split(',')
      .map((label) => {
        const foundLabel = availableLabels.find(
          (item) => item.value === label
        )?.label;
        return foundLabel ? t(foundLabel) : null;
      })
      .filter(Boolean)
      .join(', ');
  };

  return (
    <Collapsible
      expanded={expanded && applications.total > 0}
      onChange={onChange}
      icon={<InputIcon />}
      title={t('applications')}
      itemsSize={applications.total}
      displayPlusButton
      onPlusButtonClick={onPlusButtonClick}
      collapsible={applications.total > 0}
      data-testid={testId}
      disabled={disabled}
    >
      <LimitedList
        items={applications.data}
        label={t('applications')}
        renderItem={(item, index) => {
          const isLast = index === applications.data.length - 1;

          const filteredParameters =
            item.technical.environment &&
            item.technical.environment.filter(
              ({ parameter }) => parameter in AVAILABLE_SECONDARY_PARAMETERS
            );

          const secondaryLabel = filteredParameters
            ? filteredParameters.map(getTranslatedLabels).join(', ')
            : [];

          return (
            <div key={item.id}>
              <ListItem
                key={item.id}
                button
                onClick={() =>
                  history.push(`/customers/${clientId}/applications/${item.id}`)
                }
              >
                <ListItemText primary={item.name} secondary={secondaryLabel} />
                {item.isDraft && <DraftMarker $withLabel />}
                <ListItemSecondaryAction $disabled>
                  <DateTile value={item.updatedAt} />
                </ListItemSecondaryAction>
              </ListItem>
              {!isLast && <Divider />}
            </div>
          );
        }}
      />
    </Collapsible>
  );
};

ApplicationsList.propTypes = {
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  applications: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        environment: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  onPlusButtonClick: PropTypes.func,
};

export default ApplicationsList;
