import { useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import { stringOrNumber } from '../../helpers/clientDataPropTypes';

const StyledInput = styled(({ customColor, textFieldProps, ...props }) => (
  <TextField {...props} inputProps={textFieldProps} />
))`
  input {
    font-size: 16px;
    color: ${({ customColor }) => customColor};

    ::-webkit-input-placeholder {
      font-style: italic;
      font-size: 14px;
    }
    ::-moz-placeholder {
      font-style: italic;
      font-size: 14px;
    }
    :-ms-input-placeholder {
      font-style: italic;
      font-size: 14px;
    }
    :-moz-placeholder {
      font-style: italic;
      font-size: 14px;
    }
  }

  .rightText {
    font-size: 14px;
    padding-right: 17px;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: ${({ readOnly }) =>
      readOnly ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.38)'};
  }

  .MuiInputBase-root.Mui-disabled,
  .MuiInput-input.Mui-disabled {
    color: ${({ readOnly }) =>
      readOnly ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)'};
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: ${({ readOnly }) => (readOnly ? 'solid' : 'dotted')};
  }

  & .MuiFormLabel-root {
    font-size: 16px;
  }

  & .MuiInput-underline:before {
    border-bottom-color: #999999;
  }

  & .Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }

  a {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const Input = ({
  label,
  rightIcon = null,
  rightIconSize = 'small',
  customColor = 'inherit',
  leftText = '',
  rightText = '',
  fullWidth = false,
  errorMessage = '',
  password = false,
  value,
  onchange,
  onBlur,
  onFocus,
  name,
  onIconClick,
  type,
  disabled,
  rightIconDisabled = disabled,
  readOnly,
  isMinusForbidden = true,
  'data-testid': dataTestId,
  maxLength,
  multiline = false,
  ...rest
}) => {
  const iconColor = '#666666';
  const adornmentTextColor = disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit';
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    if (onchange) {
      onchange(e.target.value);
    }
  };

  const inputProps = {
    'data-testid': dataTestId,
  };

  if (leftText) {
    inputProps.startAdornment = (
      <InputAdornment position="start" style={{ color: customColor }}>
        <span className="leftText">{leftText}</span>
      </InputAdornment>
    );
  }

  if (rightIcon || rightText) {
    inputProps.endAdornment = (
      <InputAdornment position="end" style={{ color: iconColor }}>
        {rightIcon ? (
          <IconButton
            size={rightIconSize}
            onClick={() => {
              if (onIconClick) {
                onIconClick();
              }
            }}
            disabled={rightIconDisabled}
          >
            {rightIcon}
          </IconButton>
        ) : (
          <span className="rightText" style={{ color: adornmentTextColor }}>
            {rightText}
          </span>
        )}
      </InputAdornment>
    );
  } else if (password) {
    inputProps.endAdornment = (
      <InputAdornment position="end" style={{ color: iconColor }}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            handleShowPassword();
          }}
          onMouseDown={handleMouseDownPassword}
          color="inherit"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  const inputType = () => {
    if (password && !showPassword) return 'password';
    return 'text';
  };

  const handleKeyDown = (e) => {
    // prevent typing letters in numeric fields
    if (type === 'number') {
      if (
        e.key.length === 1 &&
        e.key !== '.' &&
        e.key !== ',' &&
        // eslint-disable-next-line no-restricted-globals
        isNaN(e.key) &&
        !e.ctrlKey
      ) {
        if (!isMinusForbidden && e.key === '-') {
          return true;
        }
        e.preventDefault();
      }
    }
    return true;
  };

  return (
    <StyledInput
      customColor={customColor}
      value={value || ''}
      onChange={handleChange}
      type={inputType()}
      label={label}
      InputProps={inputProps}
      fullWidth={fullWidth}
      error={!!errorMessage.length}
      helperText={errorMessage.length ? errorMessage : null}
      disabled={disabled}
      readOnly={readOnly}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      onKeyDown={handleKeyDown}
      multiline={multiline}
      textFieldProps={{
        maxLength,
        inputMode: type === 'number' && isMinusForbidden ? 'decimal' : 'text',
      }}
      {...rest}
    />
  );
};

Input.propTypes = {
  label: PropTypes.string,
  rightIcon: PropTypes.element,
  rightIconSize: PropTypes.string,
  rightText: PropTypes.string,
  leftText: PropTypes.string,
  customColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  errorMessage: PropTypes.string,
  password: PropTypes.bool,
  value: stringOrNumber,
  onchange: PropTypes.func,
  onIconClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  rightIconDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isMinusForbidden: PropTypes.bool,
  'data-testid': PropTypes.string,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
};

export default Input;
