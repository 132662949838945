import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BarChartContainer = styled.div`
  width: calc(100% - 20px);
  margin: 10px auto 0;
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 375px) {
    align-items: flex-start;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ChartContainer = styled.div`
  height: 110px;
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;

  @media (min-width: 768px) {
    height: 160px;
  }
`;

const BarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background: ${({ previousYear }) => (previousYear ? '#F2F2F2' : 'none')};

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: -30px;
    width: 100%;
    border-right: ${({ previousYear, isDecember }) =>
      previousYear && isDecember ? '1px solid #ccc' : '0'};
  }
`;

const Bar = styled.div`
  width: 35%;
  height: ${({ barHeight }) => `${barHeight}%`};
  margin: 0 auto;
  background: #3fbabb;
  position: relative;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  padding-bottom: 10px;
`;

const Description = styled.div`
  width: 9%;
  text-align: center;
  opacity: ${({ previousYear }) => (previousYear ? '0.5' : '1')};
`;

const Year = styled.div`
  position: absolute;
  top: 4px;
  left: ${({ currentYear }) => (currentYear ? '4px' : 'auto')};
  right: ${({ currentYear }) => (currentYear ? 'auto' : '4px')};
  font-size: 10px;
  color: #666666;
  opacity: 0.5;
`;

const currentYear = new Date().getFullYear();

const getMaxvalue = (chartData) =>
  Math.max(...chartData.map(({ value }) => value), 0);

const calculateBarHeight = (maxValue, value) => (value / maxValue) * 100;

const isCurrentYear = (date) => date.includes(currentYear);

const BarChart = ({ chartData, handleClick }) => {
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    setMaxValue(getMaxvalue(chartData));
  }, [chartData]);

  return (
    <BarChartContainer onClick={handleClick} data-testid="bar-chart">
      <ChartContainer>
        {chartData.map(({ month, value }) => (
          <BarContainer
            key={month}
            previousYear={!isCurrentYear(month)}
            isDecember={month.includes('12')}
          >
            <Bar barHeight={calculateBarHeight(maxValue, value)} />
            {month.includes('-12') && month.includes(currentYear - 1) && (
              <Year>{currentYear - 1}</Year>
            )}
            {month.includes('-01') && <Year currentYear>{currentYear}</Year>}
          </BarContainer>
        ))}
      </ChartContainer>
      <DescriptionContainer data-testid="chart-description">
        {chartData.map(({ month }) => (
          <Description key={month} previousYear={!isCurrentYear(month)}>
            {+month.split('-')[1]}
          </Description>
        ))}
      </DescriptionContainer>
    </BarChartContainer>
  );
};

BarChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
};

export default BarChart;
