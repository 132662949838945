import { SUPPORTED_FILE_TYPES, VIDEO } from '../constants/photo';
import {
  CUSTOMER_VIEW_KEY,
  GALLERY_KEY,
  NEW_CLIENT_GALLERY_KEY,
} from '../constants/video';
import {
  APPLICATION_LIST_TYPES,
  PHOTOS_GENERAL_GALLERY,
  PHOTOS_BEFORE_GALLERY,
  PHOTOS_AFTER_GALLERY,
} from '../constants/lists';

export function isVideoType(type) {
  return type === VIDEO;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getBackPath(
  clientId,
  from,
  applicationId = '',
  defaultTags = {}
) {
  const beforeTagId = defaultTags.beforeTag ? defaultTags.beforeTag.id : '';
  const afterTagId = defaultTags.afterTag ? defaultTags.afterTag.id : '';
  const overviewTagId = defaultTags.overviewTag
    ? defaultTags.overviewTag.id
    : '';

  const paths = {
    [CUSTOMER_VIEW_KEY]: `/customers/${clientId}`,
    [APPLICATION_LIST_TYPES.PHOTOS_GENERAL]: `/customers/${clientId}/applications/${applicationId}`,
    [APPLICATION_LIST_TYPES.PHOTOS_AFTER]: `/customers/${clientId}/applications/${applicationId}`,
    [APPLICATION_LIST_TYPES.PHOTOS_BEFORE]: `/customers/${clientId}/applications/${applicationId}`,
    [PHOTOS_GENERAL_GALLERY]: `/customers/${clientId}/applications/${applicationId}/gallery?tagId=${overviewTagId}`,
    [PHOTOS_BEFORE_GALLERY]: `/customers/${clientId}/applications/${applicationId}/gallery?tagId=${beforeTagId}`,
    [PHOTOS_AFTER_GALLERY]: `/customers/${clientId}/applications/${applicationId}/gallery?tagId=${afterTagId}`,
    [GALLERY_KEY]: `/customers/${clientId}/gallery`,
    [NEW_CLIENT_GALLERY_KEY]: `/customers/${clientId}/client/create/gallery`,
  };
  return paths[from];
}

function getThumbnailUrl(videoUrl) {
  return `${videoUrl
    .replace('/client-gallery/', '/thumbs-gallery/')
    .split('.')
    .slice(0, -1)
    .join('.')}.jpg`;
}

function processGalleryItem(item) {
  if (item.thumbnailUrl) {
    return item;
  }
  return { ...item, thumbnailUrl: getThumbnailUrl(item.fileUrl) };
}

export function parsePhotosAfterGet(data) {
  return { ...data, data: data.data.map(processGalleryItem) };
}

export function isVideoMimeSupported(mimeType) {
  return MediaRecorder.isTypeSupported(mimeType);
}

export function getVideoMimeTypeSupported() {
  const supported = Object.keys(SUPPORTED_FILE_TYPES).filter((mimeType) =>
    isVideoMimeSupported(mimeType)
  );

  return supported[0] || false;
}
