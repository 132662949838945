import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import NavigationIcon from '@material-ui/icons/Navigation';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';

import AppBar from '../../components/AppBar/AppBar.connected';
import Tip from '../../components/Tip/Tip';
import NextActions from './NextActions';
import NoClients from './NoClients';
import { hideSelectCustomersViewTip } from '../../store/reducers/app';
import {
  fetchClientListData,
  fetchClientListNearestData,
  fetchMoreClientListNearestData,
} from '../../store/reducers/clients';
import CustomersList from './CustomersList';
import ListDialog from '../../components/Dialog/ListDialog/ListDialog';
import ContactsDialogList from '../../components/Lists/ContactsDialogList';
import { fetchClientDataById } from '../../store/actions/clientDataActions';
import { fetchCurrentlyLoggedUser } from '../../store/actions/userActions';
import {
  SearchIcon,
  StyledButton,
  NoClientsTitle,
  NoClientsDescription,
  ReasonsTitle,
  ReasonsContainer,
  Version,
  AddClientButton,
} from './SelectCustomerView.styles';
import { StyledTitle } from './CustomersListy.styles';
import { createNewClient } from '../../store/reducers/client';
import { fetchProductsInCategories } from '../../store/reducers/products';
import useCreateCallReportDialog from '../../hooks/useCreateCallReportDialog';

const LOCATION_PERMISSION_DENIED = 1;

const NoNearestClients = ({ geolocationDenied }) => {
  const { t } = useTranslation();

  return (
    <>
      <NoClientsTitle>{t('customer_list_nearest')}</NoClientsTitle>
      {geolocationDenied ? (
        <>
          <ReasonsTitle>{t('geolocation_no_nearby')}</ReasonsTitle>
          <ReasonsContainer>
            <p>{t('geolocation_reason_title')}</p>
            <ul>
              {[
                'geolocation_reason_1',
                'geolocation_reason_2',
                'geolocation_reason_3',
              ].map((reason) => (
                <li key={reason}>{t(reason)}</li>
              ))}
            </ul>
          </ReasonsContainer>
        </>
      ) : (
        <NoClientsDescription>{t('no_nearest_clients')}</NoClientsDescription>
      )}
    </>
  );
};

NoNearestClients.propTypes = {
  geolocationDenied: PropTypes.bool,
};

function fetchNearest(
  enqueueSnackbar,
  dispatch,
  t,
  page = 1,
  setNearestPage,
  onLocationError
) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      if (page === 1) {
        dispatch(
          fetchClientListNearestData({
            coords: position.coords,
            page,
          })
        );
      } else {
        dispatch(
          fetchMoreClientListNearestData({
            coords: position.coords,
            page,
          })
        );
      }
      setNearestPage(page + 1);
    }, onLocationError);
  } else {
    enqueueSnackbar(t('geo_not_supported', { variant: 'error' }));
  }
}

const SelectCustomerView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [geolocationDenied, setGeolocationDenied] = useState(false);
  const [showContactsDialog, setShowContactsDialog] = useState(false);
  const [contactsDialogData, setContactsDialogData] = useState([]);
  const [generalPhoneNumber, setGeneralPhoneNumber] = useState('');

  const [nearestPage, setNearestPage] = useState(1);

  useEffect(() => {
    dispatch(fetchClientListData());

    fetchNearest(
      enqueueSnackbar,
      dispatch,
      t,
      nearestPage,
      setNearestPage,
      (error) => {
        if (error.code === LOCATION_PERMISSION_DENIED) {
          setGeolocationDenied(true);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enqueueSnackbar, t, setGeolocationDenied]);

  const {
    clients,
    plannedNextActions,
    nearestClients,
    totalNearestClients,
    loadingClients,
    loadingNextActions,
    loadingNearestClients,
  } = useSelector((state) => state.clients);
  const { showSelectCustomerViewTip } = useSelector((state) => state.app);
  const { clientData } = useSelector((state) => state);

  const [showMoreNearest, setShowMoreNearest] = useState(false);
  useEffect(() => {
    setShowMoreNearest(nearestClients?.length < totalNearestClients);
  }, [nearestClients, totalNearestClients]);

  const { online } = useSelector((state) => state.offline);

  const hasNextActions = plannedNextActions && plannedNextActions.length > 0;
  const hasNearestClients = nearestClients && nearestClients.length > 0;
  const hasClients = clients && clients.length > 0;
  const isLoading =
    loadingClients || loadingNearestClients || loadingNextActions;
  const isEmpty =
    !hasClients && !hasNextActions && !hasNearestClients && !isLoading;

  const { showDepartment } = useSelector((state) => state.user.data.miaOptions);

  const newClientAction = () => {
    const clientId = uuidv4();
    dispatch(createNewClient(clientId));
    history.push(`/customers/${clientId}/client/create`);
  };

  const handleShowMoreClick = () => {
    fetchNearest(
      enqueueSnackbar,
      dispatch,
      t,
      nearestPage,
      setNearestPage,
      (error) => {
        if (error.code === LOCATION_PERMISSION_DENIED) {
          setGeolocationDenied(true);
        }
      }
    );
    setShowMoreNearest(false);
  };

  const {
    showCreateCallReportDialog,
    setShowCreateCallReportDialog,
    callReportSelectedContact,
    setCallReportSelectedContact,
    callReportClientId,
    setCallReportClientId,
  } = useCreateCallReportDialog();

  useEffect(() => {
    dispatch(fetchCurrentlyLoggedUser());
  }, [dispatch]);

  return (
    <Container maxWidth="sm" data-testid="select-customer-view">
      <AppBar
        title={t('customer_list_title')}
        aside={
          !isEmpty && (
            <Link to="/customers/search" data-testid="search-icon">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Link>
          )
        }
      />
      <NextActions
        setContactsDialogData={setContactsDialogData}
        setShowContactsDialog={setShowContactsDialog}
        setGeneralPhoneNumber={setGeneralPhoneNumber}
        showCreateCallReportDialog={showCreateCallReportDialog}
        setShowCreateCallReportDialog={setShowCreateCallReportDialog}
        callReportSelectedContact={callReportSelectedContact}
        callReportClientId={callReportClientId}
        setCallReportClientId={setCallReportClientId}
      />
      {hasNearestClients ? (
        <CustomersList
          clientData={clientData && clientData.data}
          data-testid="nearest-clients"
          title={() => (
            <StyledTitle>
              <NavigationIcon />
              <div>{t('customer_list_nearest')}</div>
            </StyledTitle>
          )}
          items={nearestClients}
          path="/customers"
          setContactsDialogData={setContactsDialogData}
          setShowContactsDialog={setShowContactsDialog}
          online={online}
          onLinkClick={(clientId) => {
            dispatch(fetchProductsInCategories({ clientId }));
            dispatch(fetchClientDataById({ clientId }));
          }}
          showDepartment={showDepartment}
          showCustomerNumber
          setGeneralPhoneNumber={setGeneralPhoneNumber}
          showCreateCallReportDialog={showCreateCallReportDialog}
          setShowCreateCallReportDialog={setShowCreateCallReportDialog}
          callReportSelectedContact={callReportSelectedContact}
          callReportClientId={callReportClientId}
          setCallReportClientId={setCallReportClientId}
        />
      ) : (
        <NoNearestClients geolocationDenied={geolocationDenied} />
      )}
      {hasNearestClients && showMoreNearest && online ? (
        <StyledButton theme="primary" onClick={handleShowMoreClick}>
          {t('customer_list_show_more')}
        </StyledButton>
      ) : null}
      <Tip
        title={t('customer_list_tip_title')}
        text={t('customer_list_tip')}
        showTip={showSelectCustomerViewTip}
        setShowTip={() => {
          dispatch(hideSelectCustomersViewTip());
        }}
      />
      {isEmpty && <NoClients newClientAction={newClientAction} />}
      <Version>v{process.env.REACT_APP_VERSION}</Version>
      <ListDialog
        titleIcon={<PeopleIcon />}
        title={t('app_contacts')}
        open={showContactsDialog}
        setOpen={setShowContactsDialog}
        data-testid="contacts-dialog"
      >
        <ContactsDialogList
          contactsDialogData={contactsDialogData}
          generalPhoneNumber={generalPhoneNumber}
          setShowCreateCallReportDialog={setShowCreateCallReportDialog}
          setShowContactsDialog={setShowContactsDialog}
          setCallReportSelectedContact={setCallReportSelectedContact}
        />
      </ListDialog>
      {!isEmpty && (
        <AddClientButton
          color="primary"
          aria-label="add-client"
          onClick={newClientAction}
        >
          <AddIcon />
        </AddClientButton>
      )}
    </Container>
  );
};

export default SelectCustomerView;
