import styled from 'styled-components';
import MuiListItemText from '@material-ui/core/ListItemText';

const StyledListItemText = styled(MuiListItemText)`
  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MuiListItemText-secondary {
    margin-top: 2px;
    color: #666666;
  }

  .MuiListItemText-primary ~ .MuiListItemText-secondary {
    margin-top: 4px;
  }
`;

export default StyledListItemText;
