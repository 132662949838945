import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';
import { Button } from 'frontend-components';

import AppBar from '../../../components/AppBar/AppBar.connected';
import Client from '../../../components/Client/Client';
import withClientData from '../../../helpers/withClientData';
import Input from '../../../components/Input/Input';
import TextArea from '../../../components/TextArea/TextArea';
import useFormikAutoSave from '../../../components/FormikAutoSave/FormAutoSaveHook';
import FixedFooter from '../../../components/FixedFooter/FixedFooter';
import ContactPersonSection from './ContactPersonSection';
import Dialog from '../../../components/Dialog/Dialog';
import { addressProps, getAddress } from '../../../helpers/address';
import { clientDataShape } from '../../../helpers/clientDataPropTypes';
import ContactPeopleList from '../../../components/Lists/ContactPeopleList';
import { updateQrderOrQuotationExpandedPanel } from '../../../store/reducers/clientUI';
import {
  ORDER_OR_QUOTATION_PANEL_TYPE,
  NO_EXPANDED_PANEL,
} from '../../../constants/lists';

const InputsContainer = styled.div`
  & .MuiFormControl-root {
    margin: 20px 0 16px;
  }
`;

const TextAreaContainer = styled.div`
  padding: 20px 0 16px;

  textarea {
    display: block;
  }
`;

const ContactPersonSectionContainer = styled.div`
  margin: 20px 0 16px;

  & label {
    top: 0;
  }
`;

const DetailsViewContainer = styled(Container)`
  margin-bottom: 144px;
`;

const Label = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: 12px;
    padding-top: 4px;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: center;

  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: inherit;
  }
`;

const PanelContainer = styled.div`
  margin: 36px 0 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
`;

const ErrorSendCopyTo = styled(FormHelperText)`
  position: absolute;
  bottom: -24px;
`;

function filterSendCopyToContacts(contacts, contactsIds) {
  if (!contactsIds)
    return {
      total: 0,
      data: [],
    };

  const sendCopyToContacts = contacts.data.filter((contact) =>
    contactsIds.includes(contact.id)
  );

  return {
    total: sendCopyToContacts.length,
    data: sendCopyToContacts,
  };
}

const DetailsView = ({
  title,
  openContactsListPath,
  updateStore,
  clearStore,
  draftData,
  clientData,
  visitAddress,
  handleAddProductsButton: goToProducts,
  noteLabel,
  emailNoteLabel,
  internalNoteLabel,
  confirmationTitle,
  confirmationContent,
  deleteContactFromCC,
  testId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const defaultAddress = getAddress(visitAddress);
  const [isContactPersonMissing, setIsContactPersonMissing] = useState(false);
  const [isSendCopyToMissing, setIsSendCopyToMissing] = useState(false);
  const [internalNoteExpanded, setInternalNoteExpanded] = useState(false);

  const { expandedPanel } = useSelector((state) => ({
    expandedPanel: state.clientUI.orderOrQuotationExpandedPanel[clientId]
      ? state.clientUI.orderOrQuotationExpandedPanel[clientId]
      : NO_EXPANDED_PANEL,
  }));

  const {
    name,
    addresses: { invoice, delivery },
    contactPersons,
  } = clientData;

  useEffect(() => {
    setInvoiceAddress(getAddress(invoice) || defaultAddress);
  }, [invoice, defaultAddress]);

  useEffect(() => {
    setDeliveryAddress(getAddress(delivery) || defaultAddress);
  }, [delivery, defaultAddress]);

  const { handleSubmit, setFieldValue, values, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactPersonId: draftData.contactPersonId || '',
      customerOrderNumber: draftData.customerOrderNumber || '',
      note: draftData.note || '',
      emailNote: draftData.emailNote || '',
      internalNote: draftData.internalNote || '',
    },
    onSubmit: () => {
      if (dirty) {
        updateStore(values);
      }
    },
  });

  const {
    contactPersonId,
    customerOrderNumber,
    note,
    emailNote,
    internalNote,
  } = values;

  const closeConfirmationDialog = () => setOpenConfirmationDialog(false);

  const handleLeave = () => {
    clearStore();
    draftData?.quotationId
      ? history.push(
          `/customers/${clientId}/orderHistory/quotation/${draftData.quotationId}`
        )
      : history.push(`/customers/${clientId}/create`);
  };

  const handleBackButton = () => {
    draftData?.edited ? setOpenConfirmationDialog(true) : handleLeave();
  };

  useFormikAutoSave(values, handleSubmit, 300, dirty);

  const handleAddProductsButton = () => {
    if (!contactPersonId) {
      setIsContactPersonMissing(true);
      return;
    }

    goToProducts();
  };

  useEffect(() => {
    contactPersonId &&
      isContactPersonMissing &&
      setIsContactPersonMissing(false);
  }, [contactPersonId, isContactPersonMissing, setIsContactPersonMissing]);

  useEffect(() => {
    draftData.contactsCC?.length &&
      isSendCopyToMissing &&
      setIsSendCopyToMissing(false);
  }, [draftData, isSendCopyToMissing, setIsSendCopyToMissing]);

  return (
    <DetailsViewContainer maxWidth="sm" data-testid={testId}>
      <AppBar
        title={t(title)}
        displayBackButton
        backButtonAction={handleBackButton}
      />
      <Client name={name} />
      <InputsContainer>
        <Input
          id="invoiceAddress"
          label={
            invoiceAddress ? t('invoice_address') : t('pck_no_invoice_address')
          }
          value={invoiceAddress}
          fullWidth
          disabled
          multiline
        />
        <Input
          id="deliveryAddress"
          label={
            deliveryAddress
              ? t('delivery_address')
              : t('pck_no_delivery_address')
          }
          value={deliveryAddress}
          fullWidth
          disabled
          multiline
        />
      </InputsContainer>
      <form onSubmit={handleSubmit}>
        <ContactPersonSectionContainer>
          <ContactPersonSection
            contactPersonId={draftData.contactPersonId}
            openContactsList={() => history.push(openContactsListPath)}
            errorMessage={
              isContactPersonMissing ? t('validation_contact_person') : ''
            }
          />
        </ContactPersonSectionContainer>
        <PanelContainer>
          <ContactPeopleList
            data-testid="contacts-list"
            withRemoveButton
            expanded={expandedPanel === ORDER_OR_QUOTATION_PANEL_TYPE.CC}
            onChange={(event, isExpanded) => {
              dispatch(
                updateQrderOrQuotationExpandedPanel({
                  clientId,
                  expandedPanel: isExpanded
                    ? NO_EXPANDED_PANEL
                    : ORDER_OR_QUOTATION_PANEL_TYPE.CC,
                })
              );
            }}
            onPlusButtonClick={() => {
              history.push(openContactsListPath, { from: 'sendCopyTo' });
            }}
            onRemoveClick={(contactPersonId) =>
              deleteContactFromCC(contactPersonId)
            }
            contactPersons={filterSendCopyToContacts(
              contactPersons,
              draftData.contactsCC
            )}
            title={t('send_copy_to')}
            showEmail
            blockEditingContactOnClick
          />
          {isSendCopyToMissing && (
            <ErrorSendCopyTo error>{t('send_copy_to_error')}</ErrorSendCopyTo>
          )}
        </PanelContainer>

        <InputsContainer>
          <Input
            id="customerOrderNumber"
            name="customerOrderNumber"
            value={customerOrderNumber}
            label={t('create_order_num')}
            fullWidth
            onchange={(value) => setFieldValue('customerOrderNumber', value)}
            maxLength={255}
          />
        </InputsContainer>
        <TextAreaContainer>
          <TextArea
            id="note"
            label={t(noteLabel)}
            placeholder={t('create_note_placeholder')}
            fullWidth
            onChange={(e) => {
              setFieldValue('note', e.target.value);
            }}
            value={note}
            maxLength={1000}
          />
        </TextAreaContainer>
        <TextAreaContainer>
          <TextArea
            id="emailNote"
            label={t(emailNoteLabel)}
            placeholder={t('email_note_placeholder')}
            fullWidth
            onChange={(e) => {
              setFieldValue('emailNote', e.target.value);
            }}
            value={emailNote}
            maxLength={1000}
          />
        </TextAreaContainer>
        <TextAreaContainer>
          <Label
            onClick={() => setInternalNoteExpanded(!internalNoteExpanded)}
            data-testid="internal-note-label"
          >
            {t(internalNoteLabel)}
          </Label>
          <Collapse in={internalNoteExpanded} timeout="auto" unmountOnExit>
            <TextArea
              id="internalNote"
              placeholder={t('internal_note_placeholder')}
              fullWidth
              onChange={(e) => {
                setFieldValue('internalNote', e.target.value);
              }}
              value={internalNote}
              maxLength={1000}
            />
          </Collapse>
        </TextAreaContainer>
      </form>
      <FixedFooter>
        <AddButtonContainer>
          <Button
            aria-label="add-products"
            onClick={handleAddProductsButton}
            theme="primary"
          >
            {t('add_products')}
          </Button>
        </AddButtonContainer>
      </FixedFooter>
      <Dialog
        onClose={() => {
          setOpenConfirmationDialog(false);
        }}
        aria-labelledby="confirm-dialog"
        open={openConfirmationDialog}
      >
        <DialogTitle onClose={closeConfirmationDialog}>
          {confirmationTitle}
        </DialogTitle>
        <DialogContent>{confirmationContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleLeave} theme="secondary">
            {t('pck_confirm_leave')}
          </Button>
          <Button autoFocus onClick={closeConfirmationDialog} theme="primary">
            {t('pck_confirm_stay')}
          </Button>
        </DialogActions>
      </Dialog>
    </DetailsViewContainer>
  );
};

DetailsView.propTypes = {
  clientData: clientDataShape,
  title: PropTypes.string,
  openContactsListPath: PropTypes.string,
  draftData: PropTypes.shape({
    quotationId: PropTypes.string,
    contactPersonId: PropTypes.string,
    customerOrderNumber: PropTypes.string,
    note: PropTypes.string,
    edited: PropTypes.bool,
    contactsCC: PropTypes.arrayOf(PropTypes.string),
    emailNote: PropTypes.string,
    internalNote: PropTypes.string,
  }),
  visitAddress: PropTypes.shape(addressProps),
  updateStore: PropTypes.func,
  clearStore: PropTypes.func,
  handleAddProductsButton: PropTypes.func,
  noteLabel: PropTypes.string,
  confirmationTitle: PropTypes.string,
  confirmationContent: PropTypes.string,
  deleteContactFromCC: PropTypes.func,
  emailNoteLabel: PropTypes.string,
  internalNoteLabel: PropTypes.string,
  testId: PropTypes.string,
};

export default withClientData(DetailsView);
