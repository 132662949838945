import SvgIcon from '@material-ui/core/SvgIcon';

function FilePDF(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M14,2 L6,2 C4.9,2 4.01,2.9 4.01,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M9.5,14.5 C9.5,15.33 8.83,16 8,16 L7,16 L7,18 L5.5,18 L5.5,12 L8,12 C8.83,12 9.5,12.67 9.5,13.5 L9.5,14.5 Z M14.5,16.5 C14.5,17.33 13.83,18 13,18 L10.5,18 L10.5,12 L13,12 C13.83,12 14.5,12.67 14.5,13.5 L14.5,16.5 Z M18.5,13.5 L17,13.5 L17,14.5 L18.5,14.5 L18.5,16 L17,16 L17,18 L15.5,18 L15.5,12 L18.5,12 L18.5,13.5 Z M7,14.5 L8,14.5 L8,13.5 L7,13.5 L7,14.5 Z M12,16.5 L13,16.5 L13,13.5 L12,13.5 L12,16.5 Z M13,9 L13,3.5 L18.5,9 L13,9 Z"
      />
    </SvgIcon>
  );
}

export default FilePDF;
