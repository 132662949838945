import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '../../components/Input/Input';
import { stringOrNumber } from '../../helpers/clientDataPropTypes';

const CostSavings = ({
  costSavings,
  uptimeGain,
  laborTimeSaved,
  showSavings = true,
  currency,
}) => {
  const { t } = useTranslation();

  const getDisplayedValue = (value) => {
    if (!value) return '';
    return value > -0.5 && value < 0.5
      ? t('app_savings_less_than_one')
      : Math.round(value);
  };

  const isConstSavingsVisible = !!Math.round(costSavings);
  const isUptimeGainVisible = !!Math.round(uptimeGain);
  const isLaborTimeVisible = !!Math.round(laborTimeSaved);

  return (
    <>
      {(showSavings || isConstSavingsVisible) && (
        <Input
          id="costSavings"
          label={t('app_savings')}
          value={getDisplayedValue(costSavings)}
          fullWidth
          rightText={`${currency}/${t(`app_year`)}`}
          disabled
        />
      )}
      {(showSavings || isUptimeGainVisible) && (
        <Input
          id="uptimeGain"
          label={t('app_uptime_gain')}
          value={getDisplayedValue(uptimeGain)}
          fullWidth
          rightText={`${t('app_time_unit')}/${t(`app_year`)}`}
          disabled
        />
      )}
      {(showSavings || isLaborTimeVisible) && (
        <Input
          id="laborTimeSaved"
          label={t('app_labor_time_saved')}
          value={getDisplayedValue(laborTimeSaved)}
          fullWidth
          rightText={`${t('app_time_unit')}/${t(`app_year`)}`}
          disabled
        />
      )}
    </>
  );
};

CostSavings.propTypes = {
  costSavings: stringOrNumber,
  uptimeGain: stringOrNumber,
  laborTimeSaved: stringOrNumber,
  showSavings: PropTypes.bool,
  currency: PropTypes.string,
};

export default CostSavings;
