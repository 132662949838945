import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 8px;
  }

  .MuiDialogTitle-root {
    font-size: 20px;
    color: #000000;
    padding: 0 0 16px 0;
  }

  .MuiDialogContent-root {
    padding: 0;
    color: #666666;
    font-size: 14px;
  }

  .MuiDialogActions-spacing {
    padding: 24px 0 8px 0;

    @media (orientation: landscape) {
      padding: 16px 0 8px 0;
    }
  }

  .MuiPaper-elevation24 {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 24px;
  }
`;

export default StyledDialog;
