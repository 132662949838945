import apiService from './api.service';
import { sortByName } from '../helpers/sortByName';

const sortSicCodes = (data) => {
  if (!data) return data;
  sortByName(data);

  data.forEach(({ children }) => {
    sortByName(children);
  });

  return data;
};

export const getSicCodes = () =>
  apiService({
    url: `/ict/siccodes`,
    transformResponse: [({ data }) => sortSicCodes(data)],
  });
