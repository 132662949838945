import GalleryItemViewInProgress from './GalleryItemViewInProgress';
import withClientData from '../../helpers/withClientData';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const ClientGalleryItemViewInProgress = ({ clientData }) => {
  return <GalleryItemViewInProgress clientData={clientData} />;
};

ClientGalleryItemViewInProgress.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(ClientGalleryItemViewInProgress);
