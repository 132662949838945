import SvgIcon from '@material-ui/core/SvgIcon';

function FileExcel(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M14,2 L20,8 L20,20 C20,21.05 19.1799587,21.9177686 18.1486946,21.9944872 L18,22 L5.99,22 C4.94,22 4.08134298,21.1799587 4.00545267,20.1486946 L4,20 L4.01,4 C4.01,2.95 4.82092975,2.0822314 5.85136551,2.00551277 L6,2 L14,2 Z M10.2695313,11.0375 L8.08203125,11.0375 L10.3023438,14.9859375 L8.021875,19 L10.23125,19 L11.5710938,16.3039063 L12.9109375,19 L15.1257813,19 L12.8398438,14.9859375 L15.065625,11.0375 L12.8726563,11.0375 L11.5710938,13.684375 L10.2695313,11.0375 Z M13,3.5 L13,9 L18.5,9 L13,3.5 Z"
      />
    </SvgIcon>
  );
}

export default FileExcel;
