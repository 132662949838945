import Truncate from 'react-truncate';
import { Fragment } from 'react';
import { SubTitle } from './Card.styles';

const ADDRESS_ONE_LINE = 'address-1';
const ADDRESS_TWO_LINE = 'address-2';

export const DEFAULT_TILE_LINES = [ADDRESS_TWO_LINE];

const createAddress = (addressLines, subtitle) => (
  <SubTitle>
    <Truncate lines={addressLines} ellipsis={<span>...</span>}>
      {subtitle}
    </Truncate>
  </SubTitle>
);

export const createDepartment = (department) => (
  <SubTitle>
    <Truncate lines={1} ellipsis={<span>...</span>}>
      {department}
    </Truncate>
  </SubTitle>
);

export const createTileContent = (lines, subtitle) => {
  const tileConfig = {
    [ADDRESS_ONE_LINE]: () => createAddress(1, subtitle),
    [ADDRESS_TWO_LINE]: () => createAddress(2, subtitle),
  };

  return lines.map((line) => (
    <Fragment key={line}>{tileConfig[line] && tileConfig[line]()}</Fragment>
  ));
};
