import styled from 'styled-components';

export const StyledVisitNoteView = styled.div`
  margin: 0 0 0 0;

  & .MuiFormControlLabel-root {
    margin-left: 0;
    padding: 12px 0;
  }

  & .MuiFormControl-root,
  textarea {
    margin-bottom: 18px;
  }

  & .MuiCheckbox-root {
    padding-left: 0;
    padding-bottom: 24px;
  }

  & .MuiFormControlLabel-label {
    margin-bottom: 16px;
  }
`;
