import { createAction } from '@reduxjs/toolkit';

export const addProductToVisit = createAction(
  'clientData/addProductToVisit',
  function prepare(noteId, clientId, demoedProduct) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProduct,
      },
    };
  }
);

export const removeDemoedProduct = createAction(
  'clientData/removeDemoedProduct',
  function prepare(noteId, clientId, demoedProduct) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProduct,
      },
    };
  }
);

export const addDemoedProductsPerNote = createAction(
  'clientData/addDemoedProductsPerNote',
  function prepare(noteId, clientId, demoedProducts) {
    return {
      payload: {
        noteId,
        clientId,
        demoedProducts,
      },
    };
  }
);

export const addProductsPerCallNote = createAction(
  'clientData/addProductsPerCallNote',
  function prepare(noteId, clientId, suggestedProducts) {
    return {
      payload: {
        noteId,
        clientId,
        suggestedProducts,
      },
    };
  }
);

export const updateDemoedProductsDate = createAction(
  'updateDemoedProductsDate',
  function prepare(date, noteId, clientId, demoedProductId) {
    return {
      payload: {
        date,
        noteId,
        clientId,
        demoedProductId,
      },
    };
  }
);
