import SvgIcon from '@material-ui/core/SvgIcon';

function EntrancePhotoIcon(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <path
          d="M15,376 C15,371.581722 18.581722,368 23,368 L352,368 C356.418278,368 360,371.581722 360,376 L360,590 C360,594.418278 356.418278,598 352,598 L23,598 C18.581722,598 15,594.418278 15,590 L15,376 Z"
          id="path-1"
        />
        <filter
          x="-3.8%"
          y="-4.8%"
          width="107.5%"
          height="111.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Mobile-app"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="4.5.2.customer-details-filled-photo"
          transform="translate(-31.000000, -532.000000)"
        >
          <rect fill="#FFFFFF" x="0" y="0" width="375" height="1106" />
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="0"
            y="104"
            width="375"
            height="1159"
          />
          <g id="Combined-Shape">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            />
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
          </g>
          <g
            id="Icon-/-New-/-navigate"
            transform="translate(31.000000, 532.000000)"
            fill="#999999"
          >
            <path
              d="M21,19 L21,5 C21,3.9 20.1,3 19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 Z M8.5,13.5 L11,16.51 L14.5,12 L19,18 L5,18 L8.5,13.5 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default EntrancePhotoIcon;
