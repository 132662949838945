import PropTypes from 'prop-types';
import List from '../List/List';
import Product from '../Product/Product';

const ProductsList = ({ products }) => {
  return (
    <List data-testid="details-products">
      {products.map((product, index) => {
        const {
          name,
          packageCapacity,
          packageType,
          quantity,
          price,
          currency,
          code,
        } = product;

        const packageDetails = {
          packageCapacity,
          packageType,
        };

        return (
          <Product
            key={product.id || `${code}-${price}-${index}`}
            name={name || code}
            packageDetails={packageDetails}
            quantity={quantity}
            currency={currency}
            price={price}
          />
        );
      })}
    </List>
  );
};

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ProductsList;
