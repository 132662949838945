import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { useTranslation } from 'react-i18next';
import {
  findPhone,
  getContactsByPhone,
  getDateFormatted,
} from 'frontend-components/lib/helpers';
import { PHONE_PRIMARY } from 'frontend-components/lib/constants/contacts';
import { v4 as uuidv4 } from 'uuid';

import { StyledList, StyledListItem } from './CustomersListy.styles';
import {
  clientActionsListItemShape,
  clientDataShape,
} from '../../helpers/clientDataPropTypes';
import { isDelegated } from '../../helpers/permissions';
import { NOTE_TYPES } from '../../constants/noteTypes';
import CreateCallReportDialog from '../../components/Dialog/CreateCallReportDialog/CreateCallReportDialog';
import Card from '../../components/Card/Card';
import Tip from '../../components/Tip/Tip';
import { navigateToInMaps } from '../../helpers/maps';
import { DAY_FILTER_VALUES } from './DayFilter';
import { StyledButton } from './SelectCustomerView.styles';

const updateActions = (today, clientData, id, item) => {
  const openActions = [];
  const openActionsData = clientData[id].openActions.data;

  const openActionsForToday = openActionsData
    .filter(({ doAt }) => getDateFormatted(new Date(doAt)) === today)
    .sort((a, b) => new Date(a.doAt) - new Date(b.doAt));

  if (openActionsForToday.length) {
    // get unique action type with the earliest time
    const filteredArray = Object.values(
      openActionsForToday.reduce((unique, action) => {
        if (
          !unique[action.type] ||
          new Date(action.doAt) < new Date(unique[action.type].doAt)
        ) {
          unique[action.type] = action;
        }

        return unique;
      }, {})
    );

    filteredArray.forEach(({ type, doAt }) => {
      openActions.push({
        type,
        time: doAt.substr(-8),
      });
    });
  }

  item.actions = openActions;
  item.actionsDone = !openActions.length;
};

const updateClientListFromClientData = (items, clientData, plannedForToday) => {
  const today = getDateFormatted(new Date());
  items.forEach((item) => {
    const { id } = item;
    if (!clientData[id]) return;

    if (plannedForToday) {
      updateActions(today, clientData, id, item);
    }

    const contactsFromClientData = [];
    clientData[id].contactPersons.data.forEach(
      ({ firstName, lastName, phoneList, isDeleted }) => {
        const primaryPhone = findPhone(phoneList, PHONE_PRIMARY);
        if (!primaryPhone || isDeleted) return;
        contactsFromClientData.push({
          firstName,
          lastName,
          phoneNumber: primaryPhone.phone,
          phoneList,
        });
      }
    );
    item.contactPersons = contactsFromClientData;
  });
};

const sortItems = (items, clientData, plannedForToday, filterBy, online) => {
  if (!isEmpty(clientData) && !online) {
    updateClientListFromClientData(items, clientData, plannedForToday);
  }
  items.sort(
    (a, b) =>
      new Date(`${b.doAt} ${b.startTime}`) -
      new Date(`${a.doAt} ${a.startTime}`)
  );

  return filterBy === DAY_FILTER_VALUES.TODAY ? items.reverse() : items;
};

const CustomersList = ({
  title,
  items,
  path,
  'data-testid': testId,
  clientData = null,
  setContactsDialogData,
  setShowContactsDialog,
  plannedForToday,
  filterBy,
  online,
  onLinkClick,
  tileLines,
  titleLines = 2,
  showDepartment,
  showCustomerNumber,
  setGeneralPhoneNumber,
  showCreateCallReportDialog,
  setShowCreateCallReportDialog,
  callReportSelectedContact,
  callReportClientId,
  setCallReportClientId,
}) => {
  const { t } = useTranslation();
  const [sortedItems, setSortedItems] = useState([]);
  const [limit, setLimit] = useState(10);
  const [updateCustomerId, setUpdateCustomerId] = useState(null);

  useEffect(() => {
    if (clientData) {
      const sortedItemsByActionsDone = sortItems(
        [
          ...cloneDeep(
            items.filter(({ actionType }) => actionType !== 'other')
          ),
          ...cloneDeep(
            items.filter(({ actionType }) => actionType === 'other')
          ),
        ],
        clientData,
        plannedForToday,
        filterBy,
        online
      );
      setSortedItems(sortedItemsByActionsDone);
    } else {
      setSortedItems(items);
    }
  }, [items, clientData, plannedForToday, filterBy, online]);

  const history = useHistory();

  useEffect(() => {
    if (!online && clientData && !has(clientData, callReportClientId)) {
      setShowCreateCallReportDialog(false);
    }
    // eslint-disable-next-line
  }, [online, clientData, callReportClientId, showCreateCallReportDialog]);

  useEffect(() => {
    setLimit(10);
  }, [filterBy]);

  return (
    <>
      {title && title()}
      <StyledList data-testid={testId}>
        {sortedItems
          .slice(0, limit)
          .map(
            (
              {
                id,
                actionId,
                actionDone,
                description,
                doAt,
                startTime,
                endTime,
                actionType,
                reportType,
                applicationReportId,
                visitAddress: { name, street, city },
                distance,
                lat,
                lng,
                department,
                contactPersons,
                contactPersonId,
                clientNumber,
                classification,
                actions,
                owned,
                access,
                generalPhoneNumber,
              },
              index
            ) => {
              const address = [street, city].filter(Boolean).join(', ');
              const handlePhoneButtonClick = () => {
                if (
                  !generalPhoneNumber &&
                  (!contactPersons?.length ||
                    !getContactsByPhone(contactPersons)?.length)
                ) {
                  setUpdateCustomerId(id);
                  return;
                }
                generalPhoneNumber && setGeneralPhoneNumber(generalPhoneNumber);
                setContactsDialogData(getContactsByPhone(contactPersons));
                setCallReportClientId(id);
                setShowContactsDialog(true);
              };

              const handleGalleryButtonClick = () => {
                history.push(`/customers/${id}/visit/${uuidv4()}`);
              };

              const handleNavigationButtonClick = (lat, lng, address) => {
                if (!address) {
                  setUpdateCustomerId(id);
                  return;
                }
                navigateToInMaps(lat, lng, address);
              };

              return (
                <StyledListItem
                  key={actionId || id}
                  disabled={actionDone && isDelegated(access)}
                >
                  <Card
                    id={id}
                    actionId={actionId}
                    path={path}
                    description={description}
                    actionTime={{ doAt, startTime, endTime }}
                    actionType={actionType}
                    reportType={reportType}
                    applicationReportId={applicationReportId}
                    clientDetails={{
                      name,
                      address,
                      distance,
                      lat: lat?.toString(),
                      lng: lng?.toString(),
                      department,
                      classification,
                      generalPhoneNumber,
                      clientNumber,
                    }}
                    actionDone={actionDone}
                    contactPersons={contactPersons}
                    contactPersonId={contactPersonId}
                    tileLines={tileLines}
                    titleLines={titleLines}
                    actions={actions}
                    owned={owned}
                    access={access}
                    showDepartment={showDepartment}
                    showCustomerNumber={showCustomerNumber}
                    filterBy={filterBy}
                    onLinkClick={onLinkClick}
                    handlePhoneButtonClick={handlePhoneButtonClick}
                    handleNavigationButtonClick={handleNavigationButtonClick}
                    handleGalleryButtonClick={handleGalleryButtonClick}
                    isExpanded={index === 0 && !actionDone}
                  />
                </StyledListItem>
              );
            }
          )}
        {plannedForToday && sortedItems.length > limit && (
          <StyledButton
            theme="primary"
            onClick={() => {
              setLimit(limit + 10);
            }}
          >
            {t('customer_list_show_more')}
          </StyledButton>
        )}
      </StyledList>
      <CreateCallReportDialog
        open={showCreateCallReportDialog}
        setOpen={setShowCreateCallReportDialog}
        createCallReport={() =>
          history.push(
            `/customers/${callReportClientId}/${NOTE_TYPES.CALLS}/${uuidv4()}`,
            {
              callDialogContactPhoneNumber: callReportSelectedContact,
            }
          )
        }
      />
      <Tip
        title={t('update_customer_tip_title')}
        text={t('update_customer_tip')}
        showTip={!!updateCustomerId}
        setShowTip={() => {
          setUpdateCustomerId(null);
        }}
        showButton
        buttonLabel={t('update_customer_tip_button')}
        onButtonClick={() => {
          setUpdateCustomerId(null);
          history.push(`/customers/${updateCustomerId}/details`);
        }}
        data-testid="update-customer-tip"
      />
    </>
  );
};

CustomersList.propTypes = {
  title: PropTypes.func,
  items: PropTypes.arrayOf(clientActionsListItemShape),
  path: PropTypes.string,
  'data-testid': PropTypes.string,
  setContactsDialogData: PropTypes.func,
  setShowContactsDialog: PropTypes.func,
  plannedForToday: PropTypes.bool,
  filterBy: PropTypes.string,
  online: PropTypes.bool,
  onLinkClick: PropTypes.func,
  tileLines: PropTypes.arrayOf(PropTypes.string),
  titleLines: PropTypes.number,
  clientData: clientDataShape,
  showDepartment: PropTypes.bool,
  showCustomerNumber: PropTypes.bool,
  setGeneralPhoneNumber: PropTypes.func,
  setShowCreateCallReportDialog: PropTypes.func,
  showCreateCallReportDialog: PropTypes.bool,
  callReportSelectedContact: PropTypes.string,
  callReportClientId: PropTypes.string,
  setCallReportClientId: PropTypes.func,
};

export default CustomersList;
