import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { scrollToError } from 'frontend-components/lib/helpers';

import ClientDetails from './ClientDetails';
import withClientData from '../../helpers/withClientData';
import { updateClientDetails, putClient } from '../../store/reducers/clients';
import { updateClientName } from '../../store/actions/clientDetailsActions';
import {
  prepareClientDetailsPayload,
  clientNameValid,
} from '../../helpers/clients';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const EditClientView = ({ clientData }) => {
  const { clientId } = useParams();
  const { clients, searchedClients, plannedClients, plannedClientsTomorrow } =
    useSelector((state) => state.clients);
  const history = useHistory();
  const dispatch = useDispatch();

  const clientListItemData = clients
    .concat(searchedClients)
    .concat(plannedClients)
    .concat(plannedClientsTomorrow)
    .find((client) => client.id === clientId);

  const navigateToGallery = () => {
    history.push(`/customers/${clientId}/gallery`);
  };

  const backButtonAction = (
    clientListItemData,
    name,
    setIsNameMissing,
    setIsNameLengthError,
    sic,
    setIsSicCodeNameMissing
  ) => {
    if (clientListItemData.edited) {
      if (!name) {
        setIsNameMissing(true);
        scrollToError();
        return;
      }
      if (!clientNameValid(name)) {
        setIsNameLengthError(true);
        scrollToError();
        return;
      }
      if (!sic) {
        setIsSicCodeNameMissing(true);
        scrollToError();
        return;
      }
      const payload = {
        ...clientListItemData,
      };
      delete payload.edited;

      dispatch(putClient(clientId, prepareClientDetailsPayload(payload)));
    }
    history.push(`/customers/${clientId}`);
  };

  return (
    <ClientDetails
      clientData={clientData}
      clientListItemData={clientListItemData}
      editAddressPath={`/customers/${clientId}/details/address`}
      editSicCodePath={`/customers/${clientId}/details/sic`}
      updateClientDetails={updateClientDetails}
      updateClientName={updateClientName}
      title={clientListItemData.visitAddress.name}
      navigateToGallery={navigateToGallery}
      backButtonAction={backButtonAction}
    />
  );
};

EditClientView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(EditClientView);
