import apiService from './api.service';

export const login = (login, password) => {
  const data = {
    login,
    password,
  };
  return apiService({
    method: 'POST',
    url: `/login`,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const refreshAccessToken = (refreshToken) =>
  apiService({
    method: 'POST',
    url: `/token/refresh`,
    data: {
      refresh_token: refreshToken,
    },
  });
