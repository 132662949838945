import { REPORT_TYPES } from '../constants/noteTypes';

export const combineReports = (data) => {
  const visits = data?.visits?.data || [];
  const phoneCalls = data?.phoneCalls?.data || [];
  const remarks = data?.remarks?.data || [];
  return {
    total: visits.length + phoneCalls.length + remarks.length,
    data: [
      ...visits.map((item) => {
        return {
          ...item,
          reportType: REPORT_TYPES.VISIT,
        };
      }),
      ...phoneCalls.map((item) => {
        return {
          ...item,
          reportType: REPORT_TYPES.PHONE_CALL,
        };
      }),
      ...remarks.map((item) => {
        return {
          ...item,
          reportType: REPORT_TYPES.REMARK,
        };
      }),
    ].sort(
      (a, b) =>
        new Date(
          b.reportDate || b.phoneCallDate || b.visitDate || b.remarkDate
        ).getTime() -
        new Date(
          a.reportDate || a.phoneCallDate || a.visitDate || a.remarkDate
        ).getTime()
    ),
  };
};
