import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactPersonView from './ContactPersonView';
import {
  createContactPerson,
  editContactPerson,
  setApplicationContactPerson,
} from '../../store/actions/contactPersonActions';
import { setApplicationNoteContactPerson } from '../../store/actions/applicationNoteActions';

const AddContactPersonToApplicationNoteView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { applicationId, noteId, clientId, contactPersonId } = useParams();

  return (
    <ContactPersonView
      goBackAction={() => {
        history.push(
          `/customers/${clientId}/applications/${applicationId}/notes/${noteId}/addContactPerson`
        );
      }}
      onSubmit={(values, isExistingContact) => {
        const newValues = {
          ...values,
          clientId,
        };
        if (isExistingContact) {
          dispatch(editContactPerson(contactPersonId, newValues, clientId));
        } else {
          dispatch(
            createContactPerson(contactPersonId, newValues, clientId, true)
          );
          dispatch(
            setApplicationNoteContactPerson(contactPersonId, clientId, noteId)
          );
          dispatch(
            setApplicationContactPerson(
              contactPersonId,
              applicationId,
              clientId
            )
          );
        }

        history.push(
          `/customers/${clientId}/applications/${applicationId}/notes/${noteId}`
        );
      }}
    />
  );
};

export default AddContactPersonToApplicationNoteView;
