import { format } from 'date-fns';
import apiService from './api.service';

export const deleteVisit = (visitId) => {
  return apiService({
    method: 'DELETE',
    url: `/qac/visit/${visitId}`,
  });
};

export const getVisitsByDateAndClientId = ({ clientId, date }) => {
  const visitDate = format(new Date(date), 'yyyy-MM-dd');
  return apiService({
    url: `/qac/check-visit/${clientId}/${visitDate}`,
  });
};
