import { useParams } from 'react-router-dom';
import withClientData from '../../helpers/withClientData';
import NextActionView from './NextActionView';
import { NOTE_TYPES, REPORT_TYPES } from '../../constants/noteTypes';
import {
  addNextActionToVisitNote,
  deleteNextActionFromVisitNote,
} from '../../store/actions/nextActionActions';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const VisitNextActionView = ({ clientData }) => {
  const { clientId, noteId } = useParams();

  return (
    <NextActionView
      clientData={clientData}
      data={clientData.visits.data}
      reportType={REPORT_TYPES.VISIT}
      addNextActionToNote={addNextActionToVisitNote}
      deleteNextActionFromNote={deleteNextActionFromVisitNote}
      noteType={NOTE_TYPES.VISIT}
      backPath={`/customers/${clientId}/visits/${noteId}/`}
      calendarPath={`/customers/${clientId}/visits/${noteId}/calendar`}
    />
  );
};

VisitNextActionView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(VisitNextActionView);
