import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import NothingFound from '../Icons/NothingFound';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ size }) => (size === 'sm' ? '16px' : '20px')};
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 30px;

  svg {
    margin: ${({ size }) => (size === 'sm' ? '30px' : '100px')} 0 16px 0;
  }
`;

const Reason = styled.p`
  font-size: 14px;
`;

const NotFound = ({ reason, size }) => {
  const { t } = useTranslation();
  return (
    <Container size={size}>
      <NothingFound />
      {t('nothingFound')}
      {reason && <Reason>{reason}</Reason>}
    </Container>
  );
};

NotFound.propTypes = {
  reason: PropTypes.string,
  size: PropTypes.string,
};

export default NotFound;
