import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from 'frontend-components';

import Dialog from '../Dialog';
import DialogHeader from '../DialogHeader';
import Checkbox from '../../Checkbox/Checkbox';
import PhoneHorizontal from '../../Icons/PhoneHorizontal';

const StyledDialogText = styled(DialogContentText)`
  font-size: 14px;
  color: #666666;
`;

const StyledButton = styled(Button)`
  margin: 12px 0 15px;
`;

const CheckboxWrapper = styled.div`
  text-align: center;
  color: #666666;

  & > label {
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  margin: 14px;
  text-align: center;
`;

const UploaderTipDialog = ({
  open,
  setOpen,
  handleContinue,
  handleCheckbox,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      aria-labelledby="uploader-tip-dialog"
      open={open}
      fullWidth
      onClick={(e) => e.stopPropagation()}
    >
      <DialogHeader
        title={t('uploader_dialog_title')}
        handleClose={() => setOpen(false)}
      />
      <DialogContent>
        <IconWrapper>
          <PhoneHorizontal />
        </IconWrapper>
        <StyledDialogText>{t('uploader_dialog_text')}</StyledDialogText>
      </DialogContent>
      <StyledButton
        autoFocus
        aria-label="continue"
        onClick={() => {
          setOpen(false);
          handleContinue();
        }}
        theme="primary"
        fullWidth
      >
        {t('uploader_dialog_continue')}
      </StyledButton>
      <CheckboxWrapper>
        <Checkbox
          label={t('uploader_dialog_checkbox')}
          id="doNotShowAgain"
          onChange={(value) => {
            handleCheckbox(value);
          }}
        />
      </CheckboxWrapper>
    </Dialog>
  );
};

UploaderTipDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleContinue: PropTypes.func,
  handleCheckbox: PropTypes.func,
};

export default UploaderTipDialog;
