import { createAction } from '@reduxjs/toolkit';

export const addNextActionToClient = createAction(
  'clientData/addNextActionToClient',
  function prepare(noteId, clientId, clientName, nextAction) {
    return {
      payload: {
        noteId,
        clientId,
        clientName,
        nextAction,
      },
    };
  }
);

export const addNextActionToVisitNote = createAction(
  'clientData/addNextActionToVisitNote',
  function prepare(noteId, clientId, nextAction, notMarkNoteAsEdited) {
    return {
      payload: {
        noteId,
        clientId,
        nextAction,
        notMarkNoteAsEdited,
      },
    };
  }
);

export const addNextActionToCallNote = createAction(
  'clientData/addNextActionToCallNote',
  function prepare(noteId, clientId, nextAction, notMarkNoteAsEdited) {
    return {
      payload: {
        noteId,
        clientId,
        nextAction,
        notMarkNoteAsEdited,
      },
    };
  }
);

export const addNextActionToRemarkNote = createAction(
  'clientData/addNextActionToRemarkNote',
  function prepare(noteId, clientId, nextAction, notMarkNoteAsEdited) {
    return {
      payload: {
        noteId,
        clientId,
        nextAction,
        notMarkNoteAsEdited,
      },
    };
  }
);

export const addNextActionToOpenActions = createAction(
  'clientData/addNextActionToOpenActions',
  function prepare(clientId, nextAction) {
    return {
      payload: {
        clientId,
        nextAction,
      },
    };
  }
);

export const updateNextActionInOpenActions = createAction(
  'clientData/updateNextActionInOpenAction',
  function prepare(clientId, nextAction) {
    return {
      payload: {
        clientId,
        nextAction,
      },
    };
  }
);

export const putOpenAction = createAction(
  'clientData/put/OpenAction',
  function prepare(clientId, nextAction) {
    return {
      payload: {
        clientId,
        nextAction,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/next-action/${nextAction.id}`,
            method: 'PUT',
            data: { actionDone: nextAction.actionDone },
          },
          commit: {
            type: 'clientData/put/OpenAction/commit',
            meta: {
              clientId,
              nextAction,
            },
          },
          rollback: {
            type: 'clientData/put/OpenAction/rollback',
            meta: { clientId, nextAction },
          },
        },
      },
    };
  }
);

export const deleteNextActionFromVisitNote = createAction(
  'clientData/deleteNextActionFromVisitNote',
  function prepare(noteId, clientId) {
    return {
      payload: {
        noteId,
        clientId,
      },
    };
  }
);

export const deleteNextActionFromRemarkNote = createAction(
  'clientData/deleteNextActionFromRemarkNote',
  function prepare(noteId, clientId) {
    return {
      payload: {
        noteId,
        clientId,
      },
    };
  }
);

export const deleteNextActionFromCallNote = createAction(
  'clientData/deleteNextActionFromCallNote',
  function prepare(noteId, clientId) {
    return {
      payload: {
        noteId,
        clientId,
      },
    };
  }
);

export const deleteNextActionFromOpenActions = createAction(
  'clientData/deleteNextActionFromOpenActions',
  function prepare(clientId, actionId) {
    return {
      payload: {
        clientId,
        actionId,
      },
    };
  }
);
