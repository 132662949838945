import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const priceRegExp = /^\d+(?:\.\d{0,2})?$/;

const usePriceValidationSchema = () => {
  const { t } = useTranslation();

  return Yup.string()
    .required(t('pck_price_validation'))
    .matches(priceRegExp, t('pck_price_validation'));
};

export default usePriceValidationSchema;
