import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import { AsideButton, usePermissions } from 'frontend-components';
import {
  getContactPhoneNumber,
  getContactsByPhone,
  generatePhoneList,
  getDefinedPhones,
} from 'frontend-components/lib/helpers';
import { PHONES } from 'frontend-components/lib/constants/contacts';

import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import ListDialog from '../../components/Dialog/ListDialog/ListDialog';
import ContactsDialogList from '../../components/Lists/ContactsDialogList';
import AppBar from '../../components/AppBar/AppBar.connected';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import MenuItem from '../../components/Select/MenuItem';
import TextArea from '../../components/TextArea/TextArea';
import {
  StyledContactPersonView,
  IconsContainer,
  StyledIconButton,
} from './ContactPersonViewStyles';
import {
  deleteContactPerson,
  deleteContactPersonInStore,
} from '../../store/actions/contactPersonActions';
import withClientData from '../../helpers/withClientData';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { emailValidation, phoneValidation } from '../../helpers/validations';

const generateMenuItems = (options) => {
  return options.map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));
};

const ContactPersonView = ({
  clientData,
  onSubmit,
  goBackAction,
  validateEmail,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteContactDialog, setShowDeleteContactDialog] = useState(false);
  const { clientId, contactPersonId } = useParams();
  const [isExistingContact, setIsExistingContact] = useState(false);
  const [isEmailMissing, setIsEmailMissing] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [showPhonesDialog, setShowPhonesDialog] = useState(false);
  const [contactsDialogData, setContactsDialogData] = useState([]);

  const contacts = clientData.contactPersons.data;
  const matchingContact = contacts.find(({ id }) => id === contactPersonId);
  const titleOptions = [
    { value: 0, label: '' },
    { value: 1, label: t('contact_honorific_mr') },
    { value: 2, label: t('contact_honorific_mrs') },
    { value: 3, label: t('contact_honorific_miss') },
    { value: 4, label: t('contact_honorific_ms') },
  ];
  const addressingFormOptions = [
    { value: 'formal', label: t('contact_address_formal') },
    { value: 'informal', label: t('contact_address_informal') },
  ];
  const titleMenuItems = generateMenuItems(titleOptions);
  const addressingFormItems = generateMenuItems(addressingFormOptions);
  const visiblePhoneList = generatePhoneList(
    matchingContact ? matchingContact.phoneList : []
  );
  const {
    handleSubmit,
    handleBlur,
    values,
    setFieldValue,
    dirty,
    errors,
    touched,
    validateForm,
    isValid,
  } = useFormik({
    initialValues: {
      firstName: matchingContact ? matchingContact.firstName : '',
      lastName: matchingContact ? matchingContact.lastName : '',
      note: matchingContact ? matchingContact.note : '',
      phoneList: visiblePhoneList,
      emailsList: matchingContact ? matchingContact.emailsList : [],
      honorific: matchingContact ? matchingContact.honorific : 0,
      addressingForm: matchingContact
        ? matchingContact.addressingForm
        : 'formal',
      jobTitle: matchingContact ? matchingContact.jobTitle : '',
      department: matchingContact ? matchingContact.department : '',
    },
    validationSchema: Yup.object().shape(
      {
        firstName: Yup.string().when('lastName', {
          is: (lastName) => !lastName || lastName.length === 0,
          then: Yup.string()
            .trim()
            .max(100, t('contact_name_len'))
            .required(t('contact_name_validation')),
          otherwise: Yup.string().trim().max(100, t('contact_name_len')),
        }),
        lastName: Yup.string().when('firstName', {
          is: (firstName) => !firstName || firstName.length === 0,
          then: Yup.string()
            .trim()
            .max(100, t('contact_surname_len'))
            .required(t('contact_surname_validation')),
          otherwise: Yup.string().trim().max(100, t('contact_surname_len')),
        }),
        phoneList: Yup.array().of(
          Yup.object().shape({
            phone: phoneValidation(t),
          })
        ),
        emailsList: Yup.array().of(emailValidation(t)),
      },
      [['firstName', 'lastName']]
    ),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values, isExistingContact);
      }
    },
  });

  useEffect(() => {
    if (typeof matchingContact === 'undefined') {
      setIsExistingContact(false);
    } else {
      setIsExistingContact(true);
    }
  }, [matchingContact]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const { disabled, readOnly } = usePermissions(
    clientData.access,
    matchingContact && matchingContact.owned
  );

  const existingContactReadOnly =
    readOnly && isExistingContact && !matchingContact?.owned;

  const goBack = () => {
    history.goBack();
  };

  const {
    firstName,
    lastName,
    note,
    phoneList,
    emailsList,
    honorific,
    addressingForm,
    jobTitle,
    department,
  } = values;
  const appBarTitle =
    firstName.length > 0 && lastName.length > 0
      ? `${firstName} ${lastName}`
      : t('contact_create_title');
  const contactPhoneNumber = getContactPhoneNumber(phoneList);

  const backButtonAction = () => {
    if (validateEmail && dirty && emailsList.length < 1) {
      setIsEmailMissing(true);
      return;
    }
    if (!disabled && (dirty || (isExistingContact && !dirty && !isValid))) {
      handleSubmit();
    } else {
      goBackAction ? goBackAction() : goBack();
    }
  };

  useEffect(() => {
    if (validateEmail) {
      emailsList.length && setIsEmailMissing(false);
    }
  }, [emailsList, setIsEmailMissing, validateEmail]);

  useEffect(() => {
    if (isEmailMissing) {
      setEmailErrorMessage(t('validation_email_required'));
    } else {
      setEmailErrorMessage(
        errors.emailsList && errors.emailsList[0] ? errors.emailsList[0] : ''
      );
    }
  }, [errors.emailsList, isEmailMissing, setIsEmailMissing, t, validateEmail]);

  const handlePhoneButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const phones = getContactsByPhone([
      {
        firstName,
        lastName,
        phoneList,
      },
    ]);
    setContactsDialogData(phones);
    setShowPhonesDialog(true);
  };

  return (
    <Container maxWidth="sm" data-testid="contact-person-view">
      <AppBar
        title={appBarTitle}
        displayBackButton
        backButtonAction={backButtonAction}
        aside={
          isExistingContact && (
            <AsideButton
              data-testid="delete-button"
              onClick={() => {
                setShowDeleteContactDialog(true);
              }}
              disabled={disabled || existingContactReadOnly}
            >
              <DeleteIcon />
            </AsideButton>
          )
        }
      />
      <form onSubmit={handleSubmit}>
        <StyledContactPersonView>
          <Input
            data-testid="firstName_input"
            id="firstName"
            name="firstName"
            value={firstName}
            onchange={(typedIn) => {
              setFieldValue('firstName', typedIn);
            }}
            onBlur={handleBlur}
            label={t('contact_name')}
            rightIcon={<PersonIcon />}
            fullWidth
            errors={errors.firstName}
            errorMessage={touched.firstName ? errors.firstName : ''}
            disabled={disabled || existingContactReadOnly}
          />
          <Input
            data-testid="lastName_input"
            id="lastName"
            name="lastName"
            value={lastName}
            onchange={(typedIn) => {
              setFieldValue('lastName', typedIn);
            }}
            onBlur={handleBlur}
            label={t('contact_surname')}
            rightIcon={<PersonIcon />}
            fullWidth
            errors={errors.lastName}
            errorMessage={touched.lastName ? errors.lastName : ''}
            disabled={disabled || existingContactReadOnly}
          />
          {phoneList &&
            phoneList.map((phone, index) => {
              return (
                <Input
                  key={`phone-${phone.type}`}
                  id="contact_phone"
                  name={PHONES[phone.type] || 'contact_phone'}
                  value={phone.phone}
                  label={t(PHONES[phone.type] || 'contact_phone')}
                  onBlur={handleBlur}
                  rightIcon={<PhoneIcon />}
                  fullWidth
                  onchange={(val) => {
                    const newPhoneList = [...phoneList];
                    newPhoneList[index] = {
                      ...newPhoneList[index],
                      phone: val,
                    };
                    setFieldValue('phoneList', newPhoneList);
                  }}
                  errors={errors.phoneList}
                  errorMessage={
                    errors.phoneList && errors.phoneList[index]
                      ? errors.phoneList[index].phone
                      : ''
                  }
                  disabled={disabled || existingContactReadOnly}
                />
              );
            })}
          <Input
            id="email"
            name="email"
            label={t('contact_email')}
            value={emailsList && emailsList.length > 0 ? emailsList[0] : ''}
            rightIcon={<EmailIcon />}
            onBlur={handleBlur}
            fullWidth
            onchange={(val) => {
              const newEmailsList = [...emailsList];
              newEmailsList[0] = val;
              setFieldValue('emailsList', newEmailsList);
            }}
            errors={errors.emailsList}
            errorMessage={emailErrorMessage}
            disabled={disabled || existingContactReadOnly}
          />
          <Select
            id="honorific"
            data-testid="honorific"
            label={t('contact_honorific')}
            onChange={(selected) => {
              setFieldValue('honorific', selected);
            }}
            value={honorific}
            fullWidth
            disabled={disabled || existingContactReadOnly}
          >
            {titleMenuItems}
          </Select>
          <Select
            id="addressingForm"
            data-testid="addressingForm"
            label={t('contact_address_form')}
            onChange={(selected) => {
              setFieldValue('addressingForm', selected);
            }}
            value={addressingForm}
            fullWidth
            disabled={disabled || existingContactReadOnly}
          >
            {addressingFormItems}
          </Select>
          <Input
            label={t('contact_jobtitle')}
            name="contact-jobtitle"
            value={jobTitle}
            onBlur={handleBlur}
            fullWidth
            onchange={(typedIn) => {
              setFieldValue('jobTitle', typedIn);
            }}
            disabled={disabled || existingContactReadOnly}
          />
          <Input
            label={t('contact_department')}
            name="contact-department"
            value={department}
            onBlur={handleBlur}
            fullWidth
            onchange={(val) => {
              setFieldValue('department', val);
            }}
            disabled={disabled || existingContactReadOnly}
          />
          <TextArea
            id="note"
            label={t('contact_note')}
            placeholder={t('contact_note_add')}
            fullWidth
            onChange={(e) => {
              setFieldValue('note', e.target.value);
            }}
            rowsMin={2}
            value={note}
            disabled={disabled || existingContactReadOnly}
          />
          {getDefinedPhones(phoneList).length > 1 && (
            <IconsContainer>
              <StyledIconButton
                aria-label="phone"
                type="phone"
                onClick={handlePhoneButtonClick}
              >
                <PhoneIcon />
              </StyledIconButton>
            </IconsContainer>
          )}
          {getDefinedPhones(phoneList).length === 1 &&
            isExistingContact &&
            contactPhoneNumber && (
              <IconsContainer>
                <StyledIconButton
                  href={`tel:${contactPhoneNumber}`}
                  aria-label="phone"
                  type="phone"
                >
                  <PhoneIcon />
                </StyledIconButton>
              </IconsContainer>
            )}
        </StyledContactPersonView>
      </form>
      <DeleteDialog
        title={t('contact_delete_title')}
        open={showDeleteContactDialog}
        setOpen={setShowDeleteContactDialog}
        handleDelete={() => {
          dispatch(deleteContactPersonInStore(contactPersonId, clientId));
          dispatch(deleteContactPerson(contactPersonId, clientId));
          goBack();
        }}
      />
      <ListDialog
        titleIcon={<PeopleIcon />}
        title={t('app_contact_phones')}
        open={showPhonesDialog}
        setOpen={setShowPhonesDialog}
        data-testid="phones-dialog"
      >
        <ContactsDialogList contactsDialogData={contactsDialogData} />
      </ListDialog>
    </Container>
  );
};

ContactPersonView.propTypes = {
  clientData: clientDataShape,
  onSubmit: PropTypes.func,
  goBackAction: PropTypes.func,
  validateEmail: PropTypes.bool,
};

export default withClientData(ContactPersonView);
