export const READ = 'R';
export const WRITE = 'W';
export const DELEGATED = 'D';

export const Permissions = {
  READ,
  WRITE,
  DELEGATED,
};

export const isReadOnly = (access) => access === Permissions.READ;
export const isWritable = (access) => access === Permissions.WRITE;
export const isDelegated = (access) => access === Permissions.DELEGATED;
