import { EMPTY_VALUE } from '../components/ClassificationCode/ClassificationCode';
import { EMPTY, MIN_SEARCH_LENGTH } from '../constants/search';

export function prepareClientDetailsPayload(clientListItemData) {
  const {
    note,
    department,
    subDepartment,
    areaCode,
    sicCode,
    visitAddress,
    classification,
    generalPhoneNumber,
    email,
    website,
  } = clientListItemData;
  return {
    note,
    department,
    subDepartment,
    areaCode,
    sicCode,
    classification: classification === EMPTY_VALUE ? null : classification,
    visitAddress: {
      ...visitAddress,
    },
    generalPhoneNumber,
    email,
    website,
  };
}

export function getValuesWithName(values, clientListItemData) {
  const {
    areaCode,
    department,
    note,
    sicCode,
    sicDescription,
    name,
    subDepartment,
    classification,
    generalPhoneNumber,
    email,
    website,
  } = values;
  return {
    areaCode,
    department,
    note,
    sicCode: sicCode || clientListItemData.sicCode,
    sicDescription: sicDescription || clientListItemData.sicDescription,
    subDepartment,
    classification: classification || EMPTY_VALUE,
    visitAddress: { ...clientListItemData.visitAddress, name },
    generalPhoneNumber: generalPhoneNumber || '',
    email: email || '',
    website: website || '',
  };
}

export const clientNameValid = (name) => name.length >= 3;

export const getNameErrorMessage = (isNameMissing, isNameLengthError) => {
  if (isNameMissing) {
    return 'validation_client_name';
  }
  if (isNameLengthError) {
    return 'validation_client_length';
  }
  return '';
};

export function filterSearchResultsOffline(
  items,
  search,
  searchOwner,
  loggedTAId
) {
  const searchTerm = search.trim().toLowerCase();

  if (
    searchTerm === '' ||
    searchTerm === null ||
    searchTerm === undefined ||
    (searchOwner !== EMPTY && searchOwner !== loggedTAId) ||
    searchTerm.length < MIN_SEARCH_LENGTH
  ) {
    return [];
  }

  return items.filter((item) => {
    const {
      visitAddress: { name },
      clientNumber,
    } = item;

    return (
      name.toLowerCase().includes(searchTerm) ||
      clientNumber?.toLowerCase().includes(searchTerm)
    );
  });
}
