export const changeFieldValueToNumber = (value, negative = false) => {
  // if value ends with '.' replace it to ',' to display validation message
  if (value.slice(-1) === '.') {
    return value.replace('.', ',');
  }

  // if value ends with ',' display validation message
  if (value.slice(-1) === ',') {
    return value;
  }

  // replace ',' to '.' to parse string to number
  const newVal = value.replace(',', '.');
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(newVal)) || newVal.slice(-1) === '.') {
    return newVal;
  }

  if (negative) {
    if (newVal === '') return '';
    return newVal;
  }

  return Number(newVal) <= 0 ? '' : newVal;
};
