export function removeGalleryItem(state, clientId, photoId) {
  const index = state.data[clientId].photos.data.findIndex(
    (photo) => photo.id.toString() === photoId
  );
  if (index !== -1) state.data[clientId].photos.data.splice(index, 1);
  state.data[clientId].photos.total = state.data[clientId].photos.data.length;
}

export function removeGalleryItemFromApplication(
  state,
  clientId,
  applicationId,
  mediaId
) {
  const applicationIndex = state.data[clientId].applications.data.findIndex(
    ({ id }) => id === applicationId
  );

  const foundApplication =
    state.data[clientId].applications.data[applicationIndex];

  if (foundApplication.photos && foundApplication.photos.length > 0) {
    const photoIndex = foundApplication.photos.findIndex(
      (id) => id === mediaId
    );

    if (photoIndex !== -1) {
      state.data[clientId].applications.data[applicationIndex].photos.splice(
        photoIndex,
        1
      );
    }
  }
}

export function editGalleryItem(state, clientId, galleryItemId, galleryItem) {
  const index = state.data[clientId].photos.data.findIndex(
    (item) => item.id.toString() === galleryItemId
  );

  if (index !== -1) {
    const currentItem = state.data[clientId].photos.data[index];

    state.data[clientId].photos.data[index] = {
      ...currentItem,
      description: galleryItem.description,
      tags: galleryItem.tags,
    };
  }
}

export function addGalleryItemToApplication(
  state,
  clientId,
  applicationId,
  galleryItem
) {
  if (applicationId) {
    const applicationIndex = state.data[clientId].applications.data.findIndex(
      ({ id }) => id === applicationId
    );

    const foundApplication =
      state.data[clientId].applications.data[applicationIndex];

    if (foundApplication) {
      if (foundApplication.photos && foundApplication.photos.length > 0) {
        foundApplication.photos.unshift(galleryItem.id);
      } else {
        foundApplication.photos = [galleryItem.id];
      }
      foundApplication.edited = true;
      foundApplication.firstCreation = false;
    }
  }
}
