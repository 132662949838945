import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { default as MuiCard } from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import { default as MuiIconButton } from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ButtonMenu from './ButtonMenu';

const StyledCard = styled(({ expanded, boxShadow, ...props }) => (
  <MuiCard {...props} />
))`
  height: ${({ $multiline }) => ($multiline ? 'auto' : '56px')};
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: ${({ boxShadow }) =>
    boxShadow ? '0 2px 8px 0 rgba(0, 0, 0, 0.2)' : 'none'};
  padding: 16px 16px 8px 16px;
  user-select: none;
  cursor: pointer;

  ${({ expanded }) =>
    expanded &&
    css`
      height: auto;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 16px !important;
    `}
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 8px;
  color: #999999;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 6px 0;
  white-space: ${({ $multiline }) => ($multiline ? 'normal' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
`;

const AsideContainer = styled.div`
  padding-left: 8px;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: -8px;
`;

const ItemsSize = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #e5e5e5;
  color: #666666;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlusButtonContainer = styled.div`
  margin-left: 16px;
  width: 40px;
`;

export const IconButton = styled(MuiIconButton)`
  padding: 7px;
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #e52413;
`;

const CollapsedContent = styled.div`
  padding-top: 4px;
`;

export const CollapsibleContent = ({ children }) => (
  <div onClick={(event) => event.stopPropagation()}>{children}</div>
);

CollapsibleContent.propTypes = {
  children: PropTypes.node,
};

const Collapsible = ({
  icon,
  title,
  itemsSize = 0,
  collapsible = true,
  displayPlusButton = false,
  onPlusButtonClick = () => {},
  onChange = () => {},
  expanded = false,
  children,
  className,
  renderItem,
  'data-testid': testId,
  boxShadow,
  disabled,
  multiline,
  removePlusButtonEmptySpace,
  menuOptions,
  onMenuOptionClick,
}) => {
  const myRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleCollapsable = (e, expanded) => {
    onChange(e, expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = (type) => {
    handleClose();
    onMenuOptionClick(type);
  };

  return (
    <StyledCard
      onClick={(e) => (collapsible ? toggleCollapsable(e, expanded) : null)}
      aria-expanded={expanded.toString()}
      data-testid={testId}
      expanded={expanded}
      className={className}
      boxShadow={boxShadow}
      $multiline={multiline}
    >
      <Header ref={myRef}>
        {icon && <IconContainer>{icon}</IconContainer>}
        <Title $multiline={multiline}>{title}</Title>
        <AsideContainer>
          {itemsSize > 0 && (
            <ItemsSize data-testid="collapsible-items-number">
              {itemsSize}
            </ItemsSize>
          )}
          {!removePlusButtonEmptySpace && (
            <PlusButtonContainer>
              {renderItem && renderItem()}
              {!disabled && displayPlusButton && (
                <>
                  <IconButton
                    disabled={disabled}
                    aria-label="add-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      menuOptions ? handleClick(e) : onPlusButtonClick();
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                  {menuOptions && (
                    <ButtonMenu
                      anchor={anchorEl}
                      menuOptions={menuOptions}
                      onMenuSelect={handleMenuSelect}
                      onClose={handleClose}
                    />
                  )}
                </>
              )}
            </PlusButtonContainer>
          )}
        </AsideContainer>
      </Header>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CollapsedContent>{children}</CollapsedContent>
      </Collapse>
    </StyledCard>
  );
};

Collapsible.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  itemsSize: PropTypes.number,
  collapsible: PropTypes.bool,
  displayPlusButton: PropTypes.bool,
  onPlusButtonClick: PropTypes.func,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  boxShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  removePlusButtonEmptySpace: PropTypes.bool,
  renderItem: PropTypes.func,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  onMenuOptionClick: PropTypes.func,
};

export default Collapsible;
