import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export const StyledList = styled(List)`
  a {
    width: 100%;
  }

  .Mui-disabled {
    opacity: 1;
  }

  .MuiListItem-root {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    flex-direction: column;
    z-index: 1;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .MuiPaper-root.MuiCard-root {
    width: 100%;
    position: relative;
    border-radius: 8px;
    z-index: 3;
  }
`;

export const StyledListItem = styled(ListItem)`
  opacity: ${({ faded }) => (faded ? '0.5' : '1')};
`;

export const StyledTitle = styled.h2`
  color: #666666;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  padding-top: 8px;
  margin-bottom: 0;
  margin: 0 4px 16px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  > svg {
    fill: #999999;
  }
`;

export const ClassificationCodeContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const Aside = styled.div`
  padding-left: 8px;
  margin-left: auto;
  color: #999999;
  max-width: 120px;
  align-items: center;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;

  @media (max-width: 350px) {
    padding-left: 0;
  }
`;

export const StyledIconLine = styled.div`
  display: flex;
  align-items: center;
  color: #666;

  & > svg {
    fill: #999;
  }

  & > div,
  & > p {
    width: calc(100% - 50px);
    margin-left: 8px;
  }
`;

export const StyledIconLineList = styled(StyledIconLine)`
  margin-top: 5px;
`;

export const StyledTextList = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const StyledButton = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: calc(50% - 8px);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-left: 32px;

  & > svg {
    position: absolute;
    left: 12px;
  }

  & > p {
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
  }

  & > svg,
  > p {
    opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;
