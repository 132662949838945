import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CancelToken } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import UploaderButtons from './UploaderButtons';
import ErrorImage from '../Icons/ErrorImage';
import Timer from './Timer';
import {
  VIDEO,
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_VIDEO_SIZE,
  MAXIMUM_FILES_COUNT,
  MAXIMUM_FILES_COUNT_AT_ONCE,
  SUPPORTED_FILE_TYPES,
  PHOTO,
} from '../../constants/photo';
import { getVideoMimeTypeSupported } from '../../helpers/video';
import {
  uploadPhoto,
  dataURLtoFile,
  getFileName,
} from '../../helpers/uploadPhoto';
import { detectOrientation } from '../../helpers/device';

const StyledFilesUploader = styled.div`
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    flex-direction: row;
  }
`;

const StyledWebcamWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  height: calc(100vh - 260px);
  width: 100%;
  overflow: hidden;

  @media (orientation: landscape) {
    height: calc(100vh - 80px);
  }
`;

const StyledWebcam = styled(Webcam)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  @media (orientation: portrait) {
    max-height: 100%;
  }
  @media (orientation: landscape) {
    max-height: 100%;
    max-width: 100%;
  }
`;

const StyledTimerWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledError = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #efefef;
  padding: 20px;
`;

const FilesUploader = ({ allFiles, uploadedFiles, handleAddFile, tag }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [takingPhotoDisabled, setTakingPhotoDisabled] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isButtonActive, setButtonActive] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordError, setRecordError] = useState('');
  const [orientation, setOrientation] = useState(detectOrientation());
  const [documentFocus, setDocumentFocus] = useState(true);
  const { online } = useSelector((state) => state.offline);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleCapturePhoto = async () => {
    const screenshot = webcamRef.current.getScreenshot();
    const uploadDate = new Date().toISOString();
    const source = CancelToken.source();
    const data = {
      id: uuidv4(),
      fileName: getFileName(uploadDate),
      createdAt: uploadDate,
      updatedAt: uploadDate,
      description: '',
      tags: tag ? [tag] : [],
      mediaType: PHOTO,
    };

    if (screenshot && !takingPhotoDisabled) {
      const file = dataURLtoFile(screenshot, data.fileName);
      data.link = await uploadPhoto(file);

      const newFile = new File([screenshot], data.fileName);

      handleAddFile([{ file: newFile, data, source }]);

      setTakingPhotoDisabled(true);

      setTimeout(() => {
        setTakingPhotoDisabled(false);
      }, 500);
    }
  };

  const handleStartCaptureClick = useCallback(() => {
    setIsRecording(true);
    const mimeType = getVideoMimeTypeSupported();
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setIsRecording, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  }, [mediaRecorderRef, setIsRecording]);

  const handleAddVideo = () => {
    if (recordedChunks.length) {
      const mimeType = getVideoMimeTypeSupported();

      if (!mimeType) {
        enqueueSnackbar(`${t('validation_video_type')}`, {
          variant: 'error',
        });
        return;
      }

      const type = SUPPORTED_FILE_TYPES[mimeType];
      const blob = new Blob(recordedChunks, { type: mimeType });
      setRecordedChunks([]);

      const uploadDate = new Date().toISOString();
      const source = CancelToken.source();
      const fileName = `${uploadDate}.${type}`
        .toLowerCase()
        .replace(/[^a-zA-Z0-9_\-.]+/g, '-');
      const data = {
        id: uuidv4(),
        link: URL.createObjectURL(blob),
        fileName,
        createdAt: uploadDate,
        updatedAt: uploadDate,
        description: '',
        tags: tag ? [tag] : [],
        mediaType: VIDEO,
      };

      const newFile = new File([blob], data.fileName);
      handleAddFile([{ file: newFile, data, source }]);
    }
  };

  const isValidNumber = (files) => {
    if (files.length > MAXIMUM_FILES_COUNT_AT_ONCE) {
      enqueueSnackbar(t('app_pic_number_at_once'), { variant: 'error' });
      return false;
    }
    if (uploadedFiles + files.length > MAXIMUM_FILES_COUNT) {
      enqueueSnackbar(t('validation_app_pic_number'), { variant: 'error' });
      return false;
    }
    return true;
  };

  const isValidType = ({ type }) => {
    const isValid = Object.keys(SUPPORTED_FILE_TYPES).includes(type);

    if (!isValid) {
      enqueueSnackbar(
        `${
          type.includes('video')
            ? t('validation_video_type')
            : t('validation_app_pic_type')
        }: ${type}`,
        {
          variant: 'error',
        }
      );
    }
    return isValid;
  };

  const isValidSize = ({ size, type }, maxSize) => {
    const isValid = size < maxSize;
    if (!isValid) {
      enqueueSnackbar(
        type.includes('video')
          ? t('validation_video_size')
          : t('validation_app_pic_size'),
        { variant: 'error' }
      );
    }
    return isValid;
  };

  const isValidOnline = () => {
    if (!online) {
      enqueueSnackbar(t('validation_video_offline'), { variant: 'error' });
    }
    return online;
  };

  const isPhotoValid = (file) => {
    return isValidType(file) && isValidSize(file, MAXIMUM_IMAGE_SIZE);
  };

  const isVideoValid = (file) => {
    return (
      isValidType(file) &&
      isValidSize(file, MAXIMUM_VIDEO_SIZE) &&
      isValidOnline
    );
  };

  const handleSelectFile = async (e) => {
    const files = Array.from(e.target.files);

    if (!isValidNumber(files)) {
      return;
    }

    const photos = files.filter((file) => file.type.includes('image'));
    const videos = files.filter((file) => file.type.includes('video'));

    if (videos.length > 0) {
      videos.forEach((file) => {
        if (!isVideoValid(file)) {
          return;
        }

        const uploadDate = new Date().toISOString();
        const source = CancelToken.source();
        const url = URL.createObjectURL(file);
        const fileName = `${uploadDate}_${file.name}`
          .toLowerCase()
          .replace(/[^a-zA-Z0-9_\-.]+/g, '-');

        const data = {
          id: uuidv4(),
          link: url,
          fileName,
          createdAt: uploadDate,
          updatedAt: uploadDate,
          description: '',
          tags: tag ? [tag] : [],
          mediaType: VIDEO,
        };

        const newFile = new File([file], data.fileName);
        handleAddFile([{ file: newFile, data, source }]);
      });
    }

    await Promise.all(
      [...photos].map(async (file) => {
        if (!isPhotoValid(file)) {
          return;
        }

        const uploadDate = new Date().toISOString();
        const source = CancelToken.source();
        const data = {
          id: uuidv4(),
          fileName: getFileName(uploadDate, file),
          createdAt: uploadDate,
          updatedAt: uploadDate,
          description: '',
          tags: tag ? [tag] : [],
          mediaType: PHOTO,
        };

        data.link = await uploadPhoto(file);

        const newFile = new File([data.link], data.fileName);

        handleAddFile([{ file: newFile, data, source }]);
      })
    );
  };

  const handleOnMaxTime = () => {
    enqueueSnackbar(t('validation_video_too_long'), { variant: 'error' });
    handleStopCaptureClick();
  };

  const handleFocus = () => {
    setDocumentFocus(!document.hidden);
  };

  useEffect(() => {
    if (allFiles.length >= MAXIMUM_FILES_COUNT_AT_ONCE) {
      enqueueSnackbar(t('app_pic_number_at_once'), {
        variant: 'error',
      });
      setButtonActive(false);
    } else if (uploadedFiles >= MAXIMUM_FILES_COUNT) {
      enqueueSnackbar(t('validation_app_pic_number'), { variant: 'error' });
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, uploadedFiles]);

  useEffect(() => {
    handleAddVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  useEffect(() => {
    const handleResize = () => {
      setOrientation(detectOrientation());
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleFocus);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleFocus);
    };
  }, []);

  return (
    <StyledFilesUploader>
      <StyledWebcamWrapper>
        {!recordError && documentFocus && (
          <StyledWebcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            onUserMediaError={(error) => {
              setRecordError(isString(error) ? error : error.message);
            }}
            width={orientation === 'landscape' ? 1920 : 1080}
            height={orientation === 'landscape' ? 1080 : 1920}
            videoConstraints={{
              facingMode: 'environment',
            }}
            screenshotQuality={1}
          />
        )}
        {recordError && (
          <StyledError>
            <ErrorImage />
            {recordError}
          </StyledError>
        )}
        {isRecording && (
          <StyledTimerWrapper>
            <Timer onMaxTime={handleOnMaxTime} />
          </StyledTimerWrapper>
        )}
      </StyledWebcamWrapper>
      {documentFocus && (
        <UploaderButtons
          handleCapturePhoto={handleCapturePhoto}
          handleStartCaptureClick={handleStartCaptureClick}
          handleStopCaptureClick={handleStopCaptureClick}
          handleSelectFile={handleSelectFile}
          isRecording={isRecording}
          photoDisabled={!!recordError || !isButtonActive}
          videoDisabled={
            !getVideoMimeTypeSupported() || !!recordError || !isButtonActive
          }
          galleryDisabled={!isButtonActive}
        />
      )}
    </StyledFilesUploader>
  );
};

FilesUploader.propTypes = {
  allFiles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
  uploadedFiles: PropTypes.number,
  handleAddFile: PropTypes.func,
  tag: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
};

export default FilesUploader;
