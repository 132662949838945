import { createSlice } from '@reduxjs/toolkit';

import { NO_EXPANDED_PANEL } from '../../constants/lists';
import { RESET_COUNTER_NUMBER } from '../../constants/uploaderTipDialog';

const slice = createSlice({
  name: 'clientUI',
  initialState: {
    expandedPanel: {},
    applicationExpandedPanel: {},
    orderOrQuotationExpandedPanel: {},
    uploaderTipDialogCounter: 0,
    uploaderTipDialogDoNotShowAgain: false,
    autoUploaderTipDialogDoNotShowAgain: false,
    uploaderBackDialogDoNotShowAgain: false,
    cameraIOSTipDialogDoNotShowAgain: false,
    isFormBlocked: false,
    reportStatus: null,
  },
  reducers: {
    updateExpandedPanel: (state, action) => {
      const { expandedPanel, clientId } = action.payload;
      state.expandedPanel[clientId] = expandedPanel;
    },
    updateApplicationExpandedPanel: (state, action) => {
      const { expandedPanel, clientId } = action.payload;
      state.applicationExpandedPanel[clientId] = expandedPanel;
    },
    updateQrderOrQuotationExpandedPanel: (state, action) => {
      const { expandedPanel, clientId } = action.payload;
      state.orderOrQuotationExpandedPanel[clientId] = expandedPanel;
    },
    clearExpandedPanel: (state, action) => {
      const { clientId } = action.payload;
      state.expandedPanel[clientId] = NO_EXPANDED_PANEL;
    },
    clearApplicationExpandedPanel: (state, action) => {
      const { clientId } = action.payload;
      state.applicationExpandedPanel[clientId] = NO_EXPANDED_PANEL;
    },
    clearQrderOrQuotationExpandedPanel: (state, action) => {
      const { clientId } = action.payload;
      state.orderOrQuotationExpandedPanel[clientId] = NO_EXPANDED_PANEL;
    },
    updateUploaderTipDialogCounter: (state) => {
      const { uploaderTipDialogCounter } = state;
      if (uploaderTipDialogCounter === RESET_COUNTER_NUMBER) {
        state.uploaderTipDialogCounter = 0;
      } else {
        state.uploaderTipDialogCounter += 1;
      }
    },
    updateUploaderTipDialogDoNotShowAgain: (state, action) => {
      state.uploaderTipDialogDoNotShowAgain = action.payload;
    },
    updateAutoUploaderTipDialogDoNotShowAgain: (state, action) => {
      state.autoUploaderTipDialogDoNotShowAgain = action.payload;
    },
    updateUploaderBackDialogDoNotShowAgain: (state, action) => {
      state.uploaderBackDialogDoNotShowAgain = action.payload;
    },
    updateCameraIOSTipDialogDoNotShowAgain: (state, action) => {
      state.cameraIOSTipDialogDoNotShowAgain = action.payload;
    },
    blockForm: (state, action) => {
      state.isFormBlocked = action.payload;
    },
    clearReportStatus: (state) => {
      state.reportStatus = null;
    },
    offlineReportStatus: (state) => {
      state.reportStatus = 'offline';
    },
    progressReportStatus: (state) => {
      state.reportStatus = 'in-progress';
    },
  },
  extraReducers: {
    'visit/postAttachments/commit': (state) => {
      state.reportStatus = 'success';
    },
    'visit/postAttachments/rollback': (state) => {
      state.reportStatus = 'fail';
    },
  },
});

export const {
  updateExpandedPanel,
  updateApplicationExpandedPanel,
  updateQrderOrQuotationExpandedPanel,
  clearExpandedPanel,
  clearQrderOrQuotationExpandedPanel,
  clearApplicationExpandedPanel,
  updateUploaderTipDialogCounter,
  updateUploaderTipDialogDoNotShowAgain,
  updateAutoUploaderTipDialogDoNotShowAgain,
  updateUploaderBackDialogDoNotShowAgain,
  updateCameraIOSTipDialogDoNotShowAgain,
  blockForm,
  clearReportStatus,
  offlineReportStatus,
  progressReportStatus,
} = slice.actions;

export default slice.reducer;
