import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUploaderTipDialogCounter,
  updateUploaderTipDialogDoNotShowAgain,
} from '../../store/reducers/clientUI';
import UploaderTipDialog from '../Dialog/UploaderTipDialog/UploaderTipDialog';

const UploaderTip = () => {
  const dispatch = useDispatch();

  const [showUploaderTipDialog, setShowUploaderTipDialog] = useState(false);
  const { uploaderTipDialogCounter, uploaderTipDialogDoNotShowAgain } =
    useSelector((state) => state.clientUI);

  useEffect(() => {
    dispatch(updateUploaderTipDialogCounter());
    if (!uploaderTipDialogDoNotShowAgain && uploaderTipDialogCounter === 0) {
      setShowUploaderTipDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UploaderTipDialog
      open={showUploaderTipDialog}
      setOpen={setShowUploaderTipDialog}
      handleContinue={() => setShowUploaderTipDialog(false)}
      handleCheckbox={(checkboxVal) =>
        dispatch(updateUploaderTipDialogDoNotShowAgain(checkboxVal))
      }
    />
  );
};

export default UploaderTip;
