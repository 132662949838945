import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withClientData from '../../helpers/withClientData';
import NoteView from './NoteView';
import {
  createEmptyCallNote,
  updateCallNoteStore,
  deleteCallNoteStore,
} from '../../store/actions/notesActions';
import {
  postPhoneCall,
  putPhoneCall,
  deletePhoneCallById,
} from '../../store/actions/callNotesActions';
import {
  getAllProductsByGroupCode,
  getSuggestedProductsSectionData,
} from '../../helpers/products';
import { prepareCallNotePayload } from '../../helpers/prepNotePayload';
import { NOTE_TYPES } from '../../constants/noteTypes';
import { updateExpandedPanel } from '../../store/reducers/clientUI';
import { LIST_TYPES } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const CallNoteView = ({ clientData }) => {
  const { noteId, clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [productSectionData, setProductSectionData] = useState();
  const [allProductsByGroupCode, setAllProductsByGroupCode] = useState();

  const handlePostNote = (
    noteId,
    noteWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateCallNoteStore(noteId, noteWithNextActionId));
    dispatch(postPhoneCall(noteId, noteWithClientId, clientId));
    dispatch(
      updateExpandedPanel({ clientId, expandedPanel: LIST_TYPES.REPORTS })
    );
  };

  const handlePutNote = (
    noteId,
    noteWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateCallNoteStore(noteId, noteWithNextActionId));
    dispatch(putPhoneCall(noteId, noteWithClientId, clientId));
  };

  const handleDeleteNoteStore = (noteId, clientId) => {
    dispatch(deleteCallNoteStore(noteId, clientId));
  };

  const handleDeleteNote = (noteId, clientId) => {
    dispatch(deletePhoneCallById(noteId, clientId));
  };

  const handleCreateEmptyNote = (noteId, clientId) => {
    dispatch(createEmptyCallNote(noteId, clientId));
  };

  const handleUpdateNote = (values) => {
    const noteWithClientId = {
      ...values,
      phoneCallDate: values.noteDate,
      suggestedProducts: values.demoProducts,
      clientId,
    };

    delete noteWithClientId.demoProducts;
    delete noteWithClientId.noteDate;

    dispatch(updateCallNoteStore(noteId, noteWithClientId));
  };

  const getNoteDate = (data) => data.phoneCallDate;

  const selectButtonTitle = (productsLength) => {
    return productsLength ? t('note_suggested') : t('note_add_suggested');
  };

  const addProductButtonLabel = t('note_add_suggested');

  const data =
    clientData.phoneCalls &&
    clientData.phoneCalls.data.find((call) => call.id === noteId);

  const { allProducts } = useSelector((state) => ({
    allProducts: state.products[clientId].data,
  }));

  useEffect(() => {
    if (!allProducts) return;
    setAllProductsByGroupCode(getAllProductsByGroupCode(allProducts));
  }, [allProducts]);

  useEffect(() => {
    if (!allProductsByGroupCode) return;
    setProductSectionData(
      getSuggestedProductsSectionData(data, noteId, allProductsByGroupCode)
    );
  }, [data, noteId, allProductsByGroupCode]);

  const gotoCreateView = (actionType) => {
    history.push(
      `/customers/${clientId}/${NOTE_TYPES.CALLS}/${noteId}/${actionType}`
    );
  };

  return (
    <NoteView
      clientData={clientData}
      data={data}
      productSectionData={productSectionData}
      title={t('phone_note_title')}
      backURL={`/customers/${clientId}`}
      postNote={handlePostNote}
      putNote={handlePutNote}
      deleteNoteTitle={t('call_note_delete_title')}
      deleteNoteStore={handleDeleteNoteStore}
      deleteNote={handleDeleteNote}
      createEmptyNote={handleCreateEmptyNote}
      updateNote={handleUpdateNote}
      datePickerTestId="phone_call_date_picker"
      getNoteDate={getNoteDate}
      selectButtonTitle={selectButtonTitle}
      addProductButtonLabel={addProductButtonLabel}
      prepareNotePayload={prepareCallNotePayload}
      gotoCreateView={gotoCreateView}
      hasDraftSwitch
      suggestedProducts
      productsData={data && data.suggestedProducts}
      hasProducts
    />
  );
};

CallNoteView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(CallNoteView);
