import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Big from 'big.js';
import { Button, AsideButton } from 'frontend-components';
import { sortByDateAsc } from 'frontend-components/lib/helpers';

import AppBar from '../../../components/AppBar/AppBar.connected';
import Client from '../../../components/Client/Client';
import ProductListHeader from '../../../components/ProductsListHeader/ProductListHeader';
import withClientData from '../../../helpers/withClientData';
import ProductsList from '../../../components/Lists/ProductsList';
import { AddButtonContainer } from '../DetailsView/DetailsView';
import FixedFooter from '../../../components/FixedFooter/FixedFooter';
import { clientDataShape } from '../../../helpers/clientDataPropTypes';
import TotalsWithVat from '../../../components/Totals/TotalsWithVat';
import Input from '../../../components/Input/Input';
import { packagingDataProps } from '../../../helpers/packaging';
import { scrollToError } from '../../../helpers/scrollToError';
import usePriceValidationSchema from '../../../hooks/usePriceValidationSchema';
import Checkbox from '../../../components/Checkbox/Checkbox';
import DiscountIcon from '../../../components/Icons/DiscountIcon';
import DiscountDialog from '../DiscountDialog/DiscountDialog';
import { FREE_TRANSPORT } from '../../../constants/orders';

const StyledContainer = styled(Container)`
  padding-bottom: 96px;
`;

const TransportContainer = styled.div`
  padding: 24px 0 20px;
`;

const transformProductsData = (packagingData) => {
  const arrOfProducts = Object.values(packagingData).map((value) => [...value]);
  return sortByDateAsc(arrOfProducts.flat());
};

const currentDate = new Date();

const calculateTotalNetPrice = (
  vatOverDeliveryCost,
  netPrice,
  storedDeliveryCost,
  deliveryCost,
  discount
) => {
  let net;
  if (discount && discount !== '0') {
    const netWithoutDiscount = new Big(netPrice);
    const discountValue = new Big(discount);
    net = netWithoutDiscount.minus(
      netWithoutDiscount.times(discountValue).div(100)
    );
  } else {
    net = new Big(netPrice);
  }
  const delivery = new Big(storedDeliveryCost || deliveryCost);
  return vatOverDeliveryCost ? net.plus(delivery).toFixed(2) : net.toFixed(2);
};

const calculateVat = (totalNetPrice, vatRate) => {
  const net = new Big(totalNetPrice);
  const vat = new Big(vatRate);
  return (net.times(vat) / 100).toFixed(2);
};

const calculateTotalAmount = (
  vatOverDeliveryCost,
  totalNetPrice,
  vatRate,
  transportCost
) => {
  const net = new Big(totalNetPrice);
  const delivery = new Big(transportCost || 0);
  const vat = calculateVat(totalNetPrice, vatRate);

  const netWithVat = net.plus(vat);

  return vatOverDeliveryCost
    ? netWithVat.toFixed(2)
    : netWithVat.plus(delivery).toFixed(2);
};

const hasFreeShipping = (total, freeDelivery, storedDeliveryCost) => {
  return !storedDeliveryCost && new Big(total).gt(freeDelivery);
};

const SummaryView = ({
  clientData,
  title,
  listHeader,
  dateLabel,
  handleBackButton,
  footerButtonTitle,
  footerButtonAction,
  draftData,
  updateDeliverCost,
  updateHideTotals,
  setDiscount,
}) => {
  const { t } = useTranslation();
  const {
    name,
    currency,
    vatRate,
    deliveryCost,
    freeDeliveryCostFrom,
    vatOverDeliveryCost,
  } = clientData;
  const {
    packagingData,
    netPrice,
    deliveryCost: storedDeliveryCost,
    discount,
  } = draftData;

  const { hideTotals } = draftData;
  const [flattenProducts, setFlattenProducts] = useState();
  const [totalNetPrice, setTotalNetPrice] = useState(netPrice);
  const [totalVat, setTotalVat] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [oldDeliveryCost, setOldDeliveryCost] = useState('');

  useEffect(() => {
    setFlattenProducts(transformProductsData(packagingData));
  }, [packagingData]);

  const validationSchema = Yup.object({
    transportCost: usePriceValidationSchema(),
  });

  const { setFieldValue, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      transportCost: hasFreeShipping(
        totalNetPrice,
        freeDeliveryCostFrom,
        storedDeliveryCost
      )
        ? FREE_TRANSPORT
        : storedDeliveryCost || deliveryCost,
      hideTotals,
    },
    validationSchema,
    validateOnChange: false,
  });

  const { transportCost } = values;

  useEffect(() => {
    setTotalNetPrice(
      calculateTotalNetPrice(
        vatOverDeliveryCost,
        netPrice,
        storedDeliveryCost,
        deliveryCost,
        discount
      )
    );
  }, [
    vatOverDeliveryCost,
    netPrice,
    storedDeliveryCost,
    deliveryCost,
    discount,
  ]);

  useEffect(() => {
    setTotalVat(calculateVat(totalNetPrice, vatRate));
  }, [totalNetPrice, vatRate]);

  useEffect(() => {
    setTotalAmount(
      calculateTotalAmount(
        vatOverDeliveryCost,
        totalNetPrice,
        vatRate,
        transportCost
      )
    );
  }, [
    deliveryCost,
    storedDeliveryCost,
    totalNetPrice,
    vatOverDeliveryCost,
    vatRate,
    transportCost,
  ]);

  useEffect(() => {
    const freeShipping = hasFreeShipping(
      totalNetPrice,
      freeDeliveryCostFrom,
      storedDeliveryCost
    );
    if (freeShipping) {
      setFieldValue('transportCost', FREE_TRANSPORT);
    } else {
      setFieldValue('transportCost', storedDeliveryCost || deliveryCost);
    }
  }, [
    totalNetPrice,
    freeDeliveryCostFrom,
    storedDeliveryCost,
    deliveryCost,
    setFieldValue,
  ]);

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  return (
    <StyledContainer maxWidth="sm">
      <AppBar
        title={title}
        displayBackButton
        backButtonAction={handleBackButton}
        aside={
          <AsideButton
            onClick={() => {
              setShowDiscountModal(true);
            }}
            data-testid="discount-button"
          >
            <DiscountIcon />
          </AsideButton>
        }
      />
      <Client name={name} />
      <TotalsWithVat
        createdAt={currentDate}
        currency={currency}
        totalCost={totalAmount}
        dateLabel={dateLabel}
        vat={totalVat}
        vatLabel={vatRate}
        netPrice={totalNetPrice}
        discount={discount}
      />
      <ProductListHeader title={listHeader} price={t('unit_price')} />
      {flattenProducts && <ProductsList products={flattenProducts} />}
      <TransportContainer>
        <Input
          id="transportCost"
          name="transportCost"
          value={transportCost}
          label={t('transport')}
          fullWidth
          onchange={(transportCost) =>
            setFieldValue('transportCost', transportCost.replace(',', '.'))
          }
          maxLength={255}
          leftText={currency}
          errorMessage={errors.transportCost}
          type="number"
          isMinusForbidden
          onFocus={(event) => {
            setOldDeliveryCost(event.target.value);
          }}
          onBlur={(event) => {
            oldDeliveryCost !== event.target.value &&
              updateDeliverCost(transportCost);
          }}
        />
      </TransportContainer>
      <Checkbox
        checked={hideTotals}
        onChange={(value) => {
          setFieldValue('hideTotals', value);
          updateHideTotals(value);
        }}
        label={t('hideTotals')}
      />
      <FixedFooter>
        <AddButtonContainer>
          <Button
            aria-label="view-terms"
            // used onMouseDown to update delivery cost first and then call footerButtonAction
            // otherwise footerButtonAction function is called with old values
            onMouseDown={() => {
              if (errors.transportCost) {
                scrollToError();
                return;
              }
              updateDeliverCost(transportCost);
            }}
            onClick={() => {
              if (errors.transportCost) {
                scrollToError();
                return;
              }
              footerButtonAction();
            }}
            theme="primary"
          >
            {footerButtonTitle}
          </Button>
        </AddButtonContainer>
      </FixedFooter>
      <DiscountDialog
        open={showDiscountModal}
        setOpen={setShowDiscountModal}
        setDiscount={setDiscount}
        discountProp={discount}
      />
    </StyledContainer>
  );
};

SummaryView.propTypes = {
  clientData: clientDataShape,
  title: PropTypes.string,
  listHeader: PropTypes.string,
  dateLabel: PropTypes.string,
  handleBackButton: PropTypes.func,
  footerButtonTitle: PropTypes.string,
  footerButtonAction: PropTypes.func,
  draftData: PropTypes.shape({
    edited: PropTypes.bool,
    contactPersonId: PropTypes.string,
    packagingData: PropTypes.shape(packagingDataProps),
    deliveryCost: PropTypes.string,
    netPrice: PropTypes.string,
    hideTotals: PropTypes.bool,
    discount: PropTypes.string,
  }),
  updateDeliverCost: PropTypes.func,
  updateHideTotals: PropTypes.func,
  setDiscount: PropTypes.func,
};

export default withClientData(SummaryView);
