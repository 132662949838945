import styled from 'styled-components';

export const TechnicalTabContainer = styled.div`
  padding-bottom: 50px;

  & .MuiFormControl-root {
    margin: 20px 0 16px;
  }
`;

export const ApplicationTypeSection = styled.div`
  background-color: #f2f2f2;
  padding-bottom: 30px;
  padding-top: 6px;
`;

export const EnvContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & .MuiIconButton-root {
    margin-right: -15px;
    margin-bottom: 7px;
  }

  & .MuiSvgIcon-root {
    color: #999;
  }
`;

export const AddEnvParam = styled.div`
  margin: 22px 0 22px;

  & .MuiSvgIcon-root {
    color: #999;
    font-size: 24px;
  }
`;

export const TextAreaContainer = styled.div`
  margin: 30px 0 20px;
`;

export const ApplicationName = styled.div`
  padding: 27px 15px 5px;
  text-transform: uppercase;
  max-width: 600px;
  max-width: 600px;
  margin: -7px auto 0;
  background-color: ${({ component }) =>
    component ? '#f2f2f2' : 'transparent'};
  word-break: break-word;

  @media (min-width: 600px) {
    padding: 20px 24px 5px;
  }
`;

export const TemperatureHeader = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

export const TemperatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
