import { createAction } from '@reduxjs/toolkit';
import { fetchClientDataById } from './clientDataActions';

export const postPhoneCall = (phoneCallId, callData, clientId) => ({
  type: 'phone-call/post',
  payload: callData,
  meta: {
    phoneCallId,
    clientId,
    offline: {
      effect: {
        url: `/qac/phone-call/${phoneCallId}`,
        method: 'POST',
        data: callData,
      },
      commit: {
        type: 'phone-call/post/commit',
        meta: {
          phoneCallId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'phone-call/post/rollback', meta: { phoneCallId } },
    },
  },
});

export const putPhoneCall = (phoneCallId, callData, clientId) => ({
  type: 'phone-call/put',
  payload: callData,
  meta: {
    offline: {
      effect: {
        url: `/qac/phone-call/${phoneCallId}`,
        method: 'PUT',
        data: callData,
      },
      commit: {
        type: 'phone-call/put/commit',
        meta: {
          phoneCallId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'phone-call/put/rollback', meta: { phoneCallId } },
    },
  },
});

export const deletePhoneCallById = createAction(
  'phone-call/delete',
  function prepare(phoneCallId, clientId) {
    return {
      payload: { phoneCallId, clientId },
      meta: {
        offline: {
          effect: {
            url: `/qac/phone-call/${phoneCallId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'phone-call/delete/commit',
            meta: {
              phoneCallId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'phone-call/delete/rollback',
            meta: { phoneCallId },
          },
        },
      },
    };
  }
);
