import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import { Button } from 'frontend-components';

import AppBar from '../../../components/AppBar/AppBar.connected';
import ConfirmationImage from '../../../components/Icons/ConfirmationImage';
import { updateExpandedPanel } from '../../../store/reducers/clientUI';
import { LIST_TYPES } from '../../../constants/lists';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 64px;
  align-items: center;

  & .MuiButtonBase-root {
    margin-top: 24px;
    padding: 14px 42px;
  }
`;

const StyledH2 = styled.h2`
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin: 24px 0 0;
  font-weight: normal;
`;

const StyledP = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 12px 0;
`;

const ConfirmationView = ({ title, infoText }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  return (
    <Container maxWidth="sm" data-testid="confirmation-page">
      <AppBar title={t(title)} />
      <ContentContainer>
        <ConfirmationImage />
        <StyledH2>{t('thank_you')}</StyledH2>
        <StyledP>{t(infoText)}</StyledP>
        <Button
          onClick={() => {
            dispatch(
              updateExpandedPanel({
                clientId,
                expandedPanel: LIST_TYPES.ORDERS,
              })
            );
            history.push(`/customers/${clientId}`);
          }}
          theme="primary"
          data-testid="confirmation-page-button"
        >
          {t('great')}
        </Button>
      </ContentContainer>
    </Container>
  );
};

ConfirmationView.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
};

export default ConfirmationView;
