import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { isValid, format, isToday } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import isObject from 'lodash/isObject';
import { AsideButton, usePermissions } from 'frontend-components';
import {
  getTime,
  DATE_FORMAT,
  formatDate,
} from 'frontend-components/lib/helpers';

import {
  addNextActionToOpenActions,
  updateNextActionInOpenActions,
  deleteNextActionFromOpenActions,
} from '../../store/actions/nextActionActions';
import { ContactAutocomplete } from '../../components/Autocomplete/Autocomplete';
import MenuItem from '../../components/Select/MenuItem';
import TextArea from '../../components/TextArea/TextArea';
import TimePicker from '../../components/TimePicker/TimePicker';
import CalendarPicker from '../../components/CalendarPicker/CalendarPicker';
import Select from '../../components/Select/Select';
import AppBar from '../../components/AppBar/AppBar.connected';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import { selectAllTA } from '../../store/reducers/userSlice';
import {
  NEXT_ACTION_TYPE,
  NEXT_ACTION_DURATION,
  ACTION_TYPE_WITH_DURATION,
  NEXT_ACTION_DURATION_DEFAULT_BY_TYPE,
  ACTION_TYPE_WITH_START_TIME,
  APPOINTMENT,
  OTHER,
  DROP_IN,
  PHONE_CALL,
} from '../../constants/nextAction';
import { pickerMinDate, pickerMaxDate } from '../../constants/fixedDates';
import {
  clientDataShape,
  noteDataShape,
} from '../../helpers/clientDataPropTypes';

const StyledBox = styled(Box)`
  padding-top: 36px;

  &:nth-of-type(1) {
    padding-top: 24px;
  }
`;

const NextActionContainer = styled(Container)`
  padding-bottom: 50px;
`;

function getNextActionValues(nextAction) {
  const doAtDate = nextAction.doAt;
  const dateValue = doAtDate;
  const startTimeValue = new Date(doAtDate).getTime();

  return {
    action: nextAction.action,
    type: nextAction.type,
    delegatedId: nextAction.delegatedId,
    date: dateValue,
    startTime: startTimeValue,
    duration: nextAction.duration,
  };
}

function getNowTime() {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

function getNextActionDefaultValues(defaultActionType, defaultDelegateId) {
  const now = getNowTime();

  return {
    type: defaultActionType,
    date: formatDate(now),
    startTime: now.getTime(),
    delegatedId: defaultDelegateId,
    action: '',
    duration: NEXT_ACTION_DURATION_DEFAULT_BY_TYPE[defaultActionType],
  };
}

function createNextAction(values, isEdited, currentAction, noteId, reportType) {
  const { action, date, type, startTime, delegatedId, duration } = values;
  const actionDone = isEdited ? currentAction.actionDone : false;
  const time = getTime(startTime) || '00:00:00';
  const dateOnly =
    date === 'invalid date'
      ? format(new Date(), DATE_FORMAT)
      : format(new Date(formatDate(date)), DATE_FORMAT);
  const doAt = `${dateOnly} ${time}`;

  const nextAction = {
    id: noteId || uuidv4(),
    action,
    type,
    delegatedId,
    doAt,
    duration,
    actionDone,
    reportType,
  };
  return nextAction;
}

function updateNextActionInStore(
  noteId,
  clientId,
  values,
  isEdited,
  currentAction,
  dispatch,
  newValues,
  reportType,
  addNextActionToNote
) {
  const updatedValues = { ...values, ...newValues };

  if (!updatedValues.date) {
    return;
  }

  dispatch(
    addNextActionToNote(
      noteId,
      clientId,
      createNextAction(
        updatedValues,
        isEdited,
        currentAction,
        noteId,
        reportType
      )
    )
  );
}

let initialValues;
let currentAction;

const NextActionView = ({
  clientData,
  reportType,
  data,
  addNextActionToNote,
  deleteNextActionFromNote,
  backPath,
  calendarPath,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { clientId, noteId, actionId } = useParams();

  const { TAOptions, currentTA } = useSelector((state) => ({
    TAOptions: selectAllTA(state).map((ta) => ({
      label: ta.name,
      value: ta.id,
    })),
    currentTA: {
      label: state.user.data.name,
      value: state.user.data.id,
    },
  }));
  const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false);
  const [noTime, setNoTime] = useState(false);
  const [actionPathName, setActionPathName] = useState('');
  const [changesWereMade, setChangesWereMade] = useState(
    !!location.state?.hasChanged
  );

  const currentVisitIdx = data.findIndex((visit) => visit.id === noteId);
  currentAction = data[currentVisitIdx]?.nextAction;

  const isEdited = !!currentAction;

  if (isEdited) {
    initialValues = { ...initialValues, ...getNextActionValues(currentAction) };
  } else {
    initialValues = getNextActionDefaultValues(
      NEXT_ACTION_TYPE[0].value,
      currentTA.value
    );
  }
  const { disabled } = usePermissions(
    clientData.access,
    data[currentVisitIdx] && data[currentVisitIdx].owned
  );

  const {
    values,
    values: { action, date, type, startTime, duration },
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    isValid: isValidFormik,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      date: Yup.date()
        .typeError(t('validation_date_format'))
        .min(pickerMinDate, t('validation_date_range'))
        .max(pickerMaxDate, t('validation_date_range')),
      startTime: Yup.number().typeError(t('validation_time_format')),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const nextAction = createNextAction(
        values,
        isEdited,
        currentAction,
        noteId,
        reportType
      );
      if (!nextAction.doAt) {
        return;
      }

      if (!changesWereMade) {
        history.push(backPath);
        return;
      }

      dispatch(addNextActionToNote(noteId, clientId, nextAction));
      if (isEdited) {
        dispatch(updateNextActionInOpenActions(clientId, nextAction));
      } else if (!isEdited && isToday(new Date(date))) {
        dispatch(addNextActionToOpenActions(clientId, nextAction));
      }
      history.push(backPath);
    },
  });
  const hasDuration = (type) => ACTION_TYPE_WITH_DURATION.includes(type);
  const hasStartTime = (type) => ACTION_TYPE_WITH_START_TIME.includes(type);

  const initialDelegatedTA = !isEdited
    ? currentTA
    : TAOptions.find((ta) => ta.value === initialValues.delegatedId);

  const [delegatedTa, setDelegatedTA] = useState(initialDelegatedTA);

  const handleDeleteNextAction = () => {
    dispatch(deleteNextActionFromNote(noteId, clientId));
    dispatch(deleteNextActionFromOpenActions(clientId, actionId));
    history.push(backPath);
  };

  const handleUpdateNextAction = (updatedValues) => {
    updateNextActionInStore(
      noteId,
      clientId,
      values,
      isEdited,
      currentAction,
      dispatch,
      updatedValues,
      reportType,
      addNextActionToNote
    );
    setChangesWereMade(true);
  };

  const onActionTypeChange = (type) => {
    let duration;
    const changedValues = { type };
    const newStartTime = noTime ? getNowTime().getTime() : startTime;

    // eslint-disable-next-line default-case
    switch (type) {
      case APPOINTMENT:
        duration = 60;
        setFieldValue('startTime', newStartTime);
        setFieldValue('duration', duration);
        changedValues.startTime = newStartTime;
        changedValues.duration = duration;
        setNoTime(false);
        break;
      case PHONE_CALL:
        duration = null;
        setFieldValue('startTime', newStartTime);
        setFieldValue('duration', duration);
        changedValues.startTime = newStartTime;
        changedValues.duration = duration;
        setNoTime(false);
        break;
      case DROP_IN:
        duration = 30;
        setFieldValue('startTime', newStartTime);
        setFieldValue('duration', duration);
        changedValues.startTime = newStartTime;
        changedValues.duration = duration;
        setNoTime(false);
        break;
      case OTHER:
        duration = 30;
        setFieldValue('duration', null);
        setFieldValue('startTime', null);
        changedValues.duration = null;
        changedValues.startTime = null;
        setNoTime(true);
        break;
    }

    setFieldValue('type', type);
    handleUpdateNextAction(changedValues);
  };

  const onDateClick = () => {
    handleUpdateNextAction();
    history.push(calendarPath, {
      from: location.pathname,
    });
  };

  // handle browser back button when invalid date -> move back to action view with error message
  useEffect(() => {
    setActionPathName(location.pathname);

    if (location.state && location.state.invalidDate) {
      setFieldValue('date', '');
    }
  }, [location, setFieldValue]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP' && !isValidFormik) {
        history.replace(actionPathName, { invalidDate: true });
      }
    };
  }, [history, actionPathName, isValidFormik]);

  return (
    <NextActionContainer maxWidth="sm" data-testid="next-action">
      <AppBar
        title={t('next_action_title')}
        aside={
          isEdited ? (
            <AsideButton
              aria-label="delete-next-action"
              onClick={() => {
                setShowDeleteNoteDialog(true);
              }}
              disabled={disabled}
            >
              <DeleteIcon />
            </AsideButton>
          ) : null
        }
        backButtonAction={handleSubmit}
        displayBackButton
        elevated
      />
      <form>
        <StyledBox>
          <Select
            label={t('action_follow_up')}
            data-testid="follow-up"
            fullWidth
            value={type}
            onChange={onActionTypeChange}
            disabled={disabled}
          >
            {NEXT_ACTION_TYPE.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
        </StyledBox>
        <StyledBox>
          <CalendarPicker
            selected={date}
            value={date}
            onChange={(date) => {
              if (isObject(date) && isValid(date)) {
                setFieldValue('date', format(date, 'yyyy-MM-dd HH:mm:ss'));
                handleUpdateNextAction({ date });
              } else {
                setFieldValue('date', 'invalid date');
              }
            }}
            onClick={onDateClick}
            id="date"
            fullWidth
            label={t('action_date')}
            errors={errors.date}
            errorMessage={errors.date || ''}
            disabled={disabled}
            margin="none"
          />
        </StyledBox>
        {hasStartTime(type) && (
          <StyledBox>
            <TimePicker
              data-testid="time"
              label={t('action_time_start')}
              fullWidth
              value={startTime}
              onChange={(time) => {
                const startTime = time ? time.getTime() : null;
                setFieldValue('startTime', startTime);
                if (time && isValid(new Date(time))) {
                  handleUpdateNextAction({ startTime });
                }
              }}
              errors={errors.startTime}
              helperText={errors.startTime || ''}
              disabled={disabled}
              margin="none"
            />
          </StyledBox>
        )}
        {hasDuration(type) && (
          <StyledBox>
            <Select
              label={t('next_action_duration')}
              data-testid="duration"
              fullWidth
              value={duration}
              onChange={(duration) => {
                setFieldValue('duration', duration);
                handleUpdateNextAction({ duration });
              }}
              disabled={disabled}
            >
              {NEXT_ACTION_DURATION.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {t(label)}
                </MenuItem>
              ))}
            </Select>
          </StyledBox>
        )}
        <StyledBox>
          <ContactAutocomplete
            data-testid="delegated-to"
            getOptionLabel={(option) => option.label}
            autoSelect
            options={TAOptions}
            value={delegatedTa}
            getOptionSelected={(option, selected) =>
              option.value === selected.value
            }
            onChange={(event, option) => {
              setFieldValue('delegatedId', option && option.value);
              setDelegatedTA(option);
              handleUpdateNextAction({ delegatedId: option && option.value });
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('action_delegate_to')} />
            )}
            disabled={disabled}
          />
        </StyledBox>
        <StyledBox>
          <TextArea
            id="action"
            onChange={handleChange}
            onBlur={() => {
              handleUpdateNextAction({ action });
            }}
            value={action}
            label={t('action_note')}
            placeholder={t('note_placeholder')}
            fullWidth
            disabled={disabled}
          />
        </StyledBox>
      </form>
      <DeleteDialog
        title={t('action_delete_title')}
        open={showDeleteNoteDialog}
        setOpen={setShowDeleteNoteDialog}
        handleDelete={handleDeleteNextAction}
      />
    </NextActionContainer>
  );
};

NextActionView.propTypes = {
  clientData: clientDataShape,
  reportType: PropTypes.string,
  data: PropTypes.arrayOf(noteDataShape),
  addNextActionToNote: PropTypes.func,
  deleteNextActionFromNote: PropTypes.func,
  backPath: PropTypes.string,
  calendarPath: PropTypes.string,
};

export default NextActionView;
