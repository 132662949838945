import apiService from './api.service';

export const getProductsByClient = ({ clientId }) =>
  apiService({
    url: `/qac/product-list/${clientId}`,
    transformResponse: [
      ({ data }) => {
        return {
          data: data.map((category) => {
            return {
              ...category,
              products: category.products || [],
            };
          }),
        };
      },
    ],
  });
