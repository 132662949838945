import { format } from 'date-fns';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogHeader from '../Dialog/DialogHeader';
import Dialog from '../Dialog/Dialog';

const ACTION_LABELS = {
  appointment: 'follow_up_appointment',
  visitwithout: 'action_follow_up_drop_in',
};

const StyledDialogContent = styled(DialogContent)`
  min-width: 250px;
  max-width: 90vw;
`;

const EventDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  color: #000;

  @media (orientation: landscape) and (max-width: 768px) {
    margin-top: 12px;
  }
`;

const EventData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-align: right;
`;

const EventDescription = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d8d8d8;
  padding-top: 16px;
  margin-top: 16px;

  @media (orientation: landscape) and (max-width: 768px) {
    padding-top: 12px;
    margin-top: 12px;
  }
`;

const EventLabel = styled.div`
  font-weight: bold;
  padding: 12px 5px 12px 0;

  @media (orientation: landscape) and (max-width: 768px) {
    padding: 6px 5px 6px 0;
  }
`;

const EventDialog = ({ open, onClose, event }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogHeader
        title={t(ACTION_LABELS[event.type])}
        handleClose={onClose}
      />
      <StyledDialogContent>
        <EventDataWrapper>
          <EventData>
            <EventLabel>{t('action_client')}:</EventLabel>
            <div>{event.title}</div>
          </EventData>
          <EventData>
            <EventLabel>{t('action_date')}:</EventLabel>
            <div>{format(new Date(event.start), 'dd/MM/yyyy')}</div>
          </EventData>
          <EventData>
            <EventLabel>{t('action_time')}:</EventLabel>
            <div>
              {format(new Date(event.start), 'HH:mm')} -
              {format(new Date(event.end), 'HH:mm')}
            </div>
          </EventData>
          {!!event.description && (
            <EventDescription>
              <EventLabel>{t('action_description')}:</EventLabel>
              <div>{event.description}</div>
            </EventDescription>
          )}
        </EventDataWrapper>
      </StyledDialogContent>
    </Dialog>
  );
};

EventDialog.propTypes = {
  open: PropTypes.bool,
  event: PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.string,
    type: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    description: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default EventDialog;
