import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;

  .MuiIconButton-label {
    color: #999;
  }
`;

const ContactPersonSection = ({
  contactPersonId,
  openContactsList,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const [inputValue, setInputValue] = useState(null);

  const { clientContacts } = useSelector((state) => ({
    clientContacts: state.clientData.data[clientId].contactPersons.data,
  }));

  const contactPerson = clientContacts.find(
    (contact) => contact.id === contactPersonId
  );

  const isContactPersonDeleted = contactPerson && contactPerson.isDeleted === 1;

  const prefix = isContactPersonDeleted
    ? `[${t('contact_deleted').toUpperCase()}] `
    : '';

  useEffect(() => {
    if (typeof contactPerson !== 'undefined') {
      const { firstName, lastName, emailsList } = contactPerson;

      const contactPersonFullName = `${firstName} ${lastName}`;

      const value = emailsList.length
        ? `${contactPersonFullName}, ${emailsList[0]}`
        : `${contactPersonFullName}, ${t('no_mail')}`;

      setInputValue(value);
    } else {
      setInputValue('');
    }
  }, [contactPerson, t]);

  return (
    <Container data-testid="contact_person_section" onClick={openContactsList}>
      <Input
        id="contactPerson"
        label={t('contact_person_title')}
        customColor={isContactPersonDeleted ? '#666666' : 'inherit'}
        leftText={prefix}
        value={inputValue}
        rightIcon={<PeopleIcon />}
        fullWidth
        errorMessage={errorMessage}
        multiline
      />
    </Container>
  );
};

ContactPersonSection.propTypes = {
  contactPersonId: PropTypes.string,
  openContactsList: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default ContactPersonSection;
