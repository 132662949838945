import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ACTION_ADD_PHOTO_COMMIT } from '../actions/photoActions';

function updatePackaging(state, productCode, packagingData) {
  if (!state.packagingData) {
    state.packagingData = {};
  }
  const tempPackagingData = packagingData.map((item) => {
    return {
      createdAt: packagingData[0]?.createdAt || new Date(),
      ...item,
    };
  });
  state.packagingData[productCode] = tempPackagingData;
  state.edited = true;
}

const draftsSlice = createSlice({
  name: 'drafts',
  initialState: {
    postedNotes: {},
    newEmptyVisit: '',
    newOrder: {},
    newQuotation: {},
    currentApplication: {},
    currentPhoto: {},
  },
  reducers: {
    setNewEmptyVisit: (state, action) => {
      state.newEmptyVisit = action.payload;
    },
    removeNewEmptyVisit: (state) => {
      state.newEmptyVisit = '';
    },
    createNewOrder: (state) => {
      state.newOrder = { edited: false, hideTotals: false };
    },
    setNewOrder: (state, action) => {
      state.newOrder = { ...state.newOrder, ...action.payload, edited: true };
    },
    setNewOrderContactPerson: (state, action) => {
      state.newOrder.contactPersonId = action.payload;
      state.newOrder.edited = true;
    },
    setNewOrderPackagingDetails: (state, action) => {
      const { productCode, packagingData } = action.payload;
      updatePackaging(state.newOrder, productCode, packagingData);
    },
    removeNewOrder: (state) => {
      state.newOrder = {};
    },
    updateNewOrderNetPrice: (state, action) => {
      state.newOrder.netPrice = action.payload;
    },
    setOrderTermsAccepted: (state, action) => {
      state.newOrder.acceptedTerms = action.payload;
    },
    setOrderConditionsAccepted: (state, action) => {
      state.newOrder.acceptedConditions = action.payload;
    },
    setOrderSignature: (state, action) => {
      state.newOrder.signBinary = action.payload;
    },
    // used to show normal size in Signature View
    setOrderSignatureNotTrimmed: (state, action) => {
      state.newOrder.signBinaryNotTrimmed = action.payload;
    },
    setNewOrderContactsCC: (state, action) => {
      if (!state.newOrder.contactsCC) {
        state.newOrder.contactsCC = [action.payload];
      } else {
        state.newOrder.contactsCC.push(action.payload);
      }
    },
    deleteNewOrderContactFromCC: (state, action) => {
      state.newOrder.contactsCC = state.newOrder.contactsCC.filter(
        (contact) => contact !== action.payload
      );
    },
    updateOrderDeliveryCost: (state, action) => {
      state.newOrder.deliveryCost = Number(action.payload).toFixed(2);
    },
    updateOrderHideTotals: (state, action) => {
      state.newOrder.hideTotals = action.payload;
    },
    setOrderSendConfirmation: (state, action) => {
      state.newOrder.sendConfirmation = action.payload;
    },
    setOrderDiscount: (state, action) => {
      state.newOrder.discount = action.payload;
    },
    createNewQuotation: (state) => {
      state.newQuotation = { edited: false, hideTotals: true };
    },
    setNewQuotation: (state, action) => {
      state.newQuotation = {
        ...state.newQuotation,
        ...action.payload,
        edited: true,
      };
    },
    setNewQuotationContactPerson: (state, action) => {
      state.newQuotation.contactPersonId = action.payload;
      state.newQuotation.edited = true;
    },
    setNewQuotationPackagingDetails: (state, action) => {
      const { productCode, packagingData } = action.payload;
      updatePackaging(state.newQuotation, productCode, packagingData);
    },
    removeNewQuotation: (state) => {
      state.newQuotation = {};
    },
    updateNewQuotationNetPrice: (state, action) => {
      state.newQuotation.netPrice = action.payload;
    },
    setNewQuotationContactsCC: (state, action) => {
      if (!state.newQuotation.contactsCC) {
        state.newQuotation.contactsCC = [action.payload];
      } else {
        state.newQuotation.contactsCC.push(action.payload);
      }
    },
    deleteNewQuotationContactFromCC: (state, action) => {
      state.newQuotation.contactsCC = state.newQuotation.contactsCC.filter(
        (contact) => contact !== action.payload
      );
    },
    updateQuotationDeliveryCost: (state, action) => {
      state.newQuotation.deliveryCost = Number(action.payload).toFixed(2);
    },
    updateQuotationHideTotals: (state, action) => {
      state.newQuotation.hideTotals = action.payload;
    },
    setQuotationSendConfirmation: (state, action) => {
      state.newQuotation.sendConfirmation = action.payload;
    },
    setQuotationDiscount: (state, action) => {
      state.newQuotation.discount = action.payload;
    },
    removeCurrentApplication: (state) => {
      state.currentApplication = {};
    },
    removeCurrentPhoto: (state) => {
      state.currentPhoto = {};
    },
  },
  extraReducers: {
    'visit/post': (state, action) => {
      const { clientId, visitId } = action.meta;
      if (!state.postedNotes[clientId]) {
        state.postedNotes[clientId] = {};
      }
      state.postedNotes[clientId][visitId] = true;
    },
    'visit/post/commit': (state, action) => {
      const { clientId, visitId } = action.meta;
      delete state.postedNotes[clientId][visitId];
    },
    'phone-call/post': (state, action) => {
      const { clientId, phoneCallId } = action.meta;
      if (!state.postedNotes[clientId]) {
        state.postedNotes[clientId] = {};
      }
      state.postedNotes[clientId][phoneCallId] = true;
    },
    'phone-call/post/commit': (state, action) => {
      const { clientId, phoneCallId } = action.meta;
      delete state.postedNotes[clientId][phoneCallId];
    },
    'applicationReport/post/commit': (state, action) => {
      const {
        meta: { applicationId },
        payload: { id },
      } = action;

      state.currentApplication = {
        uuId: applicationId,
        targetId: id,
      };
    },
    [ACTION_ADD_PHOTO_COMMIT]: (state, action) => {
      const photoId = action.meta.photo.id;
      const { id } = action.payload;

      state.currentPhoto = {
        uuId: photoId,
        targetId: id,
      };
    },
  },
});

export const {
  setNewEmptyVisit,
  removeNewEmptyVisit,
  createNewOrder,
  setNewOrder,
  setNewOrderContactPerson,
  setNewOrderPackagingDetails,
  removeNewOrder,
  updateNewOrderNetPrice,
  setOrderTermsAccepted,
  setOrderConditionsAccepted,
  setOrderSignature,
  setNewOrderContactsCC,
  deleteNewOrderContactFromCC,
  updateOrderDeliveryCost,
  updateOrderHideTotals,
  setOrderSendConfirmation,
  setOrderDiscount,
  createNewQuotation,
  setNewQuotation,
  setNewQuotationContactPerson,
  setNewQuotationPackagingDetails,
  removeNewQuotation,
  updateNewQuotationNetPrice,
  acceptedTerms,
  acceptedConditions,
  setNewQuotationContactsCC,
  deleteNewQuotationContactFromCC,
  updateQuotationDeliveryCost,
  updateQuotationHideTotals,
  setQuotationSendConfirmation,
  setQuotationDiscount,
  setOrderSignatureNotTrimmed,
  removeCurrentApplication,
} = draftsSlice.actions;

// create async functions to use updated redux state
export const asyncSetQuotationSendConfirmation = createAsyncThunk(
  'drafts/asyncSetQuotationSendConfirmation',
  (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    dispatch(setQuotationSendConfirmation(payload));

    const { drafts } = getState();
    return drafts.newQuotation;
  }
);

export const asyncSetOrderSendConfirmation = createAsyncThunk(
  'drafts/asyncSetOrderSendConfirmation',
  (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    dispatch(setOrderSendConfirmation(payload));

    const { drafts } = getState();
    return drafts.newOrder;
  }
);

export default draftsSlice.reducer;
