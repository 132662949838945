import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import { Divider, Button } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import ListItem from '../../components/List/ListItem';
import ListItemText from '../../components/List/ListItemText';
import List from '../../components/List/List';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import NothingFound from '../../components/NothingFound/NothingFound';
import {
  Input,
  SearchContainer,
  SearchIcon,
} from '../SearchCustomerView/SearchCustomer.styles';
import { MIN_SEARCH_LENGTH } from '../../constants/search';
import { searchPhaseInObject } from '../../helpers/search';

const StyledAddContactPersonView = styled.div`
  & .MuiButton-root {
    margin: 16px 0;
  }
  & .MuiListItemIcon-root {
    margin: 0 16px;
    min-width: 20px;
  }
  & .MuiListItem-root {
    padding-top: 10px;
  }
  title {
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin: 8px 0;
  }
`;

const StyledSecondaryLine = styled.span`
  display: block;
`;

const AddContactPersonView = ({
  clientData,
  onContactSelected,
  onAddContactClicked,
  isCreateAllowed = true,
  header,
  goBackAction,
  showContactsOnlyWithEmails,
  displayEmail,
  contactToExclude,
}) => {
  const { t } = useTranslation();
  const searchInputRef = useRef(null);
  const [contacts, setContacts] = useState();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: contactPersons } = clientData.contactPersons;

  useEffect(() => {
    const searchContactPerson = (contact) => {
      if (searchTerm.trim().length < MIN_SEARCH_LENGTH) return [];

      return searchPhaseInObject(searchTerm, {
        name: `${contact.firstName} ${contact.lastName}`,
        firstName: contact.firstName,
        lastName: contact.lastName,
        jobTitle: contact.jobTitle,
      }).length;
    };

    if (showContactsOnlyWithEmails) {
      setContacts(
        contactPersons.filter(
          (contact) =>
            contact.isDeleted === 0 &&
            contact.emailsList.length > 0 &&
            contactToExclude !== contact.id &&
            searchContactPerson(contact)
        )
      );
    } else {
      setContacts(
        contactPersons.filter(
          (contact) => contact.isDeleted === 0 && searchContactPerson(contact)
        )
      );
    }
  }, [
    contactPersons,
    contactToExclude,
    showContactsOnlyWithEmails,
    searchTerm,
  ]);

  const handleSelect = (contactPersonId) => {
    if (onContactSelected) {
      onContactSelected(contactPersonId);
    }
  };

  const contactList = contacts
    ? contacts.map(({ firstName, lastName, id, emailsList, jobTitle }) => (
        <div key={id}>
          <ListItem
            button
            onClick={() => {
              handleSelect(id);
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${firstName} ${lastName}`}
              secondary={
                <>
                  <StyledSecondaryLine>{jobTitle}</StyledSecondaryLine>
                  <StyledSecondaryLine>
                    {displayEmail &&
                      (emailsList.length ? emailsList[0] : t('no_mail'))}
                  </StyledSecondaryLine>
                </>
              }
            />
          </ListItem>
          <Divider />
        </div>
      ))
    : null;

  return (
    <Container maxWidth="sm">
      {header ? (
        header()
      ) : (
        <AppBar
          title={searchVisible ? null : t('note_add_contact')}
          titleClickAction={() => setSearchVisible(true)}
          displayBackButton
          backButtonAction={goBackAction || null}
          content={
            searchVisible && (
              <SearchContainer maxWidth="sm">
                <Input
                  autoFocus
                  placeholder={t('client_search_placeholder')}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  inputProps={{ 'aria-label': 'search-contact' }}
                  inputRef={searchInputRef}
                  value={searchTerm}
                />
              </SearchContainer>
            )
          }
          aside={
            searchTerm ? (
              <IconButton
                onClick={() => {
                  setSearchVisible(false);
                  setSearchTerm('');
                }}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setSearchVisible(true);
                  searchInputRef.current && searchInputRef.current.focus();
                }}
              >
                <SearchIcon />
              </IconButton>
            )
          }
        />
      )}
      <StyledAddContactPersonView>
        {isCreateAllowed && (
          <Button
            data-testid="create_new_contact_person_btn"
            startIcon={<AddIcon />}
            theme="secondary"
            fullWidth
            onClick={() => {
              onAddContactClicked();
            }}
          >
            {t('contact_create_button')}
          </Button>
        )}
        <title>{t('contact_choose')}</title>
        <Divider />
        <List data-testid="contacts_list">{contactList}</List>
        {searchTerm && contactList.length === 0 && <NothingFound />}
      </StyledAddContactPersonView>
    </Container>
  );
};

AddContactPersonView.propTypes = {
  clientData: clientDataShape,
  onContactSelected: PropTypes.func,
  onAddContactClicked: PropTypes.func,
  isCreateAllowed: PropTypes.bool,
  header: PropTypes.node,
  goBackAction: PropTypes.func,
  showContactsOnlyWithEmails: PropTypes.bool,
  displayEmail: PropTypes.bool,
  contactToExclude: PropTypes.string,
};

export default AddContactPersonView;
