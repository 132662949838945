import { createSlice } from '@reduxjs/toolkit';
import {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
} from '../actions/notificationActions';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    showSelectCustomerViewTip: true,
    showCalendarMonthTip: true,
    showCalendarWeekTip: true,
    showFilesDownloadTip: true,
    dataLoading: false,
    notifications: [],
    actionType: [
      { value: 'appointment', label: 'follow_up_appointment' },
      { value: 'phonecall', label: 'action_follow_up_call' },
      { value: 'visitwithout', label: 'action_follow_up_drop_in' },
      { value: 'other', label: 'action_follow_up_other' },
    ],
  },
  reducers: {
    hideSelectCustomersViewTip: (state) => {
      state.showSelectCustomerViewTip = false;
    },
    hideCalendarMonthTip: (state) => {
      state.showCalendarMonthTip = false;
    },
    hideCalendarWeekTip: (state) => {
      state.showCalendarWeekTip = false;
    },
    hideFilesDownloadTip: (state) => {
      state.showFilesDownloadTip = false;
    },
    dataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
  },
  extraReducers: {
    [enqueueSnackbar]: (state, action) => {
      state.notifications.push({ key: action.payload.key, ...action.payload });
    },
    [closeSnackbar]: (state, action) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    [removeSnackbar]: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload.key
      );
    },
  },
});

export const {
  hideSelectCustomersViewTip,
  hideCalendarMonthTip,
  hideCalendarWeekTip,
  hideFilesDownloadTip,
  dataLoading,
} = appSlice.actions;
export default appSlice.reducer;
