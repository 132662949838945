import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import MenuItem from '../../../components/Select/MenuItem';
import TextArea from '../../../components/TextArea/TextArea';
import { TextAreaContainer } from './TechnicalTab.styles';
import {
  FILTER_OPTIONS,
  GEARBOX_TYPES,
  LEAKAGES_OPTIONS,
  LUBRICATION_TYPES,
  MOUNTING_POSITIONS,
  VENTILATION_SHAFT_OPTIONS,
} from '../../../constants/applicationScreen';
import { changeFieldValueToNumber } from '../../../helpers/changeFieldValueToNumber';

const Gearbox = ({ values, setFieldValue, errors, disabled }) => {
  const { t } = useTranslation();

  const {
    type,
    mountingPosition,
    diameterIncomingShaft,
    rpmIncomingShaft,
    lubricationType,
    oilVolume,
    greaseFitting,
    oilRecommended,
    filter,
    filterSize,
    ventilationShaft,
    leakages,
    leakagesLoss,
    otherIssues,
    noiseLevel,
  } = values;

  return (
    <>
      <Select
        id="gearboxType"
        data-testid="gearboxType"
        label={t('app_gbox_type')}
        fullWidth
        value={type}
        onChange={(type) => {
          setFieldValue('gearbox.type', type);
        }}
        disabled={disabled}
      >
        {GEARBOX_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <Select
        id="mountingPosition"
        data-testid="mountingPosition"
        label={t('app_gbox_position')}
        fullWidth
        value={mountingPosition}
        onChange={(mountingPosition) => {
          setFieldValue('gearbox.mountingPosition', mountingPosition);
        }}
        disabled={disabled}
      >
        {MOUNTING_POSITIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <Input
        id="diameterIncomingShaft"
        label={t('app_gbox_diameter_inc')}
        onchange={(e) => {
          setFieldValue(
            'gearbox.diameterIncomingShaft',
            changeFieldValueToNumber(e)
          );
        }}
        value={diameterIncomingShaft}
        fullWidth
        rightText={t('app_size_mm')}
        type="number"
        errorMessage={errors && errors.diameterIncomingShaft}
        disabled={disabled}
      />
      <Input
        id="rpmIncomingShaft"
        label={t('app_gbox_rpm_inc')}
        onchange={(e) => {
          setFieldValue(
            'gearbox.rpmIncomingShaft',
            changeFieldValueToNumber(e)
          );
        }}
        value={rpmIncomingShaft}
        fullWidth
        rightText={t('app_rpm')}
        type="number"
        errorMessage={errors && errors.rpmIncomingShaft}
        disabled={disabled}
      />
      <Select
        id="lubricationType"
        data-testid="lubricationType"
        label={t('app_gbox_lub_type')}
        fullWidth
        value={lubricationType}
        onChange={(lubricationType) => {
          setFieldValue('gearbox.lubricationType', lubricationType);
        }}
        disabled={disabled}
      >
        {LUBRICATION_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <Input
        id="oilVolume"
        label={t('app_gbox_oil_volume')}
        onchange={(e) => {
          setFieldValue('gearbox.oilVolume', changeFieldValueToNumber(e));
        }}
        value={oilVolume}
        fullWidth
        rightText={t('app_ml')}
        type="number"
        errorMessage={errors && errors.oilVolume}
        disabled={disabled}
      />
      <Input
        id="greaseFitting"
        label={t('app_gbox_grease_fitting')}
        onchange={(e) => {
          setFieldValue('gearbox.greaseFitting', e);
        }}
        value={greaseFitting}
        fullWidth
        maxLength={100}
        disabled={disabled}
      />
      <Input
        id="oilRecommended"
        label={t('app_gbox_oil_recommended')}
        onchange={(e) => {
          setFieldValue('gearbox.oilRecommended', e);
        }}
        value={oilRecommended}
        fullWidth
        maxLength={100}
        disabled={disabled}
      />
      <Select
        id="filter"
        data-testid="filter"
        label={t('app_gbox_filter')}
        fullWidth
        value={filter}
        onChange={(filter) => {
          setFieldValue('gearbox.filter', filter);
        }}
        disabled={disabled}
      >
        {FILTER_OPTIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      {filter === FILTER_OPTIONS[0].value && (
        <Input
          id="filterSize"
          label={t('app_gbox_filter_size')}
          onchange={(e) => {
            setFieldValue('gearbox.filterSize', changeFieldValueToNumber(e));
          }}
          value={filterSize}
          rightText={t('app_gbox_filter_size_unit')}
          fullWidth
          type="number"
          errorMessage={errors && errors.filterSize}
          disabled={disabled}
        />
      )}
      <Select
        id="ventilationShaft"
        data-testid="ventilationShaft"
        label={t('app_gbox_ventilation')}
        fullWidth
        value={ventilationShaft}
        onChange={(ventilationShaft) => {
          setFieldValue('gearbox.ventilationShaft', ventilationShaft);
        }}
        disabled={disabled}
      >
        {VENTILATION_SHAFT_OPTIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      <Select
        id="leakages"
        data-testid="leakages"
        label={t('app_gbox_leakages')}
        fullWidth
        value={leakages}
        onChange={(leakages) => {
          setFieldValue('gearbox.leakages', leakages);
        }}
        disabled={disabled}
      >
        {LEAKAGES_OPTIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      {leakages === LEAKAGES_OPTIONS[0].value && (
        <Input
          id="leakagesLoss"
          label={t('app_gbox_leakages_loss')}
          onchange={(e) => {
            setFieldValue('gearbox.leakagesLoss', changeFieldValueToNumber(e));
          }}
          value={leakagesLoss}
          fullWidth
          rightText={t('app_ml')}
          type="number"
          errorMessage={errors && errors.leakagesLoss}
          disabled={disabled}
        />
      )}
      <Input
        id="noiseLevel"
        label={t('app_gbox_noise_level')}
        onchange={(e) => {
          setFieldValue('gearbox.noiseLevel', changeFieldValueToNumber(e));
        }}
        value={noiseLevel}
        fullWidth
        rightText={t('app_dB')}
        type="number"
        errorMessage={errors && errors.noiseLevel}
        disabled={disabled}
      />
      <TextAreaContainer>
        <TextArea
          id="otherIssues"
          label={t('app_gbox_other')}
          placeholder={t('app_gbox_other')}
          fullWidth
          onChange={(e) => {
            setFieldValue('gearbox.otherIssues', e.target.value);
          }}
          value={otherIssues}
          maxLength={2000}
          disabled={disabled}
        />
      </TextAreaContainer>
    </>
  );
};

Gearbox.propTypes = {
  values: PropTypes.shape({
    type: PropTypes.string,
    mountingPosition: PropTypes.string,
    diameterIncomingShaft: PropTypes.string,
    rpmIncomingShaft: PropTypes.string,
    lubricationType: PropTypes.string,
    oilVolume: PropTypes.string,
    greaseFitting: PropTypes.string,
    oilRecommended: PropTypes.string,
    filter: PropTypes.string,
    filterSize: PropTypes.string,
    ventilationShaft: PropTypes.string,
    leakages: PropTypes.string,
    leakagesLoss: PropTypes.string,
    otherIssues: PropTypes.string,
    noiseLevel: PropTypes.string,
  }),
  setFieldValue: PropTypes.func,
  errors: PropTypes.shape({
    type: PropTypes.string,
    mountingPosition: PropTypes.string,
    diameterIncomingShaft: PropTypes.string,
    rpmIncomingShaft: PropTypes.string,
    lubricationType: PropTypes.string,
    oilVolume: PropTypes.string,
    greaseFitting: PropTypes.string,
    oilRecommended: PropTypes.string,
    filter: PropTypes.string,
    filterSize: PropTypes.string,
    ventilationShaft: PropTypes.string,
    leakages: PropTypes.string,
    leakagesLoss: PropTypes.string,
    otherIssues: PropTypes.string,
    noiseLevel: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default Gearbox;
