import { createAction } from '@reduxjs/toolkit';
import { fetchClientDataById } from './clientDataActions';

export const createNewApplication = createAction(
  'clientData/createNewApplication',
  function prepare(applicationId, clientId, defaultName = '') {
    return {
      payload: { applicationId, clientId, defaultName },
    };
  }
);

export const postApplicationReport = createAction(
  'clientData/applicationReport/post',
  function prepare(applicationId, applicationData, clientId) {
    return {
      payload: {
        applicationData,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/application-report/${applicationId}`,
            method: 'POST',
            data: applicationData,
          },
          commit: {
            type: 'applicationReport/post/commit',
            meta: {
              applicationId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'applicationReport/post/rollback',
            meta: {
              applicationId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
        },
      },
    };
  }
);

export const updateApplication = createAction(
  'clientData/updateApplication',
  function prepare(applicationId, clientId, values) {
    return {
      payload: { applicationId, clientId, values },
    };
  }
);

export const putApplicationReport = createAction(
  'clientData/applicationReport/put',
  function prepare(applicationId, applicationData, clientId) {
    return {
      payload: {
        applicationData,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/application-report/${applicationId}`,
            method: 'PUT',
            data: applicationData,
          },
          commit: {
            type: 'applicationReport/put/commit',
            meta: {
              applicationId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'applicationReport/put/rollback',
            meta: { applicationId },
          },
        },
      },
    };
  }
);

export const deleteApplication = createAction(
  'clientData/applicationReport/delete',
  function prepare(applicationId, clientId) {
    return {
      payload: {
        applicationId,
        clientId,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/application-report/${applicationId}`,
            method: 'DELETE',
          },
          commit: {
            type: 'applicationReport/delete/commit',
            meta: {
              applicationId,
              clientId,
              after: fetchClientDataById,
              afterArgs: [{ clientId }],
            },
          },
          rollback: {
            type: 'applicationReport/delete/rollback',
            meta: { applicationId },
          },
        },
      },
    };
  }
);

export const updateApplicationsSection = createAction(
  'clientData/updateApplicationsSection',
  function prepare(applicationId, clientId, values, section) {
    return {
      payload: { applicationId, clientId, values, section },
    };
  }
);

export const setApplicationInterflonLubricant = createAction(
  'clientData/setApplicationInterflonLubricant',
  function prepare(applicationId, clientId, lubricant) {
    return {
      payload: { applicationId, clientId, lubricant },
    };
  }
);

export const deleteApplicationLocally = createAction(
  'clientData/deleteApplication',
  function prepare(applicationId, clientId) {
    return {
      payload: { applicationId, clientId },
    };
  }
);
