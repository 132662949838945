import { fetchClientDataById } from './clientDataActions';

export const postOrder = (orderId, orderData, clientId) => ({
  type: 'order/post',
  payload: orderData,
  meta: {
    orderId,
    clientId,
    offline: {
      effect: {
        url: `/qac/order/${orderId}`,
        method: 'POST',
        data: orderData,
      },
      commit: {
        type: 'order/post/commit',
        meta: {
          orderId,
          clientId,
          after: fetchClientDataById,
          afterArgs: [{ clientId }],
        },
      },
      rollback: { type: 'order/post/rollback', meta: { orderId } },
    },
  },
});
