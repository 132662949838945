import { default as MuiContainer } from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import { default as MuiButton } from '@material-ui/core/Button';
import { Divider, Button } from 'frontend-components';

import AppBar from '../../../components/AppBar/AppBar.connected';
import FixedFooter from '../../../components/FixedFooter/FixedFooter';
import Checkbox from '../../../components/Checkbox/Checkbox';
import List from '../../../components/List/List';
import {
  setOrderTermsAccepted,
  setOrderConditionsAccepted,
} from '../../../store/reducers/drafts';
import useTermsAndSignatureOptions from '../../../hooks/useTermsAndSignatureOptions';
import OrderConfirmationDialog from '../../../components/Dialog/OrderConfirmationDialog/OrderConfirmationDialog';

const MessageContainer = styled.p`
  margin: 30px 0 18px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 16px 8px;
  justify-content: center;

  .MuiButton-root {
    margin: 8px;
  }
`;

const Container = styled(MuiContainer)`
  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }
`;

const TermsButton = styled(MuiButton)`
  color: #e42313;
  margin-left: -12px;
`;

const CheckboxListItem = styled(ListItem)`
  .MuiFormControlLabel-label {
    line-height: 20px;
    color: #666666;
  }

  .MuiButton-root {
    text-transform: none;
    padding: 0;
    letter-spacing: normal;
    min-width: 0;
  }
`;

const TermsAndConditionsView = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { acceptedTerms, acceptedConditions, contactPersonId, contactsCC } =
    useSelector((state) => state.drafts.newOrder);
  const { contactPersons } = useSelector(
    (state) => state.clientData.data[clientId]
  );

  const {
    termsFooterButtonTitle,
    termsFooterButtonAction,
    termsFooterButtonEnabled,
    termsContent,
    conditionsContent,
    showConfirmationDialog,
    setShowConfirmationDialog,
    finishAndSendAction,
    finishWithoutSendingAction,
  } = useTermsAndSignatureOptions();

  const emptyTerms = !termsContent.trim();
  const emptyConditions = !conditionsContent.trim();

  const contactPersonData = contactPersons.data.find(
    (contact) => contact.id === contactPersonId
  );
  const isSendActionActive =
    contactPersonData.emailsList.length || contactsCC?.length;

  return (
    <Container maxWidth="sm" data-testid="term-and-conditions">
      <AppBar
        title={t('terms_title')}
        displayBackButton
        backButtonAction={() => {
          history.push(`/customers/${clientId}/create/order/summary`);
        }}
      />
      <MessageContainer>{t('terms_message')}</MessageContainer>
      <List component="nav">
        <CheckboxListItem>
          <Checkbox
            checked={acceptedTerms}
            label={t('terms_accept')}
            data-testid="accept-terms"
            onChange={(checked) => {
              dispatch(setOrderTermsAccepted(checked));
            }}
          />
          <TermsButton
            disabled={emptyTerms}
            data-testid="terms-link"
            onClick={() =>
              history.push(`/customers/${clientId}/create/order/terms/terms`)
            }
          >
            {t('terms_terms')}
          </TermsButton>
        </CheckboxListItem>
        <Divider />
        <CheckboxListItem>
          <Checkbox
            label={t('terms_accept')}
            checked={acceptedConditions}
            data-testid="accept-conditions"
            onChange={(checked) => {
              dispatch(setOrderConditionsAccepted(checked));
            }}
          />
          <TermsButton
            disabled={emptyConditions}
            data-testid="conditions-link"
            onClick={() =>
              history.push(
                `/customers/${clientId}/create/order/terms/conditions`
              )
            }
          >
            {t('terms_conditions')}
          </TermsButton>
        </CheckboxListItem>
        <Divider />
      </List>
      <FixedFooter>
        <ButtonsContainer>
          <Button
            aria-label="terms_skip"
            onClick={termsFooterButtonAction}
            theme="secondary"
            disabled={acceptedTerms && acceptedConditions}
          >
            {t('terms_skip')}
          </Button>
          <Button
            aria-label="terms_next"
            onClick={termsFooterButtonAction}
            theme="primary"
            disabled={
              !(
                termsFooterButtonEnabled ||
                (acceptedTerms && acceptedConditions)
              )
            }
          >
            {termsFooterButtonTitle}
          </Button>
        </ButtonsContainer>
      </FixedFooter>
      <OrderConfirmationDialog
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        finishAndSendAction={isSendActionActive && finishAndSendAction}
        finishWithoutSendingAction={finishWithoutSendingAction}
        data-testid="confirm-terms-and-conditions"
      />
    </Container>
  );
};

TermsAndConditionsView.propTypes = {};

export default TermsAndConditionsView;
