import apiService from './api.service';
import { parseAppReportAfterGet } from '../helpers/parseAppReport';
import { parsePhotosAfterGet } from '../helpers/video';

export const getClientData = ({ clientId }) =>
  apiService({
    // use Date object for generating unique url to avoid requests caching
    url: `/qac/client-data/${clientId}?${new Date().getTime()}`,
    transformResponse: [
      (data) => {
        if (data.applications)
          data.applications.data = parseAppReportAfterGet(
            data.applications.data
          );
        if (data.photos) data.photos = parsePhotosAfterGet(data.photos);
        return data;
      },
    ],
  });
