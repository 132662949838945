import { useState } from 'react';

const useCreateCallReportDialog = () => {
  const [showCreateCallReportDialog, setShowCreateCallReportDialog] =
    useState(false);
  const [callReportSelectedContact, setCallReportSelectedContact] =
    useState('');
  const [callReportClientId, setCallReportClientId] = useState('');

  return {
    showCreateCallReportDialog,
    setShowCreateCallReportDialog,
    callReportSelectedContact,
    setCallReportSelectedContact,
    callReportClientId,
    setCallReportClientId,
  };
};

export default useCreateCallReportDialog;
