import { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const DAY_FILTER_VALUES = {
  TODAY: 'day_filter_today',
  TOMORROW: 'day_filter_tomorrow',
  ALL: 'day_filter_all',
  DONE: 'day_filter_done',
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 18px 0 8px;

  .MuiToggleButtonGroup-root {
    border: none;
    background: #f2f2f2;
    border-radius: 8px;
    height: 32px;
    flex: 1;
    padding: 1px;
  }

  .MuiToggleButton-root {
    border-radius: 8px;
    font-size: 12px;
    color: #666666;
    text-transform: none;
    border: none;
    flex: 1;

    &.MuiToggleButton-sizeSmall {
      padding: 0;
    }
  }

  .MuiToggleButton-root.Mui-selected {
    color: #000000;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
`;

const DayFilter = ({ onChange }) => {
  const [dayFilter, setDayFilter] = useState(DAY_FILTER_VALUES.TODAY);

  const handleDayFilterChange = (event, dayFilter) => {
    if (dayFilter !== null) {
      setDayFilter(dayFilter || DAY_FILTER_VALUES.TODAY);
      onChange && onChange(dayFilter);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Container data-testid="day-filters">
        <ToggleButtonGroup
          exclusive
          value={dayFilter}
          onChange={handleDayFilterChange}
        >
          <ToggleButton size="small" value={DAY_FILTER_VALUES.TODAY}>
            {t(DAY_FILTER_VALUES.TODAY)}
          </ToggleButton>
          <ToggleButton size="small" value={DAY_FILTER_VALUES.ALL}>
            {t(DAY_FILTER_VALUES.ALL)}
          </ToggleButton>
          <ToggleButton size="small" value={DAY_FILTER_VALUES.DONE}>
            {t(DAY_FILTER_VALUES.DONE)}
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
    </>
  );
};

DayFilter.propTypes = {
  onChange: PropTypes.func,
};

export default DayFilter;
